import React, { useState } from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import store from '../reducers/reducers';
import { Redirect } from 'react-router-dom';
import { closeModal, processForm, processModalForm } from './Modal';
import { renderNotification } from '../utils/renderNotification';
import Datetime from 'react-datetime';
const MyCalendar = ({ onRangeChange, events }) => {
    const [redirect, setRedirect] = useState(false)
    var translations = store.getState().language.translations
    const localizer = momentLocalizer(moment)
    if (redirect) {
        return <Redirect to={{
            pathname: redirect
        }} />
    }
    return (<Calendar
        messages={{
            today: translations.returnToday,
            previous: translations.previous,
            next: translations.next,
            month: translations.month,
            week: translations.week,
            day: translations.day
        }}
        onDoubleClickEvent={(e) => setRedirect(e.goTo)}
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        onRangeChange={onRangeChange}
        eventPropGetter={event => ({ className: (event.completed ? ' finished' : '') })}
    /> );
}
export const MyWorkCalendar = ({ onRangeChange, events, setModal, notification, setNotification, getData }) => {
    var translations = store.getState().language.translations
    const localizer = momentLocalizer(moment)
    return (<Calendar
        messages={{
            today: translations.returnToday,
            previous: translations.previous,
            next: translations.next,
            month: translations.month,
            week: translations.week,
            day: translations.day
        }}
        defaultView={'week'}
        views={['week']}
        onDoubleClickEvent={(e) => setModal({
            title: <div className="aicenter title_inner"><i className="fas fa-pen-square"></i> <span>{translations.scheduleControl}</span></div>,
            careModal: true,
            id: 'my_work_calendar',
            inner:
                <form className='formContainer wrap' id='updateStatusWork' action='/api/adminp/user/updateStatusWork' method='POST' onSubmit={async (e) => { await processForm(e, translations.succAddNotification, translations.errAddNotification, setNotification); await getData(); closeModal(setNotification, setModal) }}>
                    <input type='hidden' name='id' value={e.edit.id} readOnly />
                    <input type='hidden' name='edited_start_time' value='' readOnly />
                    <input type='hidden' name='edited_end_time' value='' readOnly />
                    <div className="inputDiv fdiv50">
                        <h6>{translations.dateStart}</h6>
                        <Datetime defaultValue={new Date(e.edit.start)} onChange={(date) => { document.querySelector('#updateStatusWork input[name="edited_start_time"]').value = date.format() }} viewDate={new Date()} closeOnSelect={true} />
                    </div>
                    <div className="inputDiv fdiv50">
                        <h6>{translations.dateEnd}</h6>
                        <Datetime defaultValue={new Date(e.edit.end)} viewDate={new Date()} onChange={(date) => { document.querySelector('#updateStatusWork input[name="edited_end_time"]').value = date.format() }}  closeOnSelect={true}/>                        
                    </div>
                    <button id='submit' type='submit' className='notInclude' style={{ display: 'none' }}></button>
                </form>
            ,
            onAccept: () => processModalForm('updateStatusWork'),
            notification: renderNotification(notification),
            onCancel: () => closeModal(setNotification, setModal)
        })}
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        onRangeChange={onRangeChange}
        eventPropGetter={event => ({ className: (event.completed ? ' finished' : '') })}
    /> );
}
 
export default MyCalendar;