import React, {useState, useEffect} from 'react';
export const IconDisplay = ({inner, icon, parentProps, button, cancelClick = false, myDisplay = false, mySetDisplay = false}) => {
    const [display, setDisplay] = useState(false)
    function closeOnClickOut() {
        if (mySetDisplay) {
            mySetDisplay(false)
        } else {
            setDisplay(false)
        }
        window.removeEventListener('click', bind)
    }
    const [bind] = useState(() => closeOnClickOut)
    const customSetDisplay = (tmpdisplay) => {
        if (mySetDisplay) {
            mySetDisplay(tmpdisplay)            
        } else {
            setDisplay(tmpdisplay)
        }
        if (!cancelClick) {
            if (tmpdisplay) {
                window.setTimeout(function () {
                    window.addEventListener('click', bind)
                }, 100)
            } else {
                window.removeEventListener('click', bind)
            }
        }
    }
    useEffect(() => {
        return () => {
            if (!cancelClick) {
                window.removeEventListener('click', bind)                
            }
        };
    }, [bind, cancelClick])
    let mysubdisplay = false
    if (myDisplay) {
        mysubdisplay = myDisplay
    } else {
        mysubdisplay = display
    }
    return (
        <div {...parentProps} className={((parentProps.className) ? parentProps.className + ' ': '') + 'iconDisplayer' + ((mysubdisplay) ? ' active' : '')}>
            {icon ? <i className={icon} onClick={() => customSetDisplay(!mysubdisplay)}></i> : <div className='iconDisplayerbutton' onClick={() => customSetDisplay(!mysubdisplay)} >{button}</div>}
            {(mysubdisplay) ? <div className='iconDisplayer_inner' onClick={(e) =>e.stopPropagation() }>{inner}</div> : ''}
        </div>
    )
}
export const ColorsFormated = ({onSelect, active}) => {
    var colors = [
        '#5e72e4',
        '#5603ad',
        '#8965e0',
        '#ce7389',
        '#f5365c',
        '#fb6340',
        '#fcc00a',
        '#11cdef',
        '#0fc493',
        '#222222'
    ]
    var colorsF = colors.map((item, key) => {
        return <div className={'color_selected' + ((item === active) ?  ' active' : '')} style={{background: item}} key={key} onClick={() => onSelect(item)}></div>
    })
    return (
        <div className='color_selector wrap'>
            {colorsF}
        </div>
    )
}