import React, { useState } from 'react';
import { fetchPostForm } from './../utils/forms'
import { renderNotification } from './../utils/renderNotification'
import store from '../reducers/reducers'
const InstallAdmin = ({updateStep}) => {
    var translations = store.getState().language.translations
    const [notification, setNotification] = useState(false)
    const fetchForm = async (e) => {
        if (e.target.elements.password.value === e.target.elements.repeatpassword.value){
            var response = await fetchPostForm(e, true)
            if (response === false) {
                setNotification({
                    type: 'error',
                    msg: translations.errRegister
                })
            } else {
                setNotification({
                    type: 'success',
                    msg: translations.succRegister
                })
                updateStep(3)
            }
        }else{
            e.preventDefault()
            setNotification({
                type: 'error',
                msg: translations.pwNotMatch
            })
        }
    }
    return (
    <div id='installAdminForm' className='formContainer'>
        <div className="formInner">
            <h3>{translations.createAdmin}</h3>
            {renderNotification(notification)}
            <form action='/api/install/createAdmin' method='post' onSubmit={fetchForm}>
                <div className="inputDiv">
                    <h6>{translations.username}</h6>
                    <input type='text' name='username' placeholder={translations.inUsername} required/>
                </div>
                <div className="inputDiv">
                    <h6>{translations.email}</h6>
                    <input type='email' name='email' placeholder={translations.inEmail} required/>
                </div>
                <div className="inputDiv">
                    <h6>{translations.name}</h6>
                    <input type='text' name='firstname' placeholder={translations.inName} required />
                </div>
                <div className="inputDiv">
                    <h6>{translations.firstsurname}</h6>
                    <input type='text' name='firstsurname' placeholder={translations.inFirstsurname} required />
                </div>
                <div className="inputDiv">
                    <h6>{translations.secondsurname}</h6>
                    <input type='text' name='secondsurname' placeholder={translations.inSecondsurname} />
                </div>
                <div className="inputDiv">
                    <h6>{translations.password}</h6>
                    <input type='password' name='password' placeholder={translations.inPassword} required/>
                    <input type='hidden' name='admin_permissions' value='1' />
                </div>
                <div className="inputDiv">
                    <h6>{translations.repeatPassword}</h6>
                    <input type='password' name='repeatpassword' className='notInclude' placeholder={translations.repeatPassword} required/>
                </div>
                <button type='submit' className='notInclude defaultButton'>{translations.continue}</button>
            </form>
        </div>
    </div>
    );
}
 
export default InstallAdmin;