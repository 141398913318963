import React, { useState, useEffect, useCallback } from 'react';
import store from '../reducers/reducers';
import { processData } from '../utils/specialdata';
import { IconDisplay } from './SpecialDisplayers';
import {Link} from 'react-router-dom'
const UserWork = () => {
    const [display, setDisplay] = useState(false)
    const [data, setData] = useState(null)
    const getNotifications = useCallback(async () => {
        let mydata = await fetch('/api/adminp/user/getStatusWork', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        mydata = await mydata.json()
        setData(mydata)
    }, [])
    useEffect(() => {
        if (display) {
            getNotifications()
        }
    }, [display, getNotifications])
    return (
        <IconDisplay inner={
            <UserWorkInner data={data} setData={setData} getNotifications={getNotifications} />
        } button={
            <div className='header iconDisplayerbutton'>
                <i className='far fa-clock'></i>
            </div>
        } parentProps={{ className: 'userwork action_header' }} myDisplay={display} mySetDisplay={(subd) => { setDisplay(subd); }} />
    )
}

export default UserWork;
export const UserWorkInner = ({ data, setData, getNotifications }) => {
    const changeData = async () => {
        setData(null)
        await fetch('/api/adminp/user/changeUserWork', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        await getNotifications()
    }
    var translations = store.getState().language.translations
    let mydata_rendered = ''
    if (data) {
        mydata_rendered = <div>
            <span>{translations.workingSince} {processData(data.og_start_time)}</span>
            <button className='red' onClick={changeData}>{translations.punchOut}</button>
        </div>
    } else if(data === false) {
        mydata_rendered = <div>
            <button className='green' onClick={changeData}>{translations.punchIn}</button>
        </div>
    }
    return (
        <div className='userwork_inner'>
            <h4>{translations.scheduleControl}</h4>
            {mydata_rendered}
            <Link to={'/punchIn/' + store.getState().user.id}>{translations.myCalendar}</Link>
        </div>
    )
}