import React, {useEffect, useState} from 'react';
import { returnNotifiPostForm } from '../utils/forms';
import { renderNotification } from '../utils/renderNotification';
import store from '../reducers/reducers';
const Modal = ({id, title, inner, onAccept, onCancel, careModal=false, notification}) => {
    var translations = store.getState().language.translations
    const [active, setActive] = useState(false)
    function listenEnter(key) {
        if(key.keyCode === 13) {
            // onAccept()
            // listenEventEnter(true)
        }else if(key.keyCode === 27){
            closeModal()
        }
    }
    const [eventEnter] = useState(() => listenEnter)
    const listenEventEnter = (remove = false) => {
        if (remove) {
            window.removeEventListener('keydown', eventEnter);
        } else {
            window.addEventListener('keydown', eventEnter)
        }
    }
    const closeModal = () => {
        setActive('no')
        window.setTimeout(function(){
            onCancel()
        },299)
        listenEventEnter(true)
    }
    const preventCloseModal = (event) => {
        event.stopPropagation()
    }
    useEffect(() => {
        if(!active){
            window.setTimeout(function(){
                setActive(true)
                listenEventEnter()
            },10)
        }
    })
    return ( 
    <div className={'modal' +((careModal) ? ' careModal' : '') + ((active === true) ? ' active ' : '')} id={id} onClick={closeModal}>
        <div className="modal_inner" onClick={preventCloseModal}>
            <div className='modal_header aicenter'>
                <div className='modal_title'>
                    {title}
                </div>
                <div className='modal_close' onClick={onCancel}>
                    <i className='far fa-times'></i>
                </div>
            </div>
            <div className="modal_body">
                {notification}
                {inner}
            </div>
            <div className='modal_footer aicenter'>
                <div className='cancel_modal' onClick={onCancel}>{translations.cancel}</div>
                <div className='accept_modal' onClick={onAccept}>{translations.accept}</div>
            </div>
        </div>
    </div> );
}
export default Modal;
export const closeModal = (setNotification = false, setModal) => {
    if(setNotification){
        setNotification(false)
    }
    setModal(false)
}
export const processForm = async (e, positiveMessage, negativeMessage, setNotification, antiClose = false) => {
    var notification = await returnNotifiPostForm(e, positiveMessage, negativeMessage)
    if(antiClose){
        notification.antiClose = true
    }
    setNotification(notification)
}
export const processModalForm = async (name) => {
    document.forms[name].submit.click()
}
export const renderModal = (modal, notification, setNotification, setModal) => {
    var renderModal = ''
    if (modal) {
        if (notification && notification.type !== 'error' && !notification.antiClose) {
            window.setTimeout(function () {
                closeModal(setNotification, setModal)
            }, 1000)
        }else if(notification.antiClose){
            window.setTimeout(function () {
                setNotification(false)
            }, 2000)
        }
        modal.notification = renderNotification(notification)
        renderModal = <Modal {...modal} />
    }
    return renderModal
}