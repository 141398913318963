import React, { useEffect, useState, useCallback } from 'react';  
import { Checkbox, CustomSelect, PhotoInputSearcher } from '../../minicomponents/Form';
import Datetime from 'react-datetime';
import { ProjectSearcher } from '../../minicomponents/Tasks';
import store, { changePage } from '../../reducers/reducers';
import { searchUser, searchTeam, searchCustomers } from '../../utils/forms';
import Table from '../../minicomponents/Table';
import { closeModal, processForm, processModalForm, renderModal } from '../../minicomponents/Modal';
import { renderNotification } from '../../utils/renderNotification';
import { processData } from '../../utils/specialdata';
import Tabs from '../../minicomponents/Tabs';
import Chart from 'react-apexcharts';
import { millisecondsToFormat } from '@teinorsa/react-timeinput'
import { CSVLink } from "react-csv";

const AdminStats = () => {
    const translations = store.getState().language.translations
    const [table, setTable] = useState(false)
    const [modal, setModal] = useState(false)
    const [notification, setNotification] = useState(false)
    const [filters, setFilters] = useState({
        users: [],
        teams: [],
        customers: [],
        projects: [],
        dateStart: false, 
        dateEnd: false,
        inputHours: '2',
        inputHoursBdH: '2',
        finished: '2', 
        searchUser: false
    })
    const [fields, setFields] = useState({
        finisheds: false,
        id: false,
        name: false,
        project: false,
        time: false,
        createdDate: false,
        finishedDate: false,
        user: false,
        team: false,
        description: false,
        comments: false,
        groupSubtasks: false
    })
    const deleteItem = useCallback((id, from) => {
        for (let key in filters[from]) {
            if (filters[from][key].id === id) {
                filters[from].splice(key, 1)
                break
            }
        }
        setFilters(() => {
            return {
                ...filters
            }
        })
    }, [filters])
    const resetFilters = () => {
        setFilters({
            users: [],
            teams: [],
            customers: [],
            projects: [],
            dateStart: false,
            dateEnd: false,
            inputHours: '2',
            inputHoursBdH: '2',
            finished: '2',
            searchUser: false,
        })
        setFields({
            finisheds: false,
            id: false,
            name: false,
            project: false,
            time: false,
            createdDate: false,
            finishedDate: false,
            user: false,
            team: false,
            description: false,
            comments: false,
            groupSubtasks: false
        })
        setTable(false)
    }
    const extractIds = (data) => {
        let mydata = []
        for (let item of data) {
            mydata.push(item.id)
        }
        return mydata
    }
    const parseData = useCallback(() => {
        return {
            ...filters,
            users: extractIds(filters.users),
            teams: extractIds(filters.teams),
            customers: extractIds(filters.customers),
            projects: extractIds(filters.projects),
        }
    }, [filters])
    const saveFilters = () => {
        setModal({
            title: <div className="aicenter title_inner"><i className='fas fa-plus-square'></i> <span>{translations.addFilter}</span></div>,
            id: 'create_filter',
            inner:
                <form className='formContainer wrap' id='createFilter' action='/api/adminp/adminFilters/create' method='POST' onSubmit={(e) => processForm(e, translations.succAddFilter, translations.errAddFilter, setNotification)}>
                    <div className='inputDiv fdiv80'>
                        <h6>{translations.name}</h6>
                        <input type='text' name='filter_title' placeholder={translations.inName} required />
                        <input type='hidden' name='filter_filters' value={JSON.stringify(parseData())} />
                        <input type='hidden' name='filter_fields' value={JSON.stringify(fields)} />
                        <input type='hidden' name='UserId' value={store.getState().user.id} />
                        <Checkbox parentProps={{ className: 'inputDiv checkboxparent' }} text={translations.isPublic} checkboxProps={{ id: 'isPublic', name: 'isPublic' }} />
                    </div>
                    <button id='submit' type='submit' className='notInclude' style={{ display: 'none' }}></button>
                </form>
            ,
            onAccept: () => processModalForm('createFilter'),
            notification: renderNotification(notification),
            onCancel: () => closeModal(setNotification, setModal)
        })
    }
    const mySearch = async (value, func, current) => {
        let result = await func(value)
        for (let key in result) {
            for (let subitem of current) {
                if (result[key].id === subitem.id) {
                    result.splice(key,1)
                }
            }
        }
        return result
    }

    const updateUser = (user, userItem) => {
        setFilters((olddata) => {
                return {
                    ...olddata,
                    users: [...olddata.users, userItem]
                }
        })
    }
    const updateTeam = (team, teamItem) => {
        setFilters((olddata) => {
            return {
                ...olddata, 
                teams: [...olddata.teams, teamItem ]
            }
        })
    }
    const updateCustomer = (customer, customerItem) => {
        setFilters((olddata) => {
            return {
                ...olddata, 
                customers: [...olddata.customers, customerItem ]
            }
        })
    }
    const updateProject = (id, data) => {
        data.customer_name = data.Customer.customer_name
        setFilters((olddata) => {
            return {
                ...olddata,
                projects: [...olddata.projects, data]
            }
        })
    }
    const updateData = (data, from) => {
        setFilters((olddata) => {
            olddata[from] = data
            return {
                ...olddata,
            }
        })
    }
    const updateCheckbox = (e, from) => {
        let value = e.currentTarget.checked
        setFields((olddata) => {
            olddata[from] = value
            return {
                ...olddata
            }
        })
    }
    const manageFilters = async () => {
        let result = await fetch('/api/adminp/adminFilters/findAll', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            }
        })
        result = await result.json()
        let myFilters = {
            private: [],
            public: []
        }
        for (let item of result) {
            if (item.UserId) {
                 myFilters.private.push(item)
            } else {
                myFilters.public.push(item)
             }
        }

        setModal({
            title: <div className="aicenter title_inner"><i className='fas fa-plus-square'></i> <span>{translations.manageFilters}</span></div>,
            id: 'create_filter',
            inner:
                <div>
                        {(!myFilters.private.length && !myFilters.public.length) || myFilters.private.length
                        ?
                        <div className='delete_filters_container mb20'>
                            <h4 className='mb10 '>
                                    {!myFilters.private.length && !myFilters.public.length ? translations.noFilters : myFilters.private.length ? translations.listFilters  : ''}
                            </h4>
                        <DeleteFilters filters={myFilters.private} refreshModal={manageFilters} />
                    </div>
                    : null  }
                    {myFilters.public.length ? <div className='delete_filters_container'>
                        <h4 className='mb10 '>{translations.listPublicFilters}</h4>
                        <DeleteFilters filters={myFilters.public} refreshModal={manageFilters} />
                    </div> : null }
                </div>,
            onAccept: () => closeModal(setNotification, setModal),
            notification: renderNotification(notification),
            onCancel: () => closeModal(setNotification, setModal)
        })
    }
    const changeData = async (id) => {
        let mydata = await fetch('/api/adminp/adminFilters/findOne', {
            method: 'POST',
            headers: {
                'Content-Type' : 'application/json',
            },
            body: JSON.stringify({
                id
            })
        })
        mydata = await mydata.json()
        if (mydata.filter_filters.projects.length) {
            for (let item of mydata.filter_filters.projects) {
                item.customer_name = item.Customer.customer_name
            }
        }
        if (mydata.filter_filters.dateStart) {
            mydata.filter_filters.dateStart = new Date(mydata.filter_filters.dateStart)
        }
        if (mydata.filter_filters.dateEnd) {
            mydata.filter_filters.dateEnd = new Date(mydata.filter_filters.dateEnd)
        }
        setFilters({
            ...mydata.filter_filters,
        })

        setFields({
            ...mydata.filter_fields
        })
    }
    useEffect(() => {
        changePage({
            name: 'adminstats',
        })
    }, [])
    if (table) {
        return <StatsTable fields={fields} filters={parseData()} resetFilters={resetFilters} />
    }
    return (<div className='adminstats wrap'>
        <div className='box fdiv100 mrauto'>
            <div className='box_inner'>
                <div className='box_header'>
                    <button className='btn_primary reset_filters' onClick={resetFilters}>
                        <i className='far fa-redo'></i>
                        <span>{translations.reset}</span>
                    </button>
                    <button className='btn_primary' onClick={saveFilters}>
                        <i className='fas fa-save'></i>
                        <span>{translations.save}</span>
                    </button>
                    <button className='btn_primary' onClick={manageFilters}>
                        <i className='fas fa-cog'></i>
                        <span>{translations.manageFilters}</span>
                    </button>
                    <ListFilters getDataFrom='/api/adminp/adminFilters/findByUser' text={translations.listFilters} sendDataTo={changeData}/>
                    <ListFilters getDataFrom='/api/adminp/adminFilters/findPublicAdminFilters' text={translations.listPublicFilters} sendDataTo={changeData}/>
                    <button className='btn_primary reset_filters mlauto' onClick={() => setTable(true)}>
                        <i className='far fa-search'></i>
                        <span>{translations.filter}</span>
                    </button>
                </div>
                <div className='box_content'>
                    <div className='wrap adminstats_list'>
                        <div className='adminstats_filters box fdiv50'>
                            <div className='box_inner'>
                                <div className='box_header'>
                                    <h3>{translations.filters}</h3>
                                </div>
                                <div className='box_content wrap'>
                                    <div className='fdiv50'>
                                        <h4 className='mb10'>{translations.users}</h4>
                                        <PhotoInputSearcher fieldtoshow='username' pathImg='/userImg/' updateData={updateUser} getDataFrom={(value) => mySearch(value, searchUser, filters.users)} data={{ image: undefined, text: undefined }} icon='fal fa-user' />
                                        <ListItems items={filters.users} name='username' func={deleteItem} from={'users'}/>
                                    </div>
                                    <div className='fdiv50'>
                                        <h4 className='mb10'>{translations.teams}</h4>
                                        <PhotoInputSearcher fieldtoshow='name' pathImg='/teamImg/' updateData={updateTeam} getDataFrom={(value)=>mySearch(value, searchTeam, filters.teams)} data={{ image: undefined, text: undefined }} icon='fal fa-users' />
                                        <ListItems items={filters.teams} name='name' func={deleteItem} from={'teams'} />
                                    </div>
                                    <div className='fdiv50'>
                                        <h4 className='mb10'>{translations.customers}</h4>
                                        <PhotoInputSearcher justText={true} fieldtoshow='customer_name' updateData={updateCustomer} getDataFrom={(value)=>mySearch(value, searchCustomers, filters.customers)} data={{ image: undefined, text: undefined }} icon='fal fa-users' />
                                        <ListItems items={filters.customers} name='customer_name' func={deleteItem} from={'customers'} />
                                    </div>
                                    <div className='fdiv50'>
                                        <h4 className='mb10'>{translations.projects}</h4>
                                        <ProjectSearcher onchoose={updateProject} currentProjects={[]} helper={true} />
                                        <ListItems items={filters.projects} name={['customer_name', 'project_name']} func={deleteItem} from={'projects'} />
                                    </div>
                                    <div className='fdiv50'>
                                        <h4 className='mb10'>{translations.dateStart}</h4>
                                        <div className='photoinput aicenter'>
                                            <div className='photoinput_logo aicenter tacenter'>
                                                <i className='fal fa-calendar-alt'></i>
                                            </div>
                                            <div className='photoinput_text aicenter tacenter'>
                                                <Datetime value={filters.dateStart} viewDate={new Date()} closeOnSelect={true} inputProps={{ placeholder: translations.notAssigned }} onChange={(date) => updateData(date, 'dateStart')} />
                                                {filters.dateStart ? <i className="far fa-times" onClick={() => updateData(false, 'dateStart')}></i> : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='fdiv50'>
                                        <h4 className='mb10'>{translations.dateEnd}</h4>
                                        <div className='photoinput aicenter'>
                                            <div className='photoinput_logo aicenter tacenter'>
                                                <i className='fal fa-calendar-alt'></i>
                                            </div>
                                            <div className='photoinput_text aicenter tacenter'>
                                                <Datetime value={filters.dateEnd} viewDate={new Date()} closeOnSelect={true} inputProps={{ placeholder: translations.notAssigned }} onChange={(date) => updateData(date, 'dateEnd')} />
                                                {filters.dateEnd ? <i className="far fa-times" onClick={() => updateData(false, 'dateEnd')}></i> : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='fdiv50'>
                                        <h4 className='mb10'>{translations.inputHours}</h4>
                                        <CustomSelect fixedValue={true} selectProps={{
                                            value: filters.inputHours,
                                            onChange: (value) => updateData(value, 'inputHours'),
                                            id: 'inputHours'
                                        }}
                                        options={[
                                            {
                                                value: '2',
                                                text: translations.withandnotinputHours
                                            },
                                            {
                                                value: '1',
                                                text: translations.inputHours
                                            },
                                            {
                                                value: '0',
                                                text: translations.noinputHours
                                            },
                                            ]} />
                                    </div>
                                    <div className='fdiv50'>
                                        <h4 className='mb10'>{translations.inputHoursBdH}</h4>
                                        <CustomSelect fixedValue={true} selectProps={{
                                            value: filters.inputHoursBdH,
                                            onChange: (value) => updateData(value, 'inputHoursBdH'),
                                            id: 'inputHoursBdH'
                                        }}
                                            options={[
                                                {
                                                    value: '2',
                                                    text: translations.withandnotinputHoursBdH
                                                },
                                                {
                                                    value: '1',
                                                    text: translations.inputHoursBdH
                                                },
                                                {
                                                    value: '0',
                                                    text: translations.noinputHoursBdH
                                                },
                                            ]} />
                                    </div>
                                    <div className='fdiv50'>
                                        <h4 className='mb10'>{translations.finisheds}</h4>
                                        <CustomSelect fixedValue={true} selectProps={{
                                            value: filters.finished,
                                            onChange: (value) => updateData(value, 'finished'),
                                            id: 'finished'
                                        }}
                                            options={[
                                                {
                                                    value: '2',
                                                    text: translations.finishedsAndNot
                                                },
                                                {
                                                    value: '1',
                                                    text: translations.finisheds
                                                },
                                                {
                                                    value: '0',
                                                    text: translations.notFinisheds
                                                },
                                            ]} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='adminstats_fields box fdiv50'>
                            <div className='box_inner'>
                                <div className='box_header'>
                                    <h3>{translations.fields}</h3>
                                </div>
                                <div className='box_content'>
                                    <Checkbox parentProps={{ className: 'inputDiv checkboxparent' }} text='ID' checkboxProps={{ checked: fields.id, id: 'id', onChange: (e) => updateCheckbox(e, 'id')}} />
                                    <Checkbox parentProps={{ className: 'inputDiv checkboxparent' }} text={translations.taskName} checkboxProps={{ checked: fields.name ,id: 'name', onChange: (e) => updateCheckbox(e, 'name') }} />
                                    <Checkbox parentProps={{ className: 'inputDiv checkboxparent' }} text={translations.project} checkboxProps={{ checked: fields.project, id: 'project', onChange: (e) => updateCheckbox(e, 'project') }} />
                                    <Checkbox parentProps={{ className: 'inputDiv checkboxparent' }} text={translations.time} checkboxProps={{ checked: fields.time, id: 'time', onChange: (e) => updateCheckbox(e, 'time') }} />
                                    <Checkbox parentProps={{ className: 'inputDiv checkboxparent' }} text={translations.createdDate} checkboxProps={{ checked: fields.createdDate, id: 'createdDate', onChange: (e) => updateCheckbox(e, 'createdDate') }} />
                                    <Checkbox parentProps={{ className: 'inputDiv checkboxparent' }} text={translations.finishedDate} checkboxProps={{ checked: fields.finishedDate, id: 'finishedDate', onChange: (e) => updateCheckbox(e, 'finishedDate') }} />
                                    <Checkbox parentProps={{ className: 'inputDiv checkboxparent' }} text={translations.user} checkboxProps={{ checked: fields.user, id: 'user', onChange: (e) => updateCheckbox(e, 'user') }} />
                                    <Checkbox parentProps={{ className: 'inputDiv checkboxparent' }} text={translations.team} checkboxProps={{ checked: fields.team, id: 'team', onChange: (e) => updateCheckbox(e, 'team')}} />
                                    <Checkbox parentProps={{ className: 'inputDiv checkboxparent' }} text={translations.description} checkboxProps={{ checked: fields.description, id: 'description', onChange: (e) => updateCheckbox(e, 'description') }} />
                                    <Checkbox parentProps={{ className: 'inputDiv checkboxparent' }} text={translations.comments} checkboxProps={{ checked: fields.comments, id: 'comments', onChange: (e) => updateCheckbox(e, 'comments') }} />
                                    <Checkbox parentProps={{ className: 'inputDiv checkboxparent' }} text={translations.finished} checkboxProps={{ checked: fields.finisheds, id: 'finisheds', onChange: (e) => updateCheckbox(e, 'finisheds') }} />
                                    <Checkbox parentProps={{ className: 'inputDiv checkboxparent' }} text={translations.subtasks} checkboxProps={{ checked: fields.groupSubtasks, id: 'groupSubtasks', onChange: (e) => updateCheckbox(e, 'groupSubtasks') }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {renderModal(modal, notification, setNotification, setModal)}
    </div>
    );
}
const ListItems = ({ items, name, func, from }) => {

    if (!items.length) {
        return null 
    }
    let renderThis = items.map((item, k) => {
        let myname = ''
        if (Array.isArray(name)) {
            myname = []
            for (let newname of name) {
                myname.push(item[newname])
            }
           myname = myname.join(' > ')
        } else {
            myname = item[name]
        }
        return <div className='item' key={k}>{myname} <i className="far fa-times" onClick={()=> func(item.id, from)}></i></div>
    })
    return (
        <div className='list_items'>
            {renderThis}
        </div>
    )
}

const ListFilters = ({ getDataFrom, sendDataTo, text }) => {
    const [opened, setOpened] = useState(false)
    const [newFilters, setNewFilters] = useState([])
    const getFilters = useCallback(async () => {
        var result = await fetch(getDataFrom, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        result = await result.json()
        setNewFilters(result)
    }, [getDataFrom])
    const updateData = (data) => {
        setOpened(false)
        sendDataTo(data)
    }
    let options = []
    if (newFilters.length && opened) {
        for (let item of newFilters) {
            options.push({
                text: item.filter_title,
                value: item.id
            })
        }
    } else {
        options = [{
            text,
            value: 0
        }]
    }
    useEffect(() => {
        if (opened) {
            getFilters()
        }
    }, [opened, getFilters])
    return (
        <CustomSelect selectProps={{ onChange: updateData, id: 'filshed' }} options={options} onOpen={setOpened} />
    )
}
const DeleteFilters = ({ filters, refreshModal }) => {
    const deleteFilter = async (id) => {
        await fetch('/api/adminp/adminFilters/delete', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                id
            })
        })
        refreshModal()
    }
    let renderThis = ''
    if (filters.length) {
        renderThis = filters.map((item, key) => {
            return (<div className='delete_filter fdiv50 bxsh p1em aicenter' key={key}>
                <span>{item.filter_title}</span>
                <i className='fal fa-trash-alt crpointer mlauto' onClick={()=>deleteFilter(item.id)} />
            </div>)
        })
    }
    return (<div className='delete_filters_list wrap'>
        {renderThis}
    </div>)
}
const filterData = (data) => {
    let myIDS = []
    for (let key in data) {
        let item = data[key]
        if (item.id) {
            if (item.TaskId && myIDS.includes(item.TaskId)) {
                delete data[key]
                continue
            }
            myIDS.push(item.id)
        }
    }
    return data
}
const sortByDate = (dates) => {
    let repeat = false
    for (let i = 0; i < dates.length - 1; i++){
        let split = dates[i].split('-')
        let year = parseInt(split[1])
        let month = parseInt(split[0])
        split = dates[i+1].split('-')
        let year2 = parseInt(split[1])
        let month2 = parseInt(split[0])
        if (year > year2 || (year === year2 && month > month2)) {
            let aux = dates[i + 1]
            dates[i + 1] = dates[i]
            dates[i] = aux
            repeat = true
        }
    }
    if (repeat) {
        return sortByDate(dates)
    }
    return dates
}
const filterUpgradeData = (data) => {
    let myIDS = []
    let statsIndex = {
        taskStats: {
            completed: 0,
            total: 0,
            invertedTime: 0
        },
        userStats: {},
        teamStats: {},
        projectStats: {},
        customerStats:{},
        timeStats: {}, 
        tasksMonthStats: {}
    }
    for (let key in data) {
        let item = data[key]
        if (item.id) {
            if (item.TaskId && myIDS.includes(item.TaskId)) {
                continue
            }
            statsIndex.taskStats.total = statsIndex.taskStats.total + 1
            myIDS.push(item.id)
            if (item.UserId) {
                if (statsIndex.userStats[item.UserId]) {
                    statsIndex.userStats[item.UserId].quantity = statsIndex.userStats[item.UserId].quantity + 1
                } else {
                    statsIndex.userStats[item.UserId] = {
                        label: item.User.username,
                        quantity: 1
                    }
                }
            }
            if (item.TeamId) {
                if (statsIndex.teamStats[item.TeamId]) {
                    statsIndex.teamStats[item.TeamId].quantity = statsIndex.teamStats[item.TeamId].quantity  + 1
                } else {
                    statsIndex.teamStats[item.TeamId] = {
                        label: item.Team.name,
                        quantity: 1
                    }
                }
            }
            if (item.Projects.length) {
                // Customer
                if (statsIndex.customerStats[item.Projects[0].Customer.id]) {
                    statsIndex.customerStats[item.Projects[0].Customer.id].quantity = statsIndex.customerStats[item.Projects[0].Customer.id].quantity + 1
                } else {
                    statsIndex.customerStats[item.Projects[0].Customer.id] = {
                        label: item.Projects[0].Customer.customer_name,
                        quantity: 1
                    }
                }
                // Project
                if (statsIndex.projectStats[item.Projects[0].id]) {
                    statsIndex.projectStats[item.Projects[0].id].quantity = statsIndex.projectStats[item.Projects[0].id].quantity + 1
                } else {
                    statsIndex.projectStats[item.Projects[0].id] = {
                        label: item.Projects[0].Customer.customer_name + ' > ' + item.Projects[0].project_name,
                        quantity: 1
                    }
                }
            }

            //tasksMonthStats
            let myCreateDate = new Date(item.createdAt)
            myCreateDate = ('0' + (myCreateDate.getMonth()+1)).slice(-2) + '-' + myCreateDate.getFullYear()
            if (statsIndex.tasksMonthStats[myCreateDate]) {
                statsIndex.tasksMonthStats[myCreateDate].createdTasks = statsIndex.tasksMonthStats[myCreateDate].createdTasks +1
            } else {
                statsIndex.tasksMonthStats[myCreateDate] = {
                    createdTasks: 1,
                    finishedTasks: 0,
                    time: 0
                }
            }
            if (item.TaskLogs.length) {
                statsIndex.taskStats.completed = statsIndex.taskStats.completed + 1
                let myFinishedDate = new Date(item.TaskLogs[0].updatedAt)
                myFinishedDate = ('0' + (myFinishedDate.getMonth()+1)).slice(-2) + '-' + myFinishedDate.getFullYear()
                if (statsIndex.tasksMonthStats[myFinishedDate]) {
                    statsIndex.tasksMonthStats[myFinishedDate].finishedTasks = statsIndex.tasksMonthStats[myFinishedDate].finishedTasks +1
                } else {
                    statsIndex.tasksMonthStats[myFinishedDate] = {
                        createdTasks: 0,
                        finishedTasks: 1,
                        time: 0
                    }
                }
            }
            if (item.BagsLog && item.BagsLog.length) {
                item.BagsLog.forEach((bagLog) => {
                    let dateStart = new Date(bagLog.baglog_startdate)
                    let dateFinish = new Date(bagLog.baglog_finishdate)
                    let calc = (dateFinish - dateStart)
                    let format = formatDataToTime(calc)
                    let split = format.split(':')
                    let hours = parseInt(split[0])
                    hours += (parseInt(split[1])) / 60
                    hours += (parseInt(split[2])) / 60 / 60
                    hours = parseFloat(hours.toFixed(2))
                    dateStart = ('0' + (dateStart.getMonth() + 1)).slice(-2) + '-' + dateStart.getFullYear()
                    statsIndex.taskStats.invertedTime = statsIndex.taskStats.invertedTime + hours
                    if (statsIndex.tasksMonthStats[dateStart]) {
                        statsIndex.tasksMonthStats[dateStart].time = parseFloat((statsIndex.tasksMonthStats[dateStart].time + hours).toFixed(2))
                    } else {
                        statsIndex.tasksMonthStats[dateStart] = {
                            createdTasks: 0,
                            finishedTasks: 0,
                            time: hours
                        }
                    }
                })
            }
           
            
            if (item.childs) {
                for (let child of item.childs) {
                    statsIndex.taskStats.total = statsIndex.taskStats.total + 1
                    if (child.UserId) {
                        if (statsIndex.userStats[child.UserId]) {
                            statsIndex.userStats[child.UserId].quantity = statsIndex.userStats[child.UserId].quantity + 1
                        } else {
                            statsIndex.userStats[child.UserId] = {
                                label: child.User.username,
                                quantity: 1
                            }
                        }
                    } else if( item.UserId) {
                        statsIndex.userStats[item.UserId].quantity = statsIndex.userStats[item.UserId].quantity + 1
                    }
                    if (child.TeamId) {
                        if (statsIndex.teamStats[child.TeamId]) {
                            statsIndex.teamStats[child.TeamId].quantity = statsIndex.teamStats[child.TeamId].quantity + 1
                        } else {
                            statsIndex.teamStats[child.TeamId] = {
                                label: child.Team.name,
                                quantity: 1
                            }
                        }
                    } else if (item.TeamId) {
                        statsIndex.teamStats[item.TeamId].quantity = statsIndex.teamStats[item.TeamId].quantity + 1
                    }
                    if (child.Projects.length) {
                        // Customer
                        if (statsIndex.customerStats[child.Projects[0].Customer.id]) {
                            statsIndex.customerStats[child.Projects[0].Customer.id].quantity = statsIndex.customerStats[child.Projects[0].Customer.id].quantity + 1
                        } else {
                            statsIndex.customerStats[child.Projects[0].Customer.id] = {
                                label: child.Projects[0].Customer.customer_name,
                                quantity: 1
                            }
                        }
                        // Project
                        if (statsIndex.projectStats[child.Projects[0].id]) {
                            statsIndex.projectStats[child.Projects[0].id].quantity = statsIndex.projectStats[child.Projects[0].id].quantity + 1
                        } else {
                            statsIndex.projectStats[child.Projects[0].id] = {
                                label: child.Projects[0].Customer.customer_name + ' > ' + child.Projects[0].project_name,
                                quantity: 1
                            }
                        }
                    } else if (item.Projects && item.Projects.length) {
                        // Customer
                        statsIndex.customerStats[item.Projects[0].Customer.id].quantity = statsIndex.customerStats[item.Projects[0].Customer.id].quantity + 1
                        // Project
                        statsIndex.projectStats[item.Projects[0].id].quantity = statsIndex.projectStats[item.Projects[0].id].quantity + 1
                    }
                    let myChildCreateDate = new Date(child.createdAt)
                    myChildCreateDate = ('0' + (myChildCreateDate.getMonth()+1)).slice(-2) + '-' + myChildCreateDate.getFullYear()
                    if (statsIndex.tasksMonthStats[myChildCreateDate]) {
                        statsIndex.tasksMonthStats[myChildCreateDate].createdTasks = statsIndex.tasksMonthStats[myChildCreateDate].createdTasks + 1
                    } else {
                        statsIndex.tasksMonthStats[myChildCreateDate] = {
                            createdTasks: 1,
                            finishedTasks: 0,
                            time: 0
                        }
                    }

                    if (child.TaskLogs.length) {
                        statsIndex.taskStats.completed = statsIndex.taskStats.completed + 1
                        let myFinishedChildData = new Date(child.TaskLogs[0].updatedAt)
                        myFinishedChildData = ('0' + (myFinishedChildData.getMonth()+1)).slice(-2) + '-' + myFinishedChildData.getFullYear()
                        if (statsIndex.tasksMonthStats[myFinishedChildData]) {
                            statsIndex.tasksMonthStats[myFinishedChildData].finishedTasks = statsIndex.tasksMonthStats[myFinishedChildData].finishedTasks + 1
                        } else {
                            statsIndex.tasksMonthStats[myFinishedChildData] = {
                                createdTasks: 0,
                                finishedTasks: 1,
                                time: 0
                            }
                        }
                    }
                    if (child.BagsLog && child.BagsLog.length) {
                        child.BagsLog.forEach((bagLog) => {
                            let dateStart = new Date(bagLog.baglog_startdate)
                            let dateFinish = new Date(bagLog.baglog_finishdate)
                            let calc = (dateFinish - dateStart)
                            let format = formatDataToTime(calc)
                            let split = format.split(':')
                            let hours = parseInt(split[0])
                            hours += (parseInt(split[1])) / 60
                            hours += (parseInt(split[2])) / 60 / 60
                            hours = parseFloat(hours.toFixed(2))
                            statsIndex.taskStats.invertedTime = statsIndex.taskStats.invertedTime + hours
                            dateStart = ('0' + (dateStart.getMonth() + 1)).slice(-2) + '-' + dateStart.getFullYear()
                            if (statsIndex.tasksMonthStats[dateStart]) {
                                statsIndex.tasksMonthStats[dateStart].time = parseFloat((statsIndex.tasksMonthStats[dateStart].time + hours).toFixed(2))
                            } else {
                                statsIndex.tasksMonthStats[dateStart] = {
                                    createdTasks: 0,
                                    finishedTasks: 0,
                                    time: hours
                                }
                            }
                        })
                    }
                }
            }
        }
    }
    statsIndex.taskStats.invertedTime = parseFloat(statsIndex.taskStats.invertedTime.toFixed(2))
    if (Object.keys(statsIndex.tasksMonthStats) && Object.keys(statsIndex.tasksMonthStats).length) {
        let myOrder = sortByDate(Object.keys(statsIndex.tasksMonthStats))
        let newStatsIndex = {}
        for (let date of myOrder) {
            newStatsIndex[date] = statsIndex.tasksMonthStats[date]
        }
        statsIndex.tasksMonthStats = newStatsIndex
    }
    return statsIndex
}

const filterChartData = (from, data, translation) => {
    let mydata = data[from]
    let result = {
        series:  [
        ],
        options: {
            chart: {
                id: `${from}-bar`,
                type: 'pie'
            },
            xaxis: {
                categories: [translation]
            },
            labels: []
        },
    }
    if (mydata) {
        let i = 0;
        for (let key in mydata) {
            let item = mydata[key]
            result.options.labels.push(item.label)
            result.series[i] = item.quantity
            i++
        }
    }
    return result
}

const formatDataToTime = (it) => {
    var hours = Math.floor(it / 1000 / 60 / 60)
    var minutes = 0
    var seconds = 0
    it -= hours * 1000 * 60 * 60
    if (it > 0) {
        minutes = Math.floor(it / 1000 / 60)
        it -= minutes * 1000 * 60
        if (it > 0) {
            seconds = Math.floor(it / 1000)
        }
    }
    hours = ('0' + hours).slice(-2)
    minutes = ('0' + minutes).slice(-2)
    seconds = ('0' + seconds).slice(-2)
    var calctime = hours + ':' + minutes + ':' + seconds
    return calctime
}
const StatsTable = ({ fields, filters, resetFilters }) => {
    const [currentTab, setCurrentTab]= useState(1)
    const translations = store.getState().language.translations
    var tabOptions = [
        {
            text: translations.table,
            click: ()=> setCurrentTab(0),
            active: currentTab === 0 ? true : false ,
        },
        {
            text: translations.stats,
            click: () => setCurrentTab(1),
            active: currentTab === 1 ? true : false,
        }
    ]
    const indexFields = {
        id: {
            name: 'id',
            text: 'Nº'
        },
        name: {
            name: 'task_name',
            text: translations.taskName
        },
        project: {
            name: 'Projects.project_name',
            text: translations.project,
            variableResponse: (value, row) => row.Projects.length ? row.Projects[0].Customer.customer_name + " > " + row.Projects[0].project_name : undefined
        },
        time: {
            name: 'time',
            text: translations.time,
            variableResponse: (value, row) => { 
                var it = 0
                if (row.BagsLog && row.BagsLog.length) {
                    row.BagsLog.forEach((bagLog) => {
                        var dateStart = new Date(bagLog.baglog_startdate)
                        var dateFinish = new Date(bagLog.baglog_finishdate)
                        var calc = (dateFinish - dateStart)
                        it += calc
                    })
                    return formatDataToTime(it)
                }
            }
        },
        timesubtasks: {
            name: 'timesubtasks',
            text: translations.timeSubtasks,
            variableResponse: (value, row) => {
                var it = 0
                if (row.childs && row.childs.length) {
                    row.childs.forEach((child) => {
                        if (child.BagsLog && child.BagsLog.length) {
                            child.BagsLog.forEach((bagLog) => { 
                                var dateStart = new Date(bagLog.baglog_startdate)
                                var dateFinish = new Date(bagLog.baglog_finishdate)
                                var calc = (dateFinish - dateStart)
                                it += calc
                            })
                        }
                    })
                    return formatDataToTime(it)
                }
            }
        },
        createdDate: {
            name: 'createdAt',
            text: translations.createdDate,
            variableResponse: (value) => processData(value, false)
        },
        finishedDate: {
            name: 'TaskLogs.updatedAt',
            text: translations.finishedDate,
            variableResponse: (value, row) => row.TaskLogs.length ? processData(row.TaskLogs[0].updatedAt, false) : undefined
        },
        user: {
            name: 'User.username',
            text: translations.user,
            variableResponse: (value, row) => row.User ? row.User.username : undefined
        },
        team: {
            name: 'Team.name',
            text: translations.team,
            variableResponse: (value, row) => row.Team ? row.Team.name : undefined
        },
        description: {
            name: 'description',
            text: translations.description
        },
        comments: {
            name: 'comments',
            text: translations.comments,
            variableResponse: (value, row) => {
                if (row.TaskComments.length) {
                    let myComments = []
                    for (let item of row.TaskComments) {
                        myComments.push('- ' + (item.User ? item.User.username : '') + ' : ' +item.content )
                    }       
                    return myComments.join("\n")
                }
            }
        },
        groupSubtasks: {
            name: 'groupSubtasks',
            text: translations.subtasks,
            variableResponse: (value, row) => row.childs ? row.childs.length : 0
        },
        finisheds: {
            name: 'completed',
            text: '',
            variableResponse: (value) => {
                if (value) {
                    return <div className='completed aicenter tacenter active'><i className='fal fa-check-circle'></i></div>
                }
                return <div className='completed aicenter tacenter'><i className='fal fa-check-circle'></i></div>
            }
        }
    }
    const seeMore = useCallback(async (e, row) => {
        let task_id = e.currentTarget.getAttribute('idrow')
        let elem = document.getElementById('space_for_childs' + task_id)
        elem.classList.toggle('active')
        let icon = e.currentTarget.querySelector('i')
        if (icon.getAttribute('class').includes('down')) {
            icon.setAttribute('class', icon.getAttribute('class').replace('down', 'up'))
        } else {
            icon.setAttribute('class', icon.getAttribute('class').replace('up', 'down'))
        }
        if (!elem.classList.contains('active')) {
            return
        }
        let newFields = []
        for (var key in fields) {
            if (fields[key] === true && key !== 'comments' && key !== 'groupSubtasks') {
                newFields.push(indexFields[key])
            }
        }
        let result = [
            <TableSubtasksChildren key={'header'} fields={newFields} isHeader={true} />
        ]
        for (let key in row.childs) {
            let item = row.childs[key]
            result.push(<TableSubtasksChildren key={key} data={item} fields={newFields} />)
        }
        return result
    }, [fields, indexFields])
    let newFields = []
    for (var key in fields) {
        if (fields[key] === true) {
            newFields.push(indexFields[key]) 
            if (key === 'time') {
                newFields.push(indexFields['timesubtasks']) 
            }
        }        
    }
    let where = {}
    if (filters.customers.length) {
        where['$Projects.Customer.id$'] = filters.customers
    }
    if (filters.projects.length) {
        where['$Projects.id$'] = filters.projects
    }
    if (filters.users.length) {
        where.UserId = filters.users
    }
    if (filters.teams.length) {
        where.TeamId = filters.teams
    }
    if (filters.dateStart && filters.dateEnd) {
        where = {
            ...where,
            $or: {
                updatedAt: {
                    $between: [filters.dateStart, filters.dateEnd]
                },
                createdAt: {
                    $between: [filters.dateStart, filters.dateEnd]
                },
            },
        }
    } else if (filters.dateStart) {
        where = {
            ...where,
            $or: {
                updatedAt: {
                    $gte: filters.dateStart
                },
                createdAt: {
                    $gte: filters.dateStart
                },
            },
        }
    } else if (filters.dateEnd) {
        where = {
            ...where,
            $or: {
                updatedAt: {
                    $lte: filters.dateEnd
                },
                createdAt: {
                    $lte: filters.dateEnd
                },
            },
        }
    }
    
    if (parseInt(filters.finished) === 1) {
        where.finished = true
    } else if (parseInt(filters.finished) === 0) {
        where.finished = false
    }
    if (parseInt(filters.inputHours) === 1) {
        where.inputHours = true
    } else if (parseInt(filters.inputHours) === 0) {
        where.inputHours = false
    }
    if (parseInt(filters.inputHoursBdH) === 1) {
        where.inputHoursBdH = true
    } else if (parseInt(filters.inputHoursBdH) === 0) {
        where.inputHoursBdH = false
    }

    let table = {
        name: 'tasks',
        getDataFrom: {
            from: '/api/adminp/task/findAllTaskStats',
            method: 'POST',
            defaultValues: {
                where,
                order: ['id']
            },
            filterData
        },
        actionButtonsHeader: [
            {
                text: translations.return,
                className: 'btn_primary add',
                icon: 'far fa-chevron-left',
                action: () => resetFilters(),
                type: 'button'
            }
        ],
        actionButtonsTable: [
            {
                className: 'seemore',
                icon: 'far fa-chevron-down',
                action: seeMore,
                type: 'seeMore'
            },
            {
                className: 'edit',
                icon: 'fal fa-eye',
                action: '/admin/bagofhours/',
                type: 'link'
            }
        ],
        pagination: false,
        fields: newFields,
    }
    return (
        <div className='adminstats_in'>
            <Tabs tabOptions={tabOptions} display={currentTab === 0 ? <Table table={table} spaceForChilds={true} /> : <Stats fields={newFields} filters={filters} where={where} resetFilters={resetFilters} /> } button={true} />
        </div>
    )
}
export const TableSubtasksChildren = ({ data, isHeader = false, fields }) => {
    let myWidth = 100 / fields.length
    if (!isHeader) {
        let myResult = fields.map((item, key) => {
            let mydata = false
            if (item.variableResponse) {
                mydata = item.variableResponse(data[item.name], data)
            } else {
                mydata = data[item.name]
            }
            return <div className={'table_field ' + item.name} style={{ width: myWidth + '%' }} key={key}>{mydata}</div>
        })
        return (
            <div idrow={data.id} className={'table_row aicenter clickable'}>
                {myResult}
                <div className='fdiv5table_edit table_field'>
                    <i className='fal fa-eye'></i>
                </div>
            </div>
        )
    }
    let printThis = fields.map((item, key) => {
        return <div className={'table_field ' + item.name} style={{ width: myWidth + '%' }} key={key}>{item.text}</div>
    })
    return (
        <div className={'table_row aicenter header_child'}>
            {printThis}
            <div className='fdiv5table_edit table_field'>
            </div>
        </div>
    )
}
const filterMixChartData = (from, data, translations) => {
    let mydata = data[from]
    let result = {
        series: [
            {
                name: translations.taskCreated,
                type: 'column',
                data: []
            },
            {
                name: translations.finisheds,
                type: 'column',
                data: []
            },
            {
                name: translations.time,
                type: 'line',
                data: []
            }
        ],
        options: {
            dataLabels: {
                enabled: false
            },
            stroke: {
                width: [1, 1, 4]
            },
            title: {
                align: 'left',
                offsetX: 110
            },
            xaxis: {
                type: 'category',
                categories: [],
            },
            yaxis: [
                {
                    axisTicks: {
                        show: true,
                    },
                    axisBorder: {
                        show: true,
                        color: '#008FFB'
                    },
                    labels: {
                        style: {
                            colors: '#008FFB',
                        }
                    },
                    title: {
                        text: translations.taskCreated,
                        style: {
                            color: '#008FFB',
                        }
                    },
                    tooltip: {
                        enabled: true
                    }
                },
                {
                    seriesName: 'Income',
                    opposite: true,
                    axisTicks: {
                        show: true,
                    },
                    axisBorder: {
                        show: true,
                        color: '#00E396'
                    },
                    labels: {
                        style: {
                            colors: '#00E396',
                        }
                    },
                    title: {
                        text: translations.finisheds,
                        style: {
                            color: '#00E396',
                        }
                    },
                },
                {
                    seriesName: 'Revenue',
                    opposite: true,
                    axisTicks: {
                        show: true,
                    },
                    axisBorder: {
                        show: true,
                        color: '#FEB019'
                    },
                    labels: {
                        style: {
                            colors: '#FEB019',
                        },
                    },
                    title: {
                        text: translations.time,
                        style: {
                            color: '#FEB019',
                        }
                    }
                },
            ],
            tooltip: {
                fixed: {
                    enabled: true,
                    position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
                    offsetY: 60,
                    offsetX: 60
                },
            },
            legend: {
                horizontalAlign: 'left',
                offsetX: 60
            }
        }
    }
    if (mydata) {
        for (let key in mydata) {
            result.options.xaxis.categories.push(key)
            // pushejes data [0] per created
            result.series[0].data.push(mydata[key].createdTasks)
            // pushejes data [1] per finished
            result.series[1].data.push(mydata[key].finishedTasks)
            // pushejes data [2] per time (proximamnete)
            result.series[2].data.push(mydata[key].time)
        }
    }
    return result
}
const parseDataForExcel = (result) => {
    const translations = store.getState().language.translations
    if (result) {
        const mydata = []
        const resultCopy = JSON.parse(JSON.stringify(result))
        delete resultCopy.count
        for (const key in resultCopy) {
            const item = resultCopy[key]
            let it = 0
            if (item.BagsLog.length) {
                item.BagsLog.forEach((item, key) => {
                    let dateStart = new Date(item.baglog_startdate)
                    let dateFinish = new Date(item.baglog_finishdate)
                    var calc = (dateFinish - dateStart)
                    it += calc
                })
            }
            item.invertedTime = millisecondsToFormat(it)
            item.createdAt = new Date(item.createdAt).toLocaleString()
            let finished = translations.notFinisheds
            if (item.TaskLogs && item.TaskLogs[0]) {
                finished = new Date(item.TaskLogs[0].updatedAt).toLocaleString()
            }
            item.finishedAt = finished
            let username = '-'
            if (item.User && item.User.username) {
                username = item.User.username
            }
            item.username = username
            let project = '-'
            if (item.Projects && item.Projects[0]) {
                project = item.Projects[0].project_name
            }
            item.project = project
            mydata.push(item)
        }
        return mydata
    }
    return false
}
const Stats = ({ fields, filters, where, resetFilters }) => {
    const translations = store.getState().language.translations
    const [data, setData] = useState(false)
    const [dataForExcel, setDataForExcel] = useState(false)
    const getData = useCallback(async () => {
        let result = await fetch('/api/adminp/task/findAllTaskStats', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                order: ['id'],
                where
            })
        })
        result = await result.json()
        setDataForExcel(parseDataForExcel(result))
        result = filterUpgradeData(result)
        let teamResult = filterChartData('teamStats', result, translations.taskBy + ' '+ translations.team.toLowerCase())
        let userResult = filterChartData('userStats', result, translations.taskBy + ' '+ translations.user.toLowerCase())
        let projectResult = filterChartData('projectStats', result, translations.taskBy + ' '+ translations.project.toLowerCase())
        let taskMonthResult = filterMixChartData('tasksMonthStats', result, translations)
        let customerResult = filterChartData('customerStats', result, translations.taskBy + ' ' + translations.customer.toLowerCase())
        setData({
            taskStats: result.taskStats,
            teamResult,
            userResult,
            projectResult,
            customerResult,
            taskMonthResult
        })
    }, [where, translations])
    useEffect(() => {
        getData()
    }, [getData])
    if (!data) {
        return (<div className='adminstats wrap'>
            <div className='box fdiv100 mrauto'>
                <div className='box_inner'>
                    <div className='box_header'>
                        <button className='btn_primary reset_filters' onClick={resetFilters}>
                            <i className='far fa-chevron-left'></i>
                            <span>{translations.return}</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>)
    }
    return (
        <div className='adminstats wrap'>
            <div className='box fdiv100 mrauto'>
                <div className='box_inner'>
                    <div className='box_header'>
                        <button className='btn_primary reset_filters' onClick={resetFilters}>
                            <i className='far fa-chevron-left'></i>
                            <span>{translations.return}</span>
                        </button>
                                <CSVLink data={dataForExcel}
                                    style={{color: 'white'}}
                                    filename={'Reporte ' + new Date().toLocaleString()+ '.csv'}
                                    headers={[
                                        {
                                            label: translations.task,
                                            key: 'id'
                                        },
                                        {
                                            label: translations.dateStart,
                                            key: 'createdAt'
                                        },
                                        {
                                            label: translations.taskName,
                                            key: 'task_name'
                                        },
                                        {
                                            label: translations.description,
                                            key: 'description'
                                        },
                                        {
                                            label: translations.wastedHours,
                                            key: 'invertedTime'
                                        },
                                        {
                                            label: translations.user,
                                            key: 'username'
                                        },
                                        {
                                            label: translations.project,
                                            key: 'project'
                                        },
                                        {
                                            label: translations.finishedDate,
                                            key: 'finishedAt'
                                        },
                            ]} separator=';'>
                                <button className='btn_primary' onClick={resetFilters} style={{color: 'white'}}>
                                    <i className='far fa-chevron-down'></i>
                                    <span>{translations.downloadReport}</span>
                                </button>
                            </CSVLink>
                    </div>
                    <div className='box_content'>
                        <div className='wrap adminstats_list'>
                            <Chart
                                options={data.taskMonthResult.options}
                                series={data.taskMonthResult.series}
                                width='750'
                            />
                            <div className='stats_number ml20'>
                                <div className='stats_info aicenter mb5'>
                                    <h3 className='mr5'>{translations.taskCreated}:</h3> <span className='stats_info_result'>{data.taskStats.total} </span>
                                </div>
                                <div className='stats_info aicenter mb5'>
                                    <h3 className='mr5'>{translations.finisheds}:</h3> <span className='stats_info_result'>{data.taskStats.completed} </span>
                                </div>
                                <div className='stats_info aicenter mb5'>
                                    <h3 className='mr5'>{translations.time}:</h3> <span className='stats_info_result'>{data.taskStats.invertedTime} </span>
                                </div>
                            </div>
                            {filters.users.length !== 1 ?
                                <Chart
                                options={data.userResult.options}
                                series={data.userResult.series}
                                type='pie'
                                width='750'
                                />
                            : null}
                            {filters.teams.length !== 1 ?
                                <Chart
                                    options={data.teamResult.options}
                                    series={data.teamResult.series}
                                    type='pie'
                                    width='750'
                                />
                            : null}
                            {filters.customers.length !== 1 ?
                                <Chart
                                    options={data.customerResult.options}
                                    series={data.customerResult.series}
                                    type='pie'
                                    width={data.customerResult.series.length < 16 ? '650' : 1350}
                                />
                            : null}
                            {filters.projects.length !== 1 ?
                                <Chart
                                    options={data.projectResult.options}
                                    series={data.projectResult.series}
                                    type='pie'
                                    width={data.projectResult.series.length < 16 ? '650' : 1350}
                                />
                            : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

 
export default AdminStats;