import React, { useState, useEffect } from 'react'
import Table from '../../minicomponents/Table';
import Breadcrumb from '../../minicomponents/Breadcrumb';
import { processModalForm, closeModal, processForm, renderModal } from '../../minicomponents/Modal';
import store, { changePage } from '../../reducers/reducers';
import { ProjectSearcher } from '../../minicomponents/Tasks';
import { CustomSelect } from '../../minicomponents/Form';
import Datetime from 'react-datetime';
import ReactTimeInput from '@teinorsa/react-timeinput';
import 'moment/locale/es'
import { Link } from 'react-router-dom'
import { processData } from '../../utils/specialdata';
const AdminBagsOfHours = () => {
    const [notification, setNotification] = useState(false)
    const [modal, setModal] = useState(false)
    var translations = store.getState().language.translations
    const updateProject = async (project) => {
        document.querySelector('#create_bag .project_finder').style.display = 'none'
        let result = await fetch('/api/adminp/project/findOne', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                project_id: project,
                fill: true
            })
        })
        result = await result.json()
        let selected = document.getElementById('project_selected')
        selected.style.display = 'block'
        selected.style.background = '#' + result.project_color
        selected.innerText = result.customer.customer_name + ' > ' + result.project_name
        let r = false
        let myoptions = []
        if (result.ProductId) {
            result = await fetch('/api/adminp/service/findAll', {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json'
                },
                body: JSON.stringify({
                    where: {
                        ProductId: result.ProductId,
                    }
                })
            })
            result = await result.json()
            if (result.length) {
                r = true
                myoptions = result.map((item, key) => {
                    return {
                        text: item.service_name,
                        value: item.id
                    }
                })
                myoptions.push({
                    text: '---',
                    value: 0
                })
            }
        }
        if (!r) {
            myoptions = [
                {
                    text: translations.addProject,
                    value: 0
                }
            ]
        }
        addBagOfHours(myoptions)
        document.querySelector('#create_bag #project_id').value = project
    }
    const resetProject = (e) => {
        e.currentTarget.style.display = 'none'
        document.querySelector('#create_bag .project_finder').style.display = 'block'
        addBagOfHours()
        document.querySelector('#create_bag #project_id').value = ''
    }
    const addBagOfHours = async (myoptions = [{ text: translations.addProject, value: 0 }]) => {
        var options = [
            {
                value: 0,
                text: translations.noRenew
            },
            {
                value: 1,
                text: translations.day
            },
            {
                value: 2,
                text: translations.week
            },
            {
                value: 3,
                text: translations.month
            },
            {
                value: 4,
                text: translations.year
            },
        ]
        setModal({
            title: <div className='aicenter title_inner'><i className='fas fa-plus-square'></i><span>{translations.addBag}</span></div>,
            id: 'create_bag',
            inner:
                <form className='formContainer wrap' id='createBag' action='/api/adminp/bag/createBag' method='POST' onSubmit={async (e) => { await processForm(e, translations.succAddBag, translations.errDeleteBag, setNotification) }}>
                    <div className='add_customer_control fdiv50 inputDiv'>
                        <h6>{translations.project}</h6>
                        <div id='project_selected' style={{ display: 'none' }} onClick={resetProject}></div>
                        <input type='hidden' value='' id='project_id' name='project_id' readOnly required />
                        <ProjectSearcher onchoose={updateProject} />
                    </div>
                    <div className='add_customer_control fdiv50 inputDiv'>
                        <h6>{translations.services}</h6>
                        <CustomSelect selectProps={{
                            name: 'service_id',
                            required: true
                        }} options={myoptions} />
                    </div>
                    <div className='inputDiv fdiv50'>
                        <h6>{translations.name}</h6>
                        <input type='text' name='bag_name' required placeholder={translations.inName} />
                    </div>
                    <div className='inputDiv fdiv50'>
                        <h6>{translations.hours}</h6>
                        <ReactTimeInput inputProps={{
                            name: 'bag_remainingtime',
                            required: 'required'
                        }} renderSeconds={false} worksInMilliseconds={false} divKey={'bag_remainingtime'} styled={true} />
                    </div>
                    <div className='inputDiv fdiv50'>
                        <h6>{translations.renew}</h6>
                        <CustomSelect options={options} selectProps={{ name: 'bag_renewtype', required: true }} />
                    </div>
                    <div className='inputDiv fdiv50'>
                        <h6>{translations.hoursToFill}</h6>
                        <ReactTimeInput inputProps={{
                            name: 'bag_renewtime',
                            required: 'required'
                        }} renderSeconds={false} worksInMilliseconds={false} divKey={'bag_remainingtime'} styled={true} />
                    </div>
                    <div className='inputDiv fdiv50'>
                        <h6>{translations.expireDate}</h6>
                        <Datetime viewDate={new Date()} closeOnSelect={true} inputProps={{ placeholder: translations.notAssigned, name: 'bag_expiredate' }} timeFormat={false} dateFormat='YYYY-MM-DD' />
                    </div>
                    <button id='submit' type='submit' className='notInclude' style={{ display: 'none' }}></button>
                </form>
            ,
            onAccept: () => processModalForm('createBag'),
            notification: '',
            onCancel: () => closeModal(setNotification, setModal)
        })
    }

    const seeMore = async (e) => {
        let customer_id = e.currentTarget.getAttribute('idrow')
        let elem = document.getElementById('space_for_childs' + customer_id)
        elem.classList.toggle('active')
        let icon = e.currentTarget.querySelector('i')
        if (icon.getAttribute('class').includes('down')) {
            icon.setAttribute('class', icon.getAttribute('class').replace('down', 'up'))
        } else {
            icon.setAttribute('class', icon.getAttribute('class').replace('up', 'down'))
        }
        if (!elem.classList.contains('active')) {
            return
        }
        let result = await fetch('/api/adminp/bag/findAll', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                customer_id,
                params: {
                    where: {
                        bag_active: true
                    }
                },
                fill: true
            })
        })
        result = await result.json()
        let options = [
            {
                value: 0,
                text: translations.noRenew
            },
            {
                value: 1,
                text: translations.day
            },
            {
                value: 2,
                text: translations.week
            },
            {
                value: 3,
                text: translations.month
            },
            {
                value: 4,
                text: translations.year
            },
        ]
        elem.innerHTML = ''
        let header = <TableBagChildren data={{
            bag_name: translations.name,
            bag_limit: translations.inStartHours,
            invertedTime: translations.wastedHours,
            bag_renewtype: translations.renew,
            bag_expiredate: translations.expireDate
        }} isHeader={true} key='itsheader' />
        let pushthis = [header]
        for (let k in result) {
            let row = result[k]
            row.bag_renewtype = options[row.bag_renewtype].text
            row.bag_expiredate = row.bag_expiredate ? processData(row.bag_expiredate, true) : ''
            let x = <TableBagChildren data={row} key={k} />
            pushthis.push(x)
        }
        return pushthis
    }
    var table = {
        name: 'customers',
        getDataFrom: {
            from: '/api/adminp/customer/findAllCustomers',
            method: 'POST',
            defaultParmsValues: {
                group: ['Customer.id', 'customer_code_teinor', 'customer_name', 'customer_fiscal_name', 'customer_responsable_name', 'customer_responsable_phone', 'customer_responsable_mail', 'customer_web', 'customer_active', 'Customer.createdAt', 'Customer.updatedAt'],
            }
        },
        pagination: true,
        actionButtonsHeader: [
            {
                text: translations.addBag,
                className: 'btn_primary add',
                icon: 'far fa-plus-circle',
                action: () => addBagOfHours(),
                type: 'button'
            }
        ],
        actionOnClickRow: (e) => e.currentTarget.querySelector('.edit').click(),
        actionButtonsTable: [
            {
                className: 'seemore',
                icon: 'far fa-chevron-down',
                action: seeMore,
                type: 'seeMore'
            },
            {
                className: 'edit',
                icon: 'fal fa-eye',
                action: '/admin/bagofhours/',
                type: 'link'
            }
        ],
        fields: [
            {
                name: 'customer_name',
                text: translations.name,
                searchable: true,
                sortable: true
            },
            {
                name: 'customer_fiscal_name',
                text: translations.fiscalName,
                searchable: true,
                sortable: true
            },
            {
                name: 'customer_web',
                text: translations.web,
                searchable: true,
                sortable: true
            },
        ]
    }

    useEffect(() => {
        changePage({
            name: 'adminbagofhours',
        })
    }, [])
    return (
        <div id='adminbagofhours'>
            <Breadcrumb />
            <Table table={table} spaceForChilds={true} />
            {renderModal(modal, notification, setNotification, setModal)}
        </div>
    );
}

export default AdminBagsOfHours;

export const TableBagChildren = ({ data, isHeader = false }) => {
    if (!isHeader) {
        return (
            <Link to={'/admin/bagofhours/' + data.customer_id + '/' + data.id} idrow={data.id} className={'table_row aicenter clickable'}>
                <div className='fdiv20 table_field bag_name'>{data.bag_name}</div>
                <div className='fdiv20 table_field bag_limit'>{data.bag_limit}</div>
                <div className='fdiv20 table_field inverted_time'>{data.invertedTime}</div>
                <div className='fdiv20 table_field bag_renew'>{data.bag_renewtype}</div>
                <div className='fdiv15 bag_expiredate'>{data.bag_expiredate}</div>
                <div className='fdiv5table_edit table_field'>
                    <i className='fal fa-eye'></i>
                </div>
            </Link>
        )
    }
    return (
        <div className={'table_row aicenter header_child'}>
            <div className='fdiv20 table_field bag_name'>{data.bag_name}</div>
            <div className='fdiv20 table_field bag_limit'>{data.bag_limit}</div>
            <div className='fdiv20 table_field inverted_time'>{data.invertedTime}</div>
            <div className='fdiv20 table_field bag_renew'>{data.bag_renewtype}</div>
            <div className='fdiv15 bag_expiredate'>{data.bag_expiredate}</div>
            <div className='fdiv5table_edit table_field'>
            </div>
        </div>
    )
}
