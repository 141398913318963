import React, {useState, useEffect} from 'react'
import Table from '../../minicomponents/Table'
import store, { changePage} from '../../reducers/reducers'
import { returnNotifiPostForm } from '../../utils/forms'
import { renderNotification } from '../../utils/renderNotification'
import Modal from '../../minicomponents/Modal'
import { CsvInner } from '../../minicomponents/Form';
import Tabs, { TabsHiddenChange } from '../../minicomponents/Tabs'
const Customers = ({ match }) => {
    var translations = store.getState().language.translations
    const [modal, setModal] = useState(false)
    const [notification, setNotification] = useState(false)
    const processModalForm = async (name) => {
        if (name === 'modifyUser') {
            if (document.forms[name].repeatpassword.value === document.forms[name].password.value) {
                document.forms[name].submit.click()
            } else {
                setNotification({
                    type: 'error',
                    msg: translations.pwNotMatch
                })
            }
        } else {
            document.forms[name].submit.click()
        }
    }
    const addNew = () => {
        setModal({
            title: <div className="aicenter title_inner"><i className='fas fa-plus-square'></i> <span>{translations.addCustomer}</span></div>,
            id: 'add_customer',
            inner:
                <form className='formContainer wrap' id='createCustomer' action='/api/adminp/customer/createCustomer' method='POST' onSubmit={(e) => processForm(e, translations.succCreateCustomer, translations.errCreateCustomer)}>
                    <div className='inputDiv'>
                        <h6>{translations.name}</h6>
                        <input type='text' name='customer_name' placeholder={translations.inName} required />
                    </div>
                    <div className='inputDiv'>
                        <h6>{translations.codeCustomer}</h6>
                        <input type='text' name='customer_code_teinor' placeholder={translations.inCodeCustomer} />
                    </div>
                    <div className='inputDiv'>
                        <h6>{translations.fiscalName}</h6>
                        <input type='text' name='customer_fiscal_name' placeholder={translations.inFiscalName} />
                    </div>
                    <div className='inputDiv'>
                        <h6>{translations.responsableName}</h6>
                        <input type='text' name='customer_responsable_name' placeholder={translations.inResponsableName} />
                    </div>
                    <div className='inputDiv'>
                        <h6>{translations.responsablePhone}</h6>
                        <input type='text' name='customer_responsable_phone' placeholder={translations.inResponsablePhone}  />
                    </div>
                    <div className='inputDiv'>
                        <h6>{translations.responsableEmail}</h6>
                        <input type='text' name='customer_responsable_mail' placeholder={translations.inResponsableEmail}  />
                    </div>
                    <div className='inputDiv'>
                        <h6>{translations.web}</h6>
                        <input type='text' name='customer_web' placeholder={translations.inWeb}  />
                    </div>
                    <button id='submit' type='submit' className='notInclude' style={{ display: 'none' }}></button>
                </form>
            ,
            onAccept: () => processModalForm('createCustomer'),
            notification: renderNotification(notification),
            onCancel: closeModal
        })
    }
    const addNewCsv = () => {
        setModal({
            title: <div className="aicenter title_inner"><i className='fas fa-plus-square'></i> <span>{translations.addCsv}</span></div>,
            id: 'add_user_csv',
            inner:
                <Tabs tabOptions={[
                    {
                        click: (e) => TabsHiddenChange(e, 'tabsHiddenImport', 'customers'),
                        text: translations.customers,
                        active: true
                    },
                    {
                        click: (e) => TabsHiddenChange(e, 'tabsHiddenImport', 'shops'),
                        text: translations.shops
                    },
                    {
                        click: (e) => TabsHiddenChange(e, 'tabsHiddenImport', 'projects'),
                        text: translations.projects
                    }
                ]} button={true} display={
                    <div className='tabsHidden' id='tabsHiddenImport'>
                        <div id='customers' className='tabHidden active'>
                            <CsvInner
                                exampleCsv='/customers_ex.csv'
                                text={translations.addCustomerCsv}
                                fields={['customer_code_teinor', 'customer_name', 'customer_fiscal_name', 'customer_responsable_name', 'customer_responsable_phone', 'customer_responsable_mail', 'customer_web']}
                                sendDataTo='/api/adminp/customer/createCustomer'
                                setNotification={setNotification}
                                />
                        </div>
                        <div id='shops' className='tabHidden'>
                            <CsvInner
                                exampleCsv='/shops_ex.csv'
                                text={translations.addShopCsv}
                                fields={['customer_company_code', 'shop_company_code', 'shop_name', 'shop_address', 'shop_phone', 'shop_mobile', 'shop_email', 'shop_responsable', 'shop_main']}
                                sendDataTo='/api/adminp/shop/createShop'
                                setNotification={setNotification}
                                />
                        </div>
                        <div id='projects' className='tabHidden'>
                            <CsvInner
                                exampleCsv='/projects_ex.csv'
                                text={translations.addProjectsCsv}
                                fields={['customer_company_code', 'product_id']}
                                sendDataTo='/api/adminp/project/createProject'
                                setNotification={setNotification}
                                />
                        </div>
                    </div>
                }/>
            ,
            onAccept: closeModal,
            notification: renderNotification(notification),
            onCancel: closeModal
        })
    }
    const closeModal = () => {
        setNotification(false)
        setModal(false)
    }
    const removeCustomer = async (e) => {
        var id = parseInt(e.target.parentElement.parentElement.getAttribute('idrow'))
        setModal({
            title: <div className="aicenter title_inner"><i className="fas fa-trash-alt"></i> <span>{translations.deleteCustomer}</span></div>,
            careModal: true,
            id: 'remove_customer',
            inner:
                <form className='formContainer wrap' id='deleteCustomer' action='/api/adminp/customer/deleteCustomer' method='POST' onSubmit={(e) => processForm(e, translations.succRmCustomer, translations.errRmCustomer)}>
                    <span>{translations.confirmDeleteCustomer}</span>
                    <input type='hidden' name='id' value={id} readOnly />
                    <button id='submit' type='submit' className='notInclude' style={{ display: 'none' }}></button>
                </form>
            ,
            onAccept: () => processModalForm('deleteCustomer'),
            notification: renderNotification(notification),
            onCancel: closeModal
        })
    }
    var renderModal = ''
    if (modal) {
        if (notification && notification.type !== 'error') {
            window.setTimeout(function () {
                closeModal()
            }, 1000)
        }
        modal.notification = renderNotification(notification)
        renderModal = <Modal {...modal} />
    }
    const processForm = async (e, positiveMessage, negativeMessage) => {
        var notification = await returnNotifiPostForm(e, positiveMessage, negativeMessage)
        setNotification(notification)
    }
    var table = {
        name: 'customers',
        getDataFrom: {
            from: match.params.off ? '/api/adminp/customer/findAllCustomers/off' : '/api/adminp/customer/findAllCustomers',
            method: 'POST',
            defaultParmsValues: {
                group: ['Customer.id', 'customer_code_teinor', 'customer_name', 'customer_fiscal_name', 'customer_responsable_name', 'customer_responsable_phone', 'customer_responsable_mail', 'customer_web', 'customer_active', 'Customer.createdAt', 'Customer.updatedAt']
            }
        },
        pagination: true,
        actionButtonsHeader: [
            {
                text: translations.addCustomer,
                className: 'btn_primary add',
                icon: 'far fa-plus-circle',
                action: addNew,
                type: 'button'
            },
            {
                text: translations.addCsv,
                className: 'btn_primary add',
                icon: 'fal fa-file-import',
                action: addNewCsv,
                type: 'button'
            },
            {
                text: match.params.off ? translations.viewActives : translations.viewDisableds,
                className: 'btn_primary link',
                icon: match.params.off ? 'fal fa-eye' : 'fal fa-eye-slash',
                goTo: match.params.off ? '/customers' : '/customers/off',
                type: 'link'
            }
        ],
        actionOnClickRow: (e) => e.currentTarget.querySelector('.edit').click(),
        actionButtonsTable: [
            {
                className: 'edit',
                icon: 'far fa-pencil',
                action: '/customer/',
                type: 'link'
            },
            {
                className: 'remove',
                icon: 'fal fa-trash-alt',
                action: removeCustomer,
                type: 'button'
            }
        ],
        fields: [
            {
                name: 'customer_code_teinor',
                text: translations.codeCustomer,
                searchable: true,
                sortable: true
            },
            {
                name: 'customer_name',
                text: translations.name,
                searchable: true,
                sortable: true
            },
            {
                name: 'customer_fiscal_name',
                text: translations.fiscalName,
                searchable: true,
                sortable: true
            },
            {
                name: 'customer_responsable_mail',
                text: translations.responsableEmail,
                searchable: true,
                sortable: true
            },
            {
                name: 'customer_responsable_phone',
                text: translations.responsablePhone,
                searchable: true,
                sortable: true
            },
            {
                name: 'customer_web',
                text: translations.web,
                searchable: true,
                sortable: true
            },
        ]
    }
    useEffect(() => {
        changePage({
            name: 'customers',
        })
    }, [])
    return (
    <div id='list_customers'>
        <Table table={table} />
        {renderModal}
    </div> );
}
 
export default Customers;