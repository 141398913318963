const caCA = {
    username: 'Nom d\'usuari',
    inUsername: 'Introdueix el nom d\'usuari',
    email: 'Email',
    inEmail: 'Introdueix el email',
    userOrEmail: 'Nom de usuari o email',
    name: 'Nom',
    inName: 'Introdueix el nom',
    firstsurname: 'Cognom',
    inFirstsurname: 'Introdueix el cognom',
    secondsurname: 'Segon cognom',
    inSecondsurname: 'Introdueix el segon cognom',
    password: 'Contrasenya',
    inPassword: 'Introdueix la contrasenya',
    repeatPassword: 'Repeteix la contrasenya',
    nameCustomer: 'Nom client',
    codeCustomer: 'Codi empresa',
    inCodeCustomer: 'Introdueix el codi d\'empresa',
    fiscalName: 'Nom fiscal',
    comercialName: 'Nom comercial',
    nif: 'NIF/CIF',
    assigned_comercial: 'Comercial assignat',
    inFiscalName: 'Introdueix el nom fiscal',
    responsableName: 'Nom del responsable',
    inResponsableName: 'Introdueix el nom del responsable',
    responsableEmail: 'Email del responsable',
    inResponsableEmail: 'Introdueix el email del responsable',
    responsablePhone: 'Telèfon del responsable',
    inResponsablePhone: 'Introdueix el telèfon del responsable',
    inAnswer: 'Escriu la teva resposta',
    typeUser: 'Tipus de usuari',
    inTypeUser: 'Introdueix el tipus de usuari',
    web: 'Web',
    inWeb: 'Introdueix la web',
    address: 'Direcció',
    inAddress: 'Introdueix la direcció',
    phone: 'Telèfon',
    inPhone: 'Introdueix el telèfon',
    mobile: 'Mòbil',
    inMobile: 'Introdueix el mòbil',
    description: 'Descripció',
    inDescription: 'Introdueix la descripció',
    hours: 'Hores',
    inStartHours: 'Hores inicials',
    wastedHours: 'Hores gastades',
    explanationWastedHours: 'Aquestes són les hores que vas gastar en aquesta tasca. Modifica un registre o introdueix-lo a continuació. En total has gastat',
    renew: 'Renovar',
    hoursToFill: 'Hores a renovar',
    dateStart: 'Data inici',
    dateEnd: 'Data final',
    since: 'Desde',
    to: 'Fins a',
    expireDate: 'Data de caducitat',
    maxDate: 'Data',
    title: 'Títol',
    task: 'Tasca',
    taskBy: 'Tasques per',
    taskName: 'Nom de la tasca',
    newComment: 'Nou comentari',
    inComment: 'Introdueix el comentari',
    image: 'Imatge',
    projects: 'Projectes',
    project: 'Projecte',
    inProject: 'Introdueix el nom del projecte',
    taskHelper: 'Assistent de tasca',
    taskCreated: 'Tasques creades',
    Time: 'Temps',
    timeSubtasks: 'Temps subtasques',
    createdDate: 'Data de creació',
    finishedDate: 'Data de finalització',
    addTime: 'Afegir temps invertit',
    addFile: 'Adjuntar fitxer',
    hideUntil: 'No mostrar fins...',
    toParent: 'Transformar en tasca pare',
    toSubtask: 'Transformar en subtasca',
    addSubtask: 'Afegir subtasca',
    duplicateTask: 'Duplicar tasca',
    deleteThisTask: 'Eliminar aquesta tasca',
    close: 'Tancar',
    notAssigned: 'Sense assignar',
    main: 'Principal',
    noMain: 'No principal',
    recover: 'Recuperar',
    continue: 'Continuar',
    send: 'Enviar',
    save: 'Guardar',
    or: 'O',
    login: 'Iniciar sessió',
    return: 'Tornar',
    returnToday: 'Tornar a avui',
    previous: 'Anterior',
    next: 'Següent',
    month: 'Mes',
    week: 'Setmana',
    day: 'Dia',
    year: 'Any',
    monday: 'Dilluns',
    tuesday: 'Dimarts',
    wednesday: 'Dimecres',
    thursday: 'Dijous',
    friday: 'Divendres',
    saturday: 'Dissabte',
    sunday: 'Diumenge',
    default: 'Per defecte',
    searchBy: 'Buscar per',
    searchPh: 'Vull buscar...',
    searchTask: 'Busca una tasca',
    searchCustomer: 'Busca un client',
    searchProject: 'Busca un projecte',
    all: 'Tots',
    finished: 'Finalitzada',
    subtasks: 'Subtasques',
    finisheds: 'Finalitzades',
    notFinisheds: 'Sense finalitzar',
    finishedsAndNot: 'Finalitzades i sense finalitzar',
    showed: 'Visibles',
    hidden: 'Amagades',
    showedAndHidden: 'Visibles i amagades',
    private: 'Privada',
    makePrivate: 'Fer privada', 
    privateExplanation: 'En convertir aquesta tasca en privada només serà visible per l\'usuari que sigui propietari o l\'equip al qual estigui assignat. Si aquesta tasca té subtareas sense assignar i sense equip, també seran privades.',
    publicExplanation: 'En convertir aquesta tasca en pública, tornarà a ser visible per qualsevol usuari.', 
    notification: 'notificació',
    notifications: 'notificacions',
    Notifications: 'Notificacions',
    youHave: 'Tens',
    loadMore: 'Carregar més',
    cancel: 'Cancelar',
    accept: 'Acceptar',
    information: 'Informació',
    members: 'Membres',
    admin: 'Administrador',
    active: 'Actiu',
    viewActives: 'Veure actius',
    allowList: 'Llistar',
    invited: 'Convidats',
    isPublic: 'Públic',
    status: 'Estat',
    disabled: 'Desactivat',
    viewDisableds: 'Veure desactivats',
    listFilters: 'Llista de filtres',
    listPublicFilters: 'Llista de filtres pública',
    noFilters: 'No hi ha filtres',
    manageFilters: 'Gestionar filtres',
    disabledList: 'No llistar',
    noHelpers:'No disposes d\'assistents',
    actions: 'Accions',
    resume: 'Resum',
    shops: 'Botigues',
    shop: 'Botiga',
    contactPersons: 'Contactes',
    contactPerson: 'Contacte',
    noPermissions: 'No tens permisos per accedir',
    dashboard: 'Escritori',
    myTasks: 'Les meves tasques',
    myCalendar: 'El meu calendari',
    myProfile: 'El meu perfil',
    myTeams: 'Els meus equips',
    myNotes: 'Les meves notes',
    otherTeams: 'Altres equips',
    customers: 'Clients',
    customer: 'Client',
    team: 'Equip',
    teams: 'Equips',
    settings: 'Configuracions',
    adminPanel: 'Administració',
    updatelog: 'Ajuda i actualitzacions',
    user: 'Usuari',
    users: 'Usuaris',
    usersConnected: 'Usuaris connectats',
    customFields: 'Camps personalizats',
    fields: 'Camps',
    inputHours: 'Amb temps invertit',
    noinputHours: 'Sense temps invertit',
    withandnotinputHours: 'Amb i sense temps invertit',
    inputHoursBdH: 'Amb temps invertit de Bossa d\'hores ',
    noinputHoursBdH: 'Sense temps invertit de Bossa d\'hores',
    withandnotinputHoursBdH: 'Amb i sense temps invertit de Bossa d\'hores', 
    products: 'Productos',
    color: 'Color',
    repository: 'Repositori',
    createdBy: 'Creat per',
    answers: 'Respostes',
    tags: 'Etiquetes',
    noRenew: 'No renovar',
    withoutAnswers: 'Sense respostes',
    withAnswers: 'Amb respostes',
    both: 'Ambdós',
    olderFirst: 'Més antics primer',
    newestFirst: 'Més nous primer',
    inTags: 'Conté una d\'aquestes',
    orderBy: 'Ordenar per',
    reset: 'Reiniciar',
    filters: 'Filtres',
    filter: 'Filtrar',
    comments: 'Comentaris',
    note: 'Nota',
    table: 'Taula',
    wannaUpdatePw: 'Vols actualitzar la contrasenya?',
    forgotPassword: 'Has oblidat la teva contrasenya?',
    keepSession: 'Mantindre sessió activa',
    recoverPassword: 'Recuperar contrasenya',
    pwNotMatch: 'Les contrasenyes no coincideixen',
    createAdmin: 'Crear usuari administrador',
    downloadCsv: 'Descarrega el CSV base aquí',
    downloadReport: 'Descarrega el informe',
    uploadCsv: 'Puja el CSV base amb les teves dades',
    register: 'Registrar-se',
    editingCustomer: 'Editant el client',
    updatePassword: 'Actualitzar contrasenya',
    workIn: 'Treballar a',
    stats: 'Estadístiques',
    startWork: 'Començar a treballar',
    workingSince: 'Treballant desde',
    scheduleControl: 'Control d\'horari',
    punchIn: 'Fitxar entrada',
    punchOut: 'Fitxar sortida',
    edited: 'Editat',
    companyNotifications: 'Notificacions d\'empresa',
    priority: 'Prioritat',
    high: 'Alta',
    medium: 'Mitjana',
    low: 'Baixa',
    selectBagOfHours: 'Seleccioneu una de les bosses d\'hores dels projectes assignats o no en seleccioni cap per treballar unicament a la tasca',
    selectNote: 'Selecciona una nota per veurela',
    bagOfHoursManual: 'Podeu introduir les hores que heu passat a la tasca o deixar aquest camp buit i el temporitzador s’iniciarà..',
    bagOfHours: 'Bossa d\'hores',
    addShop: 'Afegir una botiga',
    addContact: 'Afegir un contacte',
    addComment: 'Afegir comentari',
    addTask: 'Afegir tasca',
    addTeam: 'Afegir un equip',
    addUser: 'Afegir un compte',
    addCustomer: 'Afegir un client',
    addProject: 'Afegir projecte',
    addCsv: 'Importar con CSV',
    addUserCsv: 'Es convidaran als usuaris que no estiguin registrats o convidats ja',
    addCustomerCsv: 'Es crearan els clients que no estiguin creats ja',
    addShopCsv: 'Es crearan les botigues que no estiguin creades ja',
    addProjectsCsv: 'Es crearan els projectes que no estiguin creats ja i tindran el nom del producte',
    addField: 'Afegir camps',
    addProduct: 'Afegir producte',
    addService: 'Afegir servei',
    addRule: 'Afegir norma',
    addBag: 'Afegir bossa',
    addHours: 'Afegir hores',
    addAssistant: 'Afegir assistent',
    addQuestion: 'Afegir pregunta',
    addTag: 'Afegir etiqueta',
    addAnswer: 'Afegir resposta',
    addNote: 'Afegir nota',
    addNotification: 'Afegir notificació',
    addFilter: 'Afegir filtre',
    modifyShop: 'Modificar una botiga',
    modifyContact: 'Modificar una persona de contacte',
    modifyTeam: 'Modificar un equip',
    modifyUser: 'Modificar un usuari',
    modifyCustomField: 'Modificar un camp personalitzat',
    modifyProduct: 'Modificar producte',
    modifyService: 'Modificar servei',
    modifyProject: 'Modificar projecte',
    modifyAnswer: 'Modificar resposta',
    modifyNotification: 'Modificar notificació',
    markAsFinished: 'Finalitzar',
    deleteTask: 'Eliminar una tasca',
    deleteTeam: 'Eliminar un equip',
    deleteInvitation: 'Eliminar una invitació',
    deleteUser: 'Eliminar un usuari',
    deleteCustomer: 'Eliminar un client',
    deleteShop: 'Eliminar una botiga',
    deleteContactPerson: 'Eliminar una persona de contacte',
    deleteField: 'Eliminar un camp personalitzat',
    deleteProduct: 'Eliminar un producte',
    deleteService: 'Eliminar un servei',
    deleteProject: 'Eliminar projecte',
    deletePost: 'Eliminar pregunta',
    deleteAnswer: 'Eliminar resposta',
    deleteNotification: 'Eliminar notificació',
    confirmDeleteTask: 'Segur que vols eliminar aquesta tasca permanentment i totes les seves subtasques?',
    confirmDeleteTeam: 'Eliminaràs aquest equip, estàs segur?',
    confirmDeleteUser: 'Eliminaràs aquest usuari, estàs segur?',
    confirmDeleteCustomer: 'Eliminaràs aquest client, estàs segur?',
    confirmDeleteShop: 'Eliminaràs aquesta botiga, estàs segur?',
    confirmDeleteContactPerson: 'Eliminaràs aquesta persona de contacte, estàs segur?',
    confirmDeleteProduct: 'Eliminaràs aquest producte, estàs segur?',
    confirmDeleteService: 'Eliminaràs aquest servei, estàs segur?',
    confirmDeleteProject: 'Eliminaràs aquest projecte, estàs segur?',
    confirmDeletePost: 'Eliminaràs aquesta pregunta, estàs segur?',
    confirmDeleteAnswer: 'Eliminaràs aquesta resposta, estàs segur?',
    succLogin: 'Inici de sessió correcte',
    succEmailPassword: 'Consulti el seu correu per recuperar la contrasenya',
    succRecoverPw: 'Contrasenya cambiada, iniciï sessió',
    succInstall: 'Instalació realizada correctament',
    succRegister: 'Usuari registrat correctament',
    succUpdCustomer: 'Client actualitzat correctament',
    succCreateShop: 'Botiga creada correctament',
    succUpdateShop: 'Botiga actualitzada correctament',
    succCreateContact: 'Persona de contacte creada correctament',
    succUpdateContact: 'Persona de contacte actualitzada correctament',
    succAddTeam: 'Equip creat correctament',
    succUpdUser: 'Usuari actualitzat correctament',
    succRmTeam: 'Equip eliminat correctament',
    succRmUser: 'Usuari eliminat correctament',
    succUpdTeam: 'Equip actualitzat correctament',
    succUserInvited: 'Usuari convidat correctament',
    succCreateCustomer: 'Client creat correctament',
    succRmCustomer: 'Client eliminat correctament',
    succRmShop: 'Botiga eliminada correctament',
    succRmContactPerson: 'Persona de contacte eliminada correctament',
    succImport: 'Importació realitzada correctament',
    succCreateField: 'Camp personalitzat creat correctament',
    succDeleteField: 'Camp personalitzat eliminat correctament',
    succCreateProduct: 'Producte creat correctament',
    succCreateService: 'Servei creat correctament',
    succUpdProduct: 'Producte actualitzat correctament',
    succUpdService: 'Servei actualitzat correctament',
    succDeleteProduct: 'Producte eliminat correctament',
    succCreateRule : 'Norma creada correctament',
    succCreateProject : 'Projecte creat correctament',
    succUpdProject : 'Projecte modificat correctament',
    succRmProject : 'Projecte eliminat correctament',
    succRmService : 'Servei eliminat correctament',
    succAddBag: 'Bossa d\'hores creada correctament',
    succWorkIn: 'Treballant...',
    succAddAssistant: 'Assistent de tasques creat correctament',
    succRmPost: 'Pregunta eliminada correctament',
    succRmAnswer: 'Resposta eliminada correctament',
    succAddNotification: 'Notificació afegida correctament',
    succAddFilter: 'Filtre afegit correctament',
    succPrivate: 'La tasca s\'ha convertirt en privada correctament',
    errPrivate: 'No s\'ha pogut convertir en privada aquesta tasca, si us plau intenti- ho de nou',
    errEmailPassword: 'No s\'ha pogut recuperar la contrasenya, si us plau, intenta-ho de nou',
    errNoLogin: 'No s\'ha pogut iniciar sessió, si us plau, intenta-ho de nou',
    errRecoverPw: 'No s\'ha pogut cambiar la contrasenya, si us plau, intenta-ho de nou',
    errRegister: 'No s\'ha pogut registrar el usuari, si us plau, intenta-ho de nou',
    errUpdCustomer: 'No s\'ha pogut actualitzar el client, si us plau, intenta-ho de nou',
    errCreateShop: 'No s\'ha pogut crear la botiga, si us plau, intenta-ho de nou',
    errUpdateShop: 'No s\'ha pogut actualitzar la botiga, si us plau, intenta-ho de nou',
    errCreateContact: 'No s\'ha pogut crear la persona de contacte, si us plau, intenta-ho de nou',
    errUpdateContact: 'No s\'ha pogut actualitzar la persona de contacte, si us plau, intenta-ho de nou',
    errAddTeam: 'No s\'ha pogut crear el equip, si us plau, comprova que no existeix',
    errUpdUser: 'No s\'ha pogut actualitzar el usuari, intenta-ho de nou',
    errRmTeam: 'No s\'ha pogut eliminar el equip, si us plau, intenta-ho de nou',
    errRmUser: 'No s\'ha pogut eliminar el usuari, si us plau, intenta-ho de nou',
    errUpdTeam: 'No s\'ha pogut modificar el equip, si us plau, intenta-ho de nou',
    errUserInvited: 'No s\'ha pogut registrar el usuari, si us plau, comprova que no existeix o intenta-ho de nou',
    errCreateCustomer: 'No s\'ha pogut crear el client, si us plau, intenta-ho de nou',
    errRmCustomer: 'No s\'ha pogut eliminar el client, intenta-ho de nou',
    errCreateField: 'No s\'ha pogut crear el camp personalitzat, intenta-ho de nou',
    errDeleteField: 'No s\'ha pogut eliminar el camp personalitzat, intenta-ho de nou',
    errCreateProduct: 'No s\'ha pogut crear el producte',
    errUpdProduct: 'No s\'ha pogut actualitzar el producte correctament',
    errDeleteProduct: 'No s\'ha pogut eliminar el producte correctament',
    errCreateService: 'No s\'ha pogut crear el servei',
    errUpdService: 'No s\'ha pogut actualitzar el servei correctament',
    errDeleteService: 'No s\'ha pogut eliminar el servei correctament',
    errCreateRule: 'No s\'ha pogut crear la norma',
    errCreateProject: 'No s\'ha pogut crear el projecte',
    errUpdProject: 'No s\'ha pogut modificar el projecte',
    errRmProject: 'No s\'ha pogut eliminar el projecte',
    errDeleteBag: 'No s\'ha pogut crear la bossa d\'hores. Assegura\'t de tenir un client seleccionat',
    errAddAssistant: 'No s\'ha pogut crear el assistent',
    errWorkIn: 'No s\'ha pogut començar a treballar',
    errDeletePost: 'No s\'ha pogut eliminar la pregunta',
    errDeleteAnswer: 'No s\'ha pogut eliminar la resposta',
    errAddNotification: 'No s\'ha pogut afegir la notificació',
    errAddFilter: 'No s\'ha pogut afegir  el filtre',
    xCreatedTask: 'ha creat la tasca',
    createdTask: 'S\'ha creat la tasca',
    xCreatedSubtask: 'ha creat la subtasca',
    createdSubtask: 'S\'ha creat la subtasca',
    xReassignedUser: 'ha reassignat la tasca a',
    xAssignedUser: 'ha assignat la tasca a',
    xRemoveAssignedUser: 'ha eliminat la assignació',
    assignedUser: 'S\'ha assignat la tasca a',
    xReassignedTeam: 'ha reassignat la tasca al equip',
    xAssignedTeam: 'ha assignat la tasca al equip',
    xRemoveAssignedTeam: 'ha eliminat la assignació al equip',
    assignedTeam: 'S\'ha assignat la tasca al equip',
    xUnmarkAsFinished: 'ha marcat la tasca com no finalitzada',
    xFinishedTask: 'ha finalitzat la tasca',
    finishedTask: 'S\'ha finalitzat la tasca'
}
export default caCA