import React from 'react'
import {Link} from 'react-router-dom'
const Tabs = ({tabOptions, display, rightSide, button = false}) => {
    var formatTabs = tabOptions.map((item, key) => {
        if(button){
            return <div onClick={item.click} key={key} className={'tab_header_item' + ((item.active) ? ' active' : '')}>{item.text}</div>   
        }else{
            return <Link to={item.to} key={key} className={'tab_header_item' + ((item.active) ? ' active' : '') }>{item.text}</Link>   
        }
    });
    return ( 
    <div className='tabs'>
        <div className='tabs_header'>
            <div className='tab_header_left'>
                {formatTabs}
            </div>
            <div className='tab_header_right'>
                {rightSide}
            </div>
        </div>
        <div className='tab_content'>
            {display}
        </div>
    </div>
    );
}
export const TabsHiddenChange = (e,tabsId, changeTo) => {
    var clicked = e.currentTarget
    var old_active = clicked.parentElement.querySelector('.active')
    if(old_active){
        old_active.classList.remove('active')
    }
    clicked.classList.add('active')
    var current_active = document.getElementById(tabsId).querySelector('.tabHidden.active')
    if(current_active){
        current_active.classList.remove('active')
    }
    var target = document.getElementById(tabsId).querySelector('#'+changeTo)
    if(target){
        target.classList.add('active')
    }
}
export default Tabs;