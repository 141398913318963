import React, { useState, useCallback, useEffect } from 'react';
import './assets/css/app.sass';
import './assets/css/rtd.sass';
import './assets/css/fa.css';
import Install from './install/Install'
import Login from './Login'
import Recover from './Recover'
import Register from './Register'
import Main from './panel/Main'
import store, { changeUser, updateLanguage, updateCustomFields, updateCustomOrder, updateHelpers} from './reducers/reducers'
import { connect } from 'react-redux'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'
import socket from './utils/socket';
const mapStateToProps = state => ({ user: state.user, language: state.language, customfields: state.customfields, helpers: state.helpers })
const PrivateRoute = ({ path, component: Component }) => {
  return (<Route path={path} render={(props) =>
    store.getState().user ? (
      <Component {...props} />
    ) : (
        <Login {...props} />
      )} />)
}
function App() {
  const [isLoading, setIsLoading] = useState(true)
  const getStartData = useCallback(async () => {
    await updateLanguage()
    await changeUser()
    await updateCustomFields()
    await updateCustomOrder()
    await updateHelpers()
    setIsLoading(false)
    socket.on('update_custom_fields', updateCustomFields)
    socket.on('update_custom_fields', updateCustomOrder)
    socket.on('update_custom_fields', updateHelpers)
  }, [])
  useEffect(() => {
    if (isLoading) {
      getStartData()
    }
  }, [getStartData, isLoading])
  if (isLoading) {
    return ('')
  }
  return (
    <Router>
      <Switch>
        <Route exact path='/user/register/:token' render={(props) => <Register {...props} />} />
        <Route exact path='/install/' render={() => <Install />} />
        <Route exact path='/recover/:token?' render={(params) => <Recover {...params} />} />
        <PrivateRoute path='/' component={Main} />
      </Switch>
    </Router>
  );
}

export default connect(mapStateToProps)(App);
