export const formatForm = (target) => {
    var values = {}
    for(var i in target.elements){
        var elem = target.elements[i]
        if(elem.type === 'checkbox' && !elem.classList.contains('notInclude')){
            values[elem.name] = elem.checked
        } else if (elem.type === 'radio' && !elem.classList.contains('notInclude')) {
            if (elem.checked) {
                values[elem.name] = target.elements[elem.name].value !== "" ? target.elements[elem.name].value : false
            }
        }else if(elem.type ==='file'){
            if(elem.files[0]){
                var filename = ((target.getAttribute('id')) ? target.getAttribute('id') : '') + ((target.elements.id) ? target.elements.id.value : '') + elem.files[0].name
                var moveTo = elem.getAttribute('pathtosave')
                processImage(elem.files, filename, moveTo)
                values[elem.name] = filename
            }
        }else{
            if(elem.classList && !elem.classList.contains('notInclude')){
                values[elem.name] = elem.value
            }
        }
    }
    return values
}
export const processImage = (elemfiles, filename, moveTo, renameTo = false) => {
    const formData = new FormData()
    formData.append('file-select', elemfiles[0], filename)
    fetch('/api/adminp/processImg', {
        method: 'POST',
        body: formData
    }).then(() => {
        fetch('/api/adminp/moveImg', {
            method: 'POST',
            body: JSON.stringify({
                moveTo: moveTo,
                imgName: filename,
                renameTo
            }),
            headers: {
                'Content-Type': 'application/json'
            },
        })
    })
}
export const fetchPostForm = async (e, valuesOnly = false) => {
    e.preventDefault()
    var disableds = e.target.querySelector('[type="submit"]')
    disableds.setAttribute('disabled', '')
    var formData = formatForm(e.target)
    var response = await fetch(e.target.action, {
        method: e.target.method,
        body: JSON.stringify(formData),
        headers: {
            'Content-Type': 'application/json'
        }
    })
    if(valuesOnly){
        response = await response.json()
    }
    disableds.removeAttribute('disabled')
    return response
}
export const returnNotifiPostForm = async (e, positive, negative) => {
    var response = await fetchPostForm(e, true)
    if (response === false) {
        return {
            type: 'error',
            msg: negative
        }
    } else {
        return {
            type: 'success',
            msg: positive
        }
    }
}
export const searchUser = async (search, curr_user = false) => {
    var users = await fetch('/api/adminp/user/findAll', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            attributes: ['id', 'username', 'email', 'firstname', 'firstsurname', 'secondsurname', 'admin_permissions', 'active', 'image', 'description'],
            where: {
                $or: [
                    {
                        email: { $iLike: '%' + search + '%' },
                    },
                    {
                        username: { $iLike: '%' + search + '%' },
                    },
                    {
                        firstname: { $iLike: '%' + search + '%' },
                    },
                    {
                        firstsurname: { $iLike: '%' + search + '%' },
                    }
                ]
            },
            limit: 20,
            skipCount: true
        })
    })
    users = await users.json()
    if (curr_user) {
        let prio = false
        for (let k in users) {
            let user = users[k]
            if (user.id === curr_user) {
                prio = k
            }
        }
        if (prio) {
            let aux = users[prio]
            users[prio] = users[0]
            users[0] = aux
        }
    }
    return users
}
export const searchTeam = async (search) => {
    var teams = await fetch('/api/adminp/team/findAll', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            where: {
                $or: [
                    {
                        name: { $iLike: '%' + search + '%' },
                    }
                ]
            },
            limit: 20
        })
    })
    teams = await teams.json()
    return teams
}
export const searchUsersFromTeam = async (TeamId) => {
    var users = await fetch('/api/adminp/team/findUsers', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            where: {
                id: TeamId   
            }
        })
    })
    users = await users.json()
    return users
}
export const searchTasks = async (search) => {
    var tasks = await fetch('/api/adminp/task/findAll', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            where: {
                $or: [
                    {
                        id: { $iLike: '%' + search.replace('#', '') + '%' },
                    },
                    {
                        task_name: { $iLike: '%' + search + '%' },
                    }
                ]
            },
            limit: 20
        })
    })
    tasks = await tasks.json()
    return tasks
}
export const searchShop = async (search, customers_id) => {
    var shops = await fetch('/api/adminp/shop/findAll', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            params: {
                where: {
                    $and: {
                        $or: [
                            {
                                shop_idteinor: { $iLike: '%' + search + '%' },
                            },
                            {
                                shop_name: { $iLike: '%' + search + '%' },
                            },
                            {
                                shop_responsable: { $iLike: '%' + search + '%' },
                            },
                            {
                                shop_address: { $iLike: '%' + search + '%' },
                            },
                            {
                                shop_phone: { $iLike: '%' + search + '%' },
                            },
                            {
                                shop_mobile: { $iLike: '%' + search + '%' },
                            },
                            {
                                shop_mail: { $iLike: '%' + search + '%' },
                            }
                        ],
                        CustomerId: {
                            $or: [...customers_id]
                        }
                    }
                },
                limit: 20
            }
        })
    })
    shops = await shops.json()
    return shops
}
export const searchContact = async (search, customer_id) => {
    var contacts = await fetch('/api/adminp/contactPerson/findAll', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            customer_id,
            params: {
                where: {
                    $or: [
                        {
                            contactp_name: { $iLike: '%' + search + '%' },
                        },
                        {
                            contactp_phone: { $iLike: '%' + search + '%' },
                        },
                        {
                            contactp_mobile: { $iLike: '%' + search + '%' },
                        },
                        {
                            contactp_mail: { $iLike: '%' + search + '%' },
                        },
                        {
                            contactp_description: { $iLike: '%' + search + '%' },
                        }
                    ]
                },
                limit: 20
            }
        })
    })
    contacts = await contacts.json()
    return contacts
}
export const searchCustomers = async (search) => {
    var customers = await fetch('/api/adminp/customer/findAllCustomers', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            attributes : ['id', 'customer_name'],
            where: {
                $or: [
                    {
                        customer_code_teinor: { $iLike: '%' + search + '%' },
                    },
                    {
                        customer_name: { $iLike: '%' + search + '%' },
                    },
                    {
                        customer_fiscal_name: { $iLike: '%' + search + '%' },
                    },
                    {
                        customer_responsable_name: { $iLike: '%' + search + '%' },
                    },
                    {
                        customer_responsable_phone: { $iLike: '%' + search + '%' },
                    },
                    {
                        customer_web: { $iLike: '%' + search + '%' },
                    },
                    {
                        '$Shops.shop_name$': { $iLike: '%' + search + '%' },
                    },
                    {
                        '$Shops.shop_phone$': { $iLike: '%' + search + '%' },
                    },
                    {
                        '$Shops.shop_mobile$': { $iLike: '%' + search + '%' },
                    },
                    {
                        '$ContactPersons.contactp_name$': { $iLike: '%' + search + '%' },
                    },
                    {
                        '$ContactPersons.contactp_phone$': { $iLike: '%' + search + '%' },
                    },
                    {
                        '$ContactPersons.contactp_mobile$': { $iLike: '%' + search + '%' },
                    }
                ]
            },
            limit: 20,
            group: ['Customer.id', 'Customer.customer_name'],
            skipCount: true
        })
    })
    customers = await customers.json()
    return customers
}
export const searchProjects = async (search, customer_id, currentProjects) => {
    currentProjects = currentProjects.map((item) => {
        if(!item.from_parent){
            return item.id
        }
        return ''
    })
    var projects = await fetch('/api/adminp/project/findAll', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            customer_id,
            params: {
                where: {
                    $and: {
                        project_name: {$iLike : '%' + search + '%' },
                        id: {
                            $notIn: currentProjects
                        } 
                    }
                }
            }
        })
    })
    projects = await projects.json()
    return projects
}