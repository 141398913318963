import React, { useEffect} from 'react'
import store, { changePage } from '../../reducers/reducers'
import PhotoList from '../../minicomponents/PhotoList'
const Teams = () => {
    var translations = store.getState().language.translations
    const classifyTeams = (teams) => {
        let myteams = store.getState().user.teams
        myteams = myteams.map((item) => item.id)
        let myteamsformated = {
            myteams: {
                name: 'myteams',
                text: translations.myTeams,
                data: []
            },
            otherteams: {
                name: 'otherteams',
                text: translations.otherTeams,
                data: []
            },
        }
        teams.forEach((item) => {
            if(myteams.includes(item.id)){
                myteamsformated.myteams.data.push(item)
            }else{
                myteamsformated.otherteams.data.push(item)
            }
        })
        return myteamsformated
    }
    useEffect(() => {
        changePage({
            name: 'teams',
        })
    }, [])

    return (
        <div><PhotoList getDataFrom={{
            url:'/api/adminp/team/findAll',
            fields : [
                {
                    name: 'name', 
                    text: translations.name
                },
                {
                    name: 'description', 
                    text: translations.description
                }
            ],
            preImage: '/teamImg/',
        }} goTo='/team/' 
        classify={classifyTeams} /></div>
    );
}
 
export default Teams;