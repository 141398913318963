import React from 'react'
import {Link} from 'react-router-dom';
const MenuItem = ({item, hasoneactive}) => {
    var childs = false
    if(item.sublinks){
        childs = item.sublinks.map((child, key) => {
            return (
            <Link className={'menu_child menu_link' + ((child.active) ? ' active' : '')} to={child.goTo} key={key} idrow={child.idrow} >
                    {(child.icon) ? <i className={child.icon}></i> : ''}{(child.image) ? <img alt={child.text} name={child.text} className='menu_img user_img' src={child.image} /> : ''}<span>{child.text}</span>{(child.editable) ? <i className='fal fa-cog editable' onClick={child.editable}></i> : ''}
            </Link>
            )
        })
    }
    if(item.goTo){
        return ( 
            <div className={"menu_item" + ((hasoneactive) ? ' active' : '') + ((item.extralink) ? ' extralink' : ' normallink')}>
                <Link className={'menu_parent menu_link' + ((item.active) ? ' active' : '')} to={item.goTo} >
                    {(item.icon) ? <i className={item.icon}></i> : ''}{(item.image) ? <img alt={item.text} name={item.text} className='menu_img user_img' src={item.image}/> : ''}<span>{item.text}</span>
                </Link>
                {(childs.length) ? <div className="menu_childs"> {childs} </div> : ''}
            </div>
        );
    }else{
        return (
            <div className={"menu_item" + ((item.active) ? ' active' : '') + ((item.extralink) ? ' extralink' : ' normallink')}>
                <div className={'menu_parent menu_link' + ((item.active) ? ' active' : '')} onClick={(e) => e.currentTarget.parentElement.classList.toggle('active')}>
                    {(item.icon) ? <i className={item.icon}></i> : 'asd'}{(item.image) ? <img alt={item.text} name={item.text} className='menu_img user_img' src={item.image}/> : ''}<span>{item.text}</span>
                </div>
                {(childs.length) ? <div className="menu_childs"> {childs} </div> : ''}
            </div>
        );
    }
}
 
export default MenuItem;