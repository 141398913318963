import React, {useEffect, useState, useCallback} from 'react'
import store, {changePage} from '../../reducers/reducers';
import { Link, Redirect } from 'react-router-dom';
import {processData} from '../../utils/specialdata'
import {Pagination} from '../../minicomponents/Table'
import { CustomSelect } from '../../minicomponents/Form';
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css';
import { searchCustomers, fetchPostForm } from '../../utils/forms';
import socket from '../../utils/socket';
import { renderModal, processModalForm, closeModal, processForm } from '../../minicomponents/Modal';
import { renderNotification } from '../../utils/renderNotification';
const Repository = ({match, customer_name = false, prelink='', changeName=true }) => {
    let translations = store.getState().language.translations
    const [modal, setModal] = useState(false)
    const [notification, setNotification] = useState(false)
    const [query, setQuery] = useState({ 
        order: 'newestFirst',
        answers: 2,
        tags: [],
        name: false,
        username: false,
        customer_name
    })
    const [data, setData] = useState([])
    const [pagination, setPagination] = useState({
        page: 1,
        maxPage: 11,
        haveToPag: false,
        totalPages: Math.ceil(1),
        totalResults: 1
    })
    const [page, setPage] = useState(1)

    const updateQuery = (myquery) => {
        setQuery(myquery)
        if (page > 1) {
            setPage(1)
        } else {
            getData()
        }
    }
    const getData = useCallback(async () => {
        let myquery = {
            inners: {
                users: {},
                tag: {},
                answers: {},
                customer: {}
            },
            order: [
                (query.order === 'newestFirst' ? ['createdAt', 'DESC'] : ['createdAt', 'ASC']) ,
                ['$RepoPostAnswerModel$', 'valid', 'desc']
            ],
            where: {}
        }
        if(query.name){
            myquery.where.name = {
                '$iLike': '%'+query.name+'%'
            }
        }
        if(query.username){
            myquery.inners.users.where = {
                username: {
                    '$iLike': '%' + query.username + '%'
                }
            }
        }
        if(query.tags.length){
            let mytags = []
            for(let mytag of query.tags ){
                mytags.push({
                    '$iLike': '%'+mytag+'%'
                })
            }
            myquery.inners.tag = {
                where: {
                    name: {
                        '$or' : [
                            ...mytags
                        ]
                    }
                }
            }
        }
        if(query.customer_name){
            myquery.inners.customer.where = {
                customer_name: {
                    '$iLike': '%' + query.customer_name + '%'
                }
            }
        }
        let mydata = await fetch('/api/adminp/repo/findAll',{
            method: 'POST',
            headers: {
                'Content-Type' : 'application/json'
            },
            body: JSON.stringify({...myquery, limit: 11, offset: (page - 1) * 11})
        })
        mydata = await mydata.json()
        let mycount = mydata.count
        delete mydata.count
        setPagination({
            page,
            maxPage: 11,
            haveToPag: false,
            totalPages: Math.ceil(mycount / 11),
            totalResults: mycount
        })
        setData(mydata.data)
    }, [query, page])
    const changePagination = (info) => {
        setPage(info.page)
        setPagination({
            page: info.page,
            maxPage: 11,
            haveToPag: false,
            totalPages: info.totalPages,
            totalResults: info.totalResults
        })
    }
    useEffect(() => {
        getData()
        socket.emit('create', 'all_repo')
        socket.on('update_repo', getData)
        return () => {
            socket.off('update_repo')
            socket.emit('leave', 'all_repo')
        }
    }, [getData, query])
    useEffect(() => {
        if (changeName) {
            changePage({
                name: 'repository',
            })
        }
    }, [changeName])
    let dataRendered = []
    if(data.length){
        dataRendered = data.map((item, key) => {
            return <PostItem item={item} key={key} prelink={prelink}/>
        })
    }
    return (
        <div id='repository' className='wrap'>
            <div className={'box post_list ' + (match.params.post_id ? 'fdiv55' : 'fdiv100')}>
                <div className='box_inner'>
                    <div className='box_header aicenter'>
                        <Link className='btn_primary mrauto' to={prelink+ '/repository/new'}>
                            <i className='fal fa-plus-circle'></i><span>{translations.addQuestion}</span>
                        </Link>
                        <AdvancedSearcher updateQuery={updateQuery} query={query} customer_name={customer_name}/>
                    </div>
                    <div className='box_content list_posts'>
                        {dataRendered.length ? dataRendered: ''}
                    </div>
                    <Pagination pagination={pagination} sendInfoTo={changePagination} hideMaxPerPage={true} />
                </div>
            </div>
            <Post id={match.params.post_id} editing={match.params.editing} setModal={setModal} setNotification={setNotification} notification={notification} prelink={prelink} customer_name={customer_name}/>
            {renderModal(modal, notification, setNotification, setModal)}
        </div>
    );
}
export default Repository;

export const Post = ({id, editing, setModal, setNotification, notification, prelink, customer_name}) => {
    const [data, setData] = useState(false)
    const getPostData = useCallback(async () => {
        let post = await fetch('/api/adminp/repo/findOne', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                repopost_id: id
            })
        })
        try{
            post = await post.json()
            if(post){
                setData(post)
            }else{
                setData(false)
            }
        }catch (err){
            setData(false)
        }
    },[id])
    useEffect(() => {
        if(Number.isInteger(parseInt(id))){
            getPostData()
            socket.emit('create', 'repo_'+id)
            socket.on('update_repo_sing', getPostData)
            return () => {
                socket.off('update_repo_sing')
                socket.emit('leave', 'repo_'+id)
            }
        }
    }, [id, getPostData])
    if (!id || (!data && id !== 'new')) {
        return ''
    }else if(id === 'new'){
        return (
            <EditPost  prelink={prelink} customer_name={customer_name}/>
        )
    }else if(editing === 'edit' && id){
        return (
            <EditPost data={data} prelink={prelink}/>
        )
    }
    const deletePost = async (myid) => {
        socket.emit('update_repo', { room: 'repo_' + myid })
        socket.emit('update_repo', { room: 'all_repo' })
    }
    const deletePostModal = (myid) => {
        setModal({
            title: <div className="aicenter title_inner"><i className='fas fa-trash-alt'></i> <span>{translations.deletePost}</span></div>,
            id: 'delete_post',
            inner:
                <form className='formContainer wrap' id='deletePost' action='/api/adminp/repo/deleteRepoPost' method='POST' onSubmit={async (e) => {await processForm(e, translations.succRmPost, translations.errRmPost, setNotification); deletePost(myid)}}>
                    <span>{translations.confirmDeletePost}</span>
                    <input type='hidden' name='id' value={myid}/>
                    <button id='submit' type='submit' className='notInclude' style={{ display: 'none' }}></button>
                </form>
            ,
            onAccept: () => processModalForm('deletePost'),
            careModal: true,
            notification: renderNotification(notification),
            onCancel: () => closeModal(setNotification, setModal)
        })
    }
    let translations = store.getState().language.translations
    return (
        <div className='box fdiv45 mlauto mrauto post_selected'>
            <div className='box_inner'>
                <div className='box_header aicenter'>
                    <h3>{data.name}</h3>
                    <div className='edit_zone mlauto aicenter'>
                        {data.UserId === store.getState().user.id 
                        ? <div className='delete crpointer' onClick={() => deletePostModal(data.id)}><i className='fal fa-trash-alt'></i></div> 
                        : ''}
                        {data.UserId === store.getState().user.id 
                        ? <Link to={prelink+'/repository/'+data.id+'/edit'} className='edit ml10'><i className='far fa-pencil'></i></Link> 
                        : ''}
                        <Link to={prelink+'/repository/'} className='blue_icon'><i className='fa fa-times'></i></Link>
                    </div>
                </div>
                <div className='box_content'>
                    <div dangerouslySetInnerHTML={{ __html: data.description }} className='post_editor' />
                    <div className='wrap'>
                        <div className='tags mtb10 fdiv70 nopadding'>
                            <div className='aicenter'>
                                {data.RepoTags.map((item, key) => {
                                    return <div className='repo_tag' key={key}>{item.name}</div>
                                })}
                            </div>
                        </div>
                        <div className='fdiv30 nopadding taend mtb10'>
                            <h4>{data.CustomerId ? data.Customer.customer_name : ''}</h4>
                        </div>
                    </div>
                    <Comments post_id={data.id} comments={data.RepoPostsComments.filter((item) => item.RepoPostAnswerId === null)} />
                    <h2 className='mtb10 bdbt'>{translations.answers}</h2>
                    <Answers post_id={data.id} answers={data.RepoPostAnswers} comments={data.RepoPostsComments} setNotification={setNotification} notification={notification} setModal={setModal} post_owner={data.UserId}/>
                </div>
            </div>
        </div>
    );
}
export const Comments = ({post_id, answer_id = 0, comments}) => {
    let translations = store.getState().language.translations
    const deleteComment = async (id) => {
        await fetch('/api/adminp/repo/deleteComment', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id
            })
        })
        socket.emit('update_repo', { room: 'repo_' + post_id })
    }
    let comments_formated = comments.map((item, key) => {
        return (
            <div className='comment aiend' key={key}>
                <span className='comment_text'>{item.text}</span><span className='comment_username ml10'>{item.User.username} - {processData(item.updatedAt)}</span>
                {item.User.id === store.getState().user.id ? <div className='edit_zone ml10'>
                    <i className='fal fa-trash-alt crpointer' onClick={() => deleteComment(item.id)}></i>
                </div> : ''}
            </div>
        )
    })
    const submitComment = async (e) => {
        let mye = e.currentTarget
        await fetchPostForm(e) 
        mye.text.value = '' 
        socket.emit('update_repo', { room: 'repo_' + post_id })
    }
    return (
        <div className='comments'>
            <h4>{translations.comments}</h4>
            {comments_formated}
            <div className='crpointer mtb10' onClick={(e) => {e.currentTarget.parentElement.querySelector('#add_comment').classList.toggle('hidden')}}><i className='fal fa-plus-circle'></i><span>{translations.addComment}</span></div>
            <form id='add_comment' onSubmit={submitComment} className='wrap bxsh hidden' action='/api/adminp/repo/createComment' method='POST'>
                <input type='hidden' name='user_id' value={store.getState().user.id} />
                <input type='hidden' name='RepoPostId' value={post_id} />
                <input type='hidden' name='RepoPostAnswerId' value={answer_id} />
                <textarea name='text' placeholder={translations.inComment} className='noborders' style={{ height: '64px' }} maxLength='254'></textarea>
                <button type='submit' className='btn_primary mlauto mtb10 notInclude mr10'>{translations.addComment}</button>
            </form>
        </div>
    )
}
export const EditPost = ({data, prelink, customer_name}) => {
    const [queryTags, setQueryTags] = useState(
        !data ? [] : data.RepoTags.map((tag) => tag.name )
    )
    const [searchedTags, setSearchedTags] = useState([])
    const [searchedCustomers, setSearchedCustomers] = useState([])
    const [redirect, setRedirect] = useState(false)
    if (data && data.User.id !== store.getState().user.id) {
        return ''
    }
    let translations = store.getState().language.translations
    const pushTag = (val) => {
        let myQueryTags = []
        queryTags.length ? myQueryTags.push(...queryTags) : myQueryTags.push()
        if (!myQueryTags.includes(val)) {
            myQueryTags.push(val)
        }
        setQueryTags(myQueryTags)
        setSearchedTags([])
        document.getElementById('currentTag').value = ''
    }
    const controlCustomer = async (e) => {
        let c = await searchCustomers(e.currentTarget.value)
        setSearchedCustomers(c)
    }
    const controlTag = async (event) => {
        let myarray = event.currentTarget.value.split('')
        let last = myarray[myarray.length-1]
        if (last === ',') {
            myarray.splice(myarray.length-1, 1)
            let val = myarray.join('')
            pushTag(val)
        }else{
            let tags = []
            if(event.currentTarget.value !== ''){
                tags = await fetch('/api/adminp/repo/findAllTags', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        name: event.currentTarget.value
                    })
                })
                tags = await tags.json()
            }
            setSearchedTags(tags)
        }
    }
    const spliceTag = (key) => {
        let myQueryTags = []
        queryTags ? myQueryTags.push(...queryTags) : myQueryTags.push()
        myQueryTags.splice(key, 1)
        setQueryTags(myQueryTags)
    }
    const savePost = async (e) => {
        e.preventDefault()
        let name = document.getElementById('editing_name').value
        let description = document.querySelector('#editing_description .ql-editor').innerHTML
        let CustomerId = parseInt(document.getElementById('id_customer').value) !== 0 ? parseInt(document.getElementById('id_customer').value) : null
        let id = data ? data.id : undefined
        let result = await fetch(e.currentTarget.getAttribute('action'), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id,
                name,
                description,
                CustomerId,
                tags: [
                    ...queryTags
                ],
                user_id: store.getState().user.id
            })
        })
        result = await result.json()
        socket.emit('update_repo', {room : 'repo_'+result})
        socket.emit('update_repo', { room: 'all_repo'})
        setRedirect(result)
    }
    var queryTagsFormated = []
    if (queryTags.length) {
        queryTagsFormated = queryTags.map((item, key) => {
            return <div className='repo_tag' key={key} onClick={() => spliceTag(key)}>{item}</div>
        })
    }
    if(redirect){
        return <Redirect to={{
            pathname: prelink+'/repository/'+redirect
        }} />
    }
    return  (
        <div className='box fdiv45 mlauto mrauto'>
            <div className='box_inner post_editing'>
                    <div className='box_header aicenter'>
                        <h3>{data ? data.name : translations.addQuestion}</h3>
                        <Link to={prelink+'/repository'} className='mlauto'>
                            <i className='fa fa-times'></i>
                        </Link>
                    </div>
                    <div className='box_content'>
                        <form action={data ? '/api/adminp/repo/updateRepoPost' : '/api/adminp/repo/createRepoPost'} onSubmit={savePost}>
                        <h4>{translations.name}</h4>
                        <input type='text' name='name' placeholder={translations.inName} id='editing_name' required defaultValue={data ? data.name : ''} />
                        <h4>{translations.description}</h4>
                        <ReactQuill theme='snow' modules={{
                            toolbar: ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block', {
                                'size': ['large', false]
                            }]
                        }} placeholder={translations.inDescription} id='editing_description' required defaultValue={data ? data.description : ''} />
                        <div className='mtb10'>
                            <h4>{translations.tags}</h4>
                            <div className='inputDiv_inner'>
                                <div className='query_tags  aicenter bd'>
                                    <div className='actual_tags'>
                                        {queryTagsFormated}
                                    </div>
                                    <div className={'iconDisplayer' + (searchedTags && searchedTags.length ? ' active' : '')}>
                                        <div className='iconDisplayerbutton'>
                                            <input type='text' placeholder={translations.tags} onChange={controlTag} className='noborders' id='currentTag' onBlur={() => window.setTimeout(function(){setSearchedTags([])}, 1000)} />
                                        </div>
                                        <div className='iconDisplayer_inner'>
                                            {searchedTags.map((tg, key) => {
                                                return <div key={key} onClick={() => pushTag(tg.name)} >{tg.name}</div>
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='mtb10'>
                            <h4>{translations.customer}</h4>
                            <div className={'iconDisplayer' + (searchedCustomers && searchedCustomers.length ? ' active' : '')}>
                                <div className='iconDisplayerbutton'>
                                    <input type='text' id='id_customer' defaultValue={data && data.CustomerId ? data.CustomerId : customer_name ? prelink.split('/')[2] : '0'} className='hidden' />
                                    <input type='text' className={data && data.CustomerId ? 'fakeDisabled' : customer_name ? 'fakeDisabled' : ''} defaultValue={data && data.CustomerId ? data.Customer.customer_name : customer_name ? customer_name : ''} placeholder={translations.nameCustomer} onChange={controlCustomer} id='currentCustomer' onBlur={() => window.setTimeout(function(){setSearchedCustomers([])}, 1000)} onClick={() => {
                                        document.getElementById('currentCustomer').classList.remove('fakeDisabled')
                                        document.getElementById('currentCustomer').value = ''
                                        document.getElementById('id_customer').value = 0
                                    }} />
                                </div>
                                <div className='iconDisplayer_inner'>
                                    {searchedCustomers.map((customer, key) => {
                                        return <div key={key} onClick={(e) => {
                                            e.stopPropagation()
                                            document.getElementById('id_customer').value = customer.id
                                            document.getElementById('currentCustomer').value = customer.customer_name
                                            document.getElementById('currentCustomer').classList.add('fakeDisabled')
                                            setSearchedCustomers([])
                                        }} >{customer.customer_name}</div>
                                    })}
                                </div>
                            </div>
                        </div>
                        <button className='btn_primary createPost' type='submit'>{translations.save}</button>
                        </form>
                    </div>
            </div>
        </div>
    )
}
export const Answers = ({ post_id, answers, comments, setModal, setNotification, notification, post_owner }) => {
    let translations = store.getState().language.translations
    const submitAnswer = async (e, updating = false) => {
        let mytarget = e.currentTarget
        e.currentTarget.text.value = mytarget.querySelector('.ql-container .ql-editor').innerHTML
        await fetchPostForm(e)
        if(!updating){
            mytarget.querySelector('.ql-container .ql-editor').innerHTML = ''
        }
        socket.emit('update_repo', { room: 'repo_' + post_id })
    }
    const deleteAnswer = async (myid) => {
        socket.emit('update_repo', { room: 'repo_' + myid })
        socket.emit('update_repo', { room: 'all_repo' })
    }
    const deleteAnswerModal = (myid, post_id) => {
        setModal({
            title: <div className="aicenter title_inner"><i className='fas fa-trash-alt'></i> <span>{translations.deleteAnswer}</span></div>,
            id: 'delete_post_answer',
            inner:
                <form className='formContainer wrap' id='deleteAnswer' action='/api/adminp/repo/deleteAnswer' method='POST' onSubmit={async (e) => { await processForm(e, translations.succRmAnswer, translations.errRmAnswer, setNotification); deleteAnswer(post_id) }}>
                    <span>{translations.confirmDeleteAnswer}</span>
                    <input type='hidden' name='id' value={myid} />
                    <button id='submit' type='submit' className='notInclude' style={{ display: 'none' }}></button>
                </form>
            ,
            onAccept: () => processModalForm('deleteAnswer'),
            careModal: true,
            notification: renderNotification(notification),
            onCancel: () => closeModal(setNotification, setModal)
        })
    }
    const changeValid = async (myid, valid) => {
        await fetch('/api/adminp/repo/updateAnswer', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: myid,
                valid
            })
        })
        socket.emit('update_repo', { room: 'repo_' + myid })
        socket.emit('update_repo', { room: 'all_repo' })
    }
    var answersformated = answers.map((item, key) => {
        return (
            <div className='answer post_editor' key={key}>
                <div className='user aicenter bdbt mb10'>
                    <img src={'/userImg/'+item.User.image} alt='' className='user_img miniimg'/>
                    <h4 className='ml10'>{item.User.username}</h4>
                    <div className='right_zone mlauto aicenter'>
                        {item.valid || post_owner === store.getState().user.id ? <i className={'fas fa-check-circle crpointer mr10' + (item.valid ? ' valid' : '')} onClick={post_owner === store.getState().user.id ? () => changeValid(item.id, !item.valid) : () => false}></i> : ''} 
                        {item.User.id === store.getState().user.id ? <i className='fal fa-trash-alt crpointer mr10' onClick={() => deleteAnswerModal(item.id, post_id)}></i> : ''}
                        {item.User.id === store.getState().user.id ? <i className='far fa-pencil crpointer mr10' onClick={() => {
                            document.getElementById('edit_answer_'+item.id).classList.toggle('hidden')
                            document.getElementById('answer_inner_'+item.id).classList.toggle('hidden')
                        }}></i> : ''}
                        <div className='date'>
                            {processData(item.updatedAt)}
                        </div>
                    </div>
                </div>
                <div dangerouslySetInnerHTML={{ __html: item.text }} className='answer_inner' id={'answer_inner_'+item.id}/>
                {item.User.id === store.getState().user.id 
                ? <form id={'edit_answer_'+item.id} onSubmit={(e) => submitAnswer(e, true)} className='wrap bxsh mtb10 hidden edit_answer' action='/api/adminp/repo/updateAnswer' method='POST'>
                    <input type='hidden' name='user_id' value={store.getState().user.id} />
                    <input type='hidden' name='post_id' value={post_id} />
                    <input type='hidden' name='id' value={item.id}/>
                    <textarea name='text' className='hidden'></textarea>
                    <ReactQuill theme='snow' modules={{
                        toolbar: ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block', {
                            'size': ['large', false]
                        }]
                    }} placeholder={translations.inAnswer} id='answer' required defaultValue={item.text} />
                    <button type='submit' className='btn_primary mlauto mtb10 mr10 notInclude'>{translations.modifyAnswer}</button>
                </form>
                : ''}
                <Comments post_id={post_id} comments={comments.filter((cm) => cm.RepoPostAnswerId === item.id)} answer_id={item.id}/>
            </div>
        )
    })
    return (
        <div className='answers'>
            {answersformated}
            <h3 className='bdbt'>{translations.addAnswer}</h3>
            <form id='add_answer' onSubmit={submitAnswer} className='wrap bxsh mtb10' action='/api/adminp/repo/createAnswer' method='POST'>
                <input type='hidden' name='user_id' value={store.getState().user.id} />
                <input type='hidden' name='post_id' value={post_id} />
                <textarea name='text' className='hidden'></textarea>
                <ReactQuill theme='snow' modules={{
                    toolbar: ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block', {
                        'size': ['large', false]
                    }]
                }} placeholder={translations.inAnswer} id='answer' required defaultValue={''} />
                <button type='submit' className='btn_primary mlauto mtb10 mr10 notInclude'>{translations.addAnswer}</button>
            </form>
        </div>
    )
}
export const PostItem = ({item, prelink}) => {
    let translations = store.getState().language.translations
    let formatTags = []
    if(item.RepoTags.length){
        item.RepoTags.forEach((tag, key) => {
            if(key < 5){
                formatTags.push(<div className='repo_tag' key={key}>{tag.name}</div>)
            }else if(key === 5){
                formatTags.push(<div className='more_tags ml10'>...</div>)
            }
        })
    }
    return (
        <Link className='postitem_data wrap bdbt' to={prelink+'/repository/'+item.id}>
            <div className={'answers fdiv15 ' + (item.RepoPostAnswers.length && item.RepoPostAnswers[0].valid ? 'valid' : 'notanswered')}>
                <div className='number'>
                    {item.RepoPostAnswers.length}
                </div>
                <h5>{translations.answers}</h5>
            </div>
            <div className='mid_side fdiv40'>
                <h4 className='repo_name'>
                    {item.name}
                </h4>
                <div className='tags aicenter'>
                    {formatTags}
                </div>
            </div>
            <div className='customer fdiv20'>
                <h4>{translations.customer}</h4>
                {item.Customer ? item.Customer.customer_name : ''}
            </div>
            <div className='right_side fdiv25'>
                <div className='user aistart'>
                    <img src={'/userImg/'+item.User.image} alt='' className='user_img miniimg'/>
                    <span className='username ml10'>
                        {item.User.username}
                    </span>
                </div>
                <div className='created_data'>
                    {processData(item.createdAt)}
                </div>
            </div>
        </Link>
    )
}
export const AdvancedSearcher = ({query, updateQuery, customer_name}) => {
    const [opened, setOpened] = useState(false)
    let translations = store.getState().language.translations
    const changeQuery = (name, val) => {
        query[name] = val
        updateQuery(query)
    }
    var orderOptions = [
        {
            value: 'newestFirst',
            text: translations.newestFirst
        },
        {
            value: 'olderFirst',
            text: translations.olderFirst
        },
    ]
    const controlTag = (event) => {
        if(event.keyCode === 188){
            if(!query.tags.includes(event.currentTarget.value)){
                query.tags.push(event.currentTarget.value)
            }
            event.preventDefault()
            changeQuery('tags', query.tags)
            event.currentTarget.value = ''
        }
    }
    const spliceTag = (key) => {
        query.tags.splice(key, 1)
        changeQuery('tags', query.tags)
    }
    var queryTags = []
    if(query.tags.length){
        queryTags = query.tags.map((item, key) => {
            return <div className='repo_tag' key={key} onClick={() => spliceTag(key)}>{item}</div>
        })
    }
    return (
        <div className={'advanced_searcher iconDisplayer' + (opened ?  ' active' : '')}>
            <div className='search_header iconDisplayerbutton' onClick={() => {setOpened(!opened)}}>
                <span>{translations.searchBy}</span>
                <i className='fa fa-cog'></i>
            </div>
            {opened ?
                <div className='iconDisplayer_inner'>
                    <div className='inputDiv'>
                        <div className='inputDiv_inner'>
                            <h6>{translations.orderBy}</h6>
                            <CustomSelect selectProps={{ onChange: (val) => changeQuery('order', val), value: query.order }} options={orderOptions} />
                        </div>
                    </div>
                    <div className='inputDiv'>
                        <div className='inputDiv_inner'>
                            <h6>{translations.tags}</h6>
                            <div className='query_tags aicenter bd'>
                                <div className='actual_tags'>
                                {queryTags}
                                </div>
                                <input type='text' placeholder={translations.inTags} onKeyDown={controlTag} className='noborders'/>
                            </div>
                        </div>
                    </div>
                    <div className='inputDiv'>
                        <div className='inputDiv_inner'>
                            <h6>{translations.name}</h6>
                            <input type='text' placeholder={translations.inName} onChange={(e) => changeQuery('name', e.currentTarget.value) } value={query.name ? query.name : ''}/>
                        </div>
                    </div>
                    <div className='inputDiv'>
                        <div className='inputDiv_inner'>
                            <h6>{translations.createdBy}</h6>
                            <input type='text' placeholder={translations.inUsername} onChange={(e) => changeQuery('username', e.currentTarget.value)} value={query.username ? query.username : ''}/>
                        </div>
                    </div>
                    <div className='inputDiv'>
                        <div className='inputDiv_inner'>
                            <h6>{translations.customer}</h6>
                            <input type='text' placeholder={translations.inName} onChange={(e) => changeQuery('customer_name', e.currentTarget.value)} value={query.customer_name ? query.customer_name : ''}/>
                        </div>
                    </div>
                    <div className='inputDiv aiend'>
                        <div className='inputDiv_inner '>
                            <div className='btn_primary' onClick={() => 
                                updateQuery({
                                    order: 'newestFirst',
                                    answers: 2,
                                    tags: [],
                                    name: false,
                                    username: false,
                                    customer_name
                                })
                            }>
                                {translations.reset}
                            </div>
                        </div>
                    </div>
                </div>
            : ''}
        </div>
    )
}