import React, {useState, useEffect} from 'react';
import store, { changePage } from '../../reducers/reducers'
import MyCalendar from '../../minicomponents/MyCalendar';
import { connect } from 'react-redux'
const mapStateToProps = state => ({ user: state.notifications })
const UserCalendar = ({match}) => {
    const [getDataFrom] = useState({
        '/api/adminp/task/getTaskByUser' : {user_id: (match.params.id) ? match.params.id : store.getState().user.id}
    })
    var monthless = new Date()
    monthless.setMonth(monthless.getMonth() - 1)
    var monthmore = new Date()
    monthmore.setMonth(monthmore.getMonth() + 1)
    const [data, setData] = useState([])
    const [where, setWhere] = useState({
        max_date: {
            $between: [
                monthless.toUTCString(),
                monthmore.toUTCString()
            ]
        }
    })
    const myGetData = async (getDataFrom, where) => {
        let tasks = []
        for(let k in getDataFrom){
            let item = getDataFrom[k]
            let key = Object.keys(item)[0]
            let val = item[key]
            item = {}
            item[key] = val
            let result = await fetch(k, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ...item,
                    params: {
                        where: {
                            ...where
                        }
                    }
                })
            })
            result = await result.json()
            result = result.data
            result.forEach((item) => {
                if(tasks[item.id] === undefined){
                    tasks[item.id] = {
                        title: item.task_name,
                        goTo: '/user/' + ((match.params.id) ? match.params.id : store.getState().user.id) + '/' + item.id,
                        completed: item.completed,
                        start: new Date(item.max_date),
                        end: new Date(item.max_date)
                    }
                }
            })
        }
        setData(tasks)
    }
    const [getData] = useState(() => myGetData)
    let notifications = store.getState().notifications
    useEffect(() => {
        getData(getDataFrom, where)
    }, [notifications, getData, getDataFrom, where])
    const changeWhere = (dataStart) => {
        var start = false
        var finish = false
        if(Array.isArray(dataStart)){
            start = dataStart[0].setDate(dataStart[0].getDate())
            start = new Date(start)
            let length = dataStart.length - 1
            finish = dataStart[length].setDate(dataStart[length].getDate()+1)
            finish = new Date(finish)
        }else{
            start = dataStart.start.setDate(dataStart.start.getDate()-1)
            finish = dataStart.end.setDate(dataStart.end.getDate()+1)
            start = new Date(start)
            finish = new Date(finish)
        }
        setWhere({
            max_date: {
                $between: [
                    start.toUTCString(),
                    finish.toUTCString()
                ]
            }
        })
    }
    useEffect(() => {
        changePage({
            name: 'user_calendar',
        })
    }, [])
    return (
        <div id='userCalendar' className='p1em'>
            <MyCalendar onRangeChange={changeWhere} events={data}/>
        </div>
    );
}
 
export default connect(mapStateToProps)(UserCalendar);