import {combineReducers, createStore} from 'redux'
import user from './user'
import page, {getPage} from './page'
import notifications from './notifications'
import language from './language'
import chats from './chats'
import customfields from './customfields'
import customorder from './customorder'
import helpers from './helpers'
import socket from '../utils/socket';
import esEs from './translations/esEs'
import enEn from './translations/enEn'
import caCa from './translations/caCa'
import frFr from './translations/frFr'
import working from './working'
const equals = require('array-equal')
const rootReducer = combineReducers({
    user,
    page,
    notifications,
    language,
    chats,
    customfields,
    customorder,
    working,
    helpers
})
const store = createStore(rootReducer)
export const changeUser = async () => {
    var result = await fetch('/api/user/getSession', {
        method: 'GET'
    })
    result = await result.json()
    if ((result || (store.getState().page && !result)) && (!store.getState().user || !equals(JSON.stringify(store.getState().user), JSON.stringify(result)))){
        var data = { type: 'CHANGE_USER', user: result}
        store.dispatch(data)
    }
}
export const destroyUser = async () => {
    socket.emit('update_user', store.getState().user.id)
    socket.emit('leave', 'chat_room')
    socket.emit('leave_chat')
    await fetch('/api/user/destroySession')
    window.setTimeout(async () => {
        var result = await fetch('/api/user/getSession', {
            method: 'GET'
        })
        result = await result.json()
        var data = { type: 'CHANGE_USER', user: result }
        store.dispatch(data)
    }, 250)
}
export const changePage = async (tmpdata) => {
    var page = await getPage(tmpdata.name)
    if(tmpdata.subname){
        page.subname = tmpdata.subname
    }
    var data = {type : 'CHANGE_PAGE', page}
    store.dispatch(data)
}
export const updateNotifications = async (socketed = true, limit = false, removed = false, need_fill = false) => {
    if((socketed || limit) || !store.getState().notifications || need_fill){
        let teams = await store.getState().user.teams
        let where = {'$or': []}
        if(teams){
            teams.forEach((item) => {
                where['$or'].push({
                    '$and': {
                        log_type: 4,
                        target: String(item.id),
                        UserId: {$not: store.getState().user.id}  
                    }
                })
            })
        }
        where['$or'].push({
            '$and': {
                log_type: 3,
                target: String(store.getState().user.id),
                UserId: {$not: store.getState().user.id}  
            }
        })
        var currLimit = {}
        if(!socketed){
            if(limit){
                currLimit = limit
            }else if(store.getState().notifications.limit){
                currLimit = store.getState().notifications.limit
            }else{
                currLimit = {
                    limit: 11,
                    offset: 0
                }
            }
        }else if (!removed){
            currLimit = {
                limit: 1,
                offset: 0
            }
        }else{
            if (store.getState().notifications.limit){
                currLimit = {
                    limit: store.getState().notifications.limit.offset+11,
                    offset: 0
                }
            }else{
                currLimit = {
                    limit: 11,
                    offset: 0
                }
            }
        }
        var notifications = await fetch('/api/adminp/task/getActivity', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                need_fill,
                where: {
                    ...where
                },
                ...currLimit,
                order: [[
                    'updatedAt', 'DESC'
                ]]
            })
        })
        notifications = await notifications.json()
        if(socketed){
            notifications.limit = store.getState().notifications.limit
            if (!removed) {
                notifications.activity = [...notifications.activity, ...store.getState().notifications.activity]
            }
        }else if(limit || store.getState().notifications.limit){
            if(limit){
                notifications.limit = currLimit
                notifications.activity = [...store.getState().notifications.activity, ...notifications.activity]
            }else if(!socketed){
                notifications.limit = currLimit
                notifications.activity = store.getState().notifications.activity
            }
        }
        if (!store.getState().notifications || !equals(JSON.stringify(store.getState().notifications), JSON.stringify(notifications))){
            var data = {type: 'CHANGE_NOTIFICATIONS', notifications}
            store.dispatch(data)
            let howMany = 0
            if (notifications.last_readed) {
                let last = new Date(notifications.last_readed)
                howMany = notifications.activity.filter((notifc) => new Date(notifc.updatedAt) > last).length
            } else {
                if (notifications.activity.length >= 11) {
                    howMany = '+10'
                } else {
                    howMany = notifications.activity.length
                }
            }
            if ((howMany >= 1 || howMany === '+10') && window.innerWidth >= 1300) {
                try {
                    new Notification(store.getState().language.translations.youHave + ' ' + howMany + ' ' + store.getState().language.translations.notifications)
                } catch (err) {
                    console.log(err)
                }
            }
        }
    }
}
export const updateLanguage = async () => {
    let result = await fetch('/api/user/getLang', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    result = await result.text()
    if(!result){
        result = 'en'
    }
    if(!store.getState().language.lang_iso !== result){
        let translations = {}
        if(result === 'es'){
            translations = esEs
        }else if(result === 'en'){
            translations = enEn
        }else if(result === 'ca'){
            translations = caCa
        }else if(result === 'fr'){
            translations = frFr
        }
        var language = { lang_iso: result, translations }
        var data = {type: 'CHANGE_LANGUAGE', language}
        store.dispatch(data)
    }
}
export const updateCustomFields = async () => {
    var customfields = await fetch('/api/adminp/customfield/findAll', {
        method: 'POST',
        headers: {
            'Content-Type' : 'application/json'
        }
    })
    customfields = await customfields.json()
    if(customfields){
        var data = {type: 'CHANGE_CUSTOMFIELDS', customfields}
        store.dispatch(data)
    }
}
export const updateCustomOrder = async () => {
    var customorder = await fetch('/api/adminp/adminSettings/findAllCustomOrders', {
        method: 'POST',
        headers: {
            'Content-Type' : 'application/json'
        }
    })
    customorder = await customorder.json()
    if(customorder){
        var data = {type: 'CHANGE_CUSTOMORDER', customorder}
        store.dispatch(data)
    }
}
export const updateHelpers = async () => {
    let helpers = await fetch('/api/adminp/user/findAllTaskHelpers', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            user_id: store.getState().user.id
        })
    })
    helpers = await helpers.json()
    if(helpers){
        var data = { type: 'CHANGE_HELPERS', helpers }
        store.dispatch(data)
    }
}
export const updateWorking = (working) => {
    var data = { type: 'CHANGE_WORKING', working }
    store.dispatch(data)
}
export default store