export const processData = (data, onlyDate = false) => {
    let tmpdata = new Date(data)
    let dataformated = false
    if(onlyDate){
        dataformated = tmpdata.toLocaleDateString()
    }else{
        dataformated = tmpdata.toLocaleString()
    }
    return dataformated
}
export const specialOrderBy = (order, data) => {
    order.reverse()
    for(let k in order){
        data = orderByRound(order[k], data)
    }
    return data
}
export const selectionSort = (array) => {
    let sorted_pos = 0
    while (sorted_pos < array.length) {
        let min_num = sorted_pos
        for (let k = min_num; k < array.length; k++) {
            if (array[min_num] > array[k]) {
                min_num = k
            }
        }
        let item = array[min_num]
        array[min_num] = array[sorted_pos]
        array[sorted_pos] = item
        sorted_pos++
    }
    return array
}
export const orderByRound = (key, data) => {
    let findIn = key[0].split('.')
    let order = key[1]
    let myval = (order === 'ASC') ? 9999999 : 0
    if (findIn[0] === 'task_name') {
        data.sort(function (a, b) {
            if ((a.task_name.toUpperCase()) < (b.task_name).toUpperCase()) { return -1; }
            if ((a.task_name.toUpperCase()) > (b.task_name).toUpperCase()) { return 1; }
            return 0;
        })
    } else{
        data.sort(function(a, b){
            let fieldA = a
            let fieldB = b
            for(let k in findIn){
                let mykey = findIn[k]
                if(fieldA[mykey] !== undefined){
                    fieldA = fieldA[mykey]
                }else{
                    fieldA = myval
                }
                if(fieldB[mykey] !== undefined){
                    fieldB = fieldB[mykey]
                }else{
                    fieldB = myval
                }
            }
            return fieldA - fieldB
        })
    }
    if(!myval){
        data.reverse()
    }
    return data
}