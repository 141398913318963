import React, {useState, useEffect} from 'react';
import store, { changePage } from '../../reducers/reducers';
import Table from '../../minicomponents/Table'
import { returnNotifiPostForm } from '../../utils/forms'
import { renderNotification } from '../../utils/renderNotification'
import Modal from '../../minicomponents/Modal'
import Tabs from '../../minicomponents/Tabs'
import Breadcrumb from '../../minicomponents/Breadcrumb'
import { Switch, CustomSelect, CsvInner } from '../../minicomponents/Form'
import socket from '../../utils/socket';
const AdminUsers = ({match}) => {
    var translations = store.getState().language.translations
    const [modal, setModal] = useState(false)
    const [notification, setNotification] = useState(false)
    const removeInvite = async(e) => {
        var id = parseInt(e.target.parentElement.parentElement.getAttribute('idrow'))
        setModal({
            title:<div className="aicenter title_inner"><i className="fas fa-trash-alt"></i> <span>{translations.deleteInvitation}</span></div>,
            careModal:true,
            id:'remove_inviteduser',
            inner:
                <form className='formContainer wrap' id='deleteInvite' action='/api/adminp/user/deleteInvite' method='POST' onSubmit={(e) => processForm(e, translations.succRmUser, translations.errRmUser)}>
                    <span>{translations.confirmDeleteUser}</span>
                    <input type='hidden' name='id' value={id} readOnly/>
                    <button id='submit' type='submit' className='notInclude' style={{ display: 'none' }}></button>
                </form>
            ,
            onAccept:() => processModalForm('deleteInvite'),
            notification:renderNotification(notification),
            onCancel:closeModal
        })
    }
    const removeUser = async(e) => {
        var id = parseInt(e.target.parentElement.parentElement.getAttribute('idrow'))
        setModal({
            title:<div className="aicenter title_inner"><i className="fas fa-trash-alt"></i> <span>{translations.deleteUser}</span></div>,
            careModal:true,
            id:'remove_user',
            inner:
                <form className='formContainer wrap' id='removeUser' action='/api/adminp/user/removeUser' method='POST' onSubmit={(e) => processForm(e, translations.succRmUser, translations.errRmUser)}>
                    <span>{translations.confirmDeleteUser}</span>
                    <input type='hidden' name='id' value={id} readOnly/>
                    <button id='submit' type='submit' className='notInclude' style={{ display: 'none' }}></button>
                </form>
            ,
            onAccept:() => processModalForm('removeUser'),
            notification:renderNotification(notification),
            onCancel:closeModal
        })
    }
    const processModalForm = async (name) => {
        if(name === 'modifyUser'){
            if(document.forms[name].repeatpassword.value === document.forms[name].password.value){
                document.forms[name].submit.click()    
            }else{
                setNotification({
                    type: 'error',
                    msg: translations.pwNotMatch
                })
            }
        }else{
            document.forms[name].submit.click()
        }
    }
    const addNew = () => {
        setModal({ 
            title:<div className="aicenter title_inner"><i className='fas fa-plus-square'></i> <span>{translations.addUser}</span></div>, 
            id:'add_user',
            inner:
                <form className='formContainer wrap' id='createUserRegister' action='/api/adminp/user/createUserRegister' method='POST' onSubmit={(e) => processForm(e, translations.succUserInvited, translations.errUserInvited)}>
                    <div className='inputDiv'>
                        <h6>{translations.email}</h6>
                        <input type='email' name='email' placeholder={translations.inEmail} required/>
                    </div>
                    <div className='inputDiv'>
                        <h6>{translations.typeUser}</h6>
                        <CustomSelect
                            selectProps={{ required: true, name: 'admin_permissions', placeholder: translations.inTypeUser}}
                            options={[
                                {
                                    text: translations.user,
                                    value: 0
                                },
                                {
                                    text: translations.admin,
                                    value: 1
                                }
                            ]}
                        />
                    </div>
                    <button id='submit' type='submit' className='notInclude' style={{display: 'none'}}></button>
                </form>
            ,
            onAccept:() => processModalForm('createUserRegister'),
            notification:renderNotification(notification),
            onCancel:closeModal
        })
    }
    const addNewCsv = () => {
        setModal({ 
            title:<div className="aicenter title_inner"><i className='fas fa-plus-square'></i> <span>{translations.addCsv}</span></div>, 
            id:'add_user_csv',
            inner:
                <CsvInner 
                    exampleCsv='/users_ex.csv' 
                    text={translations.addUserCsv} 
                    fields={['email', 'admin_permissions']} 
                    sendDataTo='/api/adminp/user/createUserRegister'
                    setNotification={setNotification}
                />
            ,
            onAccept:closeModal,
            notification:renderNotification(notification),
            onCancel:closeModal
        })
    }
    const modifyUser = async(e) => {
        var id = false
        if(!e.currentTarget.getAttribute('idrow')){
            id = parseInt(e.target.parentElement.parentElement.getAttribute('idrow'))
        }else{
            id = parseInt(e.currentTarget.getAttribute('idrow'))
        }
        var result = await fetch('/api/adminp/user/findOne',{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                where: {id}
            })
        })
        result = await result.json()
        if(result){
            setModal({
                title: <div className="aicenter title_inner"><i className='fas fa-pen-square'></i> <span>{translations.modifyUser}</span></div>,
                id: 'modify_user',
                inner:
                    <form className='formContainer wrap' id='modifyUser' action='/api/adminp/user/updateUser' method='POST' onSubmit={async (e) => { processForm(e, translations.succUpdUser, translations.errUpdUser); socket.emit('update_user', id)}}>
                        <div className='inputDiv max100'>
                            <Switch checkboxProps={{ id: 'active', name: 'active', defaultChecked: result.active }} text={translations.active} />
                        </div>
                        <div className='inputDiv'>
                            <h6>{translations.username}</h6>
                            <input type='username' name='username' placeholder={translations.inUsername} required defaultValue={result.username}/>
                        </div>
                        <div className='inputDiv'>
                            <h6>{translations.email}</h6>
                            <input type='email' name='email' placeholder={translations.inEmail} required defaultValue={result.email}/>
                        </div>
                        <div className='inputDiv'>
                            <h6>{translations.name}</h6>
                            <input type='firstname' name='firstname' placeholder={translations.inName} required defaultValue={result.firstname}/>
                        </div>
                        <div className='inputDiv'>
                            <h6>{translations.firstsurname}</h6>
                            <input type='firstsurname' name='firstsurname' placeholder={translations.inFirstsurname} required defaultValue={result.firstsurname}/>
                        </div>
                        <div className='inputDiv'>
                            <h6>{translations.secondsurname}</h6>
                            <input type='secondsurname' name='secondsurname' placeholder={translations.inSecondsurname} defaultValue={result.secondsurname}/>
                        </div>
                        <div className='inputDiv'>
                            <h6>{translations.typeUser}</h6>
                            <CustomSelect 
                            selectProps={{required: true, name:'admin_permissions', placeholder:translations.inTypeUser, value:result.admin_permissions}} 
                            options={[
                                {
                                    text: translations.user,
                                    value: 0
                                },
                                {
                                    text: translations.admin,
                                    value: 1
                                }
                            ]}
                            />
                        </div>
                        <div className='inputDiv'>
                            <h6>{translations.updatePassword}</h6>
                            <input type='password' name='password' placeholder={translations.updatePassword} />
                        </div>
                        <div className='inputDiv'>
                            <h6>{translations.repeatPassword}</h6>
                            <input type='password' name='repeatpassword' className='notInclude' placeholder={translations.repeatPassword} />
                        </div>
                        <input type='hidden' name='id' value={id} readOnly />
                        <button id='submit' type='submit' className='notInclude' style={{ display: 'none' }}></button>
                    </form>
                ,
                onAccept: () => processModalForm('modifyUser'),
                notification: renderNotification(notification),
                onCancel: closeModal
            })
        }
    }
    const closeModal = () => {
        setNotification(false)
        setModal(false)
    }
    var tabOptions = [
        {
            text: translations.user,
            to: '/admin/users/'
        },
        {
            text: translations.invited,
            to: '/admin/users/invited'
        }
    ]
    var table = ''
    if(match.params.invited){
        tabOptions[1].active = true
        table = {
            name: 'invited_users',
            getDataFrom: {
                from: '/api/adminp/user/findAllInvited',
                method: 'POST'
            },
            pagination: true,
            actionButtonsHeader: [
                {
                    text: translations.addUser,
                    className: 'btn_primary add',
                    icon: 'far fa-plus-circle',
                    action: addNew,
                    type: 'button'
                },
                {
                    text: translations.addCsv,
                    className: 'btn_primary add',
                    icon: 'fal fa-file-import',
                    action: addNewCsv,
                    type: 'button'
                }
            ],
            actionButtonsTable: [
                {
                    className: 'remove',
                    icon: 'fal fa-trash-alt',
                    action: removeInvite,
                    type: 'button'
                }
            ],
            fields: [
                {
                    name: 'email',
                    text: translations.email,
                    searchable: true,
                    sortable: true
                },
                {
                    name: 'admin_permissions',
                    text: translations.typeUser,
                    customResponse: {
                        1: translations.admin,
                        default: translations.user
                    },
                    sortable: true
                },
            ]
        }
    }else{
    tabOptions[0].active = true
    table = {
        name: 'users',
        getDataFrom: {
            from: '/api/adminp/user/findAll',
            method: 'POST'
        },
        pagination: true,
        actionButtonsHeader: [
            {
                text: translations.addUser,
                className: 'btn_primary add',
                icon: 'far fa-plus-circle',
                action: addNew,
                type: 'button'
            },
            {
                text: translations.addCsv,
                className: 'btn_primary add',
                icon: 'fal fa-file-import',
                action: addNewCsv,
                type: 'button'
            }
        ],
        actionOnClickRow: modifyUser,
        actionButtonsTable: [
            {
                className: 'edit',
                icon: 'far fa-pencil',
                action: modifyUser,
                type: 'button'
            },
            {
                className: 'remove',
                icon: 'fal fa-trash-alt',
                action: removeUser,
                type: 'button'
            }
        ],
        fields: [
            {
                name: 'image',
                text: translations.image,
                variableResponse: (response) => <img src={'/userImg/'+response} className='user_img' alt=''/>
            },
            {
                name: 'username',
                text: translations.user,
                searchable: true,
                sortable: true
            },
            {
                name: 'email',
                text: translations.email,
                searchable: true,
                sortable: true
            },
            {
                name: 'firstname',
                text: translations.name,
                searchable: true,
                sortable: true
            },
            {
                name: 'firstsurname',
                text: translations.firstsurname,
                sortable: true
            },
            {
                name: 'admin_permissions',
                text: translations.typeUser,
                customResponse: {
                    1: translations.admin,
                    default: translations.user
                },
                sortable: true
            },
            {
                name: 'active',
                text: translations.status,
                customResponse: {
                    true: <span className='greenfield'><i className='fas fa-circle'></i><span>{translations.active}</span></span>,
                    false: <span className='redfield'><i className='fas fa-circle'></i><span>{translations.disabled}</span></span>
                },
                sortable: true
            }
        ]
    }
    }
    var renderModal = ''
    if(modal){
        if (notification && notification.type !== 'error') {
            window.setTimeout(function(){
                closeModal()
            },1000)
        }
        modal.notification = renderNotification(notification)
        renderModal = <Modal {...modal} />
    }
    const processForm = async (e, positiveMessage, negativeMessage) => {
        var notification = await returnNotifiPostForm(e, positiveMessage, negativeMessage)
        setNotification(notification)
    }
    useEffect(() => {
        changePage({
            name: 'adminusers',
        })
    }, [])
    return (
        <div>
            <Tabs tabOptions={tabOptions} display={<Table table={table} />} rightSide={<Breadcrumb />}/>
            {renderModal}
        </div> 
    );
}
 
export default AdminUsers;