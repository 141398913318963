import React, {useState, useEffect} from 'react'
import store, { changePage } from '../../reducers/reducers';
import { processModalForm, closeModal, processForm, renderModal } from '../../minicomponents/Modal';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move'
import socket from '../../utils/socket';
import { IconDisplay, ColorsFormated } from '../../minicomponents/SpecialDisplayers';
import { Switch } from '../../minicomponents/Form';
const AdminCustomFields = () => {
    var translations = store.getState().language.translations
    const [notification, setNotification] = useState(false)
    const [modal, setModal] = useState(false)
    const [customFields, setCustomFields] = useState(store.getState().customfields)
    const [fieldOpened, setFieldOpened] = useState(false)
    var cfredux = store.getState().customfields
    useEffect(() => {
        setCustomFields(cfredux)
    }, [cfredux])
    useEffect(() => {
        changePage({
            name: 'admincustomfields',
        })
    }, [])
    const myCustomAddField = async (e) => {
        await processForm(e, translations.succCreateField, translations.errCreateField, setNotification)
        socket.emit('update_custom_fields')
    }
    const myCustomDeleteField = async (e) => {
        await processForm(e, translations.succDeleteField, translations.errDeleteField, setNotification)
        socket.emit('update_custom_fields')
    }
    const updateValue = async (params) => {
        await fetch('/api/adminp/customfield/updateCustomField', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                ...params
            })
        })
        socket.emit('update_custom_fields')
    }
    const deleteField = async (id) => {
        if(fieldOpened === id){
            setFieldOpened(false)
        }
        setModal({
            title: <div className="aicenter title_inner"><i className='fas fa-trash-alt'></i> <span>{translations.deleteField}</span></div>,
            id: 'delete_customfield',
            careModal: true,
            inner:
                <form className='formContainer wrap' id='deleteCustomField' action='/api/adminp/customfield/deleteCustomField' method='POST' onSubmit={myCustomDeleteField}>
                    <input type='hidden' name='customfield_id' value={id} readOnly />
                    <button id='submit' type='submit' className='notInclude' style={{ display: 'none' }}></button>
                </form>
            ,
            onAccept: () => processModalForm('deleteCustomField'),
            notification: '',
            onCancel: () => closeModal(setNotification, setModal)
        })
    }
    const addCustomField = async () => {
        setModal({
            title: <div className="aicenter title_inner"><i className='fas fa-plus-square'></i><span>{translations.addField}</span></div>,
            id: 'add_customfield',
            inner:
                <form className='formContainer wrap' id='addCustomField' action='/api/adminp/customfield/createCustomField' method='POST' onSubmit={myCustomAddField}>
                    <input type='text' name='customfield_name' placeholder={translations.inName} />
                    <button id='submit' type='submit' className='notInclude' style={{ display: 'none' }}></button>
                </form>
            ,
            onAccept: () => processModalForm('addCustomField'),
            notification: '',
            onCancel: () => closeModal(setNotification, setModal)
        })
    }
    var customFieldsFormated = ''
    if(customFields && customFields.length){
        customFieldsFormated = customFields.map((item, key) => {
            return <SortableItem updateValue={updateValue} item={item} key={key} translations={translations} index={item.customfield_position - 1} setFieldOpened={setFieldOpened} deleteField={deleteField}/>
        })
    }
    const modifyPosition = async ({oldIndex, newIndex}) => {
        let array = arrayMove(customFields, oldIndex, newIndex)
        let arrayformated =  array.map((item, key) => {
            item.customfield_position = key+1
            return item
        })
        setCustomFields(arrayformated)
        for(let k in arrayformated){
            let item = arrayformated[k]
            await fetch('/api/adminp/customfield/updateCustomField', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ...item
                })
            })
        }
        socket.emit('update_custom_fields')
    }
    return ( 
        <div className='admincustomfields wrap'>
            <div className='box fdiv55'>
                <div className='box_inner'>
                    <div className='box_header'>
                        <button className='btn_primary' onClick={addCustomField}>
                            <i className='far fa-plus-circle'></i>
                            <span>{translations.addField}</span>
                        </button>
                    </div>
                    <SortableContainer 
                    children={customFieldsFormated} id='sortable_customfields' onSortEnd={modifyPosition} 
                    className='box_content wrap'
                    helperContainer={document.getElementById('sortable_customfields')} useDragHandle={true} />
                </div>
            </div>
            {fieldOpened ? <EditCustomField id={fieldOpened} setModal={setModal} setNotification={setNotification} updateValue={updateValue} /> : ''}
            {renderModal(modal, notification, setNotification, setModal)}
        </div> 
    );
}
export const EditCustomField = ({id, setModal, setNotification, updateValue}) => {
    const [data, setData] = useState(false)
    const [name, setName] = useState('')
    const [children, setChildren] = useState([])
    var translations = store.getState().language.translations
    const getData = async (rerender = false, myid = id, ) => {
        var result = await fetch('/api/adminp/customfield/findOne', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                where: {id: myid},
                fill: true
            })
        })
        result = await result.json()
        setName(result.customfield_name)
        setChildren(result.childrens)
        if(rerender){
            let items = document.querySelectorAll('#sortable_customfieldsval .customfieldval_name input')
            if(items.length){
                for (let k in result.childrens) {
                    let itemn = items[k]
                    let item = result.childrens[k]
                    itemn.value = item.customfieldval_name
                }
            }
        }
        setData(result)
    }
    const [mySetData] = useState(() => getData)
    const updateName = async (e) => {
        let val = e.target.value
        if(val !== data.customfield_name){
            await fetch('/api/adminp/customfield/updateCustomField', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    id: data.id,
                    customfield_name: val
                })
            })
            socket.emit('update_custom_fields')
            getData()
        }
    }
    const addChild = async () => {
        await fetch('/api/adminp/customfield/createCustomFieldVal', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                customfield_id: data.id,
            })
        })
        socket.emit('update_custom_fields')
        getData()
    }
    const updateNameVal = async (e, id, oldname) => {
        let val = e.target.value
        if (val !== oldname) {
            await fetch('/api/adminp/customfield/updateCustomFieldVal', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    id,
                    customfieldval_name: val
                })
            })
            socket.emit('update_custom_fields')
            getData()
        }
    }
    const changeName = (e) => {
        let value = e.target.value
        setName(value)
    }
    const modifyPosition = async ({ oldIndex, newIndex }) => {
        let array = await arrayMove(data.childrens, oldIndex, newIndex)
        let arrayformated = array.map((item, key) => {
            item.customfieldval_val = key + 1
            return item
        })
        setData(arrayformated)
        let items = document.querySelectorAll('#sortable_customfieldsval .customfieldval_name input')
        for (let k in arrayformated) {
            let itemn = items[k]
            let item = arrayformated[k]
            itemn.value = item.customfieldval_name
            await fetch('/api/adminp/customfield/updateCustomFieldVal', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ...item
                })
            })
        }
        socket.emit('update_custom_fields')
        getData()
    }
    const updateColor = async (val, id) => {
        await fetch('/api/adminp/customfield/updateCustomFieldVal', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id,
                customfieldval_color: val
            })
        })
        socket.emit('update_custom_fields')
        getData()
    }
    const myCustomDeleteField = async (e) => {
        await processForm(e, translations.succDeleteField, translations.errDeleteField, setNotification)
        getData(true)
        socket.emit('update_custom_fields')
    }
    const deleteFieldVal = async (id) => {
        setModal({
            title: <div className="aicenter title_inner"><i className='fas fa-trash-alt'></i> <span>{translations.deleteField}</span></div>,
            id: 'delete_customfield',
            careModal: true,
            inner:
                <form className='formContainer wrap' id='deleteCustomField' action='/api/adminp/customfield/deleteCustomFieldVal' method='POST' onSubmit={myCustomDeleteField}>
                    <input type='hidden' name='customfieldval_id' value={id} readOnly />
                    <button id='submit' type='submit' className='notInclude' style={{ display: 'none' }}></button>
                </form>
            ,
            onAccept: () => processModalForm('deleteCustomField'),
            notification: '',
            onCancel: () => closeModal(setNotification, setModal)
        })
    }
    useEffect(() => {
        mySetData(true, id)
    }, [id, mySetData])
    var switchables = []
    if(data){
        var childsformated = children.map((item, key) => {
            return <SortableItemVal item={item} key={key} index={key} updateNameVal={updateNameVal} updateColor={updateColor} deleteFieldVal={deleteFieldVal} />
        })
        switchables.push(
            <Switch checkboxProps={{ id: 'customfield_active', defaultChecked: data.customfield_active, onChange: (e) => { updateValue({ id: data.id, customfield_active: e.currentTarget.checked }); getData(true) } }} text={translations.active} parentProps={{}} key={data.id+'s1'}/>,
            <Switch checkboxProps={{ id: 'customfield_allowList', defaultChecked: data.customfield_allowList, onChange: (e) => { updateValue({ id: data.id, customfield_allowList: e.currentTarget.checked }); getData(true) } }} text={translations.allowList} parentProps={{}} key={data.id+'s2'}/>
        )
    }
    return (
        <div className='box fdiv40 mlauto'>
            <div className='box_inner'>
                <div className='box_header aicenter'><h3>{translations.modifyCustomField}</h3>
                    <div className='customfield_actions aicenter mlauto'>
                        {switchables}
                        <i className='fal fa-plus crpointer' onClick={addChild}></i>
                    </div>
                </div>
                <div className='box_content formContainer nvpadding'>
                    <div>
                        <div className='inputDiv nomargin'>
                        <input type='text' value={name} onBlur={updateName} onChange={changeName}/>
                        </div>
                        <div id='sortable_customfieldsval'>
                        <SortableContainer children={childsformated} className='inputDiv'
                            onSortEnd={modifyPosition} 
                            helperContainer={document.getElementById('sortable_customfieldsval')} useDragHandle={true} />  
                        </div>
                    </div> 
                </div>
            </div>
        </div>
    )
}
export const SortableItem = sortableElement(({ item, key, translations, setFieldOpened, deleteField, updateValue }) => (
    <div key={key} className='customfield aicenter'>
        <DragHandle text={item.customfield_position} fdiv={'fdiv20'}/>
        <span className='customfield_name fdiv20'>{item.customfield_name}</span>
        <span className={'customfield_active crpointer fdiv20 ' + ((item.customfield_active) ? 'greenfield' : 'redfield')} onClick={() => updateValue({id: item.id, customfield_active: !item.customfield_active})}>
            <i className='fas fa-circle'></i>
            <span>{item.customfield_active ? translations.active : translations.disabled}</span>
        </span>
        <span className={'customfield_allowList crpointer fdiv20 ' + ((item.customfield_allowList) ? 'greenfield' : 'redfield')} onClick={() => updateValue({ id: item.id, customfield_allowList: !item.customfield_allowList })}>
            <i className='fas fa-circle'></i>
            <span>{item.customfield_allowList ? translations.allowList : translations.disabledList}</span>
        </span>
        <div className='actions fdiv20 jcend aicenter'>
            <div className='edit crpointer' onClick={() => setFieldOpened(item.id)}>
                <i className='far fa-pencil'></i>
            </div>
            <div className='remove crpointer' onClick={() => deleteField(item.id)}>
                <i className='fal fa-trash-alt'></i>
            </div>
        </div>
    </div>
));
export const SortableItemVal = sortableElement(({ item, key, updateNameVal, updateColor, deleteFieldVal}) => {
    return (
        <div key={key} className='customfield aicenter'>
            <DragHandle text={item.customfieldval_val} fdiv='fdiv25' />
            <span className='customfieldval_name fdiv30'>
                <input type='text' defaultValue={item.customfieldval_name ? item.customfieldval_name : ''} onBlur={(e) => updateNameVal(e,item.id, item.customfieldval_name)} />
            </span>
            <div className='customfieldval_color fdiv25 aicenter tacenter '>
                <IconDisplay parentProps={{}} button={<div className='color_selected' style={{ background: item.customfieldval_color }}></div>} inner={<ColorsFormated onSelect={(val) => updateColor(val, item.id)} active={item.customfieldval_color} />}/>   
            </div>
            <div className='actions fdiv20 jcend aicenter'>
                <div className='remove crpointer' onClick={() => deleteFieldVal(item.id)}>
                    <i className='fal fa-trash-alt'></i>
                </div>
            </div>
        </div>
    )
})
export const SortableContainer = sortableContainer(({ className, children, id }) => {
    return (
        <div className={className} id={id}>
            {children}
        </div>
    )
}) 
export const DragHandle = sortableHandle(({text, fdiv}) => <div className={fdiv + ' crmove'}><i className='fal fa-arrows'></i><span>{text}</span></div>)
export default AdminCustomFields
