import React, {useState, useEffect} from 'react';
import Header from '../minicomponents/Header'
import AdminPanel from './administration/AdminPanel'
import UserNotifications, {Activity} from '../minicomponents/UserNotification'
import Menu from './Menu'
import Dashboard from './Dashboard'
import UserConfig from './routes/UserConfig'
import Customers from './routes/Customers'
import Customer from './routes/Customer'
import {
    Switch,
    Route
} from 'react-router-dom'
import {renderNotification} from '../utils/renderNotification'
import store from '../reducers/reducers';
import Team from './routes/Team';
import UserTasks from './routes/UserTasks'
import UserCalendar from './routes/UserCalendar';
import UserPunchin from './routes/UserPunchin';
import { renderModal } from '../minicomponents/Modal';
import Updates from './routes/Updates';
import WorkIn from '../minicomponents/WorkIn';
import Teams from './routes/Teams';
import Users from './routes/Users';
import Settings from './routes/Settings';
import Repository from './routes/Repository';
// import Chat from '../minicomponents/Chat';
const AdminRoute = ({ path, component: Component }) => {
    var translations = store.getState().language.translations
    return (<Route path={path} render={() =>
        store.getState().user.admin_permissions === 1 ? (
            <Component />
        ) : (
                renderNotification({
                    type: 'error',
                    msg: translations.noPermissions
                })
            )} />)
}
const Main = (params) => {
    const [opened, setOpened] = useState(window.innerWidth > 1500 ? true : false)
    const enterColumn = () => {
        setOpened('hovered')
    }
    const [myEnterColumn] = useState(() => enterColumn)
    const leftColumn = () => {
        setOpened(false)
    }
    const [myLeftColumn] = useState(() => leftColumn)
    useEffect(() => {
        if(!opened){
            document.getElementById('menu').addEventListener('mouseenter', myEnterColumn)
        }
        if(opened === 'hovered'){
            document.getElementById('menu').addEventListener('mouseleave', myLeftColumn)
        }
        return () => {
            document.getElementById('menu').removeEventListener('mouseenter', myEnterColumn)
            document.getElementById('menu').removeEventListener('mouseleave', myLeftColumn)
        };
    }, [opened, myEnterColumn, myLeftColumn])
    const [modal, setModal] = useState(false)
    const [notification, setNotification] = useState(false)
    return ( 
    <div id='panel'>
        <div id="left_column" className={opened ? 'opened' : ''}>
            <div id='menu_opener' onClick={() => setOpened(!opened)}>{(opened) ? <i className='far fa-chevron-double-left'></i> : <i className='far fa-chevron-double-right'></i>}</div>
            <Menu setModal={setModal} notification={notification} setNotification={setNotification} opened={opened} />
            <UserNotifications  match={params.match} opened={opened}/>
        </div>
        <div id="main" className={(opened ? 'opened' : '') + ' ' +(store.getState().user.mode)}>
        <Header />
            <div className='content'>
            <Switch>
                <Route path='/punchIn/:user_id' render={(params) => <UserPunchin {...params} />}></Route>
                <Route path='/user/:id/:task_id?' render={(params) => <UserTasks {...params} />}></Route>
                <Route path='/calendar/:id?' render={(params) => <UserCalendar {...params} />}></Route>
                <Route path='/user' render={() => <UserConfig />}></Route>
                <Route path='/settings' render={() => <Settings />}></Route>
                <Route path='/repository/:post_id?/:editing?' render={(params) => <Repository {...params} />}></Route>
                <Route path='/activity' render={(params) => <Activity {...params} />}></Route>
                <Route path='/customer/:id/:tab?/:project_id?/:editing?' render={(params) => <Customer {...params} />}></Route>
                <Route path='/customers/:off?' render={(params) => <Customers {...params} />}></Route>
                <Route path='/teams' render={() => <Teams />}></Route>
                <Route path='/users' render={() => <Users />}></Route>
                <Route path='/team/:id/:task_id?' render={(params) => <Team {...params} />}></Route>
                <Route path='/updates/:id?' render={(params) => <Updates {...params} />}></Route>
                <AdminRoute path='/admin' component={AdminPanel} />  
                <Route path='/:task_id?' render={(params) => <Dashboard {...params} />}></Route>
            </Switch>
            </div>
        {renderModal(modal, notification, setNotification, setModal)}            
        {/* <Chat /> */}
        <WorkIn />
        </div>
    </div> );
}
 
export default Main;