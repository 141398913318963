import React from 'react'
import { changePage } from '../../reducers/reducers'
import NewTasks from '../../minicomponents/NewTask';
const Team = ({match, history}) => {
    const updatePage = async () => {
        var myteam = await fetch('/api/adminp/team/findOne',{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                where : {
                    id: match.params.id
                }
            })
        })
        myteam = await myteam.json()
        changePage({
            name: 'team',
            subname: {
                name: 'team' + match.params.id,
                text: myteam.team.name
            }
        })
    }
    updatePage()
    return (<div><NewTasks extraParams={{ team_id: match.params.id, task_id: match.params.task_id }} url={'/team/' + match.params.id} history={history}/></div> );
}
export default Team;