import React, {useEffect} from 'react';
import store, { changePage } from '../../reducers/reducers';
import { Link } from 'react-router-dom'
const Updates = ({match}) => {
    var language = store.getState().language.lang_iso
    var myListUpdates = listUpdates(language)
    function scrollToHash() {
        const id = window.location.hash.substr(1);
        if(id){
            const anchor = document.getElementById(id);
            if (anchor) {
                anchor.scrollIntoView();
            }
        }
    }
    useEffect(() => {
        window.setTimeout(scrollToHash,100)
    })
    useEffect(() => {
        changePage({
            name: 'updatelog',
        })
    }, [])
    var currentActive = parseInt(Object.keys(myListUpdates).pop())
    var myListReformed = {}
    Object.keys(myListUpdates).reverse().forEach((item) => {
        myListReformed['id_'+item] = myListUpdates[item]
    })
    if(match.params.id !== undefined){
        currentActive = parseInt(match.params.id)
    }
    var content = ''
    if (myListReformed['id_' + currentActive]){
        myListReformed['id_'+currentActive].active = true
        content = getContent(currentActive, myListReformed['id_'+currentActive].translations)
    }
    var leftBarRendered = []
    for(let k in myListReformed){
        let item = myListReformed[k]
        leftBarRendered.push(
            <Link to={item.linkTo} key={k} className={'update_log' + ((item.active) ? ' active' : '')}>{item.name}</Link>
        )
    }
    return (
        <div id='update_log'>
           <div className='box'>
               <div className='box_inner'>
                    <div className='box_content wrap'>
                        <div className='fdiv20' id='leftbar_updates'>
                            {leftBarRendered}
                        </div>
                        <div className='fdiv80 patch' id='update_selected'>
                            {content}
                        </div>
                    </div>
               </div>
            </div> 
        </div>
    );
}
export default Updates;

export const listUpdates = (language) => {
    var data = []
    if(language === 'en'){
        data = {
            1: {
                id: 1,
                name: 'Beta v1',
                linkTo: '/updates/1'
            }
        }
    }else if(language === 'es'){
        data = {
            1: {
                id: 1,
                name: 'Beta v1',
                linkTo: '/updates/1',
                translations: {
                    title: 'Beta v1',
                    index: 'Índice',
                    language: 'Idioma',
                    users: 'Usuarios',
                    teams: 'Equipos',
                    tables: 'Tablas',
                    tasks: 'Tareas',
                    calendar: 'Calendario',
                    langExp1: 'Seleciona el idioma que desees en los diferentes selectores que hay en la aplicación. Este se guarda por ordenador.',
                    usersExp1: 'Cambia al instante como los otros te ven! Ves a tu perfil y modifica los datos que quieras, y si necesitas actualizar la contraseña también puedes hacerlo ahí!',
                    tablesExp1: 'Las tablas nos permiten listar entre muchos datos y trabajar con estos.',
                    videoTable: '/patch/1/tablesEs.mp4',
                    tasksExp1: 'Las tareas pueden ser por usuarios (En la pestaña "Mis tareas" encontrarás las tareas que te han asignado), equipos (despliega los equipos en el menú y verás tu lista de equipos donde cada uno tiene sus tareas) y proyectos (accede dentro de un cliente y ves uno de sus proyectos ) o todo a la vez!',
                    tasksExp2: 'En estas podemos ver en su cabecera diferentes opciones.',
                    taskList1: 'Va a la tarea padre',
                    taskList2: 'La tarea se esconderá y volverá a aparecer en la fecha que selecciones',
                    taskList3: 'Si es una subtarea, la transforma en una tarea principal',
                    taskList4: 'Transforma la subtarea en una subtarea de la tarea seleccionada',
                    taskList5: 'Añade subtarea dentro de esta tarea',
                    taskList6: 'Elimina la tarea y todas las subtareas',
                    taskList7: 'Anula la selección de la tarea',
                    tasksExp3: 'Dento de las tareas podemos hacer muchas cosas:',
                    videoTasks: '/patch/1/taskEs.mp4',
                    calendarExp1: 'En el calendario verás las tareas que tengas asignadas a una fecha y esten también asignadas a ti. Juega con todas las opciones que este te ofrece!',
                }
            }
        }
    }else if(language === 'ca'){
        data = {
            1: {
                id: 1,
                name: 'Beta v1',
                linkTo: '/updates/1'
            }
        }
    }
    return data
}
export const getContent = (currentActive, translations) => {
    if (translations) {        
        var data = {
            1: (
                    <div className='patch_inner'>
                        <h1 className='tacenter'>{translations.title}</h1>
                        <section id='index'>
                            <h3>{translations.index}</h3>
                            <ul>
                                <li><Link to='#index'>{translations.language}</Link></li>
                                <li><Link to='#users'>{translations.users}</Link></li>
                                <li><Link to='#tables'>{translations.tables}</Link></li>
                                <li><Link to='#tasks'>{translations.tasks}</Link></li>
                                <li><Link to='#calendar'>{translations.calendar}</Link></li>
                            </ul>
                        </section>
                        <section id='language'>
                            <h3>{translations.language}</h3>
                            <span>{translations.langExp1}</span>
                            <video src='/patch/1/changeLang.mp4' alt='' autoPlay loop />
                        </section>
                        <section id='users'>
                            <h3>{translations.users}</h3>
                            <span>{translations.usersExp1}</span>
                            <video src='/patch/1/editProfile.mp4' alt='' autoPlay loop/>
                        </section>
                        <section id='tables'>
                            <h3>{translations.tables}</h3>
                            <span>{translations.tablesExp1}</span>
                            <video src={translations.videoTable} alt='' autoPlay loop />
                        </section>
                        <section id='tasks'>
                            <h3>{translations.tasks}</h3>
                            <span>{translations.tasksExp1}</span>
                            <span>{translations.tasksExp2}</span>
                            <img src='/patch/1/task_header.png' alt='' />
                            <ol type='1'>
                                <li>{translations.taskList1}</li>
                                <li>{translations.taskList2}</li>
                                <li>{translations.taskList3}</li>
                                <li>{translations.taskList4}</li>
                                <li>{translations.taskList5}</li>
                                <li>{translations.taskList6}</li>
                                <li>{translations.taskList7}</li>
                            </ol >
                            <span>{translations.tasksExp3}</span>
                            <video src={translations.videoTasks} alt='' autoPlay loop />
                        </section>
                        <section id='calendar'>
                            <h3>{translations.calendar}</h3>
                            <span>{translations.calendarExp1}</span>
                            <img src='/patch/1/calendar.png' alt='' />
                        </section>
                    </div>
                )
        }
        return data[currentActive]
    }
}