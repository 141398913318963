import React from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import store, {destroyUser, updateLanguage} from '../reducers/reducers';
import { CustomSelect } from './Form';
import TaskHelperPortable from './TaskHelperPortable';
import Notes from './Notes';
import AdminNotificationsPortable from './AdminNotificationsPortable';
import UserWork from './UserWork';
const mapStateToProps = state => ({ page: state.page })
const Header = () => {
    return ( 
    <div id='header' className={'header aicenter '+ store.getState().page.name}>
        <div className="header_left aicenter">
            {
                (store.getState().page.backTo) 
                ? 
                    <div className='back_to'><Link className='btn_standard' to={store.getState().page.backTo.link}>
                        <i className="fas fa-chevron-left"></i><span>{store.getState().page.backTo.text}</span>
                    </Link></div> 
                : 
                    <div></div> 
            }
            <div className='lang'>
                <CustomSelect selectProps={{
                    value: (store.getState().language.lang_iso) ? store.getState().language.lang_iso : 'en', onChange: async (lang_iso) => {
                        fetch('/api/user/updateLang', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                lang_iso
                            })
                        }).then(() => {
                            updateLanguage()
                            window.location.reload()
                        })
                    }
                }}
                    options={[
                        {
                            value: 'es',
                            text: 'Español'
                        },
                        {
                            value: 'en',
                            text: 'English'
                        },
                        {
                            value: 'ca',
                            text: 'Català'
                        },
                        {
                            value: 'fr',
                            text: 'Français'
                        }
                    ]}
                    fixedValue={true} />
            </div>
        </div>
        <div className="header_center aicenter">
            <div className='page_title'><h1>{store.getState().page.subname !== undefined ? store.getState().page.subname.text : store.getState().page.title}</h1></div>
        </div>
            <div className="header_right aicenter">
            <AdminNotificationsPortable />
            <UserWork />
            <Notes />
            <TaskHelperPortable />
            <Link to='/user' className='user_header aicenter'>
                <img src={'/userImg/' + store.getState().user.image} className='user_img miniimg' alt='' />
                <span className='firstname'>{store.getState().user.firstname}</span> 
                <span className='surname'>{store.getState().user.firstsurname}</span>
            </Link>
            <div id="close_session" className='btn_standard aicenter tacenter' onClick={destroyUser}><i className="fas fa-power-off"></i></div>
        </div>
    </div> );
}
 
export default connect(mapStateToProps)(Header);
