import React, {useState, useEffect, Fragment} from 'react';
import store from '../reducers/reducers';
import CSVReader from 'react-csv-reader'
import ReactTooltip from 'react-tooltip';
export const Checkbox = ({ checkboxProps, text, parentProps, singleOnly = false }) => {
    return (
        <div {...parentProps}>
            <input type={singleOnly ? 'radio' : 'checkbox'} {...checkboxProps} />
            <label htmlFor={checkboxProps.id} className='doFlex aicenter'>
            <div className="custom_checkbox"></div>
                 {text}
            </label>
        </div>
    );
}
export const Switch = ({checkboxProps, text, parentProps}) => {
    return(
        <div {...parentProps}>
            <input type='checkbox' {...checkboxProps} />
            <label htmlFor={checkboxProps.id} className='doFlex aicenter label_switch'>
                {text ? <h6>{text}</h6> : ''}
                <div className='custom_switch_parent'>
                    <div className='custom_switch'></div>
                </div>
            </label>
        </div >
    )
}
export const CustomSelect = ({selectProps, options, fixedValue = false, onOpen }) => {
    const [value, setValue] = useState(selectProps.value)
    const [opened, setOpened] = useState(false)
    function closeOnClickOut() {
        setOpened(false)
        window.removeEventListener('click', bind)
    }
    const [bind] = useState(() => closeOnClickOut)
    const getFieldByValue = (value) => {
        if(options && options.length){
            return options.filter(field => field.value === value)[0]
        }
        return false
    }
    const setDisplay = () => {
        setOpened(true)
        window.setTimeout(function () {
            window.addEventListener('click', bind)
        }, 100)
    }
    const updateValue = (itemvalue) => {
        if(selectProps.onChange){
            selectProps.onChange(itemvalue)
        }
        setValue(itemvalue); 
        closeOnClickOut()
    }
    if (selectProps.value !== value && !opened && fixedValue) {
        setValue(selectProps.value)
    }
    var selected = getFieldByValue(value)
    var renderSelect = ''
    if(opened){
        var listOptionsFormated = options.map((item, key) => {
            return <div className={'select_item  aicenter' + ((item.value === value) ? ' active' : '')} onClick={() => updateValue(item.value)} key={key}><span>{item.text}</span>{(item.value === value) ? <i className='far fa-check mlauto'></i> : ''}</div>
        })
        renderSelect  = <div className='dropdown_select'>{listOptionsFormated}</div>
    }else{
        if(selected){
            renderSelect = <div className='selected aicenter' onClick={setDisplay}><span>{selected.text}</span><i className='fal fa-chevron-down mlauto'></i></div>
        }else{
            if (options && options.length && value !== options[0].value) {
                setValue(options[0].value)
            }else if(value){
                setValue(false)
            }
        }
    }
    useEffect(() => {
        if (onOpen) {
            onOpen(opened)
        }
    }, [opened, onOpen])
    return (
        <div className='custom_select'>
            <select {...selectProps} value={value} readOnly>
                {
                    options ? options.map((item, key) => {
                        return <option value={item.value} key={key}>{item.text}</option>
                    }) : ''
                }
            </select>
            <div className='custom_select_inner'>
                {renderSelect}
            </div>
        </div>
    )
}
export const FakeSelect = ({text, children}) => {
    const [opened, setOpened] = useState(false)
    function closeOnClickOut() {
        setOpened(false)
        window.removeEventListener('click', bind)
    }
    const [bind] = useState(() => closeOnClickOut)
    const setDisplay = () => {
        setOpened(true)
        window.setTimeout(function () {
            window.addEventListener('click', bind)
        }, 100)
    }
    let renderThis = null
    if (opened) {
        renderThis = <Fragment>
            <div className='selected aicenter' onClick={closeOnClickOut}><span>{text}</span><i className='fal fa-chevron-down mlauto'></i></div>
            <div className='selected fake_select'>{children}</div>
        </Fragment>
    } else {
        renderThis = <div className='selected aicenter' onClick={setDisplay}><span>{text}</span><i className='fal fa-chevron-down mlauto'></i></div>
    }
    return (
        <div className='custom_select' onClick={(e) => e.stopPropagation()}>
            <div className='custom_select_inner'>
                {renderThis}
            </div>
        </div>
    )
}
export const ImageInput = ({ imgProps, inputName, pathtosave, startValue, readOnly = false}) => {
    const [value, setValue] = useState(startValue)
    if(value === startValue){
        imgProps.src = imgProps.src + value
    }else{
        imgProps.src = value
    }
    const clickInput = (e) => {
        var input = e.currentTarget.parentElement.getElementsByTagName('input')[0]
        input.click()
    }
    const uploadImage = (e) => {
        let file = e.target.files[0]
        let reader = new FileReader()
        reader.onloadend = () => {
            setValue(reader.result)
        }
        reader.readAsDataURL(file)
    }
    let onClick = {}
    if (!readOnly) {
        onClick.onClick = clickInput
    }
    return (
        <div className='image_input_container'>
            {!readOnly ? <input type='file' name={inputName} className='imageInput' pathtosave={pathtosave} onChange={uploadImage} /> : ''}
            <img {...imgProps} alt='' {...onClick} />
        </div>
    )
}  
export const PhotoInputSearcher = ({ onlyImg = false, getDataFrom, data, icon, updateData, fieldtoshow, pathImg, cleanInput, timeToReset = false, justText = false, extraParams = {}}) => {
    var translations = store.getState().language.translations
    const [value, setValue] = useState(false)
    const [display, setDisplay] = useState((onlyImg) ? false : true)
    const [searchResults, setSearchResults] = useState(false)
    const [reset, setReset] = useState(true)
    const [ourTimeToReset, setOurTimeReset] = useState(false)
    const search = async (e) => {
        if(reset){
            setReset(false)
        }
        let value = e.currentTarget.value
        await setValue(value)
        setSearchResults(await getDataFrom(value))
    }
    function closeOnClickOut() {
        setDisplay(false)
        window.removeEventListener('click', bind)
    }
    const [bind] = useState(() => closeOnClickOut)
    const customSetDisplay = (tmpdisplay) => {
        setDisplay(tmpdisplay)
        if (tmpdisplay){
            window.setTimeout(function(){
                window.addEventListener('click', bind)
            },100)
        }else{
            window.removeEventListener('click', bind)
        }
    }
    if(value !== data.text && reset){
        setValue(data.text)
        setSearchResults(false)
        if(display && onlyImg){
            closeOnClickOut()
        }
        setReset(false)
    }
    const checkClose = (e) => {
        window.setTimeout(function () {
            if (value === data.text) {
                setValue('')
            }
            setReset(true)
        },200)
    }
    if(timeToReset === 1 && ourTimeToReset === false){
        setOurTimeReset(1)
        setReset(true)
    }
    if(timeToReset === false && ourTimeToReset === 1){
        setOurTimeReset(false)
    }
    var renderSearchResults = []
    if(searchResults){
        searchResults.forEach((item, key) => {
            renderSearchResults.push(<div className='searchitem aicenter' key={key} onClick={() => { updateData(item.id, item); setValue("") }}>{!justText ? <img src={pathImg + item.image} className='user_img' alt='' /> : ''}{item[fieldtoshow]}</div>)
        })
        renderSearchResults = <div className='searchresult'>{renderSearchResults}</div>
    }
    useEffect(() => {
        setReset(true)
    }, [data])
    var renderInput = ''
    if(display){
        renderInput = <div className='photoinput_text aicenter tacenter' onClick={(e) => e.stopPropagation()}>
            <input type='text' value={(value !== undefined) ? value : (data.text) ? data.text : ''} placeholder={translations.notAssigned} onChange={search} onBlur={checkClose} onClick={search}/>
            {value === undefined && !data.text && !searchResults ? '' : <i className='fa fa-times close' onClick={cleanInput}></i>}
            {renderSearchResults}
        </div>
    }
    return(
        <div className={'photoinput inputsearch' + ((onlyImg) ? ' onlyImg mauto' : '')} {...extraParams}>
            <div className='photoinput_logo aicenter tacenter' onClick={(e) => { e.stopPropagation(); onlyImg ? customSetDisplay(!display) : console.log() }} >
                {(searchResults) ? <i className='fas fa-circle-notch fa-spin' /> : (data.image) ? <img src={data.image} className='user_img' alt='' /> : <i className={icon}></i>}
            </div>
            {renderInput}
        </div>
    )
}
export const CsvInner = ({text, exampleCsv, sendDataTo, fields, setNotification}) => {
    var translations = store.getState().language.translations
    const [data, setData] = useState(false)
    const parseData = (mydata) => {
        let csv = mydata.map((row) => {
            if(row.length === 1 && row[0].includes(';')){
                row = row[0].split(';')
            }
            return row
        })
        setData(csv)
    }
    const sendData = async () => {
        for(let k in data){
            let row = {}
            let havefields = false
            for(let j in fields){
                let field = fields[j]
                row[field] = data[k][j]
                if(data[k][j] && !havefields){
                    havefields = true
                }
            }
            if(havefields && parseInt(k) !== 0){
                await fetch(sendDataTo, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(row)
                })
            }
        }
        setNotification({
            type: 'success',
            msg: translations.succImport
        })        
    }
    return (
        <div className='csv_inner'>
            <div className='text_csv'>{text}</div>
            {exampleCsv ? <a href={exampleCsv} download>{translations.downloadCsv}</a> : ''}
            <CSVReader 
                fileEncoding='ASCII'
                label={translations.uploadCsv}
                onFileLoaded={parseData}
            />
            {data ? <button onClick={sendData} className='btn_primary'>{translations.addCsv} </button> : ''}
        </div>
    )
}
export const CustomFieldPicker = ({ fieldName, options, selectedVal, onSelect, className, style, mini = false, uk = false }) => {
    const [display, setDisplay] = useState(false)
    function closeOnClickOut() {
        setDisplay(false)
        window.removeEventListener('click', bind)
    }
    const [bind] = useState(() => closeOnClickOut)
    const customSetDisplay = (tmpdisplay) => {
        setDisplay(tmpdisplay)
        if (tmpdisplay) {
            window.setTimeout(function () {
                window.addEventListener('click', bind)
            }, 100)
        } else {
            window.removeEventListener('click', bind)
        }
    }
    useEffect(() => {
        return () => {
            ReactTooltip.rebuild()
            window.removeEventListener('click', bind)
        };
    }, [bind])
    var selected = false
    if (options && options.length) {
        let optionsformated = options.map((item, key) => {
            if(selectedVal === item.id){
                selected = item
                return <div className='customfield_item active' style={{ background: item.customfieldval_color }} key={key} onClick={(e) => { customSetDisplay(false); e.stopPropagation(); onSelect(item.id) }} data-tip data-for={uk ? uk + 'ns' + item.id : ''}>
                    <span>{mini ? item.customfieldval_name.substr(0, 1) : item.customfieldval_name}</span>
                    {uk && mini && item.id ? <ReactTooltip id={uk + 'ns' + item.id}>
                        <span>{item.customfieldval_name}</span>
                    </ReactTooltip> : ''}
                </div>
            }
            return <div className='customfield_item' style={{ background: item.customfieldval_color }} key={key} onClick={(e) => { customSetDisplay(false); e.stopPropagation();onSelect(item.id) }} data-tip data-for={uk ? uk + 'ns' + item.id : ''}>
                <span>{mini ? item.customfieldval_name.substr(0,1) : item.customfieldval_name}</span>
                {uk && mini && item.id ? <ReactTooltip id={uk + 'ns' + item.id}>
                    <span>{item.customfieldval_name}</span>
                </ReactTooltip> : ''}
            </div>
        })
        optionsformated.unshift(<div className='customfield_item unselect' style={{background: '#d8d8d8', color: '#222'}} key='unselect' onClick={() => onSelect(0)}>---</div>)
        return (
            <div className={'task_customfield' + ((className) ? ' ' + className : '') + ((display) ? ' active' : '') + ((mini) ? ' mini' : '')} style={{...style}}>
                {fieldName ? <h6>{fieldName}</h6> : ''}
                <div className={'customfield_selected ' + ((selected) ? ' selected' : 'noselected')} style={{ background: selected.customfieldval_color }} onClick={(e) => { e.stopPropagation(); customSetDisplay(!display) }} data-tip data-for={uk ? uk : ''}>
                    <span>{selected ? mini ? selected.customfieldval_name.substr(0,1) : selected.customfieldval_name : mini ? '-' : '---'}</span>
                    {!mini ? <i className='fas fa-chevron-down mlauto'></i> : ''}
                    {uk && mini && selected.id ? <ReactTooltip id={uk}>
                        <span>{selected.customfieldval_name}</span>
                    </ReactTooltip> : ''}
                </div>
                {display ?<div className='customfield_select'>{optionsformated}</div> : ''}
            </div>
        )
    }
    return ('')
}
export const CheckboxListSelector = ({data, fieldName, text, onChange}) => {
    const [display, setDisplay] = useState(false)
    const [selected, setSelected] = useState([])
    function closeOnClickOut() {
        setDisplay(false)
        window.removeEventListener('click', bind)
    }
    const selectElement = async (e) => {
        let val = e.currentTarget.value
        let mySelected = selected
        if(e.currentTarget.checked){
            mySelected.push(String(val))
            await setSelected(mySelected)
        }else{
            let a = mySelected.indexOf(String(val))
            mySelected.splice(a, 1)
            await setSelected(mySelected)
        }
        onChange(mySelected)
    }
    const [bind] = useState(() => closeOnClickOut)
    const customSetDisplay = (tmpdisplay) => {
        setDisplay(tmpdisplay)
        if (tmpdisplay) {
            window.setTimeout(function () {
                window.addEventListener('click', bind)
            }, 100)
        } else {
            window.removeEventListener('click', bind)
        }
    }
    useEffect(() => {
        return () => {
            window.removeEventListener('click', bind)
        };
    }, [bind])
    var optionsformated = []
    if(data){
        for(let k in data){
            let row = data[k]
            optionsformated.push(
                <div className='select_item aicenter' onClick={(e) => e.stopPropagation()} key={k} >
                    <Checkbox checkboxProps={{ name: fieldName+'[]', value: row.id, id: fieldName + k, onChange: selectElement, defaultChecked: selected.includes(String(row.id)) ? true : false}} text={row.name} parentProps={{ className: 'fdiv100 nopadding' }} />
                </div>
            )
        }
    }
    return (
        <div className='custom_select checkboxlistselector'>
            <div className='custom_select_inner'>
                <div className='selected' onClick={() => customSetDisplay(!display)}><span>{text}</span><i className='fas fa-chevron-down'></i></div>
                {display ? <div className='dropdown_select special'>{optionsformated}</div> : ''}
            </div>
        </div>
    )
}