import React, {useEffect, useCallback} from 'react'
import store, { changePage } from '../../reducers/reducers'
import NewTask from '../../minicomponents/NewTask';
const UserTasks = ({ match, history }) => {
    var translations = store.getState().language.translations
    const updateTitle = useCallback(async () => {
        let user = await fetch('/api/adminp/user/findOne', {
            method: 'POST',
            headers: {
                'Content-Type' : 'application/json'
            },
            body: JSON.stringify({
                where : {
                    id: match.params.id
                }
            })
        })
        user = await user.json()
        changePage({
            name: 'user_tasks',
            subname: {
                name: 'user' + match.params.id,
                text: translations.myTasks + ' : ' + user.username
            } 
        })
    }, [match.params.id, translations])
    useEffect(() => {
        if (store.getState().user.id === parseInt(match.params.id)) {
            changePage({
                name: 'user_tasks',
            })
        } else {
            updateTitle()
        }
    }, [match.params.id, updateTitle])
    return (<div><NewTask extraParams={{ user_id: match.params.id, task_id : match.params.task_id }} url={'/user/'+match.params.id} history={history}/></div>);
}
export default UserTasks;