import React, {useEffect, useCallback, useState} from 'react';
import store, {changePage} from '../../reducers/reducers';
import Tabs from '../../minicomponents/Tabs'
import Breadcrumb from '../../minicomponents/Breadcrumb';
import EditCustomer, {EditShops, EditContacts, EditProjects} from '../../minicomponents/EditCustomer';
import Repository from './Repository';
const Customer = ({match, history}) => {
    const [name, setName] = useState(false)
    var translations = store.getState().language.translations
    let my_id = match.params.id
    var getName = useCallback(async (id) => {
        var result = await fetch('/api/adminp/customer/findOne', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                where: { id }
            })
        })
        result = await result.json()
        changePage({
            name: 'customer',
            subname: {
                name: 'customer' + my_id,
                text: translations.customer + ' : ' + result.customer_name
            } 
        })
        setName(result.customer_name)
    }, [my_id, translations.customer])
    useEffect(() => {
        getName(my_id)
    }, [my_id, getName])
    var tabOptions = [
        {
            text: <span><i className='fal fa-user'></i><span>{translations.resume}</span></span>,
            to: '/customer/'+match.params.id,
        },
        {
            text: <span><i className='fal fa-store-alt'></i><span>{translations.shops}</span></span>,
            to: '/customer/'+match.params.id+'/shops/'
        },
        {
            text: <span><i className='fal fa-pen-nib'></i><span>{translations.contactPersons}</span></span>,
            to: '/customer/'+match.params.id+'/contacts/'
        },
        {
            text: <span><i className='fal fa-rocket'></i><span>{translations.projects}</span></span>,
            to: '/customer/'+match.params.id+'/projects/'
        },
        {
            text: <span><i className='fal fa-comment'></i><span>{translations.repository}</span></span>,
            to: '/customer/'+match.params.id+'/repository/'
        }
    ]
    var display = ''
    if(match.params.tab){
        if(match.params.tab === 'shops'){
            tabOptions[1].active = true
            display= <EditShops id={match.params.id} />
        }else if(match.params.tab === 'contacts'){
            tabOptions[2].active = true
            display = <EditContacts id={match.params.id} />
        }else if(match.params.tab === 'projects'){
            tabOptions[3].active = true
            display = <EditProjects id={match.params.id} project_id={match.params.project_id ? match.params.project_id : false} task_id={match.params.editing ? match.params.editing : false} history={history}/>
        }else if(match.params.tab === 'repository'){
            tabOptions[4].active = true
            match.params.post_id = match.params.project_id
            display = <Repository match={match} customer_name={name ? name : false} prelink={'/customer/' + match.params.id} changeName={false}/>
        }
    }else{
        tabOptions[0].active = true
        display= <EditCustomer id={match.params.id} />
    }
    return ( 
        <div className='customer'>
            <Tabs tabOptions={tabOptions} display={display} rightSide={<Breadcrumb />} />
        </div> );
}
 
export default Customer;