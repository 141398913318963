import React, { useEffect} from 'react'
import { changePage } from '../../reducers/reducers';
import TaskHelperConfig from '../../minicomponents/TaskHelperConfig';
const Settings = () => {
    useEffect(() => {
        changePage({
            name: 'settings',
        })
    }, [])
    return ( 
        <div id='settings'>
            <TaskHelperConfig />
        </div>
    );
}
 
export default Settings;