import React, {useEffect, useState} from 'react';
import {Redirect} from 'react-router-dom'
import {returnNotifiPostForm} from './utils/forms'
import {renderNotification} from './utils/renderNotification'
import store from './reducers/reducers'
const Register = ({match}) => {
    var translations = store.getState().language.translations
    const [user, setUser] = useState(false)
    const [notification, setNotification] = useState(false)
    const [redirect, setRedirect] = useState(false)
    const tryToGetUser = async () => {
        var result = await fetch('/api/user/register/' + token, {
            method: 'GET'
        })
        result = await result.json()
        if(result){
            setUser(result)
        }
    }
    var token = match.params.token
    const fetchForm = async (e) => {
        if (e.target.elements.password.value === e.target.elements.repeatpassword.value) {
            var result = await returnNotifiPostForm(e, translations.succRegister, translations.errRegister)
            if(result.type === 'success'){
                window.setTimeout(function(){
                    setRedirect(<Redirect to='/'/>)
                },1000)
            }
            setNotification(result)
        } else {
            e.preventDefault()
            setNotification({
                type: 'error',
                msg: translations.pwNotMatch
            })
        }
    }
    useEffect(() => {
        if(!user){
            tryToGetUser()
        }
    })
    var renderThis = ''
    if(user){
        renderThis= (
            <div id='registerblock' className='max650 mt10vh aicenter'>
                <div id="registerform" className='formContainer'>
                    <div className="formInner">
                        <img src='/biglogo.svg' className='headerImg' alt='Twintask logo' width='250px'></img>
                        <h3>{translations.register}</h3>
                        {renderNotification(notification)}
                        <form action={'/api/user/register/'+match.params.token} method='post' onSubmit={fetchForm} className='wrap'>
                            <div className="inputDiv">
                                <h6>{translations.username}</h6>
                                <input type='text' name='username' placeholder={translations.inUsername} required />
                            </div>
                            <div className="inputDiv">
                                <h6>{translations.email}</h6>
                                <input type='email' name='email' placeholder={translations.inEmail} required value={user.email} readOnly/>
                            </div>
                            <div className="inputDiv">
                                <h6>{translations.name}</h6>
                                <input type='text' name='firstname' placeholder={translations.inName} required />
                            </div>
                            <div className="inputDiv">
                                <h6>{translations.firstsurname}</h6>
                                <input type='text' name='firstsurname' placeholder={translations.inFirstsurname} required />
                            </div>
                            <div className="inputDiv">
                                <h6>{translations.secondsurname}</h6>
                                <input type='text' name='secondsurname' placeholder={translations.inSecondsurname} />
                            </div>
                            <div className="inputDiv">
                                <h6>{translations.password}</h6>
                                <input type='password' name='password' placeholder={translations.inPassword} required />
                                <input type='hidden' name='admin_permissions' value={user.admin_permissions} readOnly />
                                <input type='hidden' name='active' value={true} readOnly />
                            </div>
                            <div className="inputDiv">
                                <h6>{translations.repeatPassword}</h6>
                                <input type='password' name='repeatpassword' className='notInclude' placeholder={translations.repeatPassword} required />
                            </div>
                            <button type='submit' className='notInclude defaultButton'>{translations.continue}</button>
                        </form>
                    </div>
                </div>
                
            </div>
        )
    }
    return ( <div>{redirect}{renderThis}</div> );
}
 
export default Register;

