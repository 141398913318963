import React from 'react'
import store from '../reducers/reducers'
const SearchBar = ({ searchbar, sendInfoTo}) => {
    var translations = store.getState().language.translations
    const updateSendFields = (name) => {
        searchbar.searchBy = name
        sendInfoTo(searchbar)
    }
    const updateSearchQuery = (e) => {
        searchbar.searchQuery = e.target.value
        sendInfoTo(searchbar)
    }
    if(searchbar.fields){
        var fieldsFormated = searchbar.fields.map((item, key) => {
            return <div className={'search_item' + ((item.name === searchbar.searchBy) ? ' active' : '')} onClick={() => updateSendFields(item.name)} key={key}>{item.text}</div>
        })
    }
    return ( 
    <div className='search_container aicenter'>
        <div className="searchBy aicenter">
            <h6>{translations.searchBy}</h6>
            <div className="searchDivs aicenter">
                {fieldsFormated}
                <div className={'search_item' + (('all' === searchbar.searchBy) ? ' active' : '')} onClick={() => updateSendFields('all')}>{translations.all}</div>
            </div>
        </div>
        <div className="search_bar_container aicenter">
            <input type="text" className='search_bar' placeholder={translations.searchPh} value={searchbar.searchQuery} onChange={updateSearchQuery}/>
            <i className="fal fa-search fa-flip-horizontal"></i>
        </div>
    </div>
    );
}
 
export default SearchBar;