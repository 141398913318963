import React from 'react';
import { processData } from './specialdata';
export const renderLogMessage = (log, translations) => {
    if(log.log_type === 1){
        if(log.user){
            return (<span><span className='tasklog_username'>{log.user.username}</span> <span>{translations.xCreatedTask} - {processData(log.createdAt)}</span></span>)
        }else{
            return (translations.createdTask)
        }
    }else if(log.log_type === 2){
        if (log.user) {
            return (<span><span className='tasklog_username'>{log.user.username}</span> {translations.xCreatedSubtask + ' #'+log.target}</span>)
        } else {
            return (translations.createdSubtask)
        }
    }else if(log.log_type === 3){
        if (log.user) {
            if(log.target_old && log.target){
                return (<span><span className='tasklog_username'>{log.user.username}</span> {translations.xReassignedUser+' ' + log.target.username}</span>)
            }else{
                if(log.target){
                    return (<span><span className='tasklog_username'>{log.user.username}</span> {translations.xAssignedUser+' '+ log.target.username}</span>)
                }else{
                    return (<span><span className='tasklog_username'>{log.user.username}</span> {translations.xRemovedAssignedUser}</span>)
                }
            }
        } else {
            return (translations.assignedUser+' '+log.target.username)
        }
    }else if(log.log_type === 4){
        if (log.user) {
            if (log.target_old && log.target) {
                return (<span><span className='tasklog_username'>{log.user.username}</span> {translations.xReassignedTeam+' '+ log.target.name}</span>)
            } else {
                if(log.target){
                    return (<span><span className='tasklog_username'>{log.user.username}</span> {translations.xAssignedTeam+' '+ log.target.name}</span>)
                }else{
                    return (<span><span className='tasklog_username'>{log.user.username}</span> {translations.xRemovedAssignedTeam}</span>)
                }
            }
        } else {
            return (translations.assignedTeam+' '+ log.target.name)
        }
    }else if(log.log_type === 6){
        if (log.user) {
            if(log.target === '0'){
                return (<span><span className='tasklog_username'>{log.user.username}</span> {translations.xUnmarkAsFinished} - {processData(log.updatedAt)}</span>)
            }else if(log.target === '1'){
                return (<span><span className='tasklog_username'>{log.user.username}</span> {translations.xFinishedTask} - {processData(log.updatedAt)}</span>)
            }
        } else {
            return (translations.finishedTask)
        }
    }
    return ('')
}