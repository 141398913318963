import React, { useState, useEffect, useCallback } from 'react';
import store from '../reducers/reducers';
import { IconDisplay } from './SpecialDisplayers';
import { connect } from 'react-redux';
import socket from '../utils/socket';
const mapStateToProps = state => ({ user: state.user })
const Notes = () => {
    return (
        <IconDisplay inner={
            <NotesInner />
        } button={
            <div className='header iconDisplayerbutton'>
                <i className='far fa-sticky-note'></i>
            </div>
        } parentProps={{ className: 'notes_portable action_header' }} />
    );
}
export default connect(mapStateToProps)(Notes);
export const NotesInner = () => {
    const [active, setActive] = useState(0)
    const [noteActive, setNoteActive] = useState(false)
    let translations = store.getState().language.translations
    let firstTabs = [
        {
            id: 0,
            text: translations.myNotes
        }
    ]
    for (let team of store.getState().user.teams) {
        firstTabs.push({
            id: team.id,
            text: team.name
        })
    }
    let firstTabsFormated = firstTabs.map((item, key) => {
        return <div className={'note_first_tab' + (active === item.id ? ' active' : '')} key={key} onClick={() => { setActive(item.id); setNoteActive(false)}}><span>{item.text}</span><i className='far fa-chevron-right'></i></div>
    })
    return (
        <div className='notes_inner wrap'>
            <div className='fdiv25'>
                {firstTabsFormated}
            </div>
            <NotesByActive active={active} setNoteActive={setNoteActive} noteActive={noteActive} />
            <NoteActive noteActive={noteActive} setNoteActive={setNoteActive} />
        </div>
    )
}
export const NotesByActive = ({ active, setNoteActive, noteActive}) => {
    const [data, setData] = useState(false)
    let translations = store.getState().language.translations
    const searchActive = useCallback(async () => {
        let params = {}
        if (active === 0) {
            params['UserId'] = store.getState().user.id
        } else {
            params['TeamId'] = active
        }
        let res = await fetch('/api/adminp/note/findAll', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                where: {
                   ...params 
                },
                order: [
                    ['updatedAt', 'desc']
                ]
            })
        })
        res = await res.json()
        setData(res)
    }, [active])
    useEffect(() => {
        searchActive()
        if (active !== 0) {
            socket.emit('create', 'notes_team_' + active)
            socket.on('update_note_list', searchActive)
        } else {
            socket.emit('create', 'notes_user_' + store.getState().user.id)
            socket.on('update_note_list', searchActive)
        }
        return () => {
            if (active !== 0) {
                socket.off('update_note_list')
                socket.emit('leave', 'notes_team_' + active)
            } else {
                socket.off('update_note_list')
                socket.emit('leave', 'notes_user_' + store.getState().user.id)
            }
        }
    }, [active, searchActive])
    const createNote = async () => {
        let params = {}
        if (active === 0) {
            params['UserId'] = store.getState().user.id
        } else {
            params['TeamId'] = active
        }
        let note = await fetch('/api/adminp/note/createNote', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                note_name: translations.note,
                ...params
            })
        })
        note = await note.json()
        setNoteActive(note)
        if (params.TeamId) {
            socket.emit('update_note_list', { room: 'notes_team_' + params.TeamId })
        } else {
            socket.emit('update_note_list', { room: 'notes_user_' + store.getState().user.id })
        }
    }
    let notes_formated = []
    if (data && data.length) {
        notes_formated = data.map((item, key) => {
            return <div className={'note_list' + (item.id === noteActive ? ' active' : '')} key={key} onClick={() => setNoteActive(item.id)}><span>{item.note_name}</span><i className='far fa-chevron-right'></i></div>
        })
    }
    return (
        <div className='fdiv30'>
            <div className='btn_primary fwrg mb10' onClick={createNote}><i className='far fa-plus-circle'></i><span>{translations.addNote}</span></div>
            <div className='notes_list'>
                {notes_formated}
            </div>
        </div>
    )
}
export const NoteActive = ({ noteActive, setNoteActive }) => {
    const [data, setData] = useState(false)
    const [name, setName] = useState(false)
    const [customer, setCustomer] = useState(false)
    const [description, setDescription] = useState(false)
    let translations = store.getState().language.translations
    const getData = useCallback(async () => {
        let res = await fetch('/api/adminp/note/findOne', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: noteActive
            })
        })
        res = await res.json()
        setData(res)
        setName(res.note_name ? res.note_name : '')
        setDescription(res.note_description ? res.note_description : '')
        setCustomer(res.CustomerId)
    }, [noteActive])
    useEffect(() => {
        getData()
    }, [noteActive, getData])
    const updateName = (e) => {
        setName(e.currentTarget.value)
    }
    const updateDescription = (e) => {
        setDescription(e.currentTarget.value)
    }
    const updateNote = async () => {
        if (data.note_name !== name || data.note_description !== description || data.CustomerId !== customer) {
            await fetch('/api/adminp/note/updateNote', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    id: data.id,
                    note_name: name,
                    note_description: description,
                    CustomerId: customer
                })
            })
            if (data.TeamId) {
                socket.emit('update_note_list', {room: 'notes_team_' + data.TeamId})
            } else {
                socket.emit('update_note_list', { room: 'notes_user_' + store.getState().user.id })
            }
        }
    }
    const deleteNote = async () => {
        await fetch('/api/adminp/note/deleteNote', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: data.id,
            })
        })
        if (data.TeamId) {
            socket.emit('update_note_list', { room: 'notes_team_' + data.TeamId })
        } else {
            socket.emit('update_note_list', { room: 'notes_user_' + store.getState().user.id })
        }
        setNoteActive(false)
    }
    return (
        <div className='fdiv45'>
            {data ? (
                <div className='note_inner'>
                    <div className='note_header aicenter mb10'>
                        <input type='text' value={name} onChange={updateName}  onBlur={updateNote} />
                        <div className='edit mlauto'>
                            <i className='fal fa-trash-alt crpointer' onClick={deleteNote}></i>
                        </div>
                    </div>
                    <div className='note_description'>
                        <textarea value={description} onChange={updateDescription} onBlur={updateNote} ></textarea>
                    </div>
                    <div className='note_customer'>
                        {/* {data.CustomerId ? data.Customer.} */}
                    </div>
                </div>
            ) : translations.selectNote}
        </div>
    )
}