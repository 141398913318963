import React, {useState, useEffect, useCallback} from 'react'
import store, { updateHelpers } from '../reducers/reducers';
import { renderModal, processForm, processModalForm, closeModal } from './Modal';
import { renderNotification } from '../utils/renderNotification';
import { DragHandle, SortableContainer } from '../panel/administration/AdminCustomFields';
import arrayMove from 'array-move';
import { SortableElement } from 'react-sortable-hoc';
import { Switch } from './Form';
const TaskHelperConfig = () => {
    let translations = store.getState().language.translations
    const [modal, setModal] = useState(false)
    const [notification, setNotification] = useState(false)
    const [active, setActive] = useState(false)
    const addAssistant = () => {
        setModal({
            title: <div className="aicenter title_inner"><i className='fas fa-plus-square'></i> <span>{translations.addAssistant}</span></div>,
            id: 'add_assistant',
            inner:
                <form className='formContainer wrap' id='addAssistant' action='/api/adminp/user/createTaskHelper' method='POST' onSubmit={async (e) => {await processForm(e, translations.succAddAssistant, translations.errAddAssistant, setNotification); updateHelpers()}}>
                    <input type='hidden' readOnly value={store.getState().user.id} name='user_id' />
                    <h6>{translations.name}</h6>
                    <input type='text' name='helper_name' placeholder={translations.inName} required />
                    <button id='submit' type='submit' className='notInclude' style={{ display: 'none' }}></button>
                </form>
            ,
            onAccept: () => processModalForm('addAssistant'),
            notification: renderNotification(notification),
            onCancel: () => closeModal(setNotification, setModal)
        })
    }
    let tasksHelpers = store.getState().helpers
    let renderedTaskHelpers = []
    if(tasksHelpers){
    renderedTaskHelpers = tasksHelpers.map((item, key) => {
        return (
            <div className='admin_taskhelper jcspace aicenter' onClick={() => setActive(item.id)} key={key} >
                <div className='taskhelper_name'>{item.helper_name}</div>
                <div className={'taskhelper_active ' + ((item.active) ? 'greenfield' : 'redfield')} >
                    <i className='fas fa-circle'></i>
                    <span>{item.active ? translations.active : translations.disabled}</span>
                </div>
                <div className='actions'>
                    <div className='edit'>
                        <i className='far fa-pencil'></i>
                    </div>
                </div>
            </div>
        )
    })
    }
    return (
        <div id='taskhelper_config' className='wrap'>
            <div className='box fdiv55'>
                <div className='box_inner'>
                    <div className='box_header'><h3>{translations.taskHelper}</h3></div>
                    <div className='box_content'>
                        <div className='add_assistant'>
                            <div className='btn_primary' onClick={addAssistant}><i className='fal fa-plus-circle'></i><span>{translations.addAssistant}</span></div>
                        </div>
                        <div className='assistant_list'>
                            {renderedTaskHelpers}
                        </div>
                    </div>
                </div>
            </div>
            <EditTaskHelper id={active} setActive={setActive}/>
            {renderModal(modal, notification, setNotification , setModal)}
        </div>
    );
}
export const EditTaskHelper = ({ id, setActive }) => {
    const [data, setData] = useState(false)
    let translations = store.getState().language.translations
    const options = {
        task_name: translations.title,
        customer: translations.customer,
        team: translations.team,
        user: translations.user,
        description: translations.description,
        maxDate: translations.maxDate,
        shop: translations.shop,
        contact: translations.contactPerson
    }
    const getData = useCallback(async () => {
        var result = await fetch('/api/adminp/user/findTaskHelper', {
            method: 'POST',
            headers: {
                'Content-Type' : 'application/json'
            },
            body: JSON.stringify({
                helper_id: id
            })
        })
        result = await result.json()
        setData(result)
    },[id])
    useEffect(() => {
        if(id) {            
            getData()
        }
        return () => {
            setData(false)
        }
    }, [id, getData])
    if(!id){
        return ''
    }
    const addField = async (helperfield_input) => {
        await fetch('/api/adminp/user/createTaskHelperField', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                helper_id: data.id,
                helperfield_input,
                position: data.TaskHelperFields.length+1
            })
        })
        getData()
    }
    let fieldsFormated = []
    let actualFields = data.TaskHelperFields ? data.TaskHelperFields.map((item) => item.helperfield_input) : []
    for (let opt in options) {
        if (!actualFields.includes(opt)) {
            fieldsFormated.push(<div className='field_to_add btn_primary mr10 mb10' key={opt} onClick={() => addField(opt)}>{options[opt]}</div>)
        }
    }
    const deleteOrderRule = async (id) => {
        await fetch('/api/adminp/user/deleteTaskHelperField', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id
            })
        })
        getData()
    }
    const updatePosition = async (item) => {
        await fetch('/api/adminp/user/updateTaskHelperField', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: item.id,
                position: item.position
            })
        })
    }
    const modifyPosition = async ({oldIndex, newIndex}) => {
        let array = await arrayMove(data.TaskHelperFields, oldIndex, newIndex)
        let arrayformated = array.map((item, key) => {
            item.position = key + 1
            return item
        })
        let mydata = {}
        mydata = {...data}
        mydata.TaskHelperFields = arrayformated
        setData(mydata)
        for (let k in arrayformated) {
            let item = arrayformated[k]
            await updatePosition(item, false)
        }
        getData()
    }
    let orderOptionsFormated = ''
    if (data.TaskHelperFields && data.TaskHelperFields.length) {
        orderOptionsFormated = data.TaskHelperFields.map((item, key) => {
            return <SortableItemVal item={item} key={key} index={item.position - 1} deleteOrderRule={deleteOrderRule}  options={options}/>
        })
    }
    const changeActive = async (e) => {
        await fetch('/api/adminp/user/updateTaskHelper', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: data.id,
                active: e.currentTarget.checked
            })
        })
        updateHelpers()
    }
    const deleteHelper = async () => {
        await fetch('/api/adminp/user/deleteTaskHelper', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: data.id,
            })
        })
        setData(false)
        setActive(false)
        updateHelpers()
    }
    return (
        <div className='edit_task_helper fdiv40 mlauto mrauto box'>
            <div className='box_inner'>
                <div className='box_header aicenter'>
                    <h3>{data.helper_name}</h3>
                    <div className='edit_zone mlauto aicenter'>
                        {data ? <Switch parentProps={{ className: 'mr10 customfield_actions' }} checkboxProps={{ id: 'active', name: 'active', defaultChecked: data.active, onChange: changeActive }} text={translations.active} /> : ''}
                        <i className='fal fa-trash-alt mr10 crpointer' onClick={deleteHelper}></i>
                    </div>
                </div>
                <div className='box_content'>
                    <h3 className='mb10'>{translations.addField}</h3>
                    <div id='add_helper_field' className='wrap'>
                        {fieldsFormated}
                    </div>
                    <div className='box_content' id='sortable_order'>
                        <SortableContainer
                            children={orderOptionsFormated} id='sortable_customfields' onSortEnd={modifyPosition}
                            className='box_content wrap'
                            helperContainer={document.getElementById('sortable_order')} useDragHandle={true} />
                    </div>
                </div>
            </div>
        </div>
    )
}
export const SortableItemVal = SortableElement(({ item, key, deleteOrderRule, options }) => {
    return (
        <div key={key} className='customfield aicenter flex100'>
            <DragHandle text={item.position} fdiv='fdiv33' />
            <div className='fdiv33 aicenter'>
                {options[item.helperfield_input]}
            </div>
            <div className='actions fdiv33 jcend aicenter'>
                <div className='remove crpointer' onClick={() => deleteOrderRule(item.id)}>
                    <i className='fal fa-trash-alt'></i>
                </div>
            </div>
        </div>
    )
})
export default TaskHelperConfig;