import React, {useState, useEffect, useCallback} from 'react';
import { renderModal, processForm, processModalForm, closeModal } from "../../minicomponents/Modal";
import store, { changePage } from '../../reducers/reducers';
import { renderNotification } from '../../utils/renderNotification';
import TextareaAutosize from 'react-textarea-autosize';
import { CustomSelect } from '../../minicomponents/Form';
import socket from '../../utils/socket';
const AdminNotifications = () => {
    const [notification, setNotification] = useState(false)
    const [modal, setModal] = useState(false)
    const [data, setData] = useState(false)
    const getData = useCallback(async () => {
        let mydata = await fetch('/api/adminp/adminnotifications/findAllAdminNotifications', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                order: [['updatedAt' , 'DESC']]
            })
        })
        mydata = await mydata.json()
        setData(mydata)
    }, [setData])
    const addMyNotification = async (e, mydelete = false) => {
        if (!mydelete) {
            await processForm(e, translations.succAddNotification, translations.errAddNotification, setNotification);
            socket.emit('update_adminnotifications', {show: 'true'})
        } else {
            await processForm(e, 'OK!', 'KO!', setNotification);
            socket.emit('update_adminnotifications', { show: 'false' })
        }
        getData()
    }
    const addNotification = async () => {
        let options = [
            {
                value: 1,
                text: translations.high
            },
            {
                value: 2,
                text: translations.medium
            },
            {
                value: 3,
                text: translations.low
            },
        ]
        setModal({
            title: <div className="aicenter title_inner"><i className='fas fa-plus-square'></i> <span>{translations.addNotification}</span></div>,
            id: 'add_notification',
            inner:
                <form className='formContainer wrap' id='createNotification' action='/api/adminp/adminnotifications/createAdminNotifications' method='POST' onSubmit={addMyNotification}>
                    <div className='inputDiv nopadding'>
                        <h6>{translations.title}</h6>
                        <input type='text' name='notification_title' placeholder={translations.title} required />
                    </div>
                    <div className='inputDiv nopadding'>
                        <h6>{translations.priority}</h6>
                        <CustomSelect selectProps={{
                            name: 'notification_priority'
                        }} options={options} />
                    </div>
                    <div className='inputDiv fdiv100 nopadding'>
                        <h6>{translations.description}</h6>
                        <TextareaAutosize type='text' name='notification_description' placeholder={translations.description} required maxRows={5} minRows={2} />
                    </div>
                    <button id='submit' type='submit' className='notInclude' style={{ display: 'none' }}></button>
                </form>
            ,
            onAccept: () => processModalForm('createNotification'),
            notification: renderNotification(notification),
            onCancel: () => closeModal(setNotification, setModal)
        })
    }
    useEffect(() => {
        getData()
    }, [getData])
    useEffect(() => {
        changePage({
            name: 'adminnotifications',
        })
    }, [])
    const modifyNotification = (id) => {
        let myitem = data.filter((item) => item.id === id)
        if (myitem) {
            myitem = myitem[0]
        }
        let options = [
            {
                value: 1,
                text: translations.high
            },
            {
                value: 2,
                text: translations.medium
            },
            {
                value: 3,
                text: translations.low
            },
        ]
        setModal({
            title: <div className="aicenter title_inner"><i className='fas fa-pen-square'></i> <span>{translations.modifyNotification}</span></div>,
            id: 'update_notification',
            inner:
                <form className='formContainer wrap' id='updateNotification' action='/api/adminp/adminnotifications/updateAdminNotifications' method='POST' onSubmit={addMyNotification}>
                    <input type='hidden' value={id} name='id' readOnly />
                    <div className='inputDiv nopadding'>
                        <h6>{translations.title}</h6>
                        <input type='text' name='notification_title' placeholder={translations.title} required defaultValue={myitem.notification_title} />
                    </div>
                    <div className='inputDiv nopadding'>
                        <h6>{translations.priority}</h6>
                        <CustomSelect selectProps={{
                            name: 'notification_priority',
                            value: myitem.notification_priority
                        }} options={options} />
                    </div>
                    <div className='inputDiv fdiv100 nopadding'>
                        <h6>{translations.description}</h6>
                        <TextareaAutosize type='text' name='notification_description' placeholder={translations.description} required maxRows={5} minRows={2} defaultValue={myitem.notification_description} />
                    </div>
                    <button id='submit' type='submit' className='notInclude' style={{ display: 'none' }}></button>
                </form>
            ,
            onAccept: () => processModalForm('updateNotification'),
            notification: renderNotification(notification),
            onCancel: () => closeModal(setNotification, setModal)
        })
    }
    const deleteNotification = (id) => {
        setModal({
            title: <div className="aicenter title_inner"><i className='fas fa-trash-alt'></i> <span>{translations.deleteNotification}</span></div>,
            id: 'delete_notification',
            inner:
                <form className='formContainer wrap' id='deleteNotification' action='/api/adminp/adminnotifications/deleteAdminNotifications' method='POST' onSubmit={(e) => addMyNotification(e, true)}>
                    <input type='hidden' value={id} name='id' readOnly />
                    <button id='submit' type='submit' className='notInclude' style={{ display: 'none' }}></button>
                </form>
            ,
            onAccept: () => processModalForm('deleteNotification'),
            notification: renderNotification(notification),
            onCancel: () => closeModal(setNotification, setModal),
            careModal: true
        })
    }
    let translations = store.getState().language.translations
    let notifications_rendered = ''
    if (data) {
        notifications_rendered = data.map((item, key) => {
            let myicon = ''
            if (item.notification_priority === 1) {
                myicon = 'fas fa-exclamation-circle'
            } else if(item.notification_priority === 2) {
                myicon = 'fas fa-exclamation-triangle'
            } else if (item.notification_priority === 3) {
                myicon = 'fas fa-dot-circle'
            }
            return (
                <div className='adminnotification_admin wrap fdiv33' key={key}>
                    <div className={'adminnotification aicenter fdiv80 type_' + item.notification_priority}>
                        <div className='notificationicon '>
                            <i className={myicon}></i>
                        </div>
                        <div className='notificationtext'>
                            <div className='notification_title'>{item.notification_title}</div>
                            <div className='notification_description'>{item.notification_description}</div>
                        </div>
                    </div>
                    <div className='actions fdiv20 jcend aicenter'>
                        <div className='edit crpointer' onClick={() => modifyNotification(item.id)}><i className='far fa-pencil'></i></div>
                        <div className='remove crpointer' onClick={() => deleteNotification(item.id)}><i className='fal fa-trash-alt'></i></div>
                    </div>
                </div>
            )
        })
    }
    return (
        <div className='adminnotifications wrap'>
            <div className='box fdiv100 mrauto'>
                <div className='box_inner'>
                    <div className='box_header'>
                        <button className='btn_primary add_notifications' onClick={addNotification}>
                            <i className='far fa-plus-circle'></i>
                            <span>{translations.addNotification}</span>
                        </button>
                    </div>
                    <div className='box_content'>
                        <div className='wrap adminnotifications_list'>
                            {notifications_rendered}
                        </div>
                    </div>
                </div>
            </div>
            {renderModal(modal, notification, setNotification, setModal)}
        </div>
    );
}
 
export default AdminNotifications;