import React, {useState} from 'react';
import { returnNotifiPostForm } from './utils/forms';
import { renderNotification } from './utils/renderNotification';
import store from './reducers/reducers';
import socket from './utils/socket';
const Recover = ({match}) => {
    var translations = store.getState().language.translations
    const [notification, setNotification] = useState(false)
    const [userId, setUserId] = useState(null)
    var renderThis = ''
    const fetchRecoveryForm = async (e) => {
        var response = await returnNotifiPostForm(e, translations.succEmailPassword, translations.errEmailPassword)
        setNotification(response)
    }
    const fetchNewPasswordForm = async (e) => {
        e.preventDefault()
        var response = ''
        if(e.target.password.value === e.target.repeatPassword.value){
            response = await returnNotifiPostForm(e, translations.succRecoverPw, translations.errRecoverPw)
            socket.emit('update_user', userId)
        }else{
            response = { type: 'error', msg: translations.pwNotMatch}
        }
        setNotification(response)
    }
    const fetchToken = async () => {
        let result = await fetch('/api/user/recover/'+match.params.token, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        result = await result.json()
        setUserId(result)
    }
    if(match.params.token && userId === null){
        fetchToken()
    }else{
        if(userId){
            renderThis = (
                <form action="/api/user/recover" method='post' onSubmit={fetchNewPasswordForm} >
                    <div className='inputDiv'>
                        <input type='hidden' name='token' placeholder='' value={match.params.token} required />
                    </div>
                    <div className='inputDiv'>
                        <h6>{translations.password}</h6>
                        <input type='password' name='password' placeholder={translations.password} required />
                    </div>
                    <div className='inputDiv'>
                        <input type='password' name='repeatPassword' placeholder={translations.repeatPassword} className='notInclude' required />
                    </div>
                    <button type='submit' className='notInclude defaultButton'>{translations.recover}</button>
                </form>
            )
        }else{
            renderThis = (
                <form action="/api/user/recoverPassword" method='post' onSubmit={fetchRecoveryForm} >
                    <div className='inputDiv'>
                        <h6>{translations.user}</h6>
                        <input type='text' name='user' placeholder={translations.userOrEmail} required />
                    </div>
                    <button type='submit' className='notInclude defaultButton'>{translations.recover}</button>
                </form>
            )
        }
    }
    return (
        <div id='recoverbg'>
            <div id='recoverblock' className='max350 aicenter mt10vh'>
                <div id='recoverform' className='formContainer'>
                    <div className="formInner">
                        <h3>{translations.recoverPassword}</h3>
                        {renderNotification(notification)}
                        {renderThis}
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default Recover;