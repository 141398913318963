import React, {useEffect} from 'react'
import {changePage} from '../reducers/reducers'
import NewTask from '../minicomponents/NewTask'
// import { GraphByDay } from '../minicomponents/Graphs'
const Dashboard = ({match, history}) => {
    useEffect(() => {
        changePage({
            name: 'dashboard'
        })
    }, [])
    return ( 
        <div className='dashboard'>
            <NewTask extraParams={{ task_id: match.params.task_id }} url={''} history={history} />
            {/* <GraphByDay /> */}
        </div>
    );
}
 
export default Dashboard;