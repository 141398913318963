import React, {useState} from 'react'
import { renderNotification } from './utils/renderNotification'
import { fetchPostForm } from './utils/forms';
import store, { changeUser, updateLanguage, updateCustomFields, updateCustomOrder, updateHelpers } from './reducers/reducers'
import {Checkbox,CustomSelect} from './minicomponents/Form'
import {Link} from 'react-router-dom'
import socket from './utils/socket';
const Login = () => {
    var translations = store.getState().language.translations
    const [notification, setNotification] = useState(false)
    const fetchForm = async (e) => {
        var response = await fetchPostForm(e, true)
        if (response === false) {
            setNotification({
                type: 'error',
                msg: translations.errNoLogin
            })
        } else {
            setNotification({
                type: 'success',
                msg: translations.succLogin
            })
            await updateCustomFields()
            await updateCustomOrder()
            await changeUser()
            await updateHelpers()
            socket.on('update_custom_fields', updateCustomFields)
            socket.on('update_custom_fields', updateCustomOrder)
            socket.on('update_custom_fields', updateHelpers)
        }
    }
    return (
    <div id='loginbg'>
        <div id='loginwelcome'>  
            <img src='/logob.svg' className='headerImg' alt='Twintask logo' ></img>
            <div className='loginlang'>
                    <CustomSelect selectProps={{value:(store.getState().language.lang_iso) ? store.getState().language.lang_iso : 'en', onChange: async (lang_iso) => {
                        fetch('/api/user/updateLang',{
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                lang_iso
                            })
                        }).then(() => {
                            updateLanguage()
                        })
                    }}} 
                    options={[
                        {
                            value: 'es',
                            text: 'Español'
                        },
                        {
                            value: 'en',
                            text: 'English'
                        },
                        {
                            value: 'ca',
                            text: 'Català'
                        },
                        {
                            value: 'fr',
                            text: 'Français'
                        }
                    ]}
                    fixedValue={true} />
                </div>  
        </div>
        <div id='loginblock' className='max350 aicenter'>
            <div id='loginform' className='formContainer'>
                <div className="formInner">
                    <h3>{translations.login}</h3>
                    {renderNotification(notification)}
                    <form action="/api/user/tryLogin" method='post' onSubmit={fetchForm} >
                        <div className='inputDiv'>
                            <h6>{translations.user}</h6>
                            <input type='text' name='user' placeholder={translations.userOrEmail} required />
                        </div>
                        <div className='inputDiv'>
                            <h6>{translations.password}</h6>
                            <input type='password' name='password' placeholder={translations.password} required />
                            <Link className='remember' to='/recover'>{translations.forgotPassword}</Link>
                        </div>
                        <Checkbox parentProps={{ className: 'inputDiv' }} text={translations.keepSession} checkboxProps={{id:'remember', name: 'remember'}}/>
                        <button type='submit' className='notInclude defaultButton'>{translations.continue}</button>
                    </form>
                </div>
            </div>
        </div>
        <div id='loginfooter' className='max350'>
            <nav>
                <a href="https://www.teinor.net/" target="_blank" rel="noopener noreferrer"><span>Acerca de Twintask</span></a>
                <a href="https://www.teinor.net/" target="_blank" rel="noopener noreferrer"><span>Ayuda</span></a>
                <a href="https://www.teinor.net/" target="_blank" rel="noopener noreferrer"><span>Términos</span></a>
            </nav>
            <h6>powered by <a href="https://www.teinor.net/" target="_blank" rel="noopener noreferrer">Teinor</a></h6>
        </div>
    </div> 
    );
}
 
export default Login;