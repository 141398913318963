import React, {useState, useEffect, useCallback} from 'react'
import {Link} from 'react-router-dom'
import {CustomSelect} from './Form'
import SearchBar from './Searchbar'
import Separator from './Separator'
import store from '../reducers/reducers';
const Table = ({table, spaceForChilds = false}) => {
    const actionButtonsHeader = table.actionButtonsHeader.map((item, key) => {
        if(item.type === 'button'){
            return <button className={item.className} key={key} onClick={item.action}><i className={item.icon}></i><span>{item.text}</span></button>
        } else if (item.type === 'link') {
            return <Link className={item.className} key={key} to={item.goTo}><i className={item.icon}></i><span>{item.text}</span></Link>
        }
        return <div></div>;
    })
    const [searchbar, setSearchbar] = useState(false)
    const [attrsTable, setAttrTable] = useState(false)
    const [headerTable, setHeaderTable] = useState(false)
    const [reRenderTable, setReRenderTable] = useState(false)
    const [dataTable, setDataTable] = useState([])
    const [dataTableRender, setDataTableRender] = useState(false)
    const [pagination, setPagination] = useState(false)
    const [tableName, setTableName] = useState(false)
    const updateSearchQuery = (info) => {
        pagination.haveToPag = true
        pagination.page = 1
        setPagination(pagination)
        setSearchbar({...info})
    }
    const updateOrderBy = (info) => {
        pagination.haveToPag = true
        pagination.page = 1
        setPagination(pagination)
        setHeaderTable({ ...info })
    }
    const updatePagination = (info) => {
        info.haveToPag = true
        setPagination({...info})
    }
    const asyncRender = async () => {
        var data = await TableData(headerTable, widthcalc, table, dataTable, spaceForChilds, setDataTable, setReRenderTable)
        setDataTableRender(data)
        setReRenderTable(false)
    }
    const updateDataTable = useCallback(async () => {
        if (!attrsTable) {
            return false
        }
        if (table.getDataFrom.method === 'POST') {
            if (!attrsTable.includes('id')) {
                attrsTable.push("id")
            }
            var body = {
                attributes: [...attrsTable]
            }
            if (searchbar.searchQuery) {
                var search = {}
                if (searchbar.searchBy === 'all') {
                    search['$or'] = []
                    searchbar.fields.map((item) => {
                        var citem = {}
                        citem[item.name] = { $iLike: '%' + searchbar.searchQuery + '%' }
                        search['$or'].push(citem)
                        return true;
                    })
                    body.where = search
                } else {
                    search[searchbar.searchBy] = { $iLike: '%' + searchbar.searchQuery + '%' }
                    body.where = search
                }
            }
            if (Object.keys(headerTable.orderBy).length) {
                body.order = []
                for (var k in headerTable.orderBy) {
                    var order = headerTable.orderBy[k]
                    body.order.push([k, order])
                }
            }
            if (table.getDataFrom.defaultValues) {
                body = { ...table.getDataFrom.defaultValues, params: { ...body } }
            }
            if (table.getDataFrom.defaultParmsValues) {
                body = { ...table.getDataFrom.defaultParmsValues, ...body }
            }
            if (pagination.maxPage && table.pagination) {
                body.limit = pagination.maxPage
                body.offset = (pagination.page * pagination.maxPage - pagination.maxPage)
            }
            var data = await fetch(table.getDataFrom.from, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            })
            data = await data.json()
            if (table.getDataFrom.filterData) {
                data = await table.getDataFrom.filterData(data)
            }
            if (pagination.haveToPag && table.pagination) {
                pagination.haveToPag = false
                pagination.totalPages = Math.ceil(data.count / pagination.maxPage)
                pagination.totalResults = data.count
                if (pagination.totalPages === Infinity) {
                    pagination.totalPages = 1
                }
                setPagination(pagination)
            }
            delete data.count
            setDataTable({ ...data })
            setReRenderTable(true)
        }
    }, [table, searchbar, headerTable, pagination, attrsTable])
    if ((attrsTable === false && searchbar === false) || table.name !== tableName) {
        var searchTemp = []
        var attrsTableTemp = []
        var headerTableTemp = []
        for(var k in table.fields){
            var field = table.fields[k]
            if(field.searchable){
                searchTemp.push(field)
            }
            headerTableTemp.push(field)
            attrsTableTemp.push(field.name)
        }
        setSearchbar({fields: searchTemp, searchBy: 'all', searchQuery: ''})
        setHeaderTable({fields: headerTableTemp, orderBy: {} })
        setPagination({page: 1, maxPage: 5, haveToPag: true})
        setAttrTable(attrsTableTemp)
        setTableName(table.name)
    }
    useEffect(() => {
        updateDataTable()
    }, [updateDataTable])
    if(headerTable.fields){
        var nelements = headerTable.fields.length
        if (table.actionButtonsTable) {
            nelements += 1
        }
    }
    var widthcalc = (100 / nelements) + '%';
    if(reRenderTable){
        asyncRender()
    }
    return ( 
        <div className='table_parent listing'>
            <div className="second_header aicenter">
                <div className="left aicenter">
                    {actionButtonsHeader}
                </div>
                <div className="right aicenter mlauto">
                    <SearchBar searchbar={searchbar} sendInfoTo={updateSearchQuery} />
                </div>
            </div>
            <div className="table_inner">
                <Separator />
                <div className="table_header aicenter">
                    <TableHeader headerTable={headerTable} widthcalc={widthcalc} sendInfoTo={updateOrderBy} table={table} />
                </div>
                <Separator />
                <div className="table_content">
                    {dataTableRender}
                </div>
                {(table.pagination) ? <Separator /> : ''}
                <div className="table_footer">
                    {(table.pagination) ? <Pagination pagination={pagination} sendInfoTo={updatePagination} /> : ''}
                </div>
            </div>
        </div>
    );
}
const TableHeader = ({headerTable, widthcalc, sendInfoTo, table}) => {
    var translations = store.getState().language.translations
    const updateOrderby = (info) => {
        var nextdata = headerTable
        var orderby = nextdata.orderBy
        if (headerTable.orderBy[info] === undefined) {
            orderby[info] = 'ASC'
        } else if (headerTable.orderBy[info] === 'ASC') {
            orderby[info] = 'DESC'
        } else {
            delete orderby[info]
        }
        nextdata.orderBy = orderby
        sendInfoTo(nextdata)
    }
    var tableHeaderRender = ''
    if (headerTable) {
        tableHeaderRender = headerTable.fields.map((item, key) => {
            return (
                <div style={{ width: widthcalc }} className={'table_header_element' + ((headerTable.orderBy[item.name] !== undefined) ? ' active' : '') + ((item.sortable) ? ' sortable' : '')} onClick={() => { if (item.sortable) { updateOrderby(item.name) } }} key={key}>
                    <div className="table_header_element_inner">
                        {(headerTable.orderBy[item.name] !== undefined && headerTable.orderBy[item.name] === 'DESC') ? <i className='far fa-chevron-up'></i> : (headerTable.orderBy[item.name] !== undefined && headerTable.orderBy[item.name] === 'ASC') ? <i className='far fa-chevron-down'></i> : (item.sortable) ? <i className='far fa-chevron-down'></i> : ''}
                        <span>{item.text}</span>
                    </div>
                </div>
            )
        })
        if (table.actionButtonsTable) {
            tableHeaderRender.push(<div style={{ width: widthcalc }} className='table_header_element table_header_actions' key='999'><div className="table_header_element_inner">{translations.actions}</div></div>)
        }
    }
    return (tableHeaderRender)
}
const TableData = async(headerTable, widthcalc, table, dataTable, spaceForChilds, setDataTable = false, setReRenderTable = false) => {
    const getFieldByName = (name) => {
        return table.fields.filter(field => field.name === name)[0]
    }
    const processChild = (id, mychilds) => {
        let myitem = false
        for(let k in dataTable){
            let item = dataTable[k]
            if(item.id === id){
                myitem = item
            }
        }
        myitem.mychilds = mychilds
        setDataTable(dataTable)
        setReRenderTable(true)
    }
    const formatEditButtons = (row) => {
        return table.actionButtonsTable.map((item, key) => {
            if (item.type === 'button') {
                return <button idrow={row.id} key={key} className={'table_action_btn ' + item.className} onClick={(e) =>{e.stopPropagation();item.action(e)}}>{(item.icon !== undefined && item.icon) ? <i className={item.icon}></i> : ''}</button>
            } else if(item.type === 'link'){
                return <Link to={item.action+row.id} className={'table_action_btn ' + item.className} key={key} >{(item.icon !== undefined && item.icon) ? <i className={item.icon}></i> : ''}</Link>
            } else if(item.type === 'seeMore'){
                return <button idrow={row.id} key={key} className={'table_action_btn ' + item.className} onClick={async (e) => { e.stopPropagation(); processChild(row.id , await item.action(e, row)) }}>{(item.icon !== undefined && item.icon) ? <i className={item.icon}></i> : ''}</button>
            }
            return '';
        })
    }
    var dataTableRender = []
    if (dataTable) {
        for (var key in dataTable) {
            var row = dataTable[key]
            var fields = []
            for (var j in headerTable.fields) {
                j = headerTable.fields[j].name
                var rowfield = row[j]
                var fieldintable = getFieldByName(j)
                if (fieldintable.customResponse !== undefined && fieldintable.customResponse) {
                    if (fieldintable.customResponse[rowfield]) {
                        rowfield = fieldintable.customResponse[rowfield]
                    } else if (fieldintable.customResponse['default']) {
                        rowfield = fieldintable.customResponse['default']
                    }
                }else if(fieldintable.variableResponse !== undefined && fieldintable.variableResponse){
                    rowfield = await fieldintable.variableResponse(rowfield, row)
                }
                fields.push(<div style={{ width: widthcalc }} className={'table_field ' + j} key={j}>{rowfield}</div>)
            }
            if (table.actionButtonsTable !== undefined && table.actionButtonsTable) {
                var actionButtonsTable = formatEditButtons(row)
                fields.push(<div style={{ width: widthcalc }} className='table_edit table_field' key='table_edit'>{actionButtonsTable}</div>)
            }
            dataTableRender.push(<div onClick={(e) => (table.actionOnClickRow) ? table.actionOnClickRow(e) : ''} idrow={row.id} className={'table_row aicenter' + ((table.actionOnClickRow) ? ' clickable' : '')} key={key}>{fields}</div>)
            if(spaceForChilds){
                dataTableRender.push(<div className='table_spacechilds' id={'space_for_childs'+row.id} key={'tsp'+key} >{row.mychilds ? row.mychilds : ''}</div>)
            }
        }
    }
    return dataTableRender
}
export const Pagination = ({pagination, sendInfoTo, hideMaxPerPage = false}) => {
    var nitems = 1
    const updatePage = (page) => {
        pagination.page = page
        sendInfoTo(pagination)
    }
    const updateMaxPage = (value) => {
        pagination.maxPage = parseInt(value)
        pagination.page = 1
        sendInfoTo(pagination)
    }
    var paginationFormated = ''
    if(pagination.totalPages && pagination.maxPage !== 0){
        var preparePag = []
        if(pagination.page > 1){
            preparePag.push(<div className='prevArrow' onClick={() => updatePage(pagination.page - 1)} key='prevArrow'><i className="fas fa-chevron-left"></i></div>)
            if (1 < pagination.page - nitems && pagination.totalPages > 3){
                preparePag.push(<div className='goFirst btnpageextrem btnpage' onClick={() => updatePage(1)} key='goFirst'>1</div>)
            }
            if(pagination.page === pagination.totalPages && pagination.totalPages - 2 >= 1){
                preparePag.push(<div className='btnpage' key={pagination.page - 2} onClick={() => {updatePage(pagination.page - 2)}}>{pagination.page - 2}</div>)
            }
            preparePag.push(<div className='btnpage' key={pagination.page - 1} onClick={() => {updatePage(pagination.page - 1)}}>{pagination.page - 1}</div>)
        }
        preparePag.push(<div className='btnpage active' key='active'>{pagination.page}</div>)
        if(pagination.page < pagination.totalPages){
            preparePag.push(<div key={pagination.page + 1} className='btnpage' onClick={() => { updatePage(pagination.page + 1) }}>{pagination.page + 1}</div>)
            if (pagination.page === 1 && pagination.page + 2 < pagination.totalPages) {
                preparePag.push(<div key={pagination.page + 2} className='btnpage' onClick={() => { updatePage(pagination.page + 2) }}>{pagination.page + 2}</div>)
            }
            if (pagination.page < (pagination.totalPages-nitems)) {
                preparePag.push(<div className='goLast btnpageextrem btnpage' onClick={() => updatePage(pagination.totalPages)} key='goLast'>{pagination.totalPages}</div>)
            }
            preparePag.push(<div className='nextArrow' onClick={() => updatePage(pagination.page + 1)} key='nextArrow'><i className="fas fa-chevron-right"></i></div>)
        }
        paginationFormated = preparePag
}
    return (
    <div className='pagination aicenter'>
        <div className="pagination_left totalelements">
            <span>Mostrando de {pagination.page * pagination.maxPage - pagination.maxPage + 1} a {pagination.totalResults} resultados</span>
        </div>
        <div className="pagination_center pagination_pages aicenter">{paginationFormated}</div>
        {!hideMaxPerPage ? <div className="pagination_right selectmaxpage aibaseline">
           <span>Mostrando por pagina: </span> 
           <CustomSelect selectProps={{value:pagination.maxPage,onChange:updateMaxPage}} 
           options={[
               {
                   value: '5',
                   text: '5'
               },
               {
                   value: '25',
                   text: '25'
               },
               {
                   value: '50',
                   text: '50'
               },
               {
                   value: '100',
                   text: '100'
               },
               {
                   value: '0',
                   text: 'MAX'
               },
            ]}/>
        </div> : ''}
    </div>)
}
export default Table;