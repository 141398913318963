import React, {useState} from 'react'
import { processForm } from './Modal';
const AddList = ({ inputAttr, extraInputs, helperQuery, attrsForm, notificationPos, notificationNeg, setNotification, getDataFrom, renderRow, extraFunction = false, hiddenAdd = false}) => {
    const [helper, setHelper] = useState(false)
    const [data, setData] = useState(false)
    const getData = async () => {
        var result = await getDataFrom()
        if(result){
            setData(result)
        }
    }
    const changeHelper = async (e) => {
        var result = await helperQuery(e)
        setHelper(result)
    }
    const processList = async (e) => {
        await processForm(e, notificationPos, notificationNeg, setNotification, true)
        document.getElementById(inputAttr.id).value = ''
        setHelper(false)
        getData()
        if(extraFunction){
            extraFunction()
        }
    }
    var renderList = ''
    if(data){
        renderList = data.map((item,key) => {
            return renderRow(item,key)
        })
    }else{
        getData()
    }
    return ( 
    <form {...attrsForm} onSubmit={processList}>
        {extraInputs}
        <div className='addList wrap max100'>
            <div className='renderlist fdiv100'>
                {renderList}
            </div>
                {!hiddenAdd ? <div className='fdiv80 inputsearch'>
                    <input {...inputAttr} onChange={changeHelper} />
                    <div className='searchresult'>
                        {helper}
                    </div>
                    </div>
                    : ''}
                {!hiddenAdd ? <div className='fdiv20'>
                    <button type='submit'><i className='fas fa-plus'></i></button>
                </div> : ''}
        </div> 
    </form>
    );
}
 
export default AddList;