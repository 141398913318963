import React, { useState, useEffect } from 'react';
import store, { changePage } from '../../reducers/reducers'
import {MyWorkCalendar} from '../../minicomponents/MyCalendar';
import { connect } from 'react-redux'
import { renderNotification } from '../../utils/renderNotification'
import Modal from '../../minicomponents/Modal';
import { millisecondsToFormat } from '@teinorsa/react-timeinput';
function getMonday(d) {
    let day = d.getDay(),
    diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
}
function getCurrentWeek(){
    let now = new Date()
    let monthless = getMonday(now)
    monthless.setHours(0)
    monthless.setMinutes(0)
    monthless.setSeconds(0)
    let monthmore = new Date(monthless)
    monthmore.setDate(monthmore.getDate() + 7)
    return [monthless, monthmore]
}

const mapStateToProps = state => ({ user: state.notifications })
const UserPunchin = ({ match }) => {
    const [notification, setNotification] = useState(false)
    const [modal, setModal] = useState(false)
    const [getDataFrom] = useState({
        '/api/adminp/user/getUserWork': { UserId: (match.params.user_id) ? match.params.user_id : store.getState().user.id }
    })

    const translations = store.getState().language.translations
    const [times, setTimes] = useState({
        og_calc: 0,
        calc: 0
    })
    const [data, setData] = useState([])
    const [where, setWhere] = useState({
        og_start_time: {
            $between: getCurrentWeek()
        }
    })
    const myGetData = async (getDataFrom, where) => {
        let tasks = []
        let myog_calc = 0
        let mycalc = 0 
        for (let k in getDataFrom) {
            let item = getDataFrom[k]
            let key = Object.keys(item)[0]
            let val = item[key]
            item = {}
            item[key] = val
            let result = await fetch(k, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ...item,
                    params: {
                        where: {
                            ...where
                        }
                    }
                })
            })
            result = await result.json()
            let days = {}
            for (let k in result) {
                let item = result[k]
                if (tasks[item.id] === undefined) {
                    let notEditedStart = new Date(item.og_start_time)
                    let notEditedEnd = new Date(item.og_end_time)
                    let start = new Date(item.edited_start_time ? item.edited_start_time : item.og_start_time)
                    let end = new Date(item.edited_end_time ? item.edited_end_time : item.og_end_time)
                    if (!days[start.getDay()]) {
                        days[start.getDay()] = 0
                    }
                    days[start.getDay()] = days[start.getDay()] + (end - start)
                    myog_calc += (notEditedEnd - notEditedStart)
                    mycalc += (end - start)
                    tasks[item.id] = {
                        title: "\n" + translations.hours + ': ' + millisecondsToFormat(end - start) + "\nTotal " + translations.hours + ': ' + millisecondsToFormat(days[start.getDay()]) + "\n" + (item.edited_start_time || item.edited_end_time ? (translations.edited + (notEditedStart.toLocaleString() + ' - ' + notEditedEnd.toLocaleString())) : ''),
                        edit: {
                            start,
                            end,
                            id: item.id
                        },
                        completed: item.edited_start_time || item.edited_end_time ? true : false,
                        start,
                        end
                    }
                }
            }
        }
        setTimes({
            og_calc: myog_calc,
            calc: mycalc
        })
        setData(tasks)
    }
    const [getData] = useState(() => myGetData)
    let notifications = store.getState().notifications
    useEffect(() => {
        getData(getDataFrom, where)
    }, [notifications, getData, getDataFrom, where])
    const changeWhere = (dataStart) => {
        var start = false
        var finish = false
        if (Array.isArray(dataStart)) {
            start = dataStart[0].setDate(dataStart[0].getDate())
            start = new Date(start)
            let length = dataStart.length - 1
            finish = dataStart[length].setDate(dataStart[length].getDate() + 1)
            finish = new Date(finish)
        } else {
            start = dataStart.start.setDate(dataStart.start.getDate() - 1)
            finish = dataStart.end.setDate(dataStart.end.getDate() + 1)
            start = new Date(start)
            finish = new Date(finish)
        }
        setWhere({
            og_start_time: {
                $between: [
                    start.toUTCString(),
                    finish.toUTCString()
                ]
            }
        })
    }
    useEffect(() => {
        changePage({
            name: 'user_calendar',
        })
    }, [])
    if (parseInt(match.params.user_id) !== parseInt(store.getState().user.id) && store.getState().user.admin_permissions !== 1) {
        return <div>
            {renderNotification({
                type: 'error',
                msg: translations.noPermissions
            })}
        </div>
    }
    var renderModal = ''
    if (modal) {
        renderModal = <Modal {...modal} />
    }
    return (
        <div id='userCalendar' className='p1em punchIn'>
            <div className='bgwhite aicenter mb10 p1em'>
                <h4>{translations.time} {millisecondsToFormat(times.og_calc)}</h4>
                <h4 className='ml10'>{translations.time} {translations.edited} {millisecondsToFormat(times.calc)}</h4>
            </div>
            <MyWorkCalendar onRangeChange={changeWhere} events={data} setModal={setModal} notification={notification} setNotification={setNotification} getData={() => getData(getDataFrom, where)} />
            {renderModal}
        </div>
    );
}

export default connect(mapStateToProps)(UserPunchin);