import React, {useState, useEffect} from 'react'
import store, { changePage } from '../../reducers/reducers';
import { processForm, processModalForm, closeModal, renderModal } from '../../minicomponents/Modal'
import { renderNotification } from '../../utils/renderNotification';
import { CustomSelect } from '../../minicomponents/Form';
import {DragHandle, SortableContainer } from './AdminCustomFields';
import arrayMove from 'array-move';
import { SortableElement } from 'react-sortable-hoc';
const AdminDashboard = () => {
    var translations = store.getState().language.translations
    const [orderRules, setOrderRules] = useState(false)
    const [modal, setModal] = useState(false)
    const [notification, setNotification] = useState(false)
    const updateData = async () => {
        let data = await fetch('/api/adminp/adminSettings/findAllCustomOrders', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                order: [
                    ['customorder_position', 'ASC']
                ]
            })
        })
        data = await data.json()
        setOrderRules(data)
    }
    if(!orderRules){
        updateData()
    }
    var options = [
        {
            value: 'task_name',
            text: translations.name,
            type: '1'
        }
    ]
    var mycustomfields = store.getState().customfields
    if(mycustomfields && mycustomfields.length){
        mycustomfields.forEach((item) => {
            options.push({
                value: 'task_cf_' + item.id,
                text:  item.customfield_name,
                type: '2'
            })
        })
    }
    var selectProps = {
        name: 'customorder_orderfor',
        value: false,
        onChange: (val) =>{
            let item = options.filter((item) => val === item.value)
            item = item[0].type
            document.forms[0].customorder_type.value = item
        }
    }
    var orderOptions = [
        {
            value: 'ASC',
            text: 'ASC',
        },
        {
            value: 'DESC',
            text: 'DESC',
        }
    ]
    var selectPropsOrder = {
        name: 'customorder_order',
        value: 'ASC'
    }
    const addRule = () => {
        setModal({
            title: <div className="aicenter title_inner"><i className='fas fa-plus-square'></i> <span>{translations.addRule}</span></div>,
            id: 'add_rule',
            inner:
                <form className='formContainer wrap' id='createRule' action='/api/adminp/adminSettings/createCustomOrder' method='POST' onSubmit={async (e) => { await processForm(e, translations.succCreateRule, translations.errCreateRule, setNotification); updateData()}}>
                    <input type='hidden' name='customorder_type' defaultValue={options[0].type} />
                    <input type='hidden' name='customorder_position' defaultValue={(orderRules) ? orderRules.length+1 : 1 } />
                    <CustomSelect selectProps={selectProps} options={options} />
                    <CustomSelect selectProps={selectPropsOrder} options={orderOptions} />
                    <input type='checkbox' id='customorder_forced' name='forced' />
                    <label htmlFor='customorder_forced' className='aicenter'>
                        <i className='far fa-lock-open-alt'></i>
                        <i className='far fa-lock-alt'></i>
                    </label>
                    <button id='submit' type='submit' className='notInclude' style={{ display: 'none' }}></button>
                </form>
            ,
            onAccept: () => processModalForm('createRule'),
            notification: renderNotification(notification),
            onCancel: () => closeModal(setNotification, setModal)
        })
    }
    const updateOrderRule = async (item, update = true) => {
        await fetch('/api/adminp/adminSettings/updateCustomOrder', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                ...item
            })
        })
        if(update){
            updateData()
        }
    }
    const modifyPosition = async ({ oldIndex, newIndex }) => {
        let array = await arrayMove(orderRules, oldIndex, newIndex)
        let arrayformated = array.map((item, key) => {
            item.customorder_position = key + 1
            return item
        })
        setOrderRules(arrayformated)
        for (let k in arrayformated) {
            let item = arrayformated[k]
            updateOrderRule(item, false)
        }
    }
    const deleteOrderRule = async (customorder_id) => {
        await fetch('/api/adminp/adminSettings/deleteCustomOrder', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                customorder_id
            })
        })
        updateData()
    }
    var orderOptionsFormated = ''
    if (orderRules && orderRules.length) {
        orderOptionsFormated = orderRules.map((item, key) => {
            return <SortableItemVal item={item} key={key} translations={translations} index={item.customorder_position - 1} deleteOrderRule={deleteOrderRule} updateOrderRule={updateOrderRule} />
        })
    }
    useEffect(() => {
        changePage({
            name: 'admin',
        })
    }, [])
    return ( 
        <div className='admindashboard wrap'>
            <div className='box fdiv35'>
                <div className='box_inner'>
                    <div className='box_header'>
                        <button className='btn_primary' onClick={addRule}>
                            <i className='far fa-plus-circle'></i>
                            <span>{translations.addRule}</span>
                        </button>
                    </div>
                    <div className='box_content' id='sortable_order'>
                        <SortableContainer
                            children={orderOptionsFormated} id='sortable_customfields' onSortEnd={modifyPosition}
                            className='box_content wrap'
                            helperContainer={document.getElementById('sortable_order')} useDragHandle={true} />
                    </div>
                </div>
            </div>
            {renderModal(modal, notification, setNotification, setModal)}
        </div>
    );
}
export const SortableItemVal = SortableElement(({ item, key, deleteOrderRule, translations, updateOrderRule }) => {
    var name = translations.name
    if(parseInt(item.customorder_type) === 2){
        var itemid = parseInt(item.customorder_orderfor.split('_')[2])
        let customfields = store.getState().customfields ? store.getState().customfields : [] 
        customfields = customfields.filter((item) => item.id === itemid)[0]
        if(customfields){
            name = customfields.customfield_name   
        }
    }
    return (
        <div key={key} className='customfield aicenter flex100'>
            <DragHandle text={item.customorder_position} fdiv='fdiv20' />
            <div className='fdiv20 aicenter'>
                {name}
            </div>
            <div className='fdiv20 aicenter'>
                {item.customorder_order}
            </div>
            <div className='fdiv20 aicenter'>
                <input type='checkbox' id='customorder_forced' name='forced' defaultChecked={item.forced} onChange={() => {item.forced = !item.forced;updateOrderRule(item)}}/>
                <label htmlFor='customorder_forced' className='aicenter'>
                    <i className='far fa-lock-open-alt'></i>
                    <i className='far fa-lock-alt'></i>
                </label>
            </div>
            <div className='actions fdiv20 jcend aicenter'>
                <div className='remove crpointer' onClick={() => deleteOrderRule(item.id)}>
                    <i className='fal fa-trash-alt'></i>
                </div>
            </div>
        </div>
    )
})

export default AdminDashboard;