import React from 'react'
import {
    Switch,
    Route
} from 'react-router-dom'
import AdminDashboard from './AdminDashboard'
import AdminUsers from './AdminUsers'
import AdminTeams from './AdminTeams';
import AdminCustomFields from './AdminCustomFields';
import AdminProducts from './AdminProducts';
import AdminBagOfHours from './AdminBagOfHours';
import AdminNotifications from './AdminNotifications';
import AdminStats from './AdminStats';
const AdminPanel = () => {
    return (
        <Switch>
            <Route path='/admin/users/:invited?' render={(params) => <AdminUsers {...params} /> }></Route>
            <Route path='/admin/teams/' render={(params) => <AdminTeams {...params} /> }></Route>
            <Route path='/admin/products/' render={(params) => <AdminProducts {...params} /> }></Route>
            <Route path='/admin/customfields/' render={(params) => <AdminCustomFields {...params} /> }></Route>
            <Route path='/admin/bagofhours/' render={(params) => <AdminBagOfHours {...params} /> }></Route>
            <Route path='/admin/notifications/' render={(params) => <AdminNotifications {...params} /> }></Route>
            <Route path='/admin/stats/' render={(params) => <AdminStats {...params} /> }></Route>
            <Route path='/admin' render={() => <AdminDashboard/> }></Route>
        </Switch>
    );
}
 
export default AdminPanel;