const enEn = {
    username: 'Username',
    inUsername: 'Enter the username',
    email: 'E-mail',
    inEmail: 'Enter the email',
    userOrEmail: 'Username or email',
    name: 'Name',
    inName: 'Enter the name',
    firstsurname: 'Surname',
    inFirstsurname: 'Enter the last name',
    secondsurname: 'Second surname',
    inSecondsurname: 'Enter the second last name',
    password: 'Password',
    inPassword: 'Enter the password',
    repeatPassword: 'Repeat password',
    nameCustomer: 'Customer name',
    codeCustomer: 'Company code',
    inCodeCustomer: 'Enter the company code',
    fiscalName: 'Fiscal name',
    comercialName: 'Comercial name',
    nif: 'NIF/CIF',
    assigned_comercial: 'Assigned Comercial',
    inFiscalName: 'Enter the tax name',
    responsableName: 'Name of the person in charge',
    inResponsableName: 'Enter the name of the person in charge',
    responsableEmail: 'Email of the person in charge',
    inResponsableEmail: 'Enter the email of the person in charge',
    responsablePhone: 'Person in charge Phone',
    inResponsablePhone: 'Enter the phone number of the person in charge',
    inAnswer: 'Type your answer',
    typeUser: 'Type of user',
    inTypeUser: 'Enter the type of user',
    web: 'Web',
    inWeb: 'Enter the web',
    address: 'Address',
    inAddress: 'Enter the address',
    phone: 'Phone',
    inPhone: 'Enter the phone',
    mobile: 'Mobile',
    inMobile: 'Enter the mobile',
    description: 'Description',
    inDescription: 'Enter the description',
    hours: 'Hours',
    inStartHours: 'Initial hours',
    wastedHours: 'Wasted hours',
    explanationWastedHours: 'These are the hours you wasted in this task. Modify one register or input it below. In total you have spent',
    renew: 'Renew',
    hoursToFill: 'Hours to reset',
    dateStart: 'Date start',
    dateEnd: 'Date end',
    since: 'Since',
    to: 'To',
    expireDate: 'Expire date',
    maxDate: 'Date',
    title: 'Title',
    task: 'Task',
    taskBy: 'Tasks by',
    taskName: 'Task name',
    newComment: 'New comment',
    inComment: 'Enter the comment',
    image: 'Image',
    projects: 'Projects',
    project: 'Project',
    inProject: 'Enter the name of the project',
    taskHelper: 'Task assistant',
    taskCreated: 'Created Tasks',
    time: 'Time',
    timeSubtasks: 'Subtasks time',
    createdDate: 'Creation Date',
    finishedDate: 'Finished Date',
    addTime: 'Add inverted time',
    addFile: 'Atatch file',
    hideUntil: 'Not show until...',
    toParent: 'Transform to parent',
    toSubtask: 'Transform to subtask',
    addSubtask: 'Add subtask',
    duplicateTask: 'Duplicate task',
    deleteThisTask: 'Delete this task',
    close: 'Close',
    notAssigned: 'Not assigned',
    main: 'Principal',
    noMain: 'No principal',
    recover: 'Recover',
    continue: 'Continue',
    send: 'Send',
    save: 'Save',
    or: 'Or',
    login: 'Log in',
    return: 'Return',
    returnToday: 'Back to today',
    previous: 'Previous',
    next: 'Following',
    month: 'Month',
    week: 'Week',
    day: 'Day',
    year: 'Year',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
    default: 'By default',
    searchBy: 'To look for',
    searchPh: 'I want to search ...',
    searchTask: 'Search for a task',
    searchCustomer: 'Search for a customer',
    searchProject: 'Search for a project',
    all: 'All',
    finished: 'Finished',
    subtasks: 'Subtasks',
    finisheds: 'Finished',
    notFinisheds: 'Unfinished',
    finishedsAndNot: 'Finished and unfinished',
    showed: 'Visible',
    hidden: 'Hidden',
    showedAndHidden: 'Visible and hidden',
    private: 'Private',
    makePrivate: 'Make private', 
    privateExplanation: 'Making this task private will only be visible to the user who owns it or the team to which it is assigned.If this task has subtasks unassigned and without team, they will also be private.',
    publicExplanation: 'By making this task public, it will be visible to any user again', 
    notification: 'notification',
    notifications: 'notifications',
    Notifications: 'Notifications',
    youHave: 'You have',
    loadMore: 'Load more',
    cancel: 'Cancel',
    accept: 'Accept',
    information: 'information',
    members: 'Members',
    admin: 'Administrator',
    active: 'Active',
    viewActives: 'View actives',
    allowList: 'List',
    invited: 'Guests',
    isPublic: 'Public',
    status: 'State',
    disabled: 'Disabled',
    viewDisableds: 'View disabled',
    listFilters: 'Filters list',
    listPublicFilters: 'Public filters list',
    noFilters: 'There are no filters',
    manageFilters: 'Manage filters',
    disabledList: 'Not list',
    noHelpers: 'You do not have helpers',
    actions: 'Actions',
    resume: 'Summary',
    shops: 'Stores',
    shop: 'Store',
    contactPersons: 'Contacts',
    contactPerson: 'Contact',
    noPermissions: 'You do not have permission to access',
    dashboard: 'Desk',
    myTasks: 'My tasks',
    myCalendar: 'My calendar',
    myProfile: 'My profile',
    myTeams: 'My teams',
    myNotes: 'My notes',
    otherTeams: 'Other teams',
    customers: 'Customers',
    customer: 'Customer',
    team: 'Team',
    teams: 'Teams',
    settings: 'Settings',
    adminPanel: 'Administration',
    updatelog: 'Help and updates',
    user: 'User',
    users: 'Users',
    usersConnected: 'Users connected',
    customFields: 'Custom fields',
    fields: 'Fields',
    inputHours: 'With wasted hours',
    noinputHours: 'Without wasted hours',
    withandnotinputHours: 'With and without wasted hours',
    inputHoursBdH: 'With wasted hours in bag of hours ',
    noinputHoursBdH: 'Without wasted hours in bag of hours',
    withandnotinputHoursBdH: 'With and without wasted hours in bag of hours', 
    products: 'Products',
    services: 'Services',
    color: 'Color',
    repository: 'Repository',
    createdBy: 'Created by',
    answers: 'Answers',
    tags: 'Tags',
    noRenew: 'No renew',
    withoutAnswers: 'Without answers',
    withAnswers: 'With answers',
    both: 'Both',
    olderFirst: 'First the oldest',
    newestFirst: 'First the newest',
    inTags: 'Contains one of this',
    orderBy: 'Order by',
    reset: 'Reset',
    filters: 'Filters',
    filter: 'Filter',
    comments: 'Comments',
    note: 'Note',
    table: 'Table',
    wannaUpdatePw: 'Do you want to update the password?',
    forgotPassword: 'Did you forget your password?',
    keepSession: 'Keep session active',
    recoverPassword: 'Recover password',
    pwNotMatch: 'Passwords do not match',
    createAdmin: 'Create administrator user',
    downloadCsv: 'Download the base CSV here',
    downloadReport: 'Download report',
    uploadCsv: 'Upload the base CSV base with your data',
    register: 'Check in',
    editingCustomer: 'Editing the customer',
    updatePassword: 'Update password',
    workIn: 'Work at',
    stats: 'Stats',
    startWork: 'Start work',
    workingSince: 'Working since',
    scheduleControl: 'Schedule control',
    punchIn: 'Punch in',
    punchOut: 'Punch out',
    edited: 'Edited',
    companyNotifications: 'Company notifications',
    priority: 'Priority',
    high: 'High',
    medium: 'Medium',
    low: 'Low',
    selectBagOfHours: 'Select one of the time bags of the assigned projects or select none to work on the task only',
    selectNote: 'Select a note to view it',
    bagOfHoursManual: 'You can enter the hours you have spent on the task or leave this field empty and the timer will start.',
    bagOfHours: 'Bag of hours',
    addShop: 'Add a store',
    addContact: 'Add a contact',
    addComment: 'Add comment',
    addTask: 'Add task',
    addTeam: 'Add a team',
    addUser: 'Add an account',
    addCustomer: 'Add a customer',
    addProject: 'Add project',
    addCsv: 'Import with CSV',
    addUserCsv: 'Users who are not registered or invited will be invited',
    addCustomerCsv: 'Customers who are not already created will be created',
    addShopCsv: 'Shops which are not already created will be created',
    addProjectsCsv: 'Projects which are not already created will be created and will have product name',
    addField: 'Add Field',
    addProduct: 'Add product',
    addService: 'Add service',
    addRule: 'Add rule',
    addBag: 'Add bag',
    addHours: 'Add hours',
    addAssistant: 'Add assistant',
    addQuestion: 'Add question',
    addTag: 'Add tag',
    addAnswer: 'Add answer',
    addNote: 'Add note',
    addNotification: 'Add notification',
    addFilter: 'Add filter',
    modifyShop: 'Modify a store',
    modifyContact: 'Modify a contact person',
    modifyTeam: 'Edit a team',
    modifyUser: 'Edit a user',
    modifyCustomField: 'Modify custom field',
    modifyProduct: 'Modify product',
    modifyService: 'Modify service',
    modifyProject: 'Modify project',
    modifyAnswer: 'Modify answer',
    modifyNotification: 'Modify notification',
    markAsFinished: 'Finish',
    deleteTask: 'Delete a task',
    deleteTeam: 'Delete a team',
    deleteInvitation: 'Delete an invitation',
    deleteUser: 'Delete a user',
    deleteCustomer: 'Delete a customer',
    deleteShop: 'Delete a shop',
    deleteContactPerson: 'Delete contact person',
    deleteField: 'Delete custom field',
    deleteProduct: 'Delete product',
    deleteService: 'Delete service',
    deleteProject: 'Delete project',
    deletePost: 'Delete question',
    deleteAnswer: 'Delete answer',
    deleteNotification: 'Delete notification',
    confirmDeleteTask: 'Are you sure you want to permanently delete this task and all its subtasks?',
    confirmDeleteTeam: 'Are you going to eliminate this team, are you sure?',
    confirmDeleteUser: 'Are you going to delete this user, are you sure?',
    confirmDeleteCustomer: 'You are going to delete this customer, are you sure?',
    confirmDeleteShop: 'You are going to delete this shop, are you sure?',
    confirmDeleteContactPerson: 'You are going to delete this contact person, are you sure?',
    confirmDeleteProduct: 'Are you going to delete this product, are you sure?',
    confirmDeleteService: 'Are you going to delete this service, are you sure?',
    confirmDeleteProject: 'Are you going to delete this project, are you sure?',
    confirmDeletePost: 'Are you going to delete this question, are you sure?',
    confirmDeleteAnswer: 'Are you going to delete this answer, are you sure?',
    succLogin: 'Login Successful',
    succEmailPassword: 'Check your email to recover the password',
    succRecoverPw: 'Password changed, login',
    succInstall: 'Installation successful',
    succRegister: 'User registered successfully',
    succUpdCustomer: 'Customer updated successfully',
    succCreateShop: 'Store created successfully',
    succUpdateShop: 'Store updated successfully',
    succCreateContact: 'Contact person created successfully',
    succUpdateContact: 'Contact person updated successfully',
    succAddTeam: 'Team created successfully',
    succUpdUser: 'User updated successfully',
    succRmTeam: 'Computer successfully removed',
    succRmUser: 'User successfully deleted',
    succUpdTeam: 'Team successfully modified',
    succUserInvited: 'Guest successfully invited',
    succCreateCustomer: 'Customer created successfully',
    succRmCustomer: 'Customer successfully deleted',
    succRmShop: 'Shop successfully deleted',
    succRmContactPerson: 'Contact Person successfully deleted',
    succImport: 'Import done correctly',
    succCreateField: 'Custom field successfully created',
    succDeleteField: 'Custom field successfully deleted',
    succCreateProduct: 'Product successfully created',
    succUpdProduct: 'Product successfully updated',
    succDeleteProduct: 'Product successfully deleted',
    succCreateService: 'Service successfully created',
    succUpdService: 'Service successfully updated',
    succRmService : 'Service successfully deleted',
    succCreateRule: 'Rule successfully created',
    succCreateProject : 'Project successfully created',
    succUpdProject : 'Project successfully updated',
    succRmProject : 'Project successfully deleted',
    succAddBag: 'Bag of hours successfully created',
    succWorkIn: 'Working...',
    succAddAssistant: 'Task assistent successfully created',
    succRmPost: 'Question successfully deleted',
    succRmAnswer: 'Answer successfully deleted',
    succAddNotification: 'Notification succesfully created',
    succAddFilter: 'Filter succesfully created',
    succPrivate: 'The task has been made private successfully',
    errPrivate: 'This task could not be made private, please try again',
    errEmailPassword: 'The password could not be retrieved, please try again',
    errNoLogin: 'Could not log in, please try again',
    errRecoverPw: 'The password could not be changed, please try again',
    errRegister: 'Could not register user, please try again',
    errUpdCustomer: 'The customer could not be updated, please try again',
    errCreateShop: 'The store could not be created, please try again',
    errUpdateShop: 'The store could not be updated, please try again',
    errCreateContact: 'Could not create this contact person, please try again',
    errUpdateContact: 'Could not update contact person, please try again',
    errAddTeam: 'The team could not be created, please check that it does not exist',
    errUpdUser: 'The user could not be updated, try again',
    errRmTeam: 'The team could not be deleted, please try again',
    errRmUser: 'The user could not be deleted, please try again',
    errUpdTeam: 'The team could not be modified, please try again',
    errUserInvited: 'The user could not be registered, please check that it does not exist or try again',
    errCreateCustomer: 'The customer could not be created, please try again',
    errRmCustomer: 'The customer could not be deleted, try again',
    errCreateField: 'The custom field could not be created, please try again',
    errDeleteField: 'The custom field could not be deleted, please try again',
    errCreateProduct: 'The product could not be created',
    errUpdProduct: 'The product could not be updated',
    errDeleteProduct: 'The product could not be deleted',
    errCreateService: 'The service could not be created',
    errUpdService: 'The service could not be updated',
    errDeleteService: 'The service could not be deleted',
    errCreateRule: 'The rule could not be created',
    errCreateProject: 'The product could not be created',
    errUpdProject: 'The project could not be updated',
    errRmProject: 'The project could not be deleted',
    errDeleteBag: 'The bag of hours colud not be created. Make sure you have a selected customer.',
    errWorkIn: 'Could not start working',
    errAddAssistant: 'The task assistant could not be created',
    errDeletePost: 'The question could not be deleted',
    errDeleteAnswer: 'The answer could not be deleted',
    errAddNotification: 'The notification could not be created',
    errAddFilter: 'The filter could not be created',
    xCreatedTask: 'created the task',
    createdTask: 'Task was created',
    xCreatedSubtask: 'created the subtask',
    createdSubtask: 'The subtask was created',
    xReassignedUser: 'reassigned the task to',
    xAssignedUser: 'assigned the task to',
    xRemoveAssignedUser: 'removed the assignment',
    assignedUser: 'Task was assigned to',
    xReassignedTeam: 'reassigned the task to the team',
    xAssignedTeam: 'assigned the task to the team',
    xRemoveAssignedTeam: 'removed the assignment to the team',
    assignedTeam: 'The task was assigned to the team',
    xUnmarkAsFinished: 'marked the task as not finished',
    xFinishedTask: 'finished the task',
    finishedTask: 'Task finished'
}
export default enEn