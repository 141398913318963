import React from 'react'
import store from '../reducers/reducers';
import {Link} from 'react-router-dom'
import { connect } from 'react-redux'
const mapStateToProps = state => ({ page: state.page })
const Breadcumbs = () => {
    var route = store.getState().page.route
    var renderRouting = ''
    if(route){
        renderRouting = RecursiveBreadcumb(route)
    }
    return (<div className='breadcrumb'>{renderRouting}</div>);
}
const RecursiveBreadcumb = (tree) => {
    if(tree.childs){
        var links = []
        var childs = RecursiveBreadcumb(tree.childs)
        var currentLink = <div className='breadcrumb_item' key={tree.name}><Link to={tree.url}>{tree.title}</Link> <i className='fal fa-chevron-right'></i></div>
        links.push(currentLink)
        links.push(...childs)
        return links
    }else{
    return ([<div className='active breadcrumb_item' key={tree.name}><Link to={tree.url}>{tree.title}</Link></div>])
    }
}
 
export default connect(mapStateToProps)(Breadcumbs);
