import React, {useState, useEffect, useCallback} from 'react'
import SearchBar from './Searchbar'
import {Link} from 'react-router-dom'
const PhotoList = ({getDataFrom, classify, goTo}) => {
    const [data, setData] = useState(false)
    const [searchbar, setSearchbar] = useState(false)
    const updateData = useCallback(async () => {
        let mysearchquery = {}
        if (searchbar.searchQuery) {
            var search = {}
            if (searchbar.searchBy === 'all') {
                search['$or'] = []
                searchbar.fields.map((item) => {
                    var citem = {}
                    citem[item.name] = { $iLike: '%' + searchbar.searchQuery + '%' }
                    search['$or'].push(citem)
                    return true;
                })
                mysearchquery = search
            } else {
                search[searchbar.searchBy] = { $iLike: '%' + searchbar.searchQuery + '%' }
                mysearchquery = search
            }
        }
        let mydata = await fetch(getDataFrom.url, {
            method: 'POST',
            headers: {
                'Content-Type' : 'application/json'
            },
            body: JSON.stringify({
                where : {
                    ...mysearchquery
                }
            })
        })
        mydata = await mydata.json()
        if(classify !== undefined && classify){
            mydata = classify(mydata)
        }
        setData(mydata )
    }, [classify, getDataFrom, searchbar])
    const updateSearchByQuery = (data) => {
        setSearchbar({...data})
    }
    var renderData = ''
    useEffect(() => {
        if (searchbar) {
            updateData()
        }else{
            setSearchbar({ fields: getDataFrom.fields, searchBy: 'all', searchQuery: '' })
        }
    }, [updateData, getDataFrom, searchbar])
    if(data){
        if(!classify){
            renderData = data.map((item, key) => {
                return <PhotoItem key={key} item={item} extraParams={getDataFrom.fields} preImage={getDataFrom.preImage} goTo={goTo} />
            })
        }else{
            renderData = Object.keys(data).map((item, key) => {
                item = data[item]
                let mymap = item.data.map((subitem, subkey) => {
                    return <PhotoItem key={subkey} item={subitem} extraParams={getDataFrom.fields} preImage={getDataFrom.preImage} goTo={goTo} />
                })
                return (
                    <div className={'photoitem_section ' + item.name} key={key}>
                        <div className='photoitem_headers'>
                            <h3>{item.text}</h3>
                        </div>
                        <div className='photoitem_childs'>
                            {mymap}
                        </div>
                    </div>
                )
            })
        }
    }
    return (
        <div className='box'>
            <div className='box_inner'>
                <div className='box_header'><SearchBar searchbar={searchbar} sendInfoTo={updateSearchByQuery} /></div>
                <div className='box_content'>
                    {renderData}
                </div>
            </div>
        </div>
    );
}
export const PhotoItem = ({item, extraParams, goTo, preImage}) => {
    let render = ''
    if(extraParams.length){
        render = extraParams.map((subitem, key) => {
            return <div key={key} className={'photoitem_info ' + subitem.name}>{item[subitem.name].length < 30 ? item[subitem.name] : item[subitem.name].substring(0,30) + '...'}</div>
        })
    }
    return (
        <Link className='photoitem' to={goTo+item.id} >
            <img src={preImage+item.image} alt='' className='user_img bigimg'/>
            <div className='sub_info'>
                {render}
            </div>
        </Link>
    )
}
export default PhotoList;