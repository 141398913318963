import React, {useEffect} from 'react'
import store, { changePage } from '../../reducers/reducers'
import PhotoList from '../../minicomponents/PhotoList'
const Users = () => {
    useEffect(() => {
        changePage({
            name: 'users',
        })
    }, [])
    var translations = store.getState().language.translations
    return (
        <div><PhotoList getDataFrom={{
            url: '/api/adminp/user/findAll',
            fields: [
                {
                    name: 'username',
                    text: translations.username
                },
                {
                    name: 'firstname',
                    text: translations.name
                }
            ],
            preImage: '/userImg/',
        }} goTo='/user/'
        /></div>
    );
}

export default Users;