const frFr = {
    username: 'Nom d\'utilisateur',
    inUsername: 'Introduire nom d\'utilisateur',
    email: 'Courrier électronique',
    inEmail: 'Introduire le courrier électronique',
    userOrEmail: 'Nom d\'utilisateur ou courrier électronique',
    name: 'Nom',
    inName: 'Introduire le nom',
    firstsurname: 'Prénom',
    inFirstsurname: 'Introduire le prénom',
    secondsurname: 'Deuxième prénom',
    inSecondsurname: 'Introduire le deuxième prénom',
    password: 'Mot de passe',
    inPassword: 'Introduire le mot de passe',
    repeatPassword: 'Répétez le mot de passe',
    nameCustomer: 'Nom du client',
    codeCustomer: 'Code de l\'entreprise',
    inCodeCustomer: 'Introduire le code de l\'entreprise',
    fiscalName: 'Nom fiscal',
    comercialName: 'Nom comercial',
    nif: 'NIF/CIF',
    assigned_comercial: 'Comercial asignado',
    inFiscalName: 'Introduire le nom fiscal',
    responsableName: 'Nom du responsable',
    inResponsableName: 'Introduire nom du responsable',
    responsableEmail: 'Courrier électronique du responsable',
    inResponsableEmail: 'Introduire le courrier électronique du responsable',
    responsablePhone: 'Téléphone du responsable',
    inResponsablePhone: 'Introduire le téléphone du responsable',
    inAnswer: 'Écrivez votre réponse',
    typeUser: 'Type d\'utilisateur',
    inTypeUser: 'Introduire le type d\'utilisateur',
    web: 'Site web',
    inWeb: 'Introduire le site web',
    address: 'Addresse',
    inAddress: 'Introduire l\'addresse',
    phone: 'Téléphone',
    inPhone: 'Introduire le téléphone',
    mobile: 'Téléphone mobile',
    inMobile: 'Introduire le téléphone mobile',
    description: 'Description',
    inDescription: 'Introduire la description',
    hours: 'Heures',
    inStartHours: 'Heures initiales',
    wastedHours: 'Heures inversées',
    explanationWastedHours: 'Ce sont les heures que vous avez travaillé dans cette tâche. Modifier un registre ou le saisir ci-dessous',
    renew: 'Renouveler',
    hoursToFill: 'Heures à renouveler',
    dateStart: 'Date de début',
    dateEnd: 'Date de fin',
    since: 'Depuis',
    to: 'Jusqu\'à ce',
    expireDate: 'Date d\'expiration',
    maxDate: 'Date',
    title: 'Titre',
    task: 'Tâche',
    taskBy: 'Tâches par',
    taskName: 'Nom de tâche',
    newComment: 'Noveau commentaire',
    inComment: 'Introduire le noveau commentaire',
    image: 'Image',
    projects: 'Projets',
    project: 'Projet',
    inProject: 'Introduire le nom du projet',
    taskHelper: 'Assistant de tâche',
    taskCreated: 'Tâches créé',
    Time: 'Temps',
    timeSubtasks: 'Temps sous-tâches he',
    createdDate: 'Date de creation',
    finishedDate: 'Date de fin',
    addTime: 'Ajouter du temps inversé',
    addFile: 'Pièce jointe',
    hideUntil: 'Ne pas montrer avant...',
    toParent: 'Transformer en parent',
    toSubtask: 'Transformez en sous-tâche',
    addSubtask: 'Ajouter sous-tâche',
    duplicateTask: 'Dupliquer tâche',
    deleteThisTask: 'Supprimer cette tâche',
    close: 'Fermer',
    notAssigned: 'Non attribué',
    main: 'Principale',
    noMain: 'Non-principale',
    recover: 'Récupérer',
    continue: 'Continuer',
    send: 'Envoyer',
    save: 'Garder',
    or: 'Ou',
    login: 'Ouvrir la session',
    return: 'Retour',
    returnToday: 'Retour à aujourd\'hui',
    previous: 'Précédent',
    next: 'Suivant',
    month: 'Mois',
    week: 'Semaine',
    day: 'Jour',
    year: 'Année',
    monday: 'Lundi',
    tuesday: 'Mardi',
    wednesday: 'Le mercredi',
    thursday: 'Jeudi',
    friday: 'Vendredi',
    saturday: 'Samedi',
    sunday: 'Le dimanche',
    default: 'Par défaut',
    searchBy: 'Recherche par',
    searchPh: 'Je regarde...',
    searchTask: 'Cherchez une tâche',
    searchCustomer: 'Cherchez un client',
    searchProject: 'Cherchez un projet',
    all: 'Tous',
    finished: 'Terminé',
    subtasks: 'Sous-tâches',
    finisheds: 'Terminés',
    notFinisheds: 'Sans terminer',
    finishedsAndNot: 'Terminés et sans terminer',
    showed: 'Visibles',
    hidden: 'Cachés',
    showedAndHidden: 'Visibles et cachés',
    private: 'Privé',
    makePrivate: 'Rendre privé', 
    privateExplanation: 'Rendre cette tâche privée ne sera visible que par l\'utilisateur qui la possède ou par l\'équipe à laquelle elle est affectée. Si cette tâche comporte des sous-tâches, aucune équipe et non affectée, elles seront également privées.',
    publicExplanation: 'En rendant cette tâche publique, elle sera à nouveau visible par tout utilisateur', 
    notification: 'Notification',
    notifications: 'Notifications',
    Notifications: 'Notifications',
    youHave: 'Tu as',
    loadMore: 'Charger plus',
    cancel: 'Annuler',
    accept: 'Accepter',
    information: 'Information',
    members: 'Membre',
    admin: 'Administrateur',
    active: 'Actif',
    viewActives: 'Voir les actifs',
    allowList: 'Liste',
    invited: 'Invités',
    isPublic: 'Publique',
    status: 'État',
    disabled: 'Désactivé',
    viewDisableds: 'Voir les désactivés',
    listFilters: 'Liste de filtres',
    listPublicFilters: 'Liste de filtrage publique',
    noFilters: 'Aucun filtre',
    manageFilters: 'Gérer les filtres',
    disabledList: 'Pas la liste',
    noHelpers: 'Vous n\'avez pas d\'assistant',
    actions: 'Actions',
    resume: 'Résumé',
    shops: 'Magasins',
    shop: 'Magasin',
    contactPersons: 'Contacts',
    contactPerson: 'Contactez',
    noPermissions: 'Vous n\'avez pas l\'autorisation d\'accès',
    dashboard: 'Bureau',
    myTasks: 'Mes tâches',
    myCalendar: 'Mon calendrier',
    myProfile: 'Mon profil',
    myTeams: 'Mon équipes',
    myNotes: 'Mon notes',
    otherTeams: 'Autres équipes',
    customers: 'Clients',
    customer: 'Client',
    team: 'Équipe',
    teams: 'Équipes',
    settings: 'Ajustements',
    adminPanel: 'Administration',
    updatelog: 'Aide et mises à jour',
    user: 'Utilisateur',
    users: 'Utilisateurs',
    usersConnected: 'Utilisateurs connecté',
    customFields: 'Champs personnalisés',
    fields: 'Champs',
    inputHours: 'Avec le temps investi',
    noinputHours: 'Pas de temps investi',
    withandnotinputHours: 'Avec et sans temps investi',
    inputHoursBdH: 'Avec le temps investi en sac d\'heures',
    noinputHoursBdH: 'Pas de temps investi en sac d\'heures',
    withandnotinputHoursBdH: 'Avec et sans temps investi en sac d\'heures', 
    products: 'Produits',
    services: 'Services',
    color: 'Couleur',
    repository: 'Dépôt',
    createdBy: 'Créé par',
    answers: 'Réponses',
    tags: 'Étiquette',
    noRenew: 'Non renouveler',
    withoutAnswers: 'Sans réponse',
    withAnswers: 'Avec réponse',
    both: 'Les deux',
    olderFirst: 'Plus ancien en premier',
    newestFirst: 'Plus récent en premier',
    inTags: 'Il contient l\'un de ces',
    orderBy: 'Trier par',
    reset: 'Réinitialiser',
    filters: 'Filtres',
    filter: 'Filtre',
    comments: 'Commentaries',
    note: 'Note',
    table: 'Table',
    wannaUpdatePw: '¿Souhaitez-vous mettre à jour le mot de passe?',
    forgotPassword: '¿Avez-vous oublié votre mot de passe?',
    keepSession: 'Maintenir la session active',
    recoverPassword: 'Récupérer le mot de passe',
    pwNotMatch: 'Les mots de passe ne correspondent pas',
    createAdmin: 'Créer un utilisateur administrateur',
    downloadCsv: 'Téléchargez le CSV de base ici',
    downloadReport: 'Téléchargez le CSV',
    uploadCsv: 'Téléchargez la base CSV avec vos données',
    register: 'Inscription',
    editingCustomer: 'Modification du client',
    updatePassword: 'Mise à jour le mot de passe',
    workIn: 'Travaille à',
    stats: 'Statistiques',
    startWork: 'Commence travail',
    workingSince: 'Travaillant à partir de',
    scheduleControl: 'Contrôle de planification',
    punchIn: 'Signez l\'entrée',
    punchOut: 'Signez déconnecter',
    edited: 'Édité',
    companyNotifications: 'Notifications d\'entreprise',
    priority: 'Priorité',
    high: 'Haut',
    medium: 'Médium',
    low: 'Faible',
    selectBagOfHours: 'Sélectionnez l\'un des sacs de temps des projets assignés ou n\'en sélectionnez aucun pour travailler uniquement sur la tâche.',
    selectNote: 'Sélectionnez une note pour l\'afficher',
    bagOfHoursManual: 'Vous pouvez entrer les heures que vous avez consacrées à la tâche ou laisser ce champ vide et le chronomètre démarrera. Au total, vous avez dépensé',
    bagOfHours: 'Sac d\'heures',
    addShop: 'Ajouter magasin',
    addContact: 'Ajouter contact',
    addComment: 'Ajouter commentaire',
    addTask: 'Ajouter tâche',
    addTeam: 'Ajouter équipe',
    addUser: 'Ajouter compte',
    addCustomer: 'Ajouter client',
    addProject: 'Ajouter projet',
    addCsv: 'Importer avec CSV',
    addUserCsv: 'Les utilisateurs qui ne sont ni inscrits ni invités seront invités',
    addCustomerCsv: 'Les clients qui ne sont pas déjà créés seront créés',
    addShopCsv: 'Les magasins qui ne sont pas déjà créés seront créés',
    addProjectsCsv: 'Les projets qui ne sont pas déjà créés seront créés et auront le nom du produit',
    addField: 'Ajouter champs',
    addProduct: 'Ajouter produits',
    addService: 'Ajouter service',
    addRule: 'Ajouter règle',
    addBag: 'Ajouter sac',
    addHours: 'Ajouter heures',
    addAssistant: 'Ajouter un assistant',
    addQuestion: 'Ajouter question',
    addTag: 'Ajouter étiquette',
    addAnswer: 'Ajouter une réponse',
    addNote: 'Ajouter note',
    addNotification: 'Ajouter notification',
    addFilter: 'Ajouter filtre',
    modifyShop: 'Mettez à jour le magasin',
    modifyContact: 'Modifier une personne de contact',
    modifyTeam: 'Modifier un équip',
    modifyUser: 'Modifier un utilisateur',
    modifyCustomField: 'Modifier un champ personnalisé',
    modifyProduct: 'Modifier produit',
    modifyService: 'Modifier service',
    modifyProject: 'Modifier projet',
    modifyAnswer: 'Modifier réponse',
    modifyNotification: 'Modifier notification',
    markAsFinished: 'Terminé',
    deleteTask: 'Supprimer une tâche',
    deleteTeam: 'Supprimer une équipe',
    deleteInvitation: 'Supprimer une invitation',
    deleteUser: 'Supprimer un utilisateur',
    deleteCustomer: 'Supprimer un client',
    deleteShop: 'Supprimer un magasin',
    deleteContactPerson: 'Supprimer un contact',
    deleteField: 'Supprimer le champ personnalisé',
    deleteProduct: 'Supprimer un produit',
    deleteService: 'Supprimer un service',
    deleteProject: 'Supprimer projet',
    deletePost: 'Supprimer question',
    deleteAnswer: 'Supprimer résponse',
    deleteNotification: 'Supprimer notification',
    confirmDeleteTask: '¿Étes-vous sûr de vouloir supprimer définitivement cette tâche et tous ses sous-tâches?',
    confirmDeleteTeam: 'Vous allez supprimer cette équipe, ¿êtes-vous sûr?',
    confirmDeleteUser: 'Vous allez supprimer cet utilisateur, ¿êtes-vous sûr?',
    confirmDeleteCustomer: 'Vous allez supprimer ce client, ¿êtes-vous sûr?',
    confirmDeleteShop: 'Vous allez supprimer ce magasin, ¿êtes-vous sûr?',
    confirmDeleteContactPerson: 'Vous allez supprimer ce contact, ¿êtes-vous sûr?',
    confirmDeleteProduct: 'Vous allez supprimer ce produit, ¿êtes-vous sûr?',
    confirmDeleteProject: 'Vous allez supprimer ce projet, ¿êtes-vous sûr?',
    confirmDeleteService: 'Vous allez supprimer ce service, ¿êtes-vous sûr?',
    confirmDeletePost: 'Vous allez supprimer ce question, ¿êtes-vous sûr?',
    confirmDeleteAnswer: 'Vous allez supprimer ce résponse, ¿êtes-vous sûr?',
    succLogin: 'Initation du session correct',
    succEmailPassword: 'Vérifiez votre courrier électronique pour récupérer votre mot de passe',
    succRecoverPw: 'Mot de passe modifié, initier session',
    succInstall: 'Installation effectuée correctement',
    succRegister: 'Utilisateur enregistré correctement',
    succUpdCustomer: 'Client mis à jour avec succès',
    succCreateShop: 'Magasin créé correctement',
    succUpdateShop: 'Magasin mis à jour correctement',
    succCreateContact: 'Personne de contact créé correctement',
    succUpdateContact: 'Personne de contact mis à jour correctement',
    succAddTeam: 'Équip créé correctement',
    succUpdUser: 'Utilisateur mis à jour correctamente',
    succRmTeam: 'Équip supprimé correctement',
    succRmUser: 'Utilisateur supprimé correctement',
    succRmShop: 'Magasin supprimé correctement',
    succRmContactPerson: 'Contact supprimé correctement',
    succUpdTeam: 'Équip modifié correctement',
    succUserInvited: 'Utilisateur invité modifié correctement',
    succCreateCustomer: 'Client créé correctement',
    succRmCustomer: 'Client supprimé correctement',
    succImport: 'Importation effectuée correctement',
    succCreateField: 'Champs personnalisés créé correctement',
    succDeleteField: 'Champs personnalisés supprimé correctement',
    succCreateProduct: 'Produit créé correctement',
    succUpdProduct: 'Produit modifié correctement',
    succDeleteProduct: 'Produit supprimé correctement',
    succCreateService: 'Service créé correctement',
    succUpdService: 'Service modifié correctement',
    succRmService : 'Service supprimé correctement',
    succCreateRule: 'Règle crée correctement',
    succCreateProject: 'Projet créé correctement',
    succUpdProject : 'Projet modifié correctement',
    succRmProject: 'Projet supprimé correctement',
    succAddBag: 'Sac d\'heures créé correctement',
    succWorkIn: 'Travailler...',
    succAddAssistant: 'Assistant de tâche créé avec succès',
    succRmPost: 'Question supprimée correctement',
    succRmAnswer: 'Réponse supprimée correctement',
    succAddNotification: 'Notification créé correctement',
    succAddFilter: 'Filtre créé correctement',
    succPrivate: 'La tâche a été rendue privée avec succès',
    errPrivate: 'Cette tâche n\'a pas pu être rendue privée,  s\'il vous plaît essayer à nouveau',
    errEmailPassword: 'Impossible de récupérer votre mot de passe, s\'il vous plaît essayer à nouveau',
    errNoLogin: 'Impossible d\'initate session, s\'il vous plaît essayer à nouveau',
    errRecoverPw: 'Impossible de changer le mot de passe, s\'il vous plaît essayer à nouveau',
    errRegister: 'Impossible d\'inscrire l\'utilisateur, s\'il vous plaît essayer à nouveau',
    errUpdCustomer: 'Impossible de mettre à jour le client, s\'il vous plaît essayer à nouveau',
    errCreateShop: 'Impossible de créer le magasin, s\'il vous plaît essayer à nouveau',
    errUpdateShop: 'Impossible de mettre à jour le magasin, s\'il vous plaît essayer à nouveau',
    errCreateContact: 'Impossible de créer cette personne de contact, s\'il vous plaît essayer à nouveau',
    errUpdateContact: 'Impossible de mettre à jour la personne de contact, s\'il vous plaît essayer à nouveau',
    errAddTeam: 'Impossible de créer l\'équipe, s\'il vous plaît vérifier que n\'existe pas',
    errUpdUser: 'Impossible de mettre à jour l\'utilisateur, essayez à nouveau',
    errRmTeam: 'Impossible de supprimer l\'équipe, s\'il vous plaît essayer à nouveau',
    errRmUser: 'Impossible de supprimer l\'utilisateur, s\'il vous plaît essayer à nouveau',
    errUpdTeam: 'Impossible de modifier l\'équipe, s\'il vous plaît essayer à nouveau',
    errUserInvited: 'Impossible d\'inscrire l\'utilisateur, s\'il vous plaît vérifier que n\'existe pas ou essayez à nouveau',
    errCreateCustomer: 'Impossible de créer le client, s\'il vous plaît essayer à nouveau',
    errRmCustomer: 'Impossible de supprimer le client, essayez à nouveau',
    errCreateField: 'Impossible de créer le champ personnalisé, essayez à nouveau',
    errDeleteField: 'Impossible de supprimer le champ personnalisé, essayez à nouveau',
    errCreateProduct: 'Impossible de créer le produit',
    errUpdProduct: 'Impossible de modifier le produit',
    errDeleteProduct: 'Impossible de supprimer le produit',
    errCreateService: 'Impossible de créer le service',
    errUpdService: 'Impossible de modifier le service',
    errDeleteService: 'Impossible de supprimer le service',
    errCreateRule: 'Imposible de créer le règle',
    errCreateProject: 'Imposible de creer le projet',
    errUpdProject: 'Imposible de modifier le projet',
    errRmProject: 'Imposible de supprimer le projet',
    errDeleteBag: 'Imposible de creer le sac d\'heures. Assurez-vous d\'avoir un client sélectionné',
    errWorkIn: 'Imposible de travailler',
    errAddAssistant: 'Imposible de creer l\'assistant de tâche',
    errDeletePost: 'Imposible de supprimer la question',
    errDeleteAnswer: 'Imposible de supprimer la réponse',
    errAddNotification: 'Imposible de creer le notification',
    errAddFilter: 'Imposible de creer le filre',
    xCreatedTask: 'Créer la tâche',
    createdTask: 'La tâche a été créée',
    xCreatedSubtask: 'Créer la sous-tâche',
    createdSubtask: 'La sous-tâche a été créée',
    xReassignedUser: 'Réaffecter la tâche à',
    xAssignedUser: 'Attribuer la tâche à',
    xRemoveAssignedUser: 'Éliminer l\'allocation',
    assignedUser: 'La tâche a été attribué à',
    xReassignedTeam: 'Réaffecter la tâche à l\'équipe',
    xAssignedTeam: 'Attribuer la tâche à l\'équipe',
    xRemoveAssignedTeam: 'Supprimer l\'attribution à l\'équipe',
    assignedTeam: 'La tâche a été attribué à l\'équipe',
    xUnmarkAsFinished: 'La tâche a été marquée comme non terminée',
    xFinishedTask: 'Terminer la tâche',
    finishedTask: 'La tâche a été terminée'
}
export default frFr