import React, {useState, useEffect} from 'react'
import store, { changePage } from '../../reducers/reducers';
import Breadcrumb from '../../minicomponents/Breadcrumb';
import Table from '../../minicomponents/Table';
import { renderNotification } from '../../utils/renderNotification';
import { searchUser, searchUsersFromTeam } from '../../utils/forms';
import {processForm, processModalForm, closeModal, renderModal} from '../../minicomponents/Modal';
import Tabs, {TabsHiddenChange} from '../../minicomponents/Tabs';
import { ImageInput } from '../../minicomponents/Form';
import AddList from '../../minicomponents/AddList';
import socket from '../../utils/socket';
const AdminTeams = () => {
    var translations = store.getState().language.translations
    const [modal, setModal] = useState(false)
    const [notification, setNotification] = useState(false)
    const addNew = () => {
        setModal({
            title: <div className="aicenter title_inner"><i className='fas fa-plus-square'></i> <span>{translations.addTeam}</span></div>,
            id: 'add_team',
            inner:
                <form className='formContainer wrap' id='createTeam' action='/api/adminp/team/createTeam' method='POST' onSubmit={(e) => processForm(e, translations.succAddTeam, translations.errAddTeam, setNotification)}>
                    <div className='inputDiv fdiv20'>
                        <h6>{translations.image}</h6>
                        <ImageInput imgProps={{ src: '/teamImg/', className: 'user_img mediumimg' }} startValue='teamdefault.jpg' inputName='image' pathtosave='/public/teamImg/' />
                    </div>
                    <div className='inputDiv fdiv80'>
                        <h6>{translations.name}</h6>
                        <input type='text' name='name' placeholder={translations.inName} required />
                    </div>
                    <div className='inputDiv fdiv100'>
                        <h6>{translations.description}</h6>
                        <textarea name='description' placeholder={translations.inDescription} required></textarea>
                    </div>
                    <button id='submit' type='submit' className='notInclude' style={{ display: 'none' }}></button>
                </form>
            ,
            onAccept: () => processModalForm('createTeam'),
            notification: renderNotification(notification),
            onCancel: () => closeModal(setNotification, setModal)
        })
    }
    const removeTeam = (e) => {
        var id = parseInt(e.target.parentElement.parentElement.getAttribute('idrow'))
        setModal({
            title: <div className="aicenter title_inner"><i className='fas fa-plus-square'></i> <span>{translations.deleteTeam}</span></div>,
            id: 'remove_team',
            careModal: true,
            inner:
                <form className='formContainer wrap' id='deleteTeam' action='/api/adminp/team/deleteTeam' method='POST' onSubmit={(e) => processForm(e, translations.succRmTeam, translations.errRmTeam, setNotification)}>
                    <span>{translations.confirmDeleteTeam}</span>
                    <input type='hidden' name='id' value={id} readOnly />
                    <button id='submit' type='submit' className='notInclude' style={{ display: 'none' }}></button>
                </form>
            ,
            onAccept: () => processModalForm('deleteTeam'),
            notification: renderNotification(notification),
            onCancel: () => closeModal(setNotification, setModal)
        })
    }
    const renderParticipants = async (participants) => {
        var zindex = 50
        var limit = 7
        var imgs = participants.map((item, key) => {
            zindex = zindex- 1
            if(key === limit){
                return <span key={key}>...</span>
            }else if(key < limit){
                return <img src={'/userImg/' + item.image} className='user_img tinyimg participantimg' alt={item.username} title={item.username} key={key} style={{ left: (-1 * (key * 8.5)), zIndex: zindex, border: (item.UserTeam.isAdmin) ? '2px solid #ffd600': ''}}/>
            }else{
                return ''
            }
        })
        return <div className='participants aicenter'>{imgs}</div>
    }
    var table = {
        name: 'teams',
        getDataFrom: {
            from: '/api/adminp/team/findAll',
            method: 'POST'
        },
        pagination: true,
        actionButtonsHeader: [
            {
                text: translations.addTeam,
                className: 'btn_primary add',
                icon: 'far fa-plus-circle',
                action: addNew,
                type: 'button'
            }
        ],
        actionOnClickRow: (e) => modifyTeam(e, translations, notification, setNotification, setModal),
        actionButtonsTable: [
            {
                className: 'edit',
                icon: 'far fa-pencil',
                action: (e) => modifyTeam(e, translations, notification, setNotification, setModal),
                type: 'button'
            },
            {
                className: 'remove',
                icon: 'fal fa-trash-alt',
                action: removeTeam,
                type: 'button'
            }
        ],
        fields: [
            {
                name: 'image',
                text: translations.image,
                variableResponse: (response) => <img src={'/teamImg/' + response} className='user_img' alt='' />
            },
            {
                name: 'name',
                text: translations.name,
                searchable: true,
                sortable: true
            },
            {
                name: 'description',
                text: translations.description,
                searchable: true,
                sortable: true
            },
            {
                name: 'members',
                text: translations.members,
                variableResponse: (response) => renderParticipants(response)
            },
        ]
    }
    useEffect(() => {
        changePage({
            name: 'adminteams',
        })
    }, [])
    return ( 
    <div id='adminteams'>
        <Breadcrumb />
        <Table table={table} />
        {renderModal(modal, notification, setNotification, setModal)}
    </div> );
}
export const modifyTeam = async (e,translations, notification, setNotification, setModal, admin = true) => {
    const appendUsers = async (e, team_id) => {
        var currentTarget = e.currentTarget
        var search = e.currentTarget.value
        var users = await searchUser(search)
        var users_team = await searchUsersFromTeam(team_id)
        users_team.map((item) => {
            users.map((user, key) => {
                if (item.id === user.id) {
                    delete users[key]
                }
                return user
            })
            return item
        })
        var renderThis = ''
        if (users) {
            renderThis = users.map((item, key) => {
                return <div className='aicenter' onClick={() => currentTarget.value = item.email} key={key}><img src={'/userImg/' + item.image} className='user_img miniimg' alt='' />{item.email}</div>
            })
        }
        return renderThis
    }
    const makeAdmin = async (e, TeamId, UserId) => {
        var target = e.currentTarget
        var active = !target.classList.contains('active')
        var result = await fetch('/api/adminp/team/changeUserAdmin', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                is_admin: active,
                user_id: UserId,
                team_id: TeamId
            })
        })
        result = result.json()
        if (result) {
            target.classList.toggle('active')
            setNotification({
                type: 'success',
                msg: translations.succUpdUser,
                antiClose: true
            })
            socket.emit('update_user', UserId)
        } else {
            setNotification({
                type: 'error',
                msg: translations.errUpdUser
            })
        }
    }
    const deleteTeamUser = async (e, TeamId, UserId) => {
        var target = e.currentTarget.parentElement.parentElement
        var result = await fetch('/api/adminp/team/deleteUserTeam', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                user_id: UserId,
                team_id: TeamId
            })
        })
        result = result.json()
        if (result) {
            target.remove()
            setNotification({
                type: 'success',
                msg: translations.succRmUser,
                antiClose: true
            })
            socket.emit('update_user', UserId)
        } else {
            setNotification({
                type: 'error',
                msg: translations.errRmUser
            })
        }
    }
    const sendInfoToUser = async () => {
        let users = await searchUsersFromTeam(document.forms.listUsers.elements.team_id.value)
        if (users) {
            users.forEach(item => {
                socket.emit('update_user', item.id)
            });
        }
    }
    var id = false
    if (!e.currentTarget.getAttribute('idrow')) {
        if(!e.currentTarget.parentElement.getAttribute('idrow')){
            id = parseInt(e.target.parentElement.parentElement.getAttribute('idrow'))
        }else{
            id = parseInt(e.target.parentElement.getAttribute('idrow'))
        }
    } else {
        id = parseInt(e.currentTarget.getAttribute('idrow'))
    }
    var result = await fetch('/api/adminp/team/findOne', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            where: { id }
        })
    })
    result = await result.json()
    let readOnly = {}
    if (!admin) {
        readOnly.readOnly = true 
    }
    const acceptModal = (e) => {
        e.preventDefault()
        setModal(false)
    }
    setModal({
        title: <div className="aicenter title_inner"><i className='fas fa-pen-square'></i> <span>{translations.modifyTeam}</span></div>,
        id: 'modify_team',
        inner:
            <Tabs tabOptions={[
                {
                    click: (e) => TabsHiddenChange(e, 'tabsHiddenTeam', 'modifyTeam'),
                    text: translations.information,
                    active: true
                },
                {
                    click: (e) => TabsHiddenChange(e, 'tabsHiddenTeam', 'listUsers'),
                    text: translations.members
                }
            ]} button={true} display={
                <div className='tabsHidden' id='tabsHiddenTeam'>
                    <form className='formContainer wrap tabHidden active' id='modifyTeam' action='/api/adminp/team/updateTeam' method='POST' onSubmit={(e) => admin ? processForm(e, translations.succUpdTeam, translations.errUpdTeam, setNotification) : acceptModal(e)}>
                        <input type='hidden' name='id' value={id} readOnly />
                        <div className='inputDiv fdiv20'>
                            <h6>{translations.image}</h6>
                            <ImageInput imgProps={{ src: '/teamImg/', className: 'user_img mediumimg' }} startValue={result.team.image} inputName='image' pathtosave='/public/teamImg/' readOnly={!admin}/>
                        </div>
                        <div className='inputDiv fdiv80'>
                            <h6>{translations.name}</h6>
                            <input type='text' name='name' placeholder={translations.inName} defaultValue={result.team.name} required {...readOnly} />
                        </div>
                        <div className='inputDiv fdiv100'>
                            <h6>{translations.description}</h6>
                            <textarea name='description' required placeholder={translations.inDescription} defaultValue={result.team.description} {...readOnly}></textarea>
                        </div>
                        <button id='submit' type='submit' className='notInclude' style={{ display: 'none' }}></button>
                    </form>
                    <AddList
                        inputAttr={{ type: 'text', name: 'user', id: 'teamusersearch' }}
                        helperQuery={(e) => appendUsers(e, id)}
                        hiddenAdd={!admin}
                        extraInputs={[<input type='hidden' name='team_id' value={id} readOnly key='team_id'></input>]}
                        attrsForm={{ className: 'formContainer wrap tabHidden', id: 'listUsers', action: '/api/adminp/team/addUser', method: 'POST' }}
                        notificationPos={translations.succUpdTeam}
                        notificationNeg={translations.errUpdTeam}
                        setNotification={setNotification}
                        getDataFrom={() => searchUsersFromTeam(id)}
                        extraFunction={sendInfoToUser}
                        renderRow={(item, key) => {
                            return (
                                <div idrow={item.id} className='item_list userteam aicenter' key={key}>
                                    <div className='item_list_col fdiv10 aicenter nopadding'><img src={'/userImg/' + item.image} className='user_img miniimg' alt='' /></div>
                                    <div className='item_list_col fdiv30'>{item.username}</div>
                                    <div className='item_list_col fdiv30'>{item.email}</div>
                                    <div className='item_list_col fdiv30 taend aicenter actionbuttons'>
                                        {admin ? <div className={'make_admin' + ((item.UserTeam.isAdmin) ? ' active' : '')} onClick={(e) => makeAdmin(e, item.UserTeam.TeamId, item.UserTeam.UserId)}>
                                            <i className='fal fa-crown'></i>
                                        </div> : ''}
                                        {admin ? <div className='delete_userteam' onClick={(e) => deleteTeamUser(e, item.UserTeam.TeamId, item.UserTeam.UserId)}>
                                            <i className='fal fa-trash-alt'></i>
                                        </div> : ''}
                                    </div>
                                </div>
                            )
                        }}
                    />
                </div>
            }
            />
        ,
        onAccept: () => processModalForm('modifyTeam'),
        notification: renderNotification(notification),
        onCancel: () => closeModal(setNotification, setModal)
    })
}
export default AdminTeams;