import React, {useState, useEffect} from 'react';
import { Switch, CustomSelect } from './Form';
import { returnNotifiPostForm } from '../utils/forms';
import { renderNotification } from '../utils/renderNotification';
import Table from './Table'
import { processForm, processModalForm, closeModal, renderModal } from './Modal'
import store from '../reducers/reducers';
import Tabs from './Tabs';
import { ColorsFormated, IconDisplay } from './SpecialDisplayers';
import { processData } from '../utils/specialdata';
import { Link } from 'react-router-dom';
import NewTasks from './NewTask';
const equals = require('array-equal')
const EditCustomer = ({id}) => {
    var translations = store.getState().language.translations
    const [data, setData] = useState(false)
    const [notification, setNotification] = useState(false)
    const myGetData = async (data) => {
        var result = await fetch('/api/adminp/customer/findOne',{
            method: 'POST',
            headers: {
                'Content-Type' : 'application/json'
            },
            body: JSON.stringify({
                where: {id}
            })
        })
        result = await result.json()
        if (!equals(JSON.stringify(result), JSON.stringify(data))) {
            setData(result)
        }
    }
    const [getData] = useState(() => myGetData)
    const customFetchForm = async (e) => {
        let notific = await returnNotifiPostForm(e, translations.succUpdCustomer, translations.errUpdCustomer)
        setNotification(notific)
    }
    useEffect(() => {
        getData(data)
    }, [data, getData, notification])
    return ( 
        <div className='edit_customer box'>
            <div className='box_inner'>
                <div className='box_header'>
                    <h3>{translations.editingCustomer} : {data.customer_name}</h3>
                </div>
                <div className='box_content'>
                    {renderNotification(notification)}
                    <form action='/api/adminp/customer/updateCustomer' method='POST' className='wrap formContainer' id='editCustomer' onSubmit={customFetchForm} >
                        <input type='hidden' name='id' value={id} readOnly/>
                        <Switch parentProps={{className:'fdiv100'}} checkboxProps={{id:'customer_active', name:'customer_active', defaultChecked: data.customer_active}} text='Activo' />
                        <div className='inputDiv fdiv50'>
                            <h6>{translations.nameCustomer}</h6>
                            <input type='text' name='customer_name' defaultValue={data.customer_name} required/>
                        </div>
                        <div className='inputDiv fdiv50'>
                            <h6>{translations.codeCustomer}</h6>
                            <input type='text' name='customer_code_teinor' defaultValue={data.customer_code_teinor} />
                        </div>
                        <div className='inputDiv fdiv50'>
                            <h6>{translations.fiscalName}</h6>
                            <input type='text' name='customer_fiscal_name' defaultValue={data.customer_fiscal_name} required/>
                        </div>
                        <div className='inputDiv fdiv50'>
                            <h6>{translations.comercialName}</h6>
                            <input type='text' name='comercial_name' defaultValue={data.comercial_name} />
                        </div>
                        <div className='inputDiv fdiv50'>
                            <h6>{translations.web}</h6>
                            <input type='text' name='customer_web' defaultValue={data.customer_web} />
                        </div>
                        <div className='inputDiv fdiv50'>
                            <h6>{translations.responsableName}</h6>
                            <input type='text' name='customer_responsable_name' defaultValue={data.customer_responsable_name} />
                        </div>
                        <div className='inputDiv fdiv50'>
                            <h6>{translations.responsableEmail}</h6>
                            <input type='text' name='customer_responsable_mail' defaultValue={data.customer_responsable_mail} required/>
                        </div>
                        <div className='inputDiv fdiv50'>
                            <h6>{translations.responsablePhone}</h6>
                            <input type='text' name='customer_responsable_phone' defaultValue={data.customer_responsable_phone} />
                        </div>
                        <div className='inputDiv fdiv50'>
                            <h6>{translations.nif}</h6>
                            <input type='text' name='nif' defaultValue={data.nif} />
                        </div>
                        <div className='inputDiv fdiv50'>
                            <h6>{translations.assigned_comercial}</h6>
                            <input type='text' name='assigned_comercial' defaultValue={data.assigned_comercial} />
                        </div>
                        <div className='inputDiv fdiv50'>
                            <h6>{translations.description}</h6>
                            <textarea type='text' name='notes' defaultValue={data.notes} className='like_input'/>
                        </div>
                        <div className='inputDiv fdiv50 aiend'>
                            <button type='submit' className='notInclude miniSubmit mlauto'><i className='fal fa-save'></i><span>{translations.save}</span></button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
export const EditShops = ({id}) => {
    const [modal, setModal] = useState(false)
    const [notification, setNotification] = useState(false)
    var translations = store.getState().language.translations
    const addNewShop = () => {
        setModal({
            title: <div className="aicenter title_inner"><i className='fas fa-plus-square'></i> <span>{translations.addShop}</span></div>,
            id: 'add_shop',
            inner:
                <form className='formContainer wrap' id='createShop' action='/api/adminp/shop/createShop' method='POST' onSubmit={(e) => processForm(e, translations.succCreateShop, translations.errCreateShop, setNotification)}>
                    <input type='hidden' readOnly value={id} name='customer_id' />
                    <div className='inputDiv fdiv50'>
                        <h6>{translations.codeCustomer}</h6>
                        <input type='text' name='shop_idteinor' placeholder={translations.inCodeCustomer} />
                    </div>
                    <div className='inputDiv fdiv50'>
                        <h6>{translations.name}</h6>
                        <input type='text' name='shop_name' placeholder={translations.inName} required />
                    </div>
                    <div className='inputDiv fdiv50'>
                        <h6>{translations.address}</h6>
                        <input type='text' name='shop_address' placeholder={translations.inAddress} required />
                    </div>
                    <div className='inputDiv fdiv50'>
                        <h6>{translations.phone}</h6>
                        <input type='text' name='shop_phone' placeholder={translations.inPhone} />
                    </div>
                    <div className='inputDiv fdiv50'>
                        <h6>{translations.mobile}</h6>
                        <input type='text' name='shop_mobile' placeholder={translations.inMobile} />
                    </div>
                    <div className='inputDiv fdiv50'>
                        <h6>{translations.email}</h6>
                        <input type='text' name='shop_mail' placeholder={translations.inEmail} />
                    </div>
                    <div className='inputDiv fdiv50'>
                        <h6>{translations.responsableName}</h6>
                        <input type='text' name='shop_responsable' placeholder={translations.inResponsableName} />
                    </div>
                    <Switch parentProps={{className:'fdiv50'}} checkboxProps={{id:'shop_main', name:'shop_main'}} text={translations.main} />
                    <button id='submit' type='submit' className='notInclude' style={{ display: 'none' }}></button>
                </form>
            ,
            onAccept: () => processModalForm('createShop'),
            notification: renderNotification(notification),
            onCancel: () => closeModal(setNotification, setModal)
        })
    }
    const modifyShop = async (e) => {
        var id = false
        if (!e.currentTarget.getAttribute('idrow')) {
            id = parseInt(e.target.parentElement.parentElement.getAttribute('idrow'))
        } else {
            id = parseInt(e.currentTarget.getAttribute('idrow'))
        }
        var result = await fetch('/api/adminp/shop/findOne', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                shop_id: id
            })
        })
        result = await result.json()
        if (result) {
            setModal({
                title: <div className="aicenter title_inner"><i className='fas fa-plus-square'></i> <span>{translations.modifyShop}</span></div>,
                id: 'update_shop',
                inner:
                    <form className='formContainer wrap' id='updateShop' action='/api/adminp/shop/updateShop' method='POST' onSubmit={(e) => processForm(e, translations.succUpdateShop, translations.errUpdateShop, setNotification)}>
                        <input type='hidden' readOnly value={id} name='id' />
                        <div className='inputDiv fdiv50'>
                            <h6>{translations.codeCustomer}</h6>
                            <input type='text' name='shop_idteinor' defaultValue={result.shop_idteinor} placeholder={translations.inCodeCustomer} />
                        </div>
                        <div className='inputDiv fdiv50'>
                            <h6>{translations.name}</h6>
                            <input type='text' name='shop_name' defaultValue={result.shop_name} placeholder={translations.inName} required />
                        </div>
                        <div className='inputDiv fdiv50'>
                            <h6>{translations.address}</h6>
                            <input type='text' name='shop_address' defaultValue={result.shop_address} placeholder={translations.inAddress} required />
                        </div>
                        <div className='inputDiv fdiv50'>
                            <h6>{translations.phone}</h6>
                            <input type='text' name='shop_phone' defaultValue={result.shop_phone} placeholder={translations.inPhone} />
                        </div>
                        <div className='inputDiv fdiv50'>
                            <h6>{translations.mobile}</h6>
                            <input type='text' name='shop_mobile' defaultValue={result.shop_mobile} placeholder={translations.inMobile} />
                        </div>
                        <div className='inputDiv fdiv50'>
                            <h6>{translations.email}</h6>
                            <input type='text' name='shop_mail' defaultValue={result.shop_mail} placeholder={translations.inEmail} />
                        </div>
                        <div className='inputDiv fdiv50'>
                            <h6>{translations.responsableName}</h6>
                            <input type='text' name='shop_responsable' placeholder={translations.inResponsableName} defaultValue={result.shop_responsable}/>
                        </div>
                        <Switch parentProps={{ className: 'fdiv50' }} checkboxProps={{ id: 'shop_main', name: 'shop_main', defaultChecked: result.shop_main}} text={translations.main} />
                        <button id='submit' type='submit' className='notInclude' style={{ display: 'none' }}></button>
                    </form>
                ,
                onAccept: () => processModalForm('updateShop'),
                notification: renderNotification(notification),
                onCancel: () => closeModal(setNotification, setModal)
            })
        }
    }
    const removeShop = (e) => {
        var shop_id = false
        if (!e.currentTarget.getAttribute('idrow')) {
            shop_id = parseInt(e.target.parentElement.parentElement.getAttribute('idrow'))
        } else {
            shop_id = parseInt(e.currentTarget.getAttribute('idrow'))
        }
        setModal({
            title: <div className="aicenter title_inner"><i className='fas fa-trash-alt'></i> <span>{translations.deleteShop}</span></div>,
            id: 'delete_shop',
            careModal: true,
            inner:
                <form className='formContainer wrap' id='deleteShop' action='/api/adminp/shop/deleteShop' method='POST' onSubmit={(e) => processForm(e, translations.succRmShop, translations.errRmShop, setNotification)}>
                    {translations.confirmDeleteShop}
                    <input type='hidden' name='shop_id' readOnly value={shop_id} />
                    <button id='submit' type='submit' className='notInclude' style={{ display: 'none' }}></button>
                </form>
            ,
            onAccept: () => processModalForm('deleteShop'),
            notification: '',
            onCancel: () => closeModal(false, setModal)
        })
    }
    let table = {
        name: 'shops',
        getDataFrom: {
            from: '/api/adminp/shop/findAll',
            method: 'POST',
            defaultValues: {
                customer_id: id
            }
        },
        actionButtonsHeader: [
            {
                text: translations.addShop,
                className: 'btn_primary add',
                icon: 'far fa-plus-circle',
                action: addNewShop,
                type: 'button'
            }
        ],
        actionOnClickRow: modifyShop,
        actionButtonsTable: [
            {
                className: 'edit',
                icon: 'far fa-pencil',
                action: modifyShop,
                type: 'button'
            },
            {
                className: 'remove',
                icon: 'fal fa-trash-alt',
                action: removeShop,
                type: 'button'
            }
        ],
        fields: [
            {
                name: 'shop_idteinor',
                text: translations.codeCustomer,
                searchable: true,
                sortable: true
            },
            {
                name: 'shop_name',
                text: translations.name,
                searchable: true,
                sortable: true
            },
            {
                name: 'shop_address',
                text: translations.address,
                searchable: true,
                sortable: true
            },
            {
                name: 'shop_phone',
                text: translations.phone,
                searchable: true,
            },
            {
                name: 'shop_mobile',
                text: translations.mobile,
                searchable: true,
            },
            {
                name: 'shop_responsable',
                text: translations.responsableName,
                searchable: true,
            },
            {
                name: 'shop_main',
                text: translations.main,
                customResponse: {
                    true: <span className='greenfield'><i className='fas fa-circle'></i><span>{translations.main}</span></span>,
                    false: <span className='redfield'><i className='fas fa-circle'></i><span>{translations.noMain}</span></span>
                },
                sortable: true
            }
        ]
    }
    return (
        <div id='shops'>
            <Table table={table} />
            {renderModal(modal, notification, setNotification, setModal)}
        </div>
    )
}
export const EditContacts = ({id}) => {
    const [modal, setModal] = useState(false)
    const [notification, setNotification] = useState(false)
    var translations = store.getState().language.translations
    const addNewContact = () => {
        setModal({
            title: <div className="aicenter title_inner"><i className='fas fa-plus-square'></i> <span>{translations.addContact}</span></div>,
            id: 'add_shop',
            inner:
                <form className='formContainer wrap' id='createContactPerson' action='/api/adminp/contactPerson/createContactPerson' method='POST' onSubmit={(e) => processForm(e, translations.succCreateContact, translations.errCreateContact, setNotification)}>
                    <input type='hidden' readOnly value={id} name='customer_id' />
                    <div className='inputDiv fdiv50'>
                        <h6>{translations.name}</h6>
                        <input type='text' name='contactp_name' placeholder={translations.inName} required/>
                    </div>
                    <div className='inputDiv fdiv50'>
                        <h6>{translations.phone}</h6>
                        <input type='text' name='contactp_phone' placeholder={translations.inPhone} />
                    </div>
                    <div className='inputDiv fdiv50'>
                        <h6>{translations.mobile}</h6>
                        <input type='text' name='contactp_mobile' placeholder={translations.inMobile} />
                    </div>
                    <div className='inputDiv fdiv50'>
                        <h6>{translations.email}</h6>
                        <input type='text' name='contactp_mail' placeholder={translations.inEmail} />
                    </div>
                    <div className='inputDiv fdiv100'>
                        <h6>{translations.description}</h6>
                        <textarea type='text' name='contactp_description' placeholder={translations.inDescription}/>
                    </div>
                    <button id='submit' type='submit' className='notInclude' style={{ display: 'none' }}></button>
                </form>
            ,
            onAccept: () => processModalForm('createContactPerson'),
            notification: renderNotification(notification),
            onCancel: () => closeModal(setNotification, setModal)
        })
    }
    const modifyContact = async (e) => {
        var id = false
        if (!e.currentTarget.getAttribute('idrow')) {
            id = parseInt(e.target.parentElement.parentElement.getAttribute('idrow'))
        } else {
            id = parseInt(e.currentTarget.getAttribute('idrow'))
        }
        var result = await fetch('/api/adminp/contactPerson/findOne', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                contactperson_id: id
            })
        })
        result = await result.json()
        if (result) {
            setModal({
                title: <div className="aicenter title_inner"><i className='fas fa-plus-square'></i> <span>{translations.modifyContact}</span></div>,
                id: 'update_contact',
                inner:
                    <form className='formContainer wrap' id='updateContactPerson' action='/api/adminp/contactPerson/updateContactPerson' method='POST' onSubmit={(e) => processForm(e, translations.succUpdContact, translations.errUpdContact, setNotification)}>
                        <input type='hidden' readOnly value={id} name='id' />
                        <div className='inputDiv fdiv50'>
                            <h6>{translations.name}</h6>
                            <input type='text' name='contactp_name' defaultValue={result.contactp_name} placeholder={translations.inName} required />
                        </div>
                        <div className='inputDiv fdiv50'>
                            <h6>{translations.phone}</h6>
                            <input type='text' name='contactp_phone' defaultValue={result.contactp_phone} placeholder={translations.inPhone} />
                        </div>
                        <div className='inputDiv fdiv50'>
                            <h6>{translations.mobile}</h6>
                            <input type='text' name='contactp_mobile' defaultValue={result.contactp_mobile} placeholder={translations.inMobile} />
                        </div>
                        <div className='inputDiv fdiv50'>
                            <h6>{translations.email}</h6>
                            <input type='text' name='contactp_mail' defaultValue={result.contactp_mail} placeholder={translations.inEmail} />
                        </div>
                        <div className='inputDiv fdiv100'>
                            <h6>{translations.description}</h6>
                            <textarea type='text' name='contactp_description' defaultValue={result.contactp_description} placeholder={translations.inDescription} />
                        </div>
                        <button id='submit' type='submit' className='notInclude' style={{ display: 'none' }}></button>
                    </form>
                ,
                onAccept: () => processModalForm('updateContactPerson'),
                notification: renderNotification(notification),
                onCancel: () => closeModal(setNotification, setModal)
            })
        }
    }
    const removeContact = (e) => {
        var contactperson_id = false
        if (!e.currentTarget.getAttribute('idrow')) {
            contactperson_id = parseInt(e.target.parentElement.parentElement.getAttribute('idrow'))
        } else {
            contactperson_id = parseInt(e.currentTarget.getAttribute('idrow'))
        }
        setModal({
            title: <div className="aicenter title_inner"><i className='fas fa-trash-alt'></i> <span>{translations.deleteContactPerson}</span></div>,
            id: 'delete_contactperson',
            careModal: true,
            inner:
                <form className='formContainer wrap' id='deleteContactPerson' action='/api/adminp/contactPerson/deleteContactPerson' method='POST' onSubmit={(e) => processForm(e, translations.succRmContactPerson, translations.errRmContactPerson, setNotification)}>
                    {translations.confirmDeleteContactPerson}
                    <input type='hidden' name='contactperson_id' readOnly value={contactperson_id} />
                    <button id='submit' type='submit' className='notInclude' style={{ display: 'none' }}></button>
                </form>
            ,
            onAccept: () => processModalForm('deleteContactPerson'),
            notification: '',
            onCancel: () => closeModal(false, setModal)
        })
    }
    let table = {
        name: 'shops',
        getDataFrom: {
            from: '/api/adminp/contactPerson/findAll',
            method: 'POST',
            defaultValues: {
                customer_id: id
            }
        },
        actionButtonsHeader: [
            {
                text: translations.addContact,
                className: 'btn_primary add',
                icon: 'far fa-plus-circle',
                action: addNewContact,
                type: 'button'
            }
        ],
        actionOnClickRow: modifyContact,
        actionButtonsTable: [
            {
                className: 'edit',
                icon: 'far fa-pencil',
                action: modifyContact,
                type: 'button'
            },
            {
                className: 'remove',
                icon: 'fal fa-trash-alt',
                action: removeContact,
                type: 'button'
            }
        ],
        fields: [
            {
                name: 'contactp_name',
                text: translations.name,
                searchable: true,
                sortable: true
            },
            {
                name: 'contactp_phone',
                text: translations.phone,
                searchable: true,
                sortable: true
            },
            {
                name: 'contactp_mobile',
                text: translations.mobile,
                searchable: true,
                sortable: true
            },
            {
                name: 'contactp_mail',
                text: translations.email,
                searchable: true,
                sortable: true
            },
            {
                name: 'contactp_description',
                text: translations.description
            },
        ]
    }
    return (
        <div id='contact_p'>
            <Table table={table} />
            {renderModal(modal, notification, setNotification, setModal)}
        </div>
    )
}
export const EditProjects = ({id, project_id, task_id, history}) => {
    const [modal, setModal] = useState(false)
    const [notification, setNotification] = useState(false)
    const [projects, setProjects] = useState(false)
    const [tabOptions, setTabOptions] = useState([])
    const [active, setActive] = useState(false)
    const [products, setProducts] = useState(false)
    var translations = store.getState().language.translations
    const modifyModalProject = async (n, project_id) => {
        updateProject(project_id, n)
    }
    const deleteProject = async (project_id) => {
        let result = await fetch('/api/adminp/project/deleteProject', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                project_id
            })
        })
        result = await result.json()
        if(result){
            setNotification({
                type: 'success',
                msg: translations.succRmProject
            })
            updateProjects()
        }else{
            setNotification({
                type: 'error',
                msg: translations.errRmProject
            })
        }
    }
    const updateProject = async (project_id, tab = 1) => {
        let result = await fetch('/api/adminp/project/findOne', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                project_id
            })
        })
        result = await result.json()
        let options = [
            {
                text: translations.modifyProject,
                click: () => modifyModalProject(1, project_id),
            },
            {
                text: translations.bagOfHours,
                click: () => modifyModalProject(2, project_id)
            },
            {
                text: translations.deleteProject,
                click: () => modifyModalProject(3, project_id)
            }
        ]
        options[tab-1].active = true
        let innerForm = ''
        if(tab === 1){
        innerForm = <form className='formContainer wrap' id='updateProject' action='/api/adminp/project/updateProject' method='POST' onSubmit={async (e) => { await processForm(e, translations.succUpdProject, translations.errUpdProject, setNotification); updateProjects() }}>
            <input type='hidden' readOnly value={id} name='customer_id' />
            <input type='hidden' readOnly value={project_id} name='id' />
            <div className='inputDiv fdiv50'>
                <h6>{translations.name}</h6>
                <input type='text' name='project_name' placeholder={translations.inName} required defaultValue={result.project_name} />
            </div>
            <div className='inputDiv fdiv50'>
                <h6>{translations.color}</h6>
                <input type='hidden' name='project_color' defaultValue={result.project_color} />
                <IconDisplay parentProps={{}} button={<div className='color_selected' id='color_piker_project' style={{ background: '#' + result.project_color }}></div>} inner={<ColorsFormated onSelect={(val) => { document.forms[0].project_color.value = val.substr(1); document.getElementById('color_piker_project').style.background = val }} active={'#' + result.project_color} />} />
            </div>
            <div className='inputDiv fdiv50'>
                <h6>{translations.products}</h6>
                <CustomSelect selectProps={{ name: 'product_id', value: (result.ProductId ? result.ProductId : 0) }} options={products} />
            </div>
            <div className='inputDiv fdiv100'>
                <h6>{translations.description}</h6>
                <textarea type='text' name='project_description' placeholder={translations.inDescription} defaultValue={result.project_description} />
            </div>
            <button id='submit' type='submit' className='notInclude' style={{ display: 'none' }}></button>
        </form>
        }else if(tab === 2){
            let bags = await fetch('/api/adminp/bag/findAll', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    project_id,
                    fill: true,
                    params: {
                        where : {
                            bag_active : true
                        }
                    }
                })
            })
            bags = await bags.json()
            let renewoptions  = [
                translations.noRenew, translations.day, translations.week, translations.month, translations.year
            ]
            bags = bags.map((item, key) => {
                return <div className='wrap' key={key}>
                    <div className='fdiv20 nopadding'>{item.bag_name}</div>
                    <div className='fdiv20 nopadding'>{item.bag_limit}</div>
                    <div className='fdiv20 nopadding'>{item.invertedTime}</div>
                    <div className='fdiv20 nopadding'>{renewoptions[item.bag_renewtype]}</div>
                    <div className='fdiv20 nopadding'>{item.bag_expiredate ? processData(item.bag_expiredate, true) : ''}</div>
                </div>
            })
            innerForm = <div>
                <div className='wrap'>
                    <div className='fdiv20 nopadding'>{translations.name}</div>
                    <div className='fdiv20 nopadding'>{translations.inStartHours}</div>
                    <div className='fdiv20 nopadding'>{translations.wastedHours}</div>
                    <div className='fdiv20 nopadding'>{translations.renew}</div>
                    <div className='fdiv20 nopadding'>{translations.expireDate}</div>
                </div>
                {bags}
            </div>
        }else if(tab === 3){
            innerForm = <div>
                <div>{translations.confirmDeleteProject}</div>
                <button className='btn_primary redbutton' onClick={() =>deleteProject(project_id)}>{translations.deleteProject}</button>
            </div>
        }
        setModal({
            title: <div className="aicenter title_inner"><i className='fas fa-plus-square'></i> <span>{translations.modifyProject}</span></div>,
            id: 'update_project',
            inner:
                <Tabs tabOptions={options} button={true} display={innerForm} />
            ,
            onAccept: () => processModalForm('updateProject'),
            notification: renderNotification(notification),
            onCancel: () => closeModal(setNotification, setModal)
        })
    }
    const updateProjects = async () => {
        let result = await fetch('/api/adminp/project/findAll', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                customer_id: id
            })
        })
        let myprojects = await result.json()
        let myactive = active
        let mytabs = myprojects.map((item, key) => {
            if (!myactive && Number.isInteger(parseInt(project_id)) && parseInt(project_id) === item.id) {
                myactive = key
            }
            return {
                text: <Link className='project_tab' to={'/customer/'+item.CustomerId+'/projects/'+item.id}><span className='project_name'>{item.project_name}</span><i className='fal fa-cog crpointer' onClick={() => updateProject(item.id)}></i></Link>,
                click: () => setActive(key) 
            }
        })
        mytabs.push({
            text: <Link className='project_tab' to={'/customer/'+id+'/projects/resume'}><span className='project_name'>{translations.resume}</span></Link>,
            click: () => setActive(myprojects.length)
        })
        if (!myactive && project_id === 'resume'){
            myactive = myprojects.length
        }
        setProjects(myprojects)
        setTabOptions(mytabs)
        setActive(myactive)
    }
    const updateProducts = async () => {
        let result = await fetch('/api/adminp/product/findAll', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({})
        })
        let myproducts = await result.json()
        let productsoptions =[]
        if(myproducts.length){
            myproducts.forEach((item) => {
                productsoptions.push({
                    text: item.product_name,
                    value: item.id
                })
            })
        }
        productsoptions.push({
            text: '---',
            value: 0
        })
        setProducts(productsoptions)
    }
    useEffect(() => {
        setProjects(false)
        setProducts(false)
    }, [id, project_id])
    const addNewProject = () => {
        setModal({
            title: <div className="aicenter title_inner"><i className='fas fa-plus-square'></i> <span>{translations.addProject}</span></div>,
            id: 'add_project',
            inner:
                <form className='formContainer wrap' id='createProject' action='/api/adminp/project/createProject' method='POST' onSubmit={ async(e) => {await processForm(e, translations.succCreateProject, translations.errCreateProject, setNotification); updateProjects()}}>
                    <input type='hidden' readOnly value={id} name='customer_id' />
                    <div className='inputDiv fdiv50'>
                        <h6>{translations.name}</h6>
                        <input type='text' name='project_name' placeholder={translations.inName} required />
                    </div>
                    <div className='inputDiv fdiv50'>
                        <h6>{translations.color}</h6>
                        <input type='hidden' name='project_color' defaultValue='5e72e4' />
                        <IconDisplay parentProps={{}} button={<div className='color_selected' id='color_piker_project' style={{ background: '#5e72e4' }}></div>} inner={<ColorsFormated onSelect={(val) => { document.forms[0].project_color.value = val.substr(1); document.getElementById('color_piker_project').style.background = val}} active='#5e72e4' />} />   
                    </div>
                    <div className='inputDiv fdiv50'>
                        <h6>{translations.products}</h6>
                        <CustomSelect selectProps={{name: 'product_id'}} options={products} />
                    </div>
                    <div className='inputDiv fdiv100'>
                        <h6>{translations.description}</h6>
                        <textarea type='text' name='project_description' placeholder={translations.inDescription} />
                    </div>
                    <button id='submit' type='submit' className='notInclude' style={{ display: 'none' }}></button>
                </form>
            ,
            onAccept: () => processModalForm('createProject'),
            notification: renderNotification(notification),
            onCancel: () => closeModal(setNotification, setModal)
        })
    }
    if(!projects){
        updateProjects()
        updateProducts()
    }
    const findAllIds = () => {
        if(projects.length){   
            return projects.map((item) => {
                return item.id
            })
        }
        return []
    }
    var myTabOptions = tabOptions
    var mydisplay = <div></div>
    if(active !== false){
        myTabOptions.forEach((item) => {
            if(item.active){
                item.active = false
            }
        })
        myTabOptions[active] !== undefined ? myTabOptions[active].active = true : myTabOptions[0].active = true
        let myExtra = {}
        if (projects[active] !== undefined) {
            myExtra.project_id = projects[active].id
        } else {
            myExtra.project_id = findAllIds()
        }
        myExtra.task_id = task_id
        mydisplay = <NewTasks extraParams={myExtra} url={'/customer/' + id + '/projects/' + project_id} history={history}/>
    }
    return (
        <div className='projects'>
            <Tabs tabOptions={myTabOptions} display={mydisplay} button={true} rightSide={<div className='addprojectbutton' onClick={addNewProject}><i className='fal fa-plus-circle'></i><span>{translations.addProject}</span></div>} parentProps={{ className: 'add_project' }} />
            {renderModal(modal, notification, setNotification, setModal)}
        </div>)
}
export default EditCustomer;