const esEs = {
    username: 'Nombre de usuario',
    inUsername: 'Introduce el nombre de usuario',
    email: 'Email',
    inEmail: 'Introduce el email',
    userOrEmail: 'Nombre de usuario o email', 
    name: 'Nombre',
    inName: 'Introduce el nombre',
    firstsurname: 'Apellido',
    inFirstsurname: 'Introduce el apellido',
    secondsurname: 'Segundo apellido',
    inSecondsurname: 'Introduce el segundo apellido',
    password: 'Contraseña',
    inPassword: 'Introduce la contraseña',
    repeatPassword: 'Repita la contraseña',
    nameCustomer: 'Nombre cliente',
    codeCustomer: 'Codigo empresa',
    inCodeCustomer: 'Introduce el codigo de empresa',
    fiscalName: 'Nombre fiscal',
    comercialName: 'Nombre comercial',
    nif: 'NIF/CIF',
    assigned_comercial: 'Comercial asignado',
    inFiscalName: 'Introduce el nombre fiscal',
    responsableName: 'Nombre del responsable',
    inResponsableName: 'Introduce el nombre del responsable',
    responsableEmail: 'Email del responsable',
    inResponsableEmail: 'Introduce el email del responsable',
    responsablePhone: 'Teléfono del responsable',
    inResponsablePhone: 'Introduce el teléfono del responsable',
    inAnswer: 'Escribe tu respuesta',
    typeUser: 'Tipo de usuario',
    inTypeUser: 'Introduce el tipo de usuario',
    web: 'Web',
    inWeb: 'Introduce la web',
    address: 'Dirección',
    inAddress: 'Introduce la dirección' ,
    phone: 'Teléfono',
    inPhone: 'Introduce el teléfono',
    mobile: 'Móvil',
    inMobile: 'Introduce el móvil',
    description: 'Descripción',
    inDescription: 'Introduce la descripción',
    hours: 'Horas',
    inStartHours: 'Horas iniciales',
    wastedHours: 'Horas gastadas',
    explanationWastedHours: 'Estas son las horas que gastaste en esta tarea. Modifica un registro o ingresarlo a continuación. En total has invertido',
    renew: 'Renovar',
    hoursToFill: 'Horas a renovar',
    dateStart: 'Fecha inicio',
    dateEnd: 'Fecha final',
    since: 'Desde',
    to: 'Hasta',
    expireDate: 'Fecha de caducidad',
    maxDate: 'Fecha',
    title: 'Título',
    task: 'Tarea',
    taskBy: 'Tareas por',
    taskName: 'Nombre de la tarea',
    newComment: 'Nuevo comentario',
    inComment: 'Introduce el comentario',
    image: 'Imagen',
    projects: 'Proyectos',
    project: 'Proyecto',
    inProject: 'Introduce el nombre del proyecto',
    taskHelper: 'Asistente de tarea',
    taskCreated: 'Tareas creadas',
    time: 'Tiempo',
    timeSubtasks: 'Tiempo subtareas',
    createdDate: 'Fecha de creación',
    finishedDate: 'Fecha de finalización',
    addTime: 'Añadir tiempo invertido',
    addFile: 'Adjuntar fichero',
    hideUntil: 'No mostrar hasta...',
    toParent: 'Transformar en tarea padre',
    toSubtask: 'Transformar en subtarea',
    addSubtask: 'Añadir subtarea',
    duplicateTask: 'Duplicar tarea',
    deleteThisTask: 'Eliminar esta tarea',
    close: 'Cerrar',
    notAssigned: 'Sin asignar',
    main: 'Principal',
    noMain: 'No principal',
    recover: 'Recuperar',
    continue: 'Continuar',
    send: 'Enviar',
    save: 'Guardar',
    or: 'O',
    login: 'Iniciar sesión',
    return: 'Volver',
    returnToday: 'Volver a hoy',
    previous: 'Anterior',
    next: 'Siguiente',
    month: 'Mes',
    week: 'Semana',
    day: 'Día',
    year: 'Año',
    monday: 'Lunes',
    tuesday: 'Martes',
    wednesday: 'Miércoles',
    thursday: 'Jueves',
    friday: 'Viernes',
    saturday: 'Sábado',
    sunday: 'Domingo',
    default: 'Por defecto',
    searchBy: 'Buscar por',
    searchPh: 'Quiero buscar...',
    searchTask: 'Busca una tarea',
    searchCustomer: 'Busca un cliente',
    searchProject: 'Busca un proyecto',
    all: 'Todos',
    finished: 'Finalizada',
    subtasks: 'Subtareas',
    finisheds: 'Finalizadas',
    notFinisheds: 'Sin finalizar',
    finishedsAndNot: 'Finalizadas y sin finalizar',
    showed: 'Visibles',
    hidden: 'Escondidas',
    showedAndHidden: 'Visibles y escondidas',
    private: 'Privada',
    makePrivate: 'Convertir en privada', 
    privateExplanation: 'Al convertir esta tarea en privada solo será visible por el usuario que sea propietario o el equipo al que esté asignado. Si esta tarea tiene subtareas sin equipo y sin asignar, también serán privadas.',
    publicExplanation: 'Al convertir esta tarea en pública, volverá a ser visible por cualquier usuario.', 
    notification: 'notificación',
    notifications: 'notificaciones',
    Notifications: 'Notificaciones',
    youHave: 'Tienes',
    loadMore: 'Cargar más',
    cancel: 'Cancelar',
    accept: 'Acceptar',
    information: 'Información',
    members: 'Miembros',
    admin: 'Administrador',
    active: 'Activo',
    viewActives: 'Ver activos',
    allowList: 'Listar',
    invited: 'Invitados',
    isPublic: 'Público',
    status: 'Estado',
    disabled: 'Desactivado',
    viewDisableds: 'Ver desactivados',
    disabledList: 'No listar',
    listFilters: 'Lista de filtros',
    listPublicFilters: 'Lista de filtros públicos',
    noFilters: 'No hay filtros',
    manageFilters: 'Gestionar filtros',
    noHelpers: 'No dispones de asistentes',
    actions: 'Acciones',
    resume: 'Resumen',
    shops: 'Tiendas',
    shop: 'Tienda',
    contactPersons: 'Contactos',
    contactPerson: 'Contacto',
    noPermissions: 'No tienes permisos para acceder',
    dashboard: 'Escritorio',
    myTasks: 'Mis tareas',
    myCalendar: 'Mi calendario',
    myProfile: 'Mi perfil',
    myTeams: 'Mis equipos',
    myNotes: 'Mis notas',
    otherTeams: 'Otros equipos',
    customers: 'Clientes',
    customer: 'Cliente',
    team: 'Equipo',
    teams: 'Equipos',
    settings: 'Ajustes',
    adminPanel: 'Administración',
    updatelog: 'Ayuda y actualizaciones',
    user: 'Usuario',
    users: 'Usuarios',
    usersConnected: 'Usuarios conectados',
    customFields: 'Campos personalizados',
    fields: 'Campos',
    inputHours: 'Con tiempo invertido',
    noinputHours: 'Sin tiempo invertido',
    withandnotinputHours: 'Con y sin tiempo invertido', 
    inputHoursBdH: 'Con tiempo invertido de Bolsa de Horas',
    noinputHoursBdH: 'Sin tiempo invertido de Bolsa de Horas',
    withandnotinputHoursBdH: 'Con y sin tiempo invertido de Bolsa de Horas', 
    products: 'Productos',
    services: 'Servicios',
    color: 'Color',
    repository: 'Repositorio',
    createdBy: 'Creado por',
    answers: 'Respuestas',
    tags: 'Etiquetas',
    noRenew: 'No renovar',
    withoutAnswers: 'Sin respuestas',
    withAnswers: 'Con respuestas',
    both: 'Ambas',
    olderFirst: 'Más viejas primero',
    newestFirst: 'Más nuevas primero',
    inTags: 'Contiene una de estas',
    orderBy: 'Ordenar por',
    reset: 'Reiniciar',
    filters: 'Filtros',
    filter: 'Filtrar',
    comments: 'Comentarios',
    note: 'Nota',
    table: 'Tabla',
    wannaUpdatePw: '¿Quieres actualizar la contraseña?',
    forgotPassword: '¿Olvidaste tu contraseña?',
    keepSession: 'Mantener sesión activa',
    recoverPassword: 'Recuperar contraseña',
    pwNotMatch: 'Las contraseñas no coinciden',
    createAdmin: 'Crear usuario administrador',
    downloadCsv: 'Descarga el CSV base aquí',
    downloadReport: 'Descargar el informe',
    uploadCsv: 'Sube el CSV base con tus datos',
    register: 'Registrarse',
    editingCustomer: 'Editando el cliente',
    updatePassword: 'Actualizar contraseña',
    workIn: 'Trabajar en',
    stats: 'Estadísticas',
    startWork: 'Empezar a trabajar',
    workingSince: 'Trabajando desde',
    scheduleControl: 'Control de horario',
    punchIn: 'Fichar entrada',
    punchOut: 'Fichar salida',
    edited: 'Editado',
    companyNotifications: 'Notificaciones de empresa',
    priority: 'Prioridad',
    high: 'Alta',
    medium: 'Media',
    low: 'Baja',
    selectBagOfHours: 'Seleccione una de las bolsas de horas de los proyectos asignados o no selecciones ninguno para trabajar en la tarea únicamente.',
    selectNote: 'Selecciona una nota para visualizarla',
    bagOfHoursManual: 'Puede introducir las horas que ha invertido en la tarea o dejar este campo vacio y que empieze el cronometro.',
    bagOfHours: 'Bolsa de horas',
    addShop: 'Añadir una tienda',
    addContact: 'Añadir un contacto',
    addComment: 'Añadir comentario',
    addTask: 'Añadir tarea',
    addTeam: 'Añadir un equipo',
    addUser: 'Añadir una cuenta',
    addCustomer: 'Añadir un cliente',
    addProject: 'Añadir proyecto',
    addCsv: 'Importar con CSV',
    addUserCsv: 'Se invitarán a los usuarios que no esten registrados o invitados ya',
    addCustomerCsv: 'Se crearán los clientes que no estén creados ya',
    addShopCsv: 'Se crearán las tiendas que no estén creadas ya',
    addProjectsCsv: 'Se crearán los proyectos que no estén creados ya y tendrán el nombre del producto',
    addField: 'Añadir campo',
    addProduct: 'Añadir producto',
    addService: 'Añadir servicio',
    addRule: 'Añadir regla',
    addBag: 'Añadir bolsa',
    addHours: 'Añadir horas',
    addAssistant: 'Añadir asistente',
    addQuestion: 'Añadir pregunta',
    addTag: 'Añadir etiqueta',
    addAnswer: 'Añadir respuesta',
    addNote: 'Añadir nota',
    addNotification: 'Añadir notificación',
    addFilter: 'Añadir filtro',
    modifyShop: 'Modificar una tienda',
    modifyContact: 'Modificar una persona de contacto',
    modifyTeam: 'Modificar un equipo',
    modifyUser: 'Modificar un usuario',
    modifyCustomField: 'Modificar un campo personalizado',
    modifyProduct: 'Modificar producto',
    modifyService: 'Modificar servicio',
    modifyProject: 'Modificar proyecto',
    modifyAnswer: 'Modificar respuesta',
    modifyNotification: 'Modificar notificación',
    markAsFinished: 'Finalizar',
    deleteTask: 'Eliminar una tarea',
    deleteTeam: 'Eliminar un equipo',
    deleteInvitation: 'Eliminar una invitación',
    deleteUser: 'Eliminar un usuario',
    deleteCustomer: 'Eliminar un cliente',
    deleteShop: 'Eliminar una tienda',
    deleteContactPerson: 'Eliminar una persona de contacto',
    deleteField: 'Eliminar un campo personalizado',
    deleteProduct: 'Eliminar un producto',
    deleteService: 'Eliminar un servicio',
    deleteProject: 'Eliminar proyecto',
    deletePost: 'Eliminar pregunta',
    deleteAnswer: 'Eliminar respuesta',
    deleteNotification: 'Eliminar notificación',
    confirmDeleteTask: 'Seguro que quieres eliminar esta tarea permanentemente y todas sus subtareas?',
    confirmDeleteTeam: '¿Vas a eliminar este equipo, estás seguro?',
    confirmDeleteUser: '¿Vas a eliminar este usuario, estás seguro?',
    confirmDeleteCustomer: '¿Vas a eliminar este cliente, ¿Estás seguro?',
    confirmDeleteShop: '¿Vas a eliminar esta tienda, estás seguro?',
    confirmDeleteContactPerson: '¿Vas a eliminar esta persona de contacto, estás seguro?',
    confirmDeleteProduct: 'Vas a eliminar este producto, ¿Estás seguro?',
    confirmDeleteService: 'Vas a eliminar este servicio, ¿Estás seguro?',
    confirmDeleteProject: 'Vas a eliminar este proyecto, ¿Estás seguro?',
    confirmDeletePost: 'Vas a eliminar esta pregunta, ¿Estás seguro?',
    confirmDeleteAnswer: 'Vas a eliminar esta respuesta, ¿Estás seguro?',
    succLogin: 'Inicio de sesión correcto',
    succEmailPassword: 'Consulte su correo para recuperar la contraseña',
    succRecoverPw: 'Contraseña cambiada, inicie sesión',
    succInstall: 'Instalación realizada correctamente',
    succRegister: 'Usuario registrado correctamente',
    succUpdCustomer: 'Cliente actualizado correctamente',
    succCreateShop: 'Tienda creada correctamente',
    succUpdateShop: 'Tienda actualizada correctamente',
    succCreateContact: 'Persona de contacto creada correctamente',
    succUpdateContact: 'Persona de contacto actualizada correctamente',
    succAddTeam: 'Equipo creado correctamente',
    succUpdUser: 'Usuario actualizado correctamente',
    succRmTeam: 'Equipo eliminado correctamente',
    succRmUser: 'Usuario eliminado correctamente',
    succUpdTeam: 'Equipo modificado correctamente',
    succUserInvited: 'Usuario invitado correctamente',
    succCreateCustomer: 'Cliente creado correctamente',
    succRmCustomer: 'Cliente eliminado correctamente',
    succRmShop: 'Tienda eliminada correctamente',
    succRmContactPerson: 'Persona de contacto eliminada correctamente',
    succImport: 'Importación realizada correctamente',
    succCreateField: 'Campo personalizado creado correctamente',
    succDeleteField: 'Campo personalizado eliminado correctamente',
    succCreateProduct: 'Producto creado correctamente',
    succUpdProduct: 'Producto actualizado correctamente',
    succDeleteProduct: 'Producto eliminado correctamente',
    succCreateService: 'Servicio creado correctamente',
    succUpdService: 'Servicio actualizado correctamente',
    succRmService : 'Servicio eliminado correctamente',
    succCreateRule : 'Norma creada correctamente',
    succCreateProject : 'Proyecto creado correctamente',
    succUpdProject : 'Proyecto modificado correctamente',
    succRmProject : 'Proyecto eliminado correctamente',
    succAddBag: 'Bolsa de horas creada correctamente',
    succWorkIn: 'Trabajando...',
    succAddAssistant: 'Asistente de tareas creado correctamente',
    succRmPost: 'Pregunta eliminada correctamente',
    succRmAnswer: 'Respuesta eliminada correctamente',
    succAddNotification: 'Notificación añadida correctamente',
    succAddFilter: 'Filtro añadido con éxito',
    succPrivate: 'La tarea se ha convertido en privada correctamente', 
    errPrivate: 'No se ha podido convertir en privada esta tarea, por favor inténtelo de nuevo',
    errEmailPassword: 'No se ha podido recuperar la contraseña, por favor inténtelo de nuevo',
    errNoLogin: 'No se ha podido iniciar sesión, por favor inténtelo de nuevo',
    errRecoverPw: 'No se ha podido cambiar la contraseña, por favor inténtelo de nuevo',
    errRegister: 'No se ha podido registrar el usuario, por favor inténtelo de nuevo',
    errUpdCustomer: 'No se ha podido actualizar el cliente, por favor, inténtelo de nuevo',
    errCreateShop: 'No se ha podido crear la tienda, por favor inténtelo de nuevo',
    errUpdateShop: 'No se ha podido actualizar la tienda, por favor inténtelo de nuevo',
    errCreateContact: 'No se ha podido crear esta persona de contacto, por favor inténtelo de nuevo',
    errUpdateContact: 'No se ha podido actualizar la persona de contacto, por favor inténtelo de nuevo',
    errAddTeam: 'No se ha podido crear el equipo, por favor comprueba que no existe',
    errUpdUser: 'No se ha podido actualizar el usuario, inténtelo de nuevo',
    errRmTeam: 'No se ha podido eliminar el equipo, por favor inténtelo de nuevo',
    errRmUser: 'No se ha podido eliminar el usuario, por favor inténtelo de nuevo',
    errUpdTeam: 'No se ha podido modificar el equipo, por favor inténtelo de nuevo',
    errUserInvited: 'No se ha podido registrar el usuario, por favor comprueba que no existe o inténtelo de nuevo',
    errCreateCustomer: 'No se ha podido crear el cliente, por favor inténtelo de nuevo',
    errRmCustomer: 'No se ha podido eliminar el cliente, inténtelo de nuevo',
    errCreateField: 'No se ha podido crear el campo personalizado, por favor inténtelo de nuevo',
    errDeleteField: 'No se ha podido eliminar el campo personalizado, por favor inténtelo de nuevo',
    errCreateProduct: 'No se ha podido crear el producto',
    errUpdProduct: 'No se ha podido actualizar el producto',
    errDeleteProduct: 'No se ha podido eliminar el producto',
    errCreateService: 'No se ha podido crear el servicio',
    errUpdService: 'No se ha podido actualizar el servicio',
    errDeleteService: 'No se ha podido eliminar el servicio',
    errCreateRule: 'No se ha podido crear la norma',
    errCreateProject: 'No se ha podido crear el proyecto',
    errUpdProject: 'No se ha podido modificar el proyecto',
    errRmProject: 'No se ha podido eliminar el proyecto',
    errDeleteBag: 'La bolsa de horas no ha podido ser creada. Asegúrate de tener un cliente seleccionado',
    errWorkIn: 'No se ha podido empezar a trabajar',
    errAddAssistant: 'No se ha podido crear el asistente de tareas',
    errDeletePost: 'No se ha podido eliminar la pregunta',
    errDeleteAnswer: 'No se ha podido eliminar la respuesta',
    errAddNotification: 'No se ha podido añadir la notificación',
    errAddFilter: 'No se ha podido añadir el filtro',
    xCreatedTask: 'creo la tarea',
    createdTask: 'Se creo la tarea',
    xCreatedSubtask: 'creo la subtarea',
    createdSubtask: 'Se creo la subtarea',
    xReassignedUser: 'reasignó la tarea a',
    xAssignedUser: 'asignó la tarea a',
    xRemoveAssignedUser: 'eliminó la asignación',
    assignedUser: 'Se asignó la tarea a',
    xReassignedTeam: 'reasignó la tarea al equipo',
    xAssignedTeam: 'asignó la tarea al equipo',
    xRemoveAssignedTeam: 'eliminó la asignación al equipo',
    assignedTeam: 'Se asignó la tarea al equipo',
    xUnmarkAsFinished: 'marcó la tarea como no finalizada',
    xFinishedTask: 'finalizó la tarea',
    finishedTask: 'Se finalizó la tarea'
}
export default esEs