import React, {useState} from 'react';
import {fetchPostForm} from './../utils/forms'
import {renderNotification} from './../utils/renderNotification'
const InstallDb = ({updateStep}) => {
    const [notification, setNotification] = useState(false)
    const fetchForm = async (e) => {
        var response = await fetchPostForm(e, true)
        if(response === false){
            setNotification({
                type: 'error',
                msg: 'No se ha podido crear la base de datos, por favor, intentelo de nuevo.'
            })
        }else{
            setNotification({
                type: 'success',
                msg: 'Base de datos creada correctamente.'
            })
            updateStep(2)
        }
    }
    return ( 
        <div id='databaseForm' className='formContainer'>
            <div className="formInner">
            <h3>Configuración de la base de datos</h3>
            {renderNotification(notification)}
                <form action='/api/install/createDatabase' method='post' onSubmit={fetchForm}>
                <div className='inputDiv'>
                    <h6>Nombre</h6>
                    <input type='text' name='database' placeholder='Nombre de la base de datos' required/>
                </div>
                <div className='inputDiv'>
                        <h6>Usuario</h6>
                    <input type='text' name='username' placeholder='Usuario de la base de datos' required/>
                </div>
                <div className='inputDiv'>
                    <h6>Contraseña</h6>
                    <input type='password' name='password' placeholder='Contraseña de la base de datos' required/>
                </div>
                <div className='inputDiv'>
                    <h6>Host</h6>
                    <input type='text' name='host' placeholder='Host de la base de datos' required/>
                </div>
                <div className='inputDiv'>
                    <h6>Dialecto</h6>
                    <select name='dialect' required>
                        <option value='mssql'>Microsoft SQL</option>
                        <option value='mysql'>MySQL</option>
                        <option value='mariadb'>MariaDB</option>
                        <option value='postgres'>Postgres</option>
                    </select>
                </div>
                <button type='submit' className='notInclude defaultButton'>Continuar</button>
            </form>
            </div>
        </div> 
    );
}
 
export default InstallDb;