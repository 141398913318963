import React, {useState, useEffect} from 'react';
import store, {updateNotifications, changePage, changeUser} from '../reducers/reducers';
import {connect} from 'react-redux'
import socket from '../utils/socket';
import { fetchPostForm } from '../utils/forms';
import {Link} from 'react-router-dom'
import { renderLogMessage } from '../utils/logmessage';
import {TaskEdit} from './Tasks'
import Modal, { processModalForm, closeModal } from './Modal'
const equals = require('array-equal')
const mapStateToProps = state => ({ notifications: state.notifications, user: state.user })
const UserNotification = ({ match, opened }) => {
    const [answered, setAnswered] = useState(false)
    if (window.innerWidth >= 1300) {
        try {
            if (!answered) {
                Notification.requestPermission();
                setAnswered(true)
            }
        } catch (err) {
            console.log(err)
        }
    }
    var translations = store.getState().language.translations
    const [suscribedTo, setSuscribedTo] = useState(false)
    const myResetSocket = async (suscribedTo) => {
        var teams = await store.getState().user.teams
        if (suscribedTo) {
            suscribedTo.forEach((item) => {
                socket.emit('leave', item)
            })
            socket.off('update_notifc')
        }
        let suscribed = []
        suscribed.push('user_' + store.getState().user.id + '_notif')
        socket.emit('create', 'user_' + store.getState().user.id + '_notif');
        if (teams) {
            teams.forEach((team) => {
                socket.emit('create', 'team_' + team.id + '_notif');
                suscribed.push('team_' + team.id + '_notif')
            })
        }
        socket.on('update_notifc', (answer) => updateNotifications(true, false, answer, true))
        socket.on('update_user', changeUser)
        if (!equals(suscribed, suscribedTo)) {
            setSuscribedTo(suscribed)
        }
    }
    const [resetSocket] = useState(() => myResetSocket)
    useEffect(() => {
        resetSocket(suscribedTo)
    }, [match, suscribedTo, resetSocket])
    useEffect(() => {
        updateNotifications(false)
    }, [])
    let howMany = 0
    if(store.getState().notifications.activity){
        if(store.getState().notifications.last_readed){
            let last = new Date(store.getState().notifications.last_readed)
            howMany = store.getState().notifications.activity.filter((notifc) => new Date(notifc.updatedAt) >last).length
        }else{
            if(store.getState().notifications.activity.length >= 11){
                howMany = '+10'
            }else{
                howMany = store.getState().notifications.activity.length
            }
        }
    }
    return (
    <Link className={'lb_notification ' + (howMany ? 'notifications' : 'clear')} to='/activity'>
        <h3><span className='bell fa fa-bell'></span>{opened ? '' : <i className='fas fa-bell'></i>}{howMany + ' '+ ((opened) ? ((howMany === 1) ? translations.notification : translations.notifications) : '')}</h3>
        {opened ? <h4>{store.getState().user.firstname}, {store.getState().user.firstsurname}</h4> : ''}
    </Link> );
}
 
export default connect(mapStateToProps)(UserNotification);
export const ActivityComponent = ({ history}) => {
    var translations = store.getState().language.translations
    const [modal, setModal] = useState(false)
    const fetchDeletePostForm = async (e) => {
        let result = await fetchPostForm(e,true)
        if(result.team){
            result.team.forEach((item) => {
                emitOldTeam(item)
            })
        }
        if(result.user){
            result.user.forEach((item) => {
                emitOldUser(item)
            })
        }
        if(result.ids){
            result.ids.forEach((item) => {
                emitTaskData(item)
            })
        }
        setModal(false)
    }
    const deleteTaskModal = (task_id) => {
        setModal({
            title: <div className="aicenter title_inner"><i className='fas fa-trash-alt'></i> <span>{translations.deleteTask}</span></div>,
            id: 'delete_task',
            careModal: true,
            inner:
                <form className='formContainer wrap' id='deleteTask' action='/api/adminp/task/deleteTask' method='POST' onSubmit={fetchDeletePostForm}>
                    {translations.confirmDeleteTask}
                    <input type='hidden' name='task_id' readOnly value={task_id} />
                    <button id='submit' type='submit' className='notInclude' style={{ display: 'none' }}></button>
                </form>
            ,
            onAccept: () => processModalForm('deleteTask'),
            notification: '',
            onCancel: () => closeModal(false, setModal)
        })
    }
    var renderModal = ''
    if(modal){
        renderModal = <Modal {...modal} />
    }
    const emitTaskData = (current_id) => {
        socket.emit('update_data', 'task_' + current_id)
    }
    const emitOldTeam = (current_id) => {
        socket.emit('update_groupdata', 'team_' + current_id)
    }
    const emitOldUser = (current_id) => {
        socket.emit('update_groupdata', 'user_' + current_id)
    }
    const [currentTask, setCurrentTask] = useState(false)

    const updateLimitNotifications = async () => {
        await updateNotifications(false, {
            limit: 11,
            offset: (store.getState().notifications.limit ? store.getState().notifications.limit.offset+10 : 11)
        })
    }
    let notifics = store.getState().notifications.activity
    useEffect(() => {
        if(notifics && notifics.length){
            fetch('/api/adminp/task/lastNotificationRead',{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    last_readed: notifics[0].updatedAt
                })
            }).then(() => {
                if(store.getState().notifications.last_readed !== notifics[0].updatedAt){
                    updateNotifications(true)
                }else{
                    updateNotifications(false)
                }
            })
        }
    }, [notifics])
    useEffect(() => {
        changePage({
            name: 'activity'
        })
    },[])
    let notifications_render = []
    var more = false
    var limit = 10
    var need_reRender = false
    if(store.getState().notifications.limit){
        limit = store.getState().notifications.limit.limit+store.getState().notifications.limit.offset - 2
    }
    if(notifics){
        notifications_render = notifics.map((item, key) => {
            let message = renderLogMessage(item, translations)
            if(key < limit){
                if(!item.task){
                    need_reRender = true
                    return ''
                }
                item = item.task
                return (
                <div key={key} onClick={() => setCurrentTask(item.id)} className='activity_log'>
                    {message}
                    <div className={'tasklist_item aicenter wrap' + ((item.id === currentTask) ? ' active' : '')} >
                            <div className={'completed fdiv10 tacenter aicenter' + ((item.completed) ? ' active' : '')} >{(item.completed) ? <i className="fad fa-check-circle"></i> : <i className="fal fa-check-circle"></i>}</div>
                            <div className='name fdiv70 aicenter'><span className='idtask'>#{item.id}</span><div>{item.task_name}</div></div>
                            <div className='user fdiv10 tacenter'><div className='photoinput aicenter inputsearch onlyImg mauto tacenter'>{item.User ? <img src={'/userImg/'+item.User.image} alt='' className='user_img miniimg' /> : <i className='fal fa-user'></i> }</div></div>
                            <div className='team fdiv10 tacenter'><div className='photoinput aicenter inputsearch onlyImg mauto tacenter'>{item.Team ? <img src={'/teamImg/'+item.Team.image} alt='' className='user_img miniimg' /> : <i className='fal fa-users'></i> }</div></div>
                    </div>
                </div>
                )
            }else{
                more = true
                return ''
            }
        })
        if(need_reRender){
            updateNotifications(false, false, false, true)
            need_reRender = false
        }
    }
    return (
        <div id='activity' className='wrap tasks'>
            <div className='box fdiv55'>
                <div className='box_inner'>
                    <div className='box_header'>
                        <h3>{translations.Notifications}</h3>
                    </div>
                    <div className='box_content'>
                        {notifications_render}
                        {(more) ? <button className='btn_primary' onClick={updateLimitNotifications}>{translations.loadMore}</button> : ''}
                    </div>
                </div>
            </div>
            <div className='fdiv45 mlauto box task_single'>
                <TaskEdit task_id={currentTask} setCurrentTask={setCurrentTask} emitTaskData={emitTaskData} emitOldTeam={emitOldTeam} emitOldUser={emitOldUser} deleteTaskModal={deleteTaskModal} history={history} url='/activity' setModal={setModal}/>
            </div>
            {renderModal}            
        </div>
    )
}
export const Activity = connect(mapStateToProps)(ActivityComponent)