import React, { useState, useEffect, useCallback } from 'react';
import { IconDisplay } from './SpecialDisplayers';
import socket from '../utils/socket';
const AdminNotificationsPortable = () => {
    const [display, setDisplay] = useState(false)
    const [highlight, setHighligh] = useState(false)
    const [data, setData] = useState(false)
    const [socketed, setSocketed] = useState(false)
    const getNotifications = useCallback(async () => {
        let mydata = await fetch('/api/adminp/adminnotifications/findAllAdminNotifications', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                order: [['updatedAt', 'DESC']]
            })
        })
        mydata = await mydata.json()
        setData(mydata)
    }, [])
    useEffect(() => {
        setSocketed(true)
        if (!socketed) {
            socket.on('update_adminnotifications', async (data) => {
                await getNotifications()
                if (data.show === 'true' && !display) {
                    setHighligh(true)
                }
            })
        }
        return () => {
            if (socketed) {
                setSocketed(false)
            }
        };
    }, [socketed, setSocketed, display, getNotifications])
    useEffect(() => {
        getNotifications()
    }, [getNotifications])
    if (!data && !data.length) {
        return ''
    }
    return (
        <IconDisplay inner={
            <AdminNotificationsInner data={data}/>
        } button={
            <div className={'header iconDisplayerbutton' + (highlight ? ' highlight' : '')}>
                <i className='fas fa-exclamation-circle'></i>
            </div>
            } parentProps={{ className: 'adminnotifications_portable action_header' }} myDisplay={display} mySetDisplay={(subd) => { setDisplay(subd); setHighligh(false) }}/>
    )
}
 
export default AdminNotificationsPortable;
export const AdminNotificationsInner = ({ data }) => {
    let mydata_rendered = data.map((item, key) => {
        let myicon = ''
        if (item.notification_priority === 1) {
            myicon = 'fas fa-exclamation-circle'
        } else if (item.notification_priority === 2) {
            myicon = 'fas fa-exclamation-triangle'
        } else if (item.notification_priority === 3) {
            myicon = 'fas fa-dot-circle'
        }
        return (
            <div className={'adminnotification aicenter type_' + item.notification_priority} key={key}>
                <div className='notificationicon '>
                    <i className={myicon}></i>
                </div>
                <div className='notificationtext'>
                    <div className='notification_title'>{item.notification_title}</div>
                    <div className='notification_description'>{item.notification_description}</div>
                </div>
            </div>
        )
    })
    return (
        <div className='adminnotifications_portable_inner'>
            {mydata_rendered}
        </div>
    )
}