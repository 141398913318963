import React, { useState, useEffect, useCallback } from 'react';
import Datetime from 'react-datetime';
import store from '../reducers/reducers';
import { PhotoInputSearcher, CustomSelect, Switch } from './Form';
import { searchUser, searchTeam, searchShop, searchContact } from '../utils/forms';
import { ProjectSearcher } from './Tasks';
import { IconDisplay } from './SpecialDisplayers';
import { Redirect, Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip'
import { processForm, processModalForm, closeModal, renderModal } from './Modal';
import { renderNotification } from '../utils/renderNotification';
import socket from '../utils/socket';
import { processData } from '../utils/specialdata';
const TaskHelperPortable = () => {
    return (
        <IconDisplay inner={
            <TaskHelperInner />
        } button={
            <div className='header iconDisplayerbutton'>
                <i className='fas fa-plus'></i>
            </div>
        } parentProps={{ className: 'task_helper_portable action_header' }} cancelClick={true}/>
    )
}
export const TaskHelperInner = () => {
    const [selected, setSelected] = useState(false)
    const [listOfTasks, setListOfTasks] = useState(false)
    const [redirect, setRedirect] = useState(false)

    let data = store.getState().helpers
    let translations = store.getState().language.translations
    var renderThis = ''
    console.log(listOfTasks)
    if (selected) {
        renderThis = <div className={'task_helper_container' + (listOfTasks ? ' has_list' : '')}>
            {listOfTasks ?
                <div className='list_of_tasks'>
                    <h4>{translations.taskCreated}</h4>
                    {listOfTasks.data.map((item, key) => {
                        return <div className='helper_task' key={key} onClick={() => setRedirect('/customer/'+listOfTasks.customer_id+'/projects/'+listOfTasks.project_id+'/'+item.id)}>
                        <h4>{item.task_name}</h4>
                        <div className='created_date'>
                            <i className='fal fa-calendar-plus'></i>
                            <span>{processData(item.createdAt)}</span>
                        </div>
                        </div>
                    })}
                </div>
                : null}
            <div className='th_helper'>
                <CustomSelect selectProps={{ onChange: (val) => setSelected(val) }} options={data.map((item) => {
                return {
                    value: item.id,
                    text: item.helper_name
                }
                })} /><HelperCreateTask id={selected} listOfTasks={listOfTasks} setListOfTasks={setListOfTasks} redirect={redirect} setRedirect={setRedirect}/>
            </div>
        </div>
    } else if (!data.length) {
        renderThis = <div><div>{translations.noHelpers}</div><Link to='/settings/' className='btn_primary mt10 dblock' onClick={() => document.querySelector('.task_helper_portable .iconDisplayerbutton').click()}><i className='fas fa-plus-square'></i> <span>{translations.addAssistant}</span></Link></div>
    } else if (data.length) {
        setSelected(data[0].id)
    }
    return renderThis
}
export const HelperCreateTask = ({ id, listOfTasks, setListOfTasks, redirect, setRedirect }) => {
    const [data, setData] = useState(false)
    const [user, setUser] = useState(false)
    const [shop, setShop] = useState(false)
    const [contact, setContact] = useState(false)
    const [team, setTeam] = useState(false)
    const [projects, setProjects] = useState(false)
    const [date, setDate] = useState(false)
    const [modal, setModal] = useState(false)
    const [notification, setNotification] = useState(false)
    var translations = store.getState().language.translations
    const createTask = async () => {
        let mydata = {
            params: {
                task_name: document.getElementById('helper_task_name') ? document.getElementById('helper_task_name').value : '',
                max_date: date ? date : undefined,
                description: document.getElementById('helper_description') ? document.getElementById('helper_description').value : undefined,
            },
            who_creates: store.getState().user.id
        }
        if (user || team || projects) {
            if (user) {
                mydata['user_id'] = user.id
            }
            if (team) {
                mydata['team_id'] = team.id
            }
            if(projects){
                mydata['project_id'] = projects.id
            }
        } else {
            mydata['user_id'] = store.getState().user.id
        }
        if (shop) {
            mydata['params']['ShopId'] = shop.id
        }
        if (contact) {
            mydata['params']['ContactPersonsId'] = contact.id
        }
        let task_id = await fetch('/api/adminp/task/createTask', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                ...mydata
            })
        })
        task_id = await task_id.json()
        if (user || team || projects) {
            if (user) {
                socket.emit('update_groupdata', { room: 'user_' + user.id, task_id })
            }
            if (team) {
                socket.emit('update_groupdata', { room: 'team_' + team.id, task_id })
            }
            if (projects) {
                socket.emit('update_groupdata', { room: 'project_' + projects.id, task_id })
            }
        } else {
            socket.emit('update_groupdata', { room: 'user_' + store.getState().user.id, task_id })
        }
        let redirect_to = false
        for (let item of data.TaskHelperFields) {
            if (item.helperfield_input === 'user' && mydata.user_id) {
                redirect_to = '/user/' + mydata.user_id +'/'+ task_id
                break
            } else if (item.helperfield_input === 'team' && mydata.team_id) {
                redirect_to = '/team/' + mydata.team_id +'/'+ task_id
                break
            } else if (item.helperfield_input === 'customer' && mydata.project_id) {
                redirect_to = '/customer/'+projects.customer.id+'/projects/'+mydata.project_id+'/'+task_id
                break
            }
        }
        if (!redirect_to) {
            redirect_to = '/user/'+mydata.user_id+task_id
        }
        if (task_id) {
            setRedirect(redirect_to)
        }
    }
    const getListOfTasks = async (project_id, customer_id) => {
        let projects = await fetch('/api/adminp/project/findAll', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                customer_id
            })
        })
        projects = await projects.json()
        let ids = []
        for (let k in projects) {
            ids.push(projects[k].id)
        }
        var result = await fetch('/api/adminp/task/getTaskByProject', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                project_id: ids,
                params: {
                    limit: 40,
                    order: [
                        ['id', 'ASC']
                    ],
                    where: {
                        completed: false
                    }
                }
            })
        })
        result = await result.json()
        setListOfTasks({
            data: result.data,
            project_id,
            customer_id
        })
    }
    const options = {
        task_name: translations.title,
        customer: translations.customer,
        team: translations.team,
        user: translations.user,
        description: translations.description,
        maxDate: translations.maxDate,
        shop: translations.shop,
        contact: translations.contactPerson
    }
    const getData = useCallback(async () => {
        let result = await fetch('/api/adminp/user/findTaskHelper', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                helper_id: id
            })
        })
        result = await result.json()
        setData(result)
    }, [id])
    useEffect(() => {
        if (redirect) {
            setRedirect(false)
            document.querySelector('.task_helper_portable .iconDisplayerbutton').click()
        } else {
            getData()
        }
    }, [id, getData, redirect, setRedirect])
    useEffect(() => {
        if (!projects && listOfTasks) {
            setListOfTasks(false)
        }
    }, [projects, listOfTasks, setListOfTasks])
    const addNewShop = (customer_id) => {
        setModal({
            title: <div className="aicenter title_inner"><i className='fas fa-plus-square'></i> <span>{translations.addShop}</span></div>,
            id: 'add_shop',
            inner:
                <form className='formContainer wrap' id='createShop' action='/api/adminp/shop/createShop' method='POST' onSubmit={(e) => processForm(e, translations.succCreateShop, translations.errCreateShop, setNotification)}>
                    <input type='hidden' readOnly value={customer_id} name='customer_id' />
                    <div className='inputDiv fdiv50'>
                        <h6>{translations.codeCustomer}</h6>
                        <input type='text' name='shop_idteinor' placeholder={translations.inCodeCustomer} />
                    </div>
                    <div className='inputDiv fdiv50'>
                        <h6>{translations.name}</h6>
                        <input type='text' name='shop_name' placeholder={translations.inName} required />
                    </div>
                    <div className='inputDiv fdiv50'>
                        <h6>{translations.address}</h6>
                        <input type='text' name='shop_address' placeholder={translations.inAddress} required />
                    </div>
                    <div className='inputDiv fdiv50'>
                        <h6>{translations.phone}</h6>
                        <input type='text' name='shop_phone' placeholder={translations.inPhone} />
                    </div>
                    <div className='inputDiv fdiv50'>
                        <h6>{translations.mobile}</h6>
                        <input type='text' name='shop_mobile' placeholder={translations.inMobile} />
                    </div>
                    <div className='inputDiv fdiv50'>
                        <h6>{translations.email}</h6>
                        <input type='text' name='shop_mail' placeholder={translations.inEmail} />
                    </div>
                    <div className='inputDiv fdiv50'>
                        <h6>{translations.responsableName}</h6>
                        <input type='text' name='shop_responsable' placeholder={translations.inResponsableName} />
                    </div>
                    <Switch parentProps={{ className: 'fdiv50' }} checkboxProps={{ id: 'shop_main', name: 'shop_main' }} text={translations.main} />
                    <button id='submit' type='submit' className='notInclude' style={{ display: 'none' }}></button>
                </form>
            ,
            onAccept: () => processModalForm('createShop'),
            notification: renderNotification(notification),
            onCancel: () => closeModal(setNotification, setModal)
        })
    }
    const modifyShop = async (id) => {
        var result = await fetch('/api/adminp/shop/findOne', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                shop_id: id
            })
        })
        result = await result.json()
        if (result) {
            setModal({
                title: <div className="aicenter title_inner"><i className='fas fa-plus-square'></i> <span>{translations.modifyShop}</span></div>,
                id: 'update_shop',
                inner:
                    <form className='formContainer wrap' id='updateShop' action='/api/adminp/shop/updateShop' method='POST' onSubmit={async (e) => { await processForm(e, translations.succUpdateShop, translations.errUpdateShop, setNotification); updateShop(id, [])}}>
                        <input type='hidden' readOnly value={id} name='id' />
                        <div className='inputDiv fdiv50'>
                            <h6>{translations.codeCustomer}</h6>
                            <input type='text' name='shop_idteinor' defaultValue={result.shop_idteinor} placeholder={translations.inCodeCustomer} />
                        </div>
                        <div className='inputDiv fdiv50'>
                            <h6>{translations.name}</h6>
                            <input type='text' name='shop_name' defaultValue={result.shop_name} placeholder={translations.inName} required />
                        </div>
                        <div className='inputDiv fdiv50'>
                            <h6>{translations.address}</h6>
                            <input type='text' name='shop_address' defaultValue={result.shop_address} placeholder={translations.inAddress} required />
                        </div>
                        <div className='inputDiv fdiv50'>
                            <h6>{translations.phone}</h6>
                            <input type='text' name='shop_phone' defaultValue={result.shop_phone} placeholder={translations.inPhone} />
                        </div>
                        <div className='inputDiv fdiv50'>
                            <h6>{translations.mobile}</h6>
                            <input type='text' name='shop_mobile' defaultValue={result.shop_mobile} placeholder={translations.inMobile} />
                        </div>
                        <div className='inputDiv fdiv50'>
                            <h6>{translations.email}</h6>
                            <input type='text' name='shop_mail' defaultValue={result.shop_mail} placeholder={translations.inEmail} />
                        </div>
                        <div className='inputDiv fdiv50'>
                            <h6>{translations.responsableName}</h6>
                            <input type='text' name='shop_responsable' placeholder={translations.inResponsableName} defaultValue={result.shop_responsable} />
                        </div>
                        <Switch parentProps={{ className: 'fdiv50' }} checkboxProps={{ id: 'shop_main', name: 'shop_main', defaultChecked: result.shop_main }} text={translations.main} />
                        <button id='submit' type='submit' className='notInclude' style={{ display: 'none' }}></button>
                    </form>
                ,
                onAccept: () => processModalForm('updateShop'),
                notification: renderNotification(notification),
                onCancel: () => closeModal(setNotification, setModal)
            })
        }
    }
    const modifyContact = async (id) => {
        var result = await fetch('/api/adminp/contactPerson/findOne', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                contactperson_id: id
            })
        })
        result = await result.json()
        if (result) {
            setModal({
                title: <div className="aicenter title_inner"><i className='fas fa-plus-square'></i> <span>{translations.modifyContact}</span></div>,
                id: 'update_contact',
                inner:
                    <form className='formContainer wrap' id='updateContactPerson' action='/api/adminp/contactPerson/updateContactPerson' method='POST' onSubmit={async (e) => { await processForm(e, translations.succUpdateShop, translations.errUpdateShop, setNotification); updateContact(id, []) }}>
                        <input type='hidden' readOnly value={id} name='id' />
                        <div className='inputDiv fdiv50'>
                            <h6>{translations.name}</h6>
                            <input type='text' name='contactp_name' defaultValue={result.contactp_name} placeholder={translations.inName} required />
                        </div>
                        <div className='inputDiv fdiv50'>
                            <h6>{translations.phone}</h6>
                            <input type='text' name='contactp_phone' defaultValue={result.contactp_phone} placeholder={translations.inPhone} />
                        </div>
                        <div className='inputDiv fdiv50'>
                            <h6>{translations.mobile}</h6>
                            <input type='text' name='contactp_mobile' defaultValue={result.contactp_mobile} placeholder={translations.inMobile} />
                        </div>
                        <div className='inputDiv fdiv50'>
                            <h6>{translations.email}</h6>
                            <input type='text' name='contactp_mail' defaultValue={result.contactp_mail} placeholder={translations.inEmail} />
                        </div>
                        <div className='inputDiv fdiv100'>
                            <h6>{translations.description}</h6>
                            <textarea type='text' name='contactp_description' defaultValue={result.contactp_description} placeholder={translations.inDescription} />
                        </div>
                        <button id='submit' type='submit' className='notInclude' style={{ display: 'none' }}></button>
                    </form>
                ,
                onAccept: () => processModalForm('updateContactPerson'),
                notification: renderNotification(notification),
                onCancel: () => closeModal(setNotification, setModal)
            })
        }
    }
    const addNewContact = (customer_id) => {
        setModal({
            title: <div className="aicenter title_inner"><i className='fas fa-plus-square'></i> <span>{translations.addContact}</span></div>,
            id: 'add_shop',
            inner:
                <form className='formContainer wrap' id='createContactPerson' action='/api/adminp/contactPerson/createContactPerson' method='POST' onSubmit={(e) => processForm(e, translations.succCreateContact, translations.errCreateContact, setNotification)}>
                    <input type='hidden' readOnly value={customer_id} name='customer_id' />
                    <div className='inputDiv fdiv50'>
                        <h6>{translations.name}</h6>
                        <input type='text' name='contactp_name' placeholder={translations.inName} required />
                    </div>
                    <div className='inputDiv fdiv50'>
                        <h6>{translations.phone}</h6>
                        <input type='text' name='contactp_phone' placeholder={translations.inPhone} />
                    </div>
                    <div className='inputDiv fdiv50'>
                        <h6>{translations.mobile}</h6>
                        <input type='text' name='contactp_mobile' placeholder={translations.inMobile} />
                    </div>
                    <div className='inputDiv fdiv50'>
                        <h6>{translations.email}</h6>
                        <input type='text' name='contactp_mail' placeholder={translations.inEmail} />
                    </div>
                    <div className='inputDiv fdiv100'>
                        <h6>{translations.description}</h6>
                        <textarea type='text' name='contactp_description' placeholder={translations.inDescription} />
                    </div>
                    <button id='submit' type='submit' className='notInclude' style={{ display: 'none' }}></button>
                </form>
            ,
            onAccept: () => processModalForm('createContactPerson'),
            notification: renderNotification(notification),
            onCancel: () => closeModal(setNotification, setModal)
        })
    }
    const searchUsers = async (value) => {
        var results = await searchUser(value, store.getState().user.id)
        return results
    }
    const searchTeams = async (value) => {
        var results = await searchTeam(value)
        return results
    }
    const searchShops = async (value, customers = [projects]) => {
        let ids = []
        for (let customer of customers) {
            ids.push(customer.CustomerId)
        }
        var results = await searchShop(value, ids)
        return results
    }
    const searchContacts = async (value, customers = [projects]) => {
        let ids = []
        for (let customer of customers) {
            ids.push(customer.CustomerId)
        }
        var results = await searchContact(value, ids)
        return results
    }
    const updateTeam = (id, data) => {
        if (id === 0) {
            setTeam(0)
        } else {
            setTeam(data)
        }
    }
    const updateUser = (id, data) => {
        if (id === 0) {
            setUser(0)
        } else {
            setUser(data)
        }
    }
    const updateShop = async (id, data) => {
        if (id === 0) {
            setShop(0)
        } else {
            if (data.length) {
                setShop(data)
            } else {
                var result = await fetch('/api/adminp/shop/findOne', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        shop_id: id
                    })
                })
                result = await result.json()
                setShop(result)
            }
        }
    }
    const updateContact = async (id, data) => {
        if (id === 0) {
            setContact(0)
        } else {
            if (data.length) {
                setContact(data)
            } else {
                var result = await fetch('/api/adminp/contactPerson/findOne', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        contactperson_id: id
                    })
                })
                result = await result.json()
                setContact(result)
            }
        }
    }
    const updateProject = (id, data, customer) => {
        if (id === 0) {
            setProjects(false)
        } else {
            data.customer = customer
            setProjects(data)
        }
    }
    const updateDate = (date) => {
        setDate(date.format())
    }
    if (redirect) {
        return (
            <Redirect to={{
                pathname: redirect
            }} />
        )
    }
    let mydata = data
        ? data.TaskHelperFields.map((item, key) => {
            let field = ''
            if (item.helperfield_input === 'task_name') {
                field = <input type='text' id='helper_task_name' placeholder={translations.name} />
            } else if (item.helperfield_input === 'customer') {
                const has_description = projects && (projects.project_description || projects.Customer.notes) ? true : false
                field = projects ? <div>
                    <div data-tip data-for={'task_helper_customer_' + projects.id} className='task_project' onClick={() => updateProject(0)}>{has_description ? <i className='w15 fa fa-sticky-note notes_icon'></i> : null}{projects.customer.customer_name} &gt; {projects.project_name}
                        <ReactTooltip id={'task_helper_customer_' + projects.id} place='bottom'>
                            <div className='mb5'><i className='w15 fal fa-phone-alt'></i><span>{projects.Customer.customer_responsable_phone ? projects.Customer.customer_responsable_phone : ''}</span></div>
                            <div className='mb5'><i className='w15 fal fa-envelope'></i><span>{projects.Customer.customer_responsable_mail ? projects.Customer.customer_responsable_mail : ''}</span></div>
                            <div className='mb5'><i className='w15 fal fa-user'></i><span>{projects.Customer.assigned_comercial ? projects.Customer.assigned_comercial : ''}</span></div>
                            <div className='mb5'><i className='far fa-sticky-note'></i><span>{projects.project_description ? projects.project_description : ''}</span></div>
                            <div className='mb5'><i className='far fa-sticky-note'></i><span>{projects.Customer.notes ? projects.Customer.notes : ''}</span></div>
                        </ReactTooltip>
                    </div>
                    <div className='addHelper' onClick={() => getListOfTasks(projects.id, projects.Customer.id)}><i className='far fa-eye'></i><span>{translations.taskCreated}</span></div>
                    </div> : <ProjectSearcher onchoose={updateProject} currentProjects={[]} helper={true} />
            } else if (item.helperfield_input === 'team') {
                field = <PhotoInputSearcher cleanInput={() => updateTeam(0)} fieldtoshow='name' pathImg='/teamImg/' updateData={updateTeam} getDataFrom={searchTeams} data={{ image: (team) ? '/teamImg/' + team.image : undefined, text: (team ? team.name : undefined)}} icon='fal fa-users' />            
            } else if (item.helperfield_input === 'user') {
                field = <PhotoInputSearcher cleanInput={() => updateUser(0)} fieldtoshow='username' pathImg='/userImg/' updateData={updateUser} getDataFrom={searchUsers} data={{ image: (user ? '/userImg/' + user.image : undefined), text: (user ? user.username : undefined) }} icon='fal fa-user' />  
            } else if (item.helperfield_input === 'description') {
                field = <textarea id='helper_description' placeholder={translations.description} />
            } else if (item.helperfield_input === 'maxDate') {
                field = <div className='photoinput aicenter'>
                        <div className='photoinput_logo aicenter tacenter'>
                            <i className='fal fa-calendar-alt'></i>
                        </div>
                        <div className='photoinput_text aicenter tacenter'>
                            <Datetime defaultValue={''} viewDate={new Date()}  closeOnSelect={true} inputProps={{ placeholder: translations.notAssigned }} onChange={updateDate}/>
                        </div>
                    </div>
            } else if (item.helperfield_input === 'shop') {
                field = projects
                        ? (
                        <div>
                        <PhotoInputSearcher extraParams={{ 'data-tip': 'data-tip', 'data-for': 'shop_taskhelper' }} cleanInput={() => updateShop(0)} fieldtoshow='shop_name' updateData={updateShop} getDataFrom={searchShops} data={{ text: (shop  ? shop.shop_name : undefined) }} icon='fal fa-store-alt' justText={true} />
                            <div className='addHelper' onClick={() => shop ? modifyShop(shop.id) : addNewShop(projects.CustomerId)}><i className='far fa-plus-circle'></i><span>{shop ? translations.modifyShop : translations.addShop }</span></div>
                        </div>
                        )
                        : (<div className='photoinput aicenter'>
                            <div className='photoinput_logo aicenter tacenter'>
                                <i className='fal fa-store-alt'></i>
                            </div>
                            <div className='photoinput_text aicenter tacenter'>
                                <div className='aicenter'>
                                    <input type='text' readOnly value={translations.addProject} />
                                </div>
                            </div>
                        </div>)
            } else if (item.helperfield_input === 'contact') {
                field = projects
                            ? (
                                <div>
                                    <PhotoInputSearcher extraParams={{ 'data-tip': 'data-tip', 'data-for': 'contact_taskhelper' }} cleanInput={() => updateContact(0)} fieldtoshow='contactp_name' updateData={updateContact} getDataFrom={searchContacts} data={{ text: (contact ? contact.contactp_name : undefined) }} icon='fal fa-id-badge' justText={true} />
                                    <div className='addHelper' onClick={() => contact ? modifyContact(contact.id) : addNewContact(projects.CustomerId)}><i className='far fa-plus-circle'></i><span>{contact ? translations.modifyContact : translations.addContact }</span></div>
                                </div>
                            )
                            : (<div className='photoinput aicenter'>
                                <div className='photoinput_logo aicenter tacenter'>
                                    <i className='fal fa-id-badge'></i>
                                </div>
                                <div className='photoinput_text aicenter tacenter'>
                                    <div className='aicenter'>
                                        <input type='text' readOnly value={translations.addProject} />
                                    </div>
                                </div>
                            </div>)
                        
            }
            return <div className='task_helper_item mtb10' key={key}>
                <h4 className='mb10'>{options[item.helperfield_input]}</h4>
                {field}
            </div>
        })
        : []
    return (
        <div id='createTask_helper'>
            <input type='text' className='hidden' id='task_helper_userid' defaultValue={user ? user.id : 0} />
            <input type='text' className='hidden' id='task_helper_project_id' defaultValue={projects ? projects.id : 0} />
            <input type='text' className='hidden' id='task_helper_teamid' defaultValue={team ? team.id : 0} />
            {shop && Object.keys(shop).length ? <ReactTooltip id='shop_taskhelper'>
                <h6>{shop.shop_name}</h6>
                <div className='mb5'><i className='w15 fal fa-home'></i><span>{shop.shop_address}</span></div>
                <div className='mb5'><i className='w15 fal fa-phone-alt'></i><span>{shop.shop_phone}</span></div>
                <div className='mb5'><i className='w15 fal fa-mobile'></i><span>{shop.shop_mobile}</span></div>
                <div className='mb5'><i className='w15 fal fa-envelope'></i><span>{shop.shop_mail}</span></div>
                <div className='mb5'><i className='w15 fal fa-user'></i><span>{shop.shop_responsable}</span></div>
            </ReactTooltip> : ''}
            {contact && Object.keys(contact).length ? <ReactTooltip id='contact_taskhelper'>
                <h6>{contact.contactp_name}</h6>
                <div className='mb5'><i className='w15 fal fa-phone-alt'></i><span>{contact.contactp_phone}</span></div>
                <div className='mb5'><i className='w15 fal fa-mobile'></i><span>{contact.contactp_mobile}</span></div>
                <div className='mb5'><i className='w15 fal fa-envelope'></i><span>{contact.contactp_mail}</span></div>
                <div className='mb5'><i className='w15 fal fa-sticky-note'></i><span>{contact.contactp_description}</span></div>
            </ReactTooltip> : ''}
            {mydata}
            <div className='footer aicenter'>
                <button className='btn_cancel' onClick={() => document.querySelector('.task_helper_portable .iconDisplayerbutton').click()}>{translations.cancel}</button>
                <button className='btn_primary mlauto' onClick={createTask}>{translations.save}</button>
            </div>
            {renderModal(modal, notification, setNotification, setModal)}
        </div>
    )
}
export default TaskHelperPortable;