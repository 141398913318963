import React, {useState} from 'react'
import InstallAdmin from './InstallAdmin'
import InstallDb from './InstallDb'
import store from '../reducers/reducers'
import {renderNotification} from './../utils/renderNotification'
const Install = () => {
    var translations = store.getState().language.translations
    const [step, setStep] = useState(2)
    const updateStep = (newstep) => {
        window.setTimeout(function(){
            setStep(newstep)
        },1000)
    }
    var render = ''
    if(step === 1){
        render = <InstallDb updateStep={updateStep}/>
    }else if(step === 2){
        render = <InstallAdmin updateStep={updateStep}/>
    }else if(step === 3){
        render = renderNotification({
            type: 'success',
            msg: translations.succInstall
        })
    }
    return (
        <div id='install_block' className='max350 mt10vh aicenter'>
            {render}
        </div>
    );
}
 
export default Install;