import store from "./reducers";
export default function page(state = false, action) {
    switch (action.type) {
        case 'CHANGE_PAGE':
            return state = action.page
        default:
            return state
    }
}
export const treeApp = (translations) => {
    return {
        dashboard: {
            title: translations.dashboard,
            name: 'dashboard',
            url: '/'
        },
        user_tasks: {
            title: translations.myTasks,
            name: 'user_tasks',
            url: '/user/:id'
        },
        user_calendar: {
            title: translations.myCalendar,
            name: 'user_calendar',
            url: '/calendar/:id'
        },
        customers: {
            title: translations.customers,
            name: 'customers',
            url: '/customers',
            backTo: {
                link: '/',
                text: translations.return
            },
            childs: {
                customer: {
                    title: translations.customer,
                    name: 'customer',
                    url: '/customer/:id',
                    backTo: {
                        link: '/customers',
                        text: translations.return
                    }
                }
            }
        },
        teams: {
            title: translations.teams,
            name: 'teams',
            url: '/teams',
            backTo: {
                link: '/',
                text: translations.return
            },
            childs: {
                team: {
                    title: translations.team,
                    name: 'team',
                    url: '/team',
                    backTo: {
                        link: '/teams',
                        text: translations.return
                    }
                },
            }
        },
        users: {
            title: translations.users,
            name: 'users',
            url: '/users/'
        },
        userconfig: {
            title: translations.myProfile,
            name: 'userconfig',
            url: '/user'
        },
        settings: {
            title: translations.settings,
            name: 'settings',
            url: '/settings'
        },
        activity: {
            title: translations.Notifications,
            name: 'activity',
            url: '/activity'
        },
        updatelog: {
            title: translations.updatelog,
            name: 'updatelog',
            url: '/updates',
        },
        repository: {
            title: translations.repository,
            name: 'repository',
            url: '/updates',
        },
        admin: {
            title: translations.adminPanel,
            name: 'admin',
            url: '/admin',
            backTo: {
                link: '/',
                text: translations.return
            },
            childs: {
                adminusers: {
                    title: translations.users,
                    name: 'adminusers',
                    url: '/admin/users',
                    backTo: {
                        link: '/admin',
                        text: translations.return
                    }
                },
                adminteams: {
                    title: translations.teams,
                    name: 'adminteams',
                    url: '/admin/teams',
                    backTo: {
                        link: '/admin',
                        text: translations.return
                    }
                },
                adminproducts: {
                    title: translations.products,
                    name: 'adminproducts',
                    url: '/admin/products',
                    backTo: {
                        link: '/admin',
                        text: translations.return
                    }
                },
                admincustomfields: {
                    title: translations.customFields,
                    name: 'admincustomfields',
                    url: '/admin/teams',
                    backTo: {
                        link: '/admin',
                        text: translations.return
                    }
                },
                adminbagofhours: {
                    title: translations.bagOfHours,
                    name: 'adminbagofhours',
                    url: '/admin/bagofhours',
                    backTo: {
                        link: '/admin',
                        text: translations.return
                    }
                },
                adminnotifications: {
                    title: translations.companyNotifications,
                    name: 'adminnotifications',
                    url: '/admin/notifications',
                    backTo: {
                        link: '/admin',
                        text: translations.return
                    }
                },
                adminstats: {
                    name: 'adminstats',
                    title: translations.stats,
                    url: '/admin/stats',
                    backTo: {
                        link: '/admin',
                        text: translations.return
                    }
                }
            }
        }
    }
}
export const getPage = (name, tree = treeApp(store.getState().language.translations)) => {
    var tempTree = false
    for(var k in tree){
        if(k === 'name' && tree[k] === name){
            tempTree = { ...tree}
            delete tempTree.childs
            return { ...tempTree, route: tree }
        }else{
            if(k === name){
                tempTree = {...tree[k]}
                delete tempTree.childs
                return {...tempTree, route: tempTree}
            }else if(tree[k].childs){
                var tempData = false
                tempData = getPage(name, tree[k].childs)
                if(tempData){
                    var parent = {...tree[k]}
                    parent.childs = tempData.route
                    tempData.route = parent
                    return tempData
                }
            }
        }
    }
}
