import React,  {useState, useCallback, useEffect} from 'react'
import store, { changePage } from '../../reducers/reducers'
import { processData } from '../../utils/specialdata'
import ReactTimeInput, { millisecondsToFormat } from '@teinorsa/react-timeinput'
import { CustomSelect, Switch } from '../../minicomponents/Form';
import {Link} from 'react-router-dom'
import Datetime from 'react-datetime';
import 'moment/locale/es'
import { CSVLink } from "react-csv";
const ListBagCustomer = ({match}) => {
    const [data, setData] = useState(false)
    const [projects, setProjects] = useState(false)
    const [services, setServices] = useState(false)
    const [selected, setSelected] = useState(match.params.bag_id ? match.params.bag_id : false)
    const [reset, setReset] = useState(false)
    var translations = store.getState().language.translations
    const getData = useCallback(async () => {
        let bags = await fetch('/api/adminp/bag/findAll', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                customer_id: match.params.customer_id,
                fill: true
            })
        })
        bags = await bags.json()
        await setData(bags)
    }, [match])
    const updateProjects = async () => {
        let projects = await fetch('/api/adminp/project/findAll', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                customer_id: match.params.customer_id,
            })
        })
        projects = await projects.json()
        await setProjects(projects)
    }
    const updateServices = async () => {
        let services = await fetch('/api/adminp/service/findAll', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({})
        })
        services = await services.json()
        await setServices(services)
    }
    const updatePage = async () => {
        var mycustomer = await fetch('/api/adminp/customer/findOne', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                where: {
                    id: match.params.customer_id
                }
            })
        })
        mycustomer = await mycustomer.json()
        changePage({
            name: 'adminbagofhours',
            subname: {
                name: 'adminbagofhours' + match.params.customer_id,
                text: translations.bagOfHours + ' : ' + mycustomer.customer_name
            }
        })
    }
    if(!store.getState().page || !store.getState().page.subname || store.getState().page.subname.name !== 'adminbagofhours'+match.params.customer_id){
        updatePage()
        updateServices()
        updateProjects()
    }
    useEffect(() => {
        if(match.params.bag_id && match.params.bag_id !== selected){
            setSelected(match.params.bag_id)
            setReset(true)
        }
        getData()
    }, [getData, match, selected])
    var dataRendered = {
        actives: {},
        noActives: {}
    }
    if(data){
        data.forEach((item) => {
            if(item.bag_active){
                if (dataRendered.actives[item.ProjectId] === undefined){
                    let actualProject = projects ? projects.filter((project) => item.ProjectId === project.id)[0] : false
                    dataRendered.actives[item.ProjectId] = {
                        name: actualProject.project_name
                    }
                }
                if (dataRendered.actives[item.ProjectId][item.ServiceId ? item.ServiceId : 0] === undefined){
                    let actualServices = services ? services.filter((service) => item.ServiceId === service.id)[0] : false
                    dataRendered.actives[item.ProjectId][item.ServiceId ? item.ServiceId : 0] = {
                        name: actualServices ?  actualServices.service_name : translations.default
                    }
                }
                dataRendered.actives[item.ProjectId][item.ServiceId ? item.ServiceId : 0][item.id] = item
            }else{
                if(dataRendered.noActives[item.ProjectId] === undefined){
                    let actualProject = projects ? projects.filter((project) => item.ProjectId === project.id)[0] : false
                    dataRendered.noActives[item.ProjectId] = {
                        name: actualProject.project_name
                    }
                }
                if (dataRendered.noActives[item.ProjectId][item.ServiceId ? item.ServiceId : 0] === undefined) {
                    let actualServices = services ? services.filter((service) => item.ServiceId === service.id)[0] : false
                    dataRendered.noActives[item.ProjectId][item.ServiceId ? item.ServiceId : 0] = {
                        name: actualServices ? actualServices.service_name : translations.default
                    }
                }
                dataRendered.noActives[item.ProjectId][item.ServiceId ? item.ServiceId : 0][item.id] = item
            }
        })
    }
    return ( 
        <div id='adminbagofhours_customer' className='wrap'>
            <div className='box fdiv55'>
                <div className='box_inner'>
                    <div className='box_header'></div>
                    <div className='box_content'>
                        {Object.keys(dataRendered.actives).length ? 
                            <BagCustomerSection name={translations.active} item={dataRendered.actives} selected={selected} setSelected={setSelected} /> : ''} 
                        {Object.keys(dataRendered.noActives).length ? 
                            <BagCustomerSection name={translations.disabled} item={dataRendered.noActives} selected={selected} setSelected={setSelected} /> : ''} 
                    </div>
                </div>
            </div>
            {selected ? <SelectedBag customer_id={match.params.customer_id} reset={reset} setReset={setReset} bag_id={selected} setSelected={setSelected} getData={getData}/> : ''}
        </div>
    );
}
 
export default ListBagCustomer;
export const SelectedBag = ({customer_id, bag_id, reset, setReset, getData}) => {
    const [data, setData] = useState(false)
    let translations = store.getState().language.translations
    let getInfo = useCallback(async () => {
        let result = await fetch('/api/adminp/bag/findOne', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                bag_id,
                fill: true
            })
        })
        result = await result.json()
        result.logs = {}
        var it = 0
        let project_id = result.ProjectId
        if(result.BagsLog.length){
            result.BagsLog.forEach((item, key) => {
                if (result.logs[item.TaskId] === undefined) {
                    result.logs[item.TaskId] = {
                        users: {},
                        project_id,
                        wastedHours: 0,
                        ...item.Task
                    }
                }
                if(result.logs[item.TaskId]['users'][item.UserId] === undefined){
                    result.logs[item.TaskId]['users'][item.UserId] = {
                        wastedHours: 0,
                        project_id,
                        items: [],
                        ...item.User
                    }
                }
                let dateStart = new Date(item.baglog_startdate)
                let dateFinish = new Date(item.baglog_finishdate)
                var calc = (dateFinish - dateStart)
                result.logs[item.TaskId]['users'][item.UserId].items.push({item, calc, dateStart: processData(dateStart), dateFinish: processData(dateFinish) })
                result.logs[item.TaskId].wastedHours += calc
                result.logs[item.TaskId]['users'][item.UserId].wastedHours += calc
                it += calc
            })
        }
        result.invertedTime = it
        setData(result)
    }, [bag_id])
    useEffect(() => {
        if(reset){
            setData(false)
            setReset(false)
        }
        getInfo()
    }, [bag_id, getInfo, reset, setReset])
    if(data){
        var myoptions = [
            {
                value: 0,
                text: translations.noRenew
            },
            {
                value: 1,
                text: translations.day
            },
            {
                value: 2,
                text: translations.week
            },
            {
                value: 3,
                text: translations.month
            },
            {
                value: 4,
                text: translations.year
            },
        ]
        var taskLogs = []
        let logsCSV = []
        if(Object.keys(data.logs).length){
            let totaltime = 0
            for(let k in data.logs){
                taskLogs.push(
                    <TaskLog taskLog={data.logs[k]} key={k} customer_id={customer_id}></TaskLog>
                )
                totaltime += data.logs[k].wastedHours
                logsCSV.push({
                    id: data.logs[k].id,
                    createdAt: processData(data.logs[k].createdAt),
                    name: data.logs[k].task_name,
                    description: data.logs[k].description ? data.logs[k].description.replace(/(\r\n|\n|\r)/gm, " ") : '',
                    wastedHours: millisecondsToFormat(data.logs[k].wastedHours)
                })
            }
            logsCSV.push({
                id: '',
                createdAt: '',
                name: '',
                description: '',
                wastedHours: ''
            })
            logsCSV.push({
                id: translations.inStartHours,
                createdAt: data.bag_limit,
                name: '',
                description: translations.wastedHours,
                wastedHours: millisecondsToFormat(totaltime)
            })
        }

        const updateValue = async (name, val) => {
            let data = {
                id: bag_id
            }
            data[name] = val
            await fetch('/api/adminp/bag/updateBag', {
                method: 'POST',
                headers: {
                    'Content-Type' : 'application/json'
                },
                body: JSON.stringify({
                    ...data
                })
            })
            getData()
        }
        return (
            <div className='box fdiv40 mlauto mrauto baglog'>
                <div className='box_inner'>
                    <div className='box_header header_2 aicenter'>
                        <h3>
                            <input type='text' defaultValue={data.bag_name} className='noborders nopadding' onBlur={(e) => updateValue('bag_name', e.currentTarget.value)} />
                        </h3>
                        <div className='mlauto'>
                            <CSVLink data={logsCSV}
                                filename={data.bag_name+'.csv'}
                                headers={[
                                {
                                    label: translations.task,
                                    key: 'id'
                                },
                                {
                                    label: translations.dateStart,
                                    key: 'createdAt'
                                },
                                {
                                    label: translations.taskName,
                                    key: 'name'
                                },
                                {
                                    label: translations.description,
                                    key: 'description'
                                },
                                {
                                    label: translations.wastedHours,
                                    key: 'wastedHours'
                                },
                                ]} separator=';'>{translations.downloadReport}</CSVLink>
                        </div>
                    </div>
                    <div className='box_content nopadding'>
                        <div className='formContainer'>
                            <div className='wrap'>
                                <div className='fdiv33 inputDiv'>
                                    <h6>{translations.inStartHours}</h6>
                                    <ReactTimeInput inputProps={{
                                        name: 'bag_limit',
                                        required: 'required',
                                    }} divKey={'bag_limit'} styled={true} defaultValue={data.bag_limit} worksInMilliseconds={false} onChange={(val) => updateValue('bag_limit', val) }/>
                                </div>
                                <div className='fdiv33 inputDiv'>
                                    <h6>{translations.wastedHours}</h6>
                                    <div className='fake_input'>
                                        {millisecondsToFormat(data.invertedTime)}
                                    </div>
                                </div>
                                <div className='fdiv33 inputDiv'>
                                    <h6>{translations.expireDate}</h6>
                                    <Datetime 
                                    viewDate={new Date()} 
                                    defaultValue={data.bag_expiredate ? new Date(data.bag_expiredate) : ''} closeOnSelect={true} inputProps={{ placeholder: translations.notAssigned, name: 'bag_expiredate' }} timeFormat={false} onChange={(val) => updateValue('bag_expiredate', val)} />
                                </div>
                                <div className='fdiv33 inputDiv'>
                                    <h6>{translations.renew}</h6>
                                    <CustomSelect selectProps={{
                                        value: data.bag_renewtype,
                                        name: 'bag_renewtype',
                                        required: true,
                                        onChange: (val) => updateValue('bag_renewtype', val)
                                    }} options={myoptions} />
                                </div>
                                <div className='fdiv33 inputDiv'>
                                    <h6>{translations.hoursToFill}</h6>
                                    <ReactTimeInput inputProps={{
                                        name: 'bag_renewtime',
                                        required: 'required',
                                    }} divKey={'bag_renewtime'} styled={true} defaultValue={data.bag_renewtime} worksInMilliseconds={false} onChange={(val) => updateValue('bag_renewtime', val)} />
                                </div>
                                <div className='fdiv33 inputDiv'>
                                    <h6>{translations.active}</h6>
                                    <Switch checkboxProps={{ id: 'bag_active', defaultChecked: data.bag_active, onChange: (e) => updateValue('bag_active', e.currentTarget.checked)}} parentProps={{}} />
                                </div>
                            </div>
                        </div>
                        <div className='logs_container'>
                            {taskLogs}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return ''
}
export const TaskLog = ({taskLog, customer_id}) => {
    let usersRendered = []
    for(let k in taskLog.users){
        usersRendered.push(<TaskLogUser user={taskLog.users[k]} key={k} />)
    }
    console.log(taskLog)
    return (
        <div className='task_log bdbt'>
            <div className='task_header aicenter crpointer' onClick={(e) => {e.currentTarget.parentNode.classList.toggle('active')}}>
                <div className={'completed fdiv10 aicenter tacenter' + ((taskLog.completed) ? ' active' : '')} >{(taskLog.completed) ? <i className="fal fa-check-circle"></i> : <i className="fal fa-check-circle"></i>}</div>
                <div className='name fdiv60 aicenter nopadding'><a rel="noopener noreferrer" className='idtask' target='_blank' href={'/customer/' + customer_id + '/projects/' + taskLog.project_id + '/' + taskLog.id} >#{taskLog.id}</a><span>{taskLog.task_name}</span></div>
                <div className='fdiv20 aicenter'>{millisecondsToFormat(taskLog.wastedHours)}</div>
                <div className='fdiv10 aicenter crpointer'><i className="fal fa-chevron-down opener"></i><i className="fal fa-chevron-up closer"></i></div>
            </div>
            <div className='task_content'>
                {usersRendered}
            </div>
        </div>
    )
}
export const TaskLogUser = ({user}) => {
    let logsRendered = []
    for(let k in user.items){
        let item = user.items[k]
        logsRendered.push(
            <div className='user_log aicenter' key={k}>
                <div className='fdiv30 mlauto'>{item.dateStart}</div>
                <div className='fdiv30'>{item.dateFinish}</div>
                <div className='fdiv30'>{millisecondsToFormat(item.calc)}</div>
            </div>
        )
    }
    return (
        <div className='task_log_user'>
            <div className='task_log_user_header aicenter crpointer' onClick={(e) => {e.currentTarget.parentNode.classList.toggle('active')}}>
                <div className='fdiv10 mlauto aicenter tacenter nopadding' ><img alt='' className='user_img tinyimg' src={'/userImg/'+user.image} /></div>
                <div className='name fdiv55 aicenter nopadding'><span>{user.username}</span></div>
                <div className='fdiv20 aicenter'>{millisecondsToFormat(user.wastedHours)}</div>
                <div className='fdiv10 aicenter crpointer'><i className="fal fa-chevron-down opener"></i><i className="fal fa-chevron-up closer"></i></div>
            </div>
            <div className='user_content'>
                {logsRendered}
            </div>
        </div>
    )
}
export const BagCustomerSection = ({item, name, ...props}) => {
    let content = []
    for(let k in item){
        let row = item[k]
        content.push(<BagCustomerProjects key={k} name={row.name} item={row} {...props} />)
    }
    return (
        <div className='customerbag_section'>
            <div className='customerbag_section_header'>
                <h2>{name}</h2>
            </div>
            <div className='customer_bag_section_content'>
                {content}
            </div>
        </div>
    )
}
export const BagCustomerProjects = ({name, item, ...props}) => {
    let content = []
    delete item.name
    for (let k in item) {
        let row = item[k]
        let name = row.name
        delete row.name
        content.push(<BagCustomerServices key={k} name={name} item={row} {...props} />)
    }
    return (
    <div className='customerbag_project'>
        <div className='customerbag_project_header'>
            <h3>{name}</h3>
        </div>
        <div className='customer_bag_project_content'>
            {content}
        </div>
    </div>
    )
}
export const BagCustomerServices = ({name, item, ...props}) => {
    var translations = store.getState().language.translations
    let renderItems = []
    let renewoptions = [
        translations.noRenew, translations.day, translations.week, translations.month, translations.year
    ]
    for(let k in item){
        let row = item[k]
        renderItems.push(
        <Link to={'/admin/bagofhours/'+row.customer_id+'/'+row.id} className={'wrap bgadmin_content crpointer' + (props.selected === k ? ' active' : '' )} key={k} >
                <div className='fdiv20 nopadding'>{row.bag_name}</div>
                <div className='fdiv20 nopadding'>{row.bag_limit}</div>
                <div className='fdiv20 nopadding'>{row.invertedTime}</div>
                <div className='fdiv20 nopadding'>{renewoptions[row.bag_renewtype]}</div>
                <div className='fdiv20 nopadding'>{row.bag_expiredate ? processData(row.bag_expiredate, true) : ''}</div>
            </Link>
        )
    }
    return (
        <div className='customerservices_project'>
            <div className='customerservices_project_header'>
                <h4>{name}</h4>
            </div>
            <div className='customer_services_project_content'>
                <div className='wrap bgadmin_header'>
                    <div className='fdiv20 nopadding'>{translations.name}</div>
                    <div className='fdiv20 nopadding'>{translations.inStartHours}</div>
                    <div className='fdiv20 nopadding'>{translations.wastedHours}</div>
                    <div className='fdiv20 nopadding'>{translations.renew}</div>
                    <div className='fdiv20 nopadding'>{translations.expireDate}</div>
                </div>
                {renderItems}
            </div>
        </div>
    )
}