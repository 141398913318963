import React from 'react'
import {
    Switch,
    Route
} from 'react-router-dom'
import AdminBagsOfHours from './AdminBagsOfHours';
import ListBagCustomer from './ListBagCustomer';
const AdminBagOfHours = () => {
    return (
        <Switch>
            <Route exact path='/admin/bagofhours/:customer_id/:bag_id?' render={(params) => <ListBagCustomer {...params} />}></Route>
            <Route exact path='/admin/bagofhours/' render={(params) => <AdminBagsOfHours {...params} />}></Route>
        </Switch>
    );
}

export default AdminBagOfHours;