import React, {useState, useEffect} from 'react';
import store, {changePage, changeUser} from '../../reducers/reducers'
import {returnNotifiPostForm} from '../../utils/forms'
import {renderNotification} from '../../utils/renderNotification'
import { ImageInput } from '../../minicomponents/Form';
import socket from '../../utils/socket';
const UserConfig = () => {
    var translations = store.getState().language.translations
    const [notification, setNotification] = useState(false)
    const fetchForm = async (e) => {
        e.preventDefault()
        e.target.elements.id.value = store.getState().user.id
        if (e.target.repeatpassword.value === e.target.password.value) {
            var result = await returnNotifiPostForm(e, translations.succUpdUser, translations.errUpdUser)
            setNotification(result)
            changeUser()
            socket.emit('update_user', store.getState().user.id)
        }else{
            setNotification({
                type: 'error',
                msg: translations.pwNotMatch
            })
        }
    }
    useEffect(() => {
        changePage({
            name: 'userconfig',
        })
    }, [])
    var user = store.getState().user
    return (
        <div id='userProfile' className='p1em'>
            <div className='wrap'>
                <div className='box fdiv60'>
                    <div className='box_inner'>
                    <div className='box_header'><h3>{translations.user}</h3></div>
                    <div className='box_content'>
                        {renderNotification(notification)}
                        <form action='/api/adminp/user/updateUser' method='post' onSubmit={fetchForm} className='formContainer wrap' id='updateUser'>
                            <input type='hidden' name='id' value={user.id} readOnly/>
                            <div className='inputDiv fdiv20'>
                                <h6>{translations.image}</h6>
                                <ImageInput imgProps={{src:'/userImg/', className:'user_img mediumimg'}} startValue={user.image} inputName='image' pathtosave='/public/userImg/' />
                            </div>
                            <div className='inputDiv fdiv40'>
                                <h6>{translations.username}</h6>
                                <input type='text' name='username' defaultValue={user.username} required placeholder={translations.inUsername} />
                            </div>
                            <div className='inputDiv fdiv40'>
                                <h6>{translations.email}</h6>
                                <input type='email' name='email' defaultValue={user.email} required placeholder={translations.inEmail} />
                                </div>
                            <div className='inputDiv fdiv33'>
                                <h6>{translations.name}</h6>
                                <input type='text' name='firstname' defaultValue={user.firstname} required placeholder={translations.inName} />
                                </div>
                            <div className='inputDiv fdiv33'>
                                <h6>{translations.firstsurname}</h6>
                                <input type='text' name='firstsurname' defaultValue={user.firstsurname} required placeholder={translations.inFirstsurname} />
                                </div>
                            <div className='inputDiv fdiv33'>
                                <h6>{translations.secondsurname}</h6>
                                <input type='text' name='secondsurname' defaultValue={user.secondsurname}  placeholder={translations.inSecondsurname}/>
                            </div>
                            <div className='inputDiv fdiv100'>
                                <h6>{translations.description}</h6>
                                <textarea name='description' defaultValue={user.description}  placeholder={translations.inDescription} />
                            </div>
                            <div className='fdiv100 updatepw_question'>
                                <h6>{translations.wannaUpdatePw}</h6>
                            </div>
                            <div className='inputDiv fdiv50'>
                                <h6>{translations.updatePassword}</h6>
                                <input type='password' name='password' placeholder={translations.updatePassword} />
                            </div>
                            <div className='inputDiv fdiv50'>
                                <h6>{translations.repeatPassword}</h6>
                                <input type='password' name='repeatpassword' className='notInclude' placeholder={translations.repeatPassword} />
                            </div>
                            <button type='submit' className='notInclude defaultButton'>{translations.save}</button>
                        </form>
                    </div>
                    </div>
                </div>
                <div className='box fdiv35 mlauto mrauto userprofile'>
                    <div className='box_inner noover'>
                        <div className='box_header tacenter'>
                            <div className='floatimg'>
                                <img src={'/userImg/'+user.image} className='user_img bigimg' alt='' />
                            </div>
                            <div className='user_name'>{user.firstname} {user.firstsurname}</div>
                            <div className='username'>{user.username}</div>
                            <div className='description'>{user.description}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default UserConfig;