import React, {useState, useEffect} from 'react'
import store, { changePage } from '../../reducers/reducers';
import { processForm, processModalForm, closeModal, renderModal } from '../../minicomponents/Modal'
import { renderNotification } from '../../utils/renderNotification';
import {CustomSelect} from '../../minicomponents/Form'
const AdminProducts = () => {
    const [modal, setModal] = useState(false)
    const [notification, setNotification] = useState(false)
    const [products, setProducts] = useState(false)
    const [services, setServices] = useState(false)
    var translations = store.getState().language.translations
    const updateProducts = async () => {
        let result = await fetch('/api/adminp/product/findAll', {
            method: 'POST',
            headers: {
                'Content-type': 'application/json'
            },
            body: JSON.stringify({})
        })
        result = await result.json()
        setProducts(result)
    }
    const updateServices = async () => {
        let result = await fetch('/api/adminp/service/findAll', {
            method: 'POST',
            headers: {
                'Content-type': 'application/json'
            },
            body: JSON.stringify({})
        })
        result = await result.json()
        setServices(result)
    }
    useEffect(() => {
        updateProducts()
        updateServices()
    }, [])
    useEffect(() => {
        changePage({
            name: 'adminproducts',
        })
    }, [])
    const addService = () => {
        let myproducts = products.map((item) => {
            return {
                text: item.product_name,
                value: item.id
            }
        })
        setModal({
            title: <div className="aicenter title_inner"><i className='fas fa-plus-square'></i> <span>{translations.addService}</span></div>,
            id: 'add_service',
            inner: 
                myproducts.length ? <form className='formContainer wrap' id='createService' action='/api/adminp/service/createService' method='POST' onSubmit={async (e) => {await processForm(e, translations.succCreateService, translations.errCreateService, setNotification);updateServices()}}>
                    <div className='inputDiv nomargin nopadding'>
                        <CustomSelect selectProps={{
                            name: 'product_id',
                            className: 'fdiv50',
                            required: true
                        }} options={myproducts} />
                    </div>
                    <div className='inputDiv nomargin'>
                        <input type='text' name='service_name' placeholder={translations.inName} required/>
                    </div>
                    <div className='inputDiv' style={{paddingRight: '1em', paddingLeft: '0'}}>
                        <input type='text' name='service_code' placeholder={translations.codeCustomer} />
                    </div>
                    <button id='submit' type='submit' className='notInclude' style={{ display: 'none' }}></button>
                </form> : translations.errCreateService
            ,
            onAccept: () => processModalForm('createService'),
            notification: renderNotification(notification),
            onCancel: () => closeModal(setNotification, setModal)
        })
    }
    const updateService = (id) => {
        let myproducts = products.map((item) => {
            return {
                text: item.product_name,
                value: item.id
            }
        })
        let mydata = services.filter((item) => item.id === id)
        mydata = mydata.length ? mydata[0] : {}
        setModal({
            title: <div className="aicenter title_inner"><i className='fas fa-plus-square'></i> <span>{translations.modifyService}</span></div>,
            id: 'update_service',
            inner: 
                <form className='formContainer wrap' id='updateService' action='/api/adminp/service/updateService' method='POST' onSubmit={async (e) => { await processForm(e, translations.succUpdService, translations.errUpdService, setNotification);updateServices()}}>
                    <input type='hidden' name='id' value={id} readOnly />
                    <div className='inputDiv nomargin nopadding'>
                        <CustomSelect selectProps={{
                            name: 'product_id',
                            className: 'fdiv50',
                            required: true,
                            value: mydata.ProductId
                        }} options={myproducts} />
                    </div>
                    <div className='inputDiv nomargin'>
                        <input type='text' name='service_name' placeholder={translations.inName} required defaultValue={mydata.service_name}/>
                    </div>
                    <div className='inputDiv' style={{ paddingRight: '1em', paddingLeft: '0' }}>
                        <input type='text' name='service_code' placeholder={translations.codeCustomer} defaultValue={mydata.service_code}/>
                    </div>
                    <button id='submit' type='submit' className='notInclude' style={{ display: 'none' }}></button>
                </form>
            ,
            onAccept: () => processModalForm('updateService'),
            notification: renderNotification(notification),
            onCancel: () => closeModal(setNotification, setModal)
        })
    }
    const deleteService = (id) => {
        setModal({
            title: <div className="aicenter title_inner"><i className='fas fa-plus-square'></i> <span>{translations.deleteService}</span></div>,
            id: 'delete_service',
            careModal: true,
            inner: 
                <form className='formContainer wrap' id='deleteService' action='/api/adminp/service/deleteService' method='POST' onSubmit={async (e) => { await processForm(e, translations.succRmService, translations.errDeleteService, setNotification);updateServices()}}>
                    <input type='hidden' name='id' value={id} readOnly />
                    <span>{translations.confirmDeleteService}</span>
                    <button id='submit' type='submit' className='notInclude' style={{ display: 'none' }}></button>
                </form>
            ,
            onAccept: () => processModalForm('deleteService'),
            notification: renderNotification(notification),
            onCancel: () => closeModal(setNotification, setModal)
        })
    }
    const addProduct = () => {
        setModal({
            title: <div className="aicenter title_inner"><i className='fas fa-plus-square'></i> <span>{translations.addProduct}</span></div>,
            id: 'add_product',
            inner: 
                <form className='formContainer wrap' id='createProduct' action='/api/adminp/product/createProduct' method='POST' onSubmit={async (e) => {await processForm(e, translations.succCreateProduct, translations.errCreateProduct, setNotification);updateProducts()}}>
                    <input type='text' name='product_name' placeholder={translations.inName} required/>
                    <button id='submit' type='submit' className='notInclude' style={{ display: 'none' }}></button>
                </form>
            ,
            onAccept: () => processModalForm('createProduct'),
            notification: renderNotification(notification),
            onCancel: () => closeModal(setNotification, setModal)
        })
    }
    const updateProduct = (id, name) => {
        setModal({
            title: <div className="aicenter title_inner"><i className='fas fa-plus-square'></i> <span>{translations.modifyProduct}</span></div>,
            id: 'update_product',
            inner: 
                <form className='formContainer wrap' id='updateProduct' action='/api/adminp/product/updateProduct' method='POST' onSubmit={async (e) => { await processForm(e, translations.succUpdProduct, translations.errUpdProduct, setNotification);updateProducts()}}>
                    <input type='hidden' name='id' value={id} readOnly />
                    <input type='text' name='product_name' defaultValue={name} placeholder={translations.inName} required/>
                    <button id='submit' type='submit' className='notInclude' style={{ display: 'none' }}></button>
                </form>
            ,
            onAccept: () => processModalForm('updateProduct'),
            notification: renderNotification(notification),
            onCancel: () => closeModal(setNotification, setModal)
        })
    }
    const deleteProduct = (id) => {
        setModal({
            title: <div className="aicenter title_inner"><i className='fas fa-plus-square'></i> <span>{translations.deleteProduct}</span></div>,
            id: 'delete_product',
            careModal: true,
            inner: 
                <form className='formContainer wrap' id='deleteProduct' action='/api/adminp/product/deleteProduct' method='POST' onSubmit={async (e) => { await processForm(e, translations.succDeleteProduct, translations.errDeleteProduct, setNotification);updateProducts()}}>
                    <input type='hidden' name='id' value={id} readOnly />
                    <span>{translations.confirmDeleteProduct}</span>
                    <button id='submit' type='submit' className='notInclude' style={{ display: 'none' }}></button>
                </form>
            ,
            onAccept: () => processModalForm('deleteProduct'),
            notification: renderNotification(notification),
            onCancel: () => closeModal(setNotification, setModal)
        })
    }
    let productsformated = ''
    if(products.length){
        productsformated = products.map((item, key) => {
            return (
            <div className='fdiv33 aicenter adminproduct' key={key} >
                <span>{item.id} - {item.product_name}</span>
                <div className='actions mlauto aicenter'>
                    <div className='edit crpointer' onClick={() => updateProduct(item.id, item.product_name)}>
                        <i className='far fa-pencil'></i>
                    </div>
                    <div className='remove crpointer' onClick={() => deleteProduct(item.id)}>
                        <i className='fal fa-trash-alt'></i>
                    </div>
                </div>
            </div>)
        })
    }
    let servicesformated = ''
    if(services.length){
        servicesformated = services.map((item, key) => {
            return (
            <div className='fdiv33 aicenter adminproduct' key={key} >
                <span>{item[`Product.product_name`]} - {item.service_name}</span>
                <div className='actions mlauto aicenter'>
                    <div className='edit crpointer' onClick={() => updateService(item.id, item.service_name)}>
                        <i className='far fa-pencil'></i>
                    </div>
                    <div className='remove crpointer' onClick={() => deleteService(item.id)}>
                        <i className='fal fa-trash-alt'></i>
                    </div>
                </div>
            </div>)
        })
    }
    return ( 
    <div className='adminproducts wrap'>
        <div className='box fdiv50 mrauto'>
            <div className='box_inner'>
                <div className='box_header'>
                        <button className='btn_primary add_adminproducts' onClick={addProduct}>
                        <i className='far fa-plus-circle'></i>
                        <span>{translations.addProduct}</span>
                    </button>
                </div>
                    <div className='box_content'><div className='wrap adminproducts_list'>{productsformated}</div></div>
            </div>
        </div>
        <div className='box fdiv47-5'>
            <div className='box_inner'>
                <div className='box_header'>
                        <button className='btn_primary add_adminservices' onClick={addService}>
                        <i className='far fa-plus-circle'></i>
                        <span>{translations.addService}</span>
                    </button>
                </div>
                    <div className='box_content'><div className='wrap adminservices_list'>{servicesformated}</div></div>
            </div>
        </div>
        {renderModal(modal, notification, setNotification, setModal)}
    </div>
    );
}
 
export default AdminProducts;