import React, {useState, useEffect} from 'react'
import {millisecondsToFormat} from '@teinorsa/react-timeinput'
import store, { updateWorking } from '../reducers/reducers'
import { connect } from 'react-redux'
import socket from '../utils/socket';
const mapStateToProps = state => ({ working: state.working })
const WorkIn = () => {
    const [milliseconds, setMilliseconds] = useState(0)
    const [paused, setPause] = useState(false)
    const myInterval = window.setInterval(() => {
        clearInterval(myInterval)
        setMilliseconds(milliseconds+1000)
    }, 1000)
    const emitTaskData = (current_id) => {
        socket.emit('update_data', 'task_' + current_id)
    }
    const [interval] = useState(() => myInterval)
    if(paused || store.getState().working === undefined || store.getState().working === false){
        clearInterval(myInterval)
        if(!store.getState().working && milliseconds){
            setMilliseconds(0)
        }
    }else{
        if (parseInt(milliseconds)%30000 === 0) {
            var data = {
                task_id: store.getState().working.task_id,
                bag_id: store.getState().working.bag_id,
                user_id: store.getState().user.id,
                params: {
                    id: store.getState().working.id,
                    baglog_finishdate: '[NOW]'
                }
            }
            fetch('/api/adminp/task/workIn', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })
        }
    }
    useEffect(() => {
        return(() => {
            clearInterval(interval)
        })
    })
    const myMovePointer = (e) => {
        let current = document.querySelector('.working')
        let cross = current.querySelector('.working_header .fa-arrows')
        let currentX = current.offsetLeft - cross.offsetLeft
        let currentY = current.offsetTop - cross.offsetTop
        if(!current.classList.contains('edited')){
            current.classList.add('edited')
        }
        let clientX = e.clientX
        let clientY = e.clientY
        let minX = 0
        let minY = 0
        let maxX = window.innerWidth
        let maxY = window.innerHeight
        if(clientX >= minX &&  clientX <= maxX){
            currentX = clientX - cross.offsetLeft
        }
        if(clientY >= minY &&  clientY <= maxY){
            currentY = clientY - cross.offsetTop
        }
        if(currentX <= 0){
            currentX = 0
        }
        if(currentY <= 0){
            currentY = 0
        }
        current.style.left = currentX+'px'
        current.style.top = currentY+'px'
    }
    const [movePointer] = useState(() => myMovePointer)
    const myMoveUp = () => {
        window.removeEventListener('mousemove', movePointer)   
        window.removeEventListener('mouseup', moveUp)
    }
    const [moveUp] = useState(() => myMoveUp)
    const moveReady = () => {
        window.addEventListener('mousemove', movePointer)
        window.addEventListener('mouseup', moveUp)
    }
    const timeToPlay = async () => {
        var data = {
            task_id: store.getState().working.task_id,
            bag_id: store.getState().working.bag_id,
            user_id: store.getState().user.id,
            params: {}
        }
        let id = await fetch('/api/adminp/task/createBagLog', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        var currentWorking = store.getState().working
        currentWorking.id = await id.json()
        updateWorking(currentWorking)
        setPause(false)
    }
    const timeToPause = async (stop = false) => {
        if(!paused){
        var data = {
            task_id : store.getState().working.task_id,
            bag_id: store.getState().working.bag_id,
            user_id: store.getState().user.id,
            params: {
                id: store.getState().working.id,
                baglog_finishdate: '[NOW]'
            }
        }
        await fetch('/api/adminp/task/workIn', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        setPause(true)
        }
        emitTaskData(store.getState().working.task_id)
        if(stop){
            updateWorking(false)
            setPause(false)
        }
    }
    var task = store.getState().working
    if (!task) {
        return (<div></div>)
    }
    var time = millisecondsToFormat(milliseconds).split(':')
    return ( 
        <div className={'working' + (paused ? ' noplaying' : '')}>
            <div className='working_header wrap' onMouseDown={moveReady}>
                <i className='fal fa-arrows'></i>
                <i className={'fas fa-hourglass-start mlauto'+ (!paused ? ' fa-spin' : '')}></i>
            </div>
            <div className='working_content'>
                <div className='workingtask_name'>
                    <strong>#{task.task_id}</strong><span>{task.task_name.substring(0,20) + (task.task_name.length > 20 ? '...' : '')}</span>
                </div>
                <div className='workingtask_counter'>
                    <div className='working_task_hours task_timer'>{time[0]}</div>
                    <div className='working_task_hours task_timer'>{time[1]}</div>
                    <div className='working_task_hours task_timer'>{time[2]}</div>
                </div>
                <div className='workingtask_actions'>
                    {paused ? <i className='fas fa-play-circle crpointer' onClick={timeToPlay}></i> : ''}
                    {!paused ? <i className='fas fa-pause-circle crpointer' onClick={() => timeToPause(false)}></i> : ''}
                    <i className='fas fa-stop-circle crpointer' onClick={() => timeToPause(true)}></i>
                </div>
            </div>
        </div> 
    );
}
export default connect(mapStateToProps)(WorkIn);