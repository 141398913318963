import React, {useState, useEffect} from 'react'
import store, { updateWorking } from '../reducers/reducers';
import { processData, specialOrderBy } from '../utils/specialdata';
import { PhotoInputSearcher, CustomSelect, CustomFieldPicker, Checkbox } from './Form';
import { searchUser, searchTeam, fetchPostForm, searchTasks, searchProjects, searchCustomers, processImage, searchContact, searchShop } from '../utils/forms';
import Datetime from 'react-datetime';
import 'moment/locale/es'
import SearchBar from './Searchbar';
import socket from '../utils/socket';
import Modal, {processModalForm, closeModal} from './Modal'
import { IconDisplay } from './SpecialDisplayers';
import { renderLogMessage } from '../utils/logmessage';
import { renderNotification } from '../utils/renderNotification';
import { connect } from 'react-redux'
import ReactTimeInput, { formatToMilliseconds, millisecondsToFormat } from '@teinorsa/react-timeinput';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip'
import TextareaAutosize from 'react-textarea-autosize';
import { List, AutoSizer } from 'react-virtualized';
const equals = require('array-equal')
const Tasks = ({ extraParams, url, history }) => {
    var translations = store.getState().language.translations
    const [currentTask, setCurrentTask] = useState(extraParams.task_id ? parseInt(extraParams.task_id) : false)
    const [updateHeight, setUpdateHeight] = useState(false)
    const [modal, setModal] = useState(false)
    const emitTaskData = (current_id) => {
        socket.emit('update_data', 'task_' + current_id)
        socket.emit('update_groupdata_globaltasks', { task_id: current_id})
    }
    if (extraParams.task_id !== undefined && extraParams.task_id && parseInt(extraParams.task_id) !== currentTask) { 
        setCurrentTask(parseInt(extraParams.task_id))
    }
    const emitOldTeam = (current_id, task_id) => {
        socket.emit('update_groupdata', {room: 'team_' + current_id, task_id})
    }
    const emitOldUser = (current_id, task_id) => {
        socket.emit('update_groupdata', {room: 'user_' + current_id, task_id})
    }
    const emitOldProject = (current_id, task_id) => {
        socket.emit('update_groupdata', {room: 'project_' + current_id, task_id})
    }
    const fetchDeletePostForm = async (e, special = false, task_id = false) => {
        let result = await fetchPostForm(e,true)
        if(result.team){
            result.team.forEach((item) => {
                emitOldTeam(item, task_id)
            })
        }
        if(result.user){
            result.user.forEach((item) => {
                emitOldUser(item, task_id)
            })
        }
        if(result.ids){
            result.ids.forEach((item) => {
                emitTaskData(item, task_id)
            })
        }
        if(result.projects){
            result.projects.forEach((item) => {
                emitOldProject(item, task_id)
            })
        }
        if(special){
            var element = false
            if(document.querySelector('.tasklist_item.active').previousSibling.classList.contains('tasklist_item')){
                element = document.querySelector('.tasklist_item.active').previousSibling
            }
            // else if(document.querySelector('.tasklist_item.active').nextSibling){
            //     element = document.querySelector('.tasklist_item.active').nextSibling
            // }
            if(element){
                element.querySelector('.name textarea').focus()
                element.click()
            }
        }
        setModal(false)
    }
    const deleteTaskModal = (task_id, parent_id = false, special = false) => {
        setModal({
            title: <div className="aicenter title_inner"><i className='fas fa-trash-alt'></i> <span>{translations.deleteTask}</span></div>,
            id: 'delete_task',
            careModal: true,
            inner:
                <form className='formContainer wrap' id='deleteTask' action='/api/adminp/task/deleteTask' method='POST' onSubmit={(e) => fetchDeletePostForm(e, special, task_id)}>
                    {translations.confirmDeleteTask}
                    <input type='hidden' name='task_id' readOnly value={task_id} />
                    <button id='submit' type='submit' className='notInclude' style={{ display: 'none' }}></button>
                </form>
            ,
            onAccept: () => processModalForm('deleteTask'),
            notification: '',
            onCancel: () => closeModal(false, setModal)
        })
    }
    var renderModal = ''
    if(modal){
        renderModal = <Modal {...modal} />
    }
    return ( 
        <div className={'tasks wrap ' + (currentTask ? 'haveTask' : 'noTask') } >
        <div className={'box task_list ' + (currentTask ? 'fdiv55' : 'fdiv100') }>
                <TaskList deleteTaskModal={deleteTaskModal} extraParams={extraParams} setCurrentTask={setCurrentTask} currentTask={currentTask} emitTaskData={emitTaskData} emitOldTeam={emitOldTeam} emitOldUser={emitOldUser} updateHeight={updateHeight} setUpdateHeight={setUpdateHeight}/>
        </div>
            {currentTask ? <div className={'box mlauto mrauto task_single ' + (currentTask ? 'fdiv45' : 'fdiv0')}>
                <TaskEdit setModal={setModal} task_id={currentTask} deleteTaskModal={deleteTaskModal} setCurrentTask={setCurrentTask} emitTaskData={emitTaskData} emitOldTeam={emitOldTeam} emitOldUser={emitOldUser} emitOldProject={emitOldProject} history={history} url={url} updateHeight={updateHeight} setUpdateHeight={setUpdateHeight}/>
            </div> : ''}
        {renderModal}
    </div>
    );
}
const mapStateToProps = state => ({ working: state.working })
export default Tasks;

const MyTaskEdit = ({ task_id, setCurrentTask, emitTaskData, emitOldTeam, emitOldUser, emitOldProject, deleteTaskModal, isnew = false, setModal = false, history, url, updateHeight, setUpdateHeight}) => {
    var translations = store.getState().language.translations
    const [current_id, setCurrentId] = useState(false)
    const [data, setData] = useState(false)
    const [taskDescription, setTaskDescription] = useState(false)
    const [isFocused, setIsFocused] = useState(false)
    const [isConnected, setIsConnected] = useState(false)
    const [timeToReset, setTimeToReset] = useState(false)
    const [taskName, setTaskName] = useState(false)
    const [haveToFocus, setHaveToFocus] = useState(false)
    const [notification, setNotification] = useState(false)
    const [working, setWorking] = useState(store.getState().working)
    const [mydate, setMyDate] = useState(false)
    const [triggerEvDesc, setTriggerEvDesc] = useState(false)
    const getData = async () => {
        let result = await fetch('/api/adminp/task/getTask', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                task_id
            })
        })
        result = await result.json()
        if (result.id === undefined) {
            //task has been deleted
            resetTask()
        }
        if (result.description === 'false' || !result.description) {
            result.description = ''
        }
        setData(result)
        setTaskDescription(result.description)
        setTaskName(result.task_name)
        setMyDate(result.max_date)
    }
    const updateSocketData = (exec = true) => {
        if(exec){
            setTimeToReset(1)
            getData()
        }
    }
    if((store.getState().working && store.getState().working.id !== working.id) || (working && !store.getState().working)){
        setWorking(store.getState().working)
        updateSocketData()
    }
    useEffect(() => {
        if (timeToReset === 1) {
            setTimeToReset(2)
        }
        if(timeToReset === 2){
            setTimeToReset(false)
        }
        if (task_id) {
            history.push(url+'/'+task_id)
        }
    },[timeToReset, history, task_id, url])
    const updateTitle = async (e, mytask = task_id, currentTaskName = data.task_name, forceVal = false) => {
        clearTimeout(triggerEvDesc)
        var task_name = forceVal
        if (!forceVal) {
            task_name = e.target.value
        }
        if(task_name !== currentTaskName){
            await fetch('/api/adminp/task/updateTask', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    id: mytask,
                    task_name
                })
            })
            emitTaskData(mytask)
        }
    }
    const updateDescription = async (e, currentDescription = data.description, forceVal = false) => {
        setIsFocused(false)
        clearTimeout(triggerEvDesc)
        var description = forceVal
        if (!forceVal) {
            description = e.currentTarget.value
        }
        if(description !== currentDescription){
            await fetch('/api/adminp/task/updateTask', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    id: task_id,
                    description
                })
            })
            emitTaskData(current_id)
        }
    }
    const resetTask = async () => {
        socket.emit('leave', 'task_' + current_id)
        socket.off('update_data')
        setCurrentTask(false)
        if (setUpdateHeight) {
            setUpdateHeight(true)
        }
    }
    if (task_id !== current_id) {
        if (setUpdateHeight) {
            setUpdateHeight(true)
        }
        if(current_id){
            socket.emit('leave', 'task_'+current_id)
            socket.off('update_data')
        }
        if(task_id){
            socket.emit('create', 'task_'+task_id);
            socket.on('update_data', updateSocketData)
            if (!isConnected) {
                socket.on('update_data', updateSocketData)
                setIsConnected(true)
            }
            getData()
        }
        setCurrentId(task_id)
        setTimeToReset(1)
    }
    if((!task_id || !data) && !isnew){
        return <div></div>
    }
    const addSubTask = async () => {
        let result = await fetch('/api/adminp/task/createTask', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                who_creates: store.getState().user.id,
                params: { task_name: '', parent_id: task_id},
            })
        })
        result = await result.json()
        setHaveToFocus(result)
        emitTaskData(current_id)
    }
    const searchUsers = async (value) => {
        var results = await searchUser(value, store.getState().user.id)
        return results
    }
    const markAsFinish = async (mytask = task_id, mycompleted = data.completed) => {
        await fetch('/api/adminp/task/updateTask',{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                who_updates: store.getState().user.id,
                id: mytask,
                completed: !mycompleted
            })
        })
        emitTaskData(mytask)
    }
    const updateDate = (max_date = null) => {
        if(max_date){
            if (max_date.format !== undefined) {
                max_date = max_date.format()
            }
            if(max_date !== 'Invalid Date'){
                setMyDate(max_date)
            }
            if (!data.max_date) {
                updateDateFetch(null, max_date)
            }
        } else {
            updateDateFetch(null, null)
        }
    }
    const updateDateFetch = async (e, seconddate) => {
        await fetch('/api/adminp/task/updateTask',{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: task_id,
                max_date: seconddate !== undefined ? seconddate : mydate
            })
        })
        emitTaskData(current_id)
    }
    const updateRememberDate = (remember_date = null) => {
        if(remember_date){
            remember_date = remember_date.format()
        }
        fetch('/api/adminp/task/updateTask',{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: task_id,
                remember_date
            })
        })
        emitTaskData(current_id)
    }
    const updateUser = async (user_id = 0, oldUser = data.UserId, current_id = task_id) => {
        oldUser = data.UserId
        await fetch('/api/adminp/task/assignTaskToUser', {
            method: 'POST',
            headers : {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                who_updates: store.getState().user.id,
                user_id,
                task_id: current_id
            })
        })
        emitOldUser(oldUser, current_id)
        emitTaskData(current_id)
    }
    const updateTeam = async (team_id = 0, oldTeam = data.TeamId, current_id = task_id) => {
        oldTeam = data.TeamId
        await fetch('/api/adminp/task/assignTaskToTeam', {
            method: 'POST',
            headers : {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                who_updates: store.getState().user.id,
                team_id,
                task_id: current_id
            })
        })
        emitOldTeam(oldTeam, current_id)
        emitTaskData(current_id)
    }
    const updateShop = async (shop_id = 0) => {
        await fetch('/api/adminp/task/assignTaskToShop', {
            method: 'POST',
            headers : {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                shop_id,
                task_id: current_id
            })
        })
        emitTaskData(current_id)
    }
    const updateContact = async (contact_id = 0) => {
        await fetch('/api/adminp/task/assignTaskToContact', {
            method: 'POST',
            headers : {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                contact_id,
                task_id: current_id
            })
        })
        emitTaskData(current_id)
    }
    const updateProject = async (project_id, remove = false, current_id = task_id) => {
        await fetch('/api/adminp/task/assignTaskToProject', {
            method: 'POST',
            headers : {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                who_updates: store.getState().user.id,
                project_id,
                task_id: current_id,
                remove
            })
        })
        emitOldProject(project_id, current_id)
        emitTaskData(current_id)
    }
    const searchTeams = async (value) => {
        var results = await searchTeam(value)
        return results
    }
    const searchShops = async (value, customers = data.projects) => {
        let ids = []
        for (let customer of customers) {
            ids.push(customer.Customer.id)
        }
        var results = await searchShop(value, ids)
        return results
    }
    const searchContacts = async (value, customers = data.projects) => {
        let ids= []
        for(let customer of customers){
            ids.push(customer.Customer.id)
        }
        var results = await searchContact(value, ids)
        return results
    }
    const formatComment = async (e) => {
        e.preventDefault()
        var disableds = e.target.querySelector('[type="submit"]')
        disableds.setAttribute('disabled', '')
        var target = e.target.content
        var formData = {
            task_id: e.target.task_id.value,
            user_id: e.target.user_id.value,
            params: {
                data_type: e.target.data_type.value,
                content: e.target.content.value
            }
        }
        await fetch(e.target.action, {
            method: e.target.method,
            body: JSON.stringify(formData),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        disableds.removeAttribute('disabled')
        target.value = ''
        emitTaskData(current_id)
    }
    const deleteComment = (taskcomment_id) => {
        fetch('/api/adminp/task/deleteTaskComment', {
                method: 'POST',
                body: JSON.stringify({
                    taskcomment_id
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
        })
        emitTaskData(current_id)
    }
    const modifyComment = (e, taskcomment_id) => {
        let content = e.currentTarget.value
        fetch('/api/adminp/task/updateTaskComment', {
            method: 'POST',
            body: JSON.stringify({
                id: taskcomment_id,
                content
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        let comment_inner = document.createElement('div')
        comment_inner.classList.add('comment_inner')
        comment_inner.innerText = content
        e.currentTarget.parentNode.replaceChild(comment_inner, e.currentTarget)
        emitTaskData(current_id)
    }
    const transformToTextArea = (e, taskcomment_id) => {
        let target = e.currentTarget.parentNode.parentNode.parentNode.querySelector('.comment_inner')
        let textarea = document.createElement('textarea')
        textarea.value = target.innerText
        textarea.addEventListener('blur', (e) => modifyComment(e,taskcomment_id))
        target.parentNode.replaceChild(textarea, target)
    }
    const assignToTask = async (task_id, parent_id, remove = false) => {
        await fetch('/api/adminp/task/assignTaskToTask', {
            method: 'POST',
            body: JSON.stringify({
                task_id,
                parent_id,
                remove
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        emitTaskData(task_id)
        emitTaskData(parent_id)
    }
    const assignCustomFieldToTask = async (task_id, customfield_id, customfieldval_id, parent_id) => {
        await fetch('/api/adminp/task/assignCustomFieldToTask', {
            method: 'POST',
            body: JSON.stringify({
                task_id,
                customfield_id,
                customfieldval_id
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        emitTaskData(task_id)
        emitTaskData(parent_id)
    }
    const uploadTaskFile = async (e) => {
        e.preventDefault()
        var elem = e.target.elements.uploadFile
        var filename = elem.files[0].name
        var moveTo = elem.getAttribute('pathtosave')
        var taskComm = await fetch('/api/adminp/task/getTaskComments', {
            method: 'POST',
            body: JSON.stringify({
                task_id
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        taskComm = await taskComm.json()
        let last_task_id = String(taskComm.length + 1)
        processImage(elem.files, filename, moveTo, last_task_id+filename)
        await fetch('/api/adminp/task/createTaskComment', {
            method: 'POST',
            body: JSON.stringify({
                task_id,
                user_id: store.getState().user.id,
                params: {
                    data_type: 2,
                    content: last_task_id + filename
                }
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        elem.value = ''
        emitTaskData(current_id)
    }
    const prepareToWork = async (e) => {
        let task_id = e.currentTarget['task_id'].value
        let bag_id = false
        if(e.currentTarget['bag_id'] && e.currentTarget['bag_id'].value !== ""){
            bag_id = parseInt(e.currentTarget['bag_id'].value)
        }
        let task_name = taskName
        let wasted_time = e.currentTarget['wasted_time'].value
        let id = await fetchPostForm(e, true)
        if (store.getState().working) {
            document.querySelector('.workingtask_actions .fa-stop-circle').click()
            setModal(false)
            updateWorking(false)
            window.setTimeout(() => {
                updateWorking({
                    task_id,
                    bag_id,
                    task_name,
                    id,
                    user_id: store.getState().user.id
                })
            }, 1000)
        } else {
            if(parseInt(wasted_time) === 0 ){
            updateWorking({
                task_id,
                bag_id,
                task_name,
                id,
                user_id:  store.getState().user.id
            })
            setModal(false)
            }else{
                emitTaskData(task_id)
                modifyTime()
            }
        }
    }
    const workIn = async () => {
        var formatBags = []
        let myprojects = await fetch('/api/adminp/task/getProjectsByTask', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                task_id
            })
        })
        myprojects = await myprojects.json()
        if(myprojects.length){
            for(let k in myprojects) {
                let project = myprojects[k]
                if(project.bags.length){
                    for(let j in project.bags){
                        let bag = project.bags[j]
                        formatBags.push(<Checkbox key={'bag'+k+j} singleOnly={true} checkboxProps={{name:'bag_id',value:bag.id, id:'bag'+k+j}} text={bag.bag_name + (bag.service ? ' - '+bag.service.service_name : '')} parentProps={{className: 'fdiv100 nopadding'}} />)
                    }
                }
            }
        }
        setModal({
            title: <div className="aicenter title_inner"><i className='fas fa-alarm-plus'></i> <span>{translations.workIn}</span></div>,
            id: 'work_in',
            inner:
                <form className='formContainer wrap' id='workIn' action='/api/adminp/task/createBagLog' method='POST' onSubmit={prepareToWork}>
                    <p>{translations.selectBagOfHours}</p>
                    <div className='mtb10'>
                        {formatBags}
                    </div>
                    <input type='hidden' name='task_id' readOnly value={task_id} />
                    <input type='hidden' name='user_id' readOnly value={store.getState().user.id} />
                    <p>{translations.bagOfHoursManual}</p>
                    <div className='mtb10 aicenter'>
                        <ReactTimeInput inputProps={{
                            name: 'wasted_time',
                            required: 'required',
                        }} worksInMilliseconds={true} divKey={'wasted_time'} styled={true}
                            defaultValue='0'
                        />
                        <span className='horz_separator'>{translations.or}</span>
                        <button className='btn-primary nomargin notInclude fw500'>{translations.startWork}</button>
                    </div>
                    <button id='submit' type='submit' className='notInclude' style={{ display: 'none' }}></button>
                </form>
            ,
            onAccept: () => processModalForm('workIn'),
            notification: renderNotification(notification),
            onCancel: () => closeModal(setNotification, setModal)
        })
    }
    const hideParent = async (id) => {
        document.querySelector('.table_workinghours_item[idrow="'+id+'"]').classList.toggle('hidden')
    }
    // const duplicateTask = async () => {
    //     let result = await fetch('/api/adminp/task/duplicateTask', {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json'
    //         },
    //         body: JSON.stringify({
    //             task_id
    //         })
    //     })
    // }
    const processHoursWasted = async () => {
        let elements = document.getElementById('modifyTime').querySelectorAll('.table_workinghours_item')
        let processed = {
            toModify: [],
            toDelete: []
        }
        elements.forEach((item) => {
            if(!item.classList.contains('noinclude')){
                if(item.classList.contains('hidden')){
                    processed.toDelete.push(item.getAttribute('idrow'))    
                }else{
                    processed.toModify.push({
                        id: item.getAttribute('idrow'),
                        baglog_startdate: item.querySelector('input[name="baglog_startdate"]').value,
                        wasted_time: item.querySelector('input[name="bag_remainingtime"]').value
                    })
                }
            }
        })
        var result = await fetch('/api/adminp/bag/masiveUpdate', {
            method: 'POST',
            headers: {
                'Content-Type' : 'application/json'
            },
            body: JSON.stringify({
                ...processed
            })
        })
        await result.json()
        emitTaskData(task_id)
        setModal(false)
    }
    const modifyTime = async () => {
        var data = await fetch('/api/adminp/bag/logsOfTask', {
            method: 'POST',
            headers: {
                'Content-Type' : 'application/json'
            },
            body: JSON.stringify({
                task_id,
                params: {
                    where: {
                        UserId: store.getState().user.id
                    }
                }
            })
        })
        data = await data.json()
        let it = 0
        let renderedTable = []
        if(data && data !== undefined && data.length){
            renderedTable = data.map((item, key) => {
                let inTask = formatToMilliseconds(...item.inverted_time.split(':'))
                it += inTask
                return (
                <div className='table_workinghours_item wrap fdiv50 nopadding' key={key} idrow={item.id} >
                    <div className='inner aicenter bxsh'>
                        <div className='start_date fdiv45'>
                                <input type='hidden' name='baglog_startdate' defaultValue={item.baglog_startdate} />
                                <Datetime defaultValue={(item.baglog_startdate) ? new Date(item.baglog_startdate) : ''} onChange={(date) => { document.querySelector('.table_workinghours_item[idrow="'+item.id+'"] input[name="baglog_startdate"]').value = date.format()} } viewDate={new Date()} closeOnSelect={true} inputProps={{ placeholder: translations.notAssigned, required: 'required' }} />
                        </div>
                        <div className='inverted_time fdiv45'>
                            <ReactTimeInput inputProps={{
                                name: 'bag_remainingtime',
                                required: 'required',
                            }} worksInMilliseconds={true} divKey={'bag_remainingtime'} styled={true} 
                            defaultValue={String(inTask)} 
                            />
                        </div>
                        <div className='fdiv10 nopadding tacenter'>
                            <i className='fal fa-trash-alt crpointer' onClick={() => hideParent(item.id)}></i>
                        </div>
                    </div>
                </div>
                )
            })
        }
        renderedTable.push(
            <div className='table_workinghours_item wrap fdiv50 nopadding noinclude' key={'add_workingitem'} >
                <div className='inner aicenter bxsh tacenter crpointer' onClick={workIn} >
                    <i className='far fa-plus-circle'></i><span>{translations.addHours}</span>
                </div>
            </div>
        )
        setModal({
            title: <div className="aicenter title_inner"><i className='fas fa-alarm-plus'></i> <span>{translations.wastedHours}</span></div>,
            id: 'modify_time',
            inner:
                <div className='formContainer' id='modifyTime' >
                <p>{translations.explanationWastedHours} <strong>{millisecondsToFormat(it)}h</strong></p>
                    <div className='table_workinghours wrap'>
                        {renderedTable}
                    </div>
                    <button id='submit' type='submit' className='notInclude' style={{ display: 'none' }}></button>
                </div>
            ,
            onAccept: processHoursWasted,
            notification: renderNotification(notification),
            onCancel: () => closeModal(setNotification, setModal)
        })
    }
    var commentsFormated = []
    if(data.taskComments){
        for(let k in data.taskComments){
            let comment = data.taskComments[k]
            var date = processData(comment.updatedAt)
            var imageExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
            commentsFormated.push(
                <div className='task_comment' commentid={comment.id} key={k}>
                    <div className='comment_header aicenter'>
                        <div className='task_comment_user aicenter'>
                            <img src={'/userImg/'+comment.user.image} className='user_img miniimg' alt=''/> 
                            <span>{comment.user.username}</span>
                        </div>
                        <div className='task_comment_date'>
                            {date}
                        </div>
                        <div className='task_comment_edit mlauto'>
                            {(comment.user.id === store.getState().user.id && comment.data_type === parseInt(1)) ? <i className='fal fa-pencil' onClick={(e) => transformToTextArea(e, comment.id)}></i> : ''}
                            {(comment.user.id === store.getState().user.id) ? <i className='fal fa-trash-alt' onClick={() => deleteComment(comment.id) }></i> : ''}
                        </div>
                    </div>
                    <div className='comment_inner'>
                        {(comment.data_type === parseInt(1)) ? comment.content : (
                            (imageExtensions.exec(comment.content) ? <a download href={'/taskImg/task_' + comment.TaskId + '/' + comment.content}><img src={'/taskImg/task_' + comment.TaskId + '/'+ comment.content} alt={comment.content} /></a> : <a download href={'/taskImg/task_' + comment.TaskId + '/'+ comment.content}><i className='fal fa-file-download'></i></a>)
                        )}
                    </div>
                </div>
            )
        }
    }
    var extraDateParms = {}
    if(!data.max_date){
        extraDateParms.value = '' 
    }
    var extraRememberDateParms = {}
    if(!data.remember_date){
        extraRememberDateParms.value = '' 
    }
    var childrenTasks = ''
    if(data.childrenTasks.length){
        var myChildrens = []
        for(let k in data.childrenTasks){
            let children = data.childrenTasks[k]
            myChildrens.push(<Subtask haveToFocus={haveToFocus} setHaveToFocus={setHaveToFocus} deleteTaskModal={deleteTaskModal} addSubTask={addSubTask} timeToReset={timeToReset} updateUser={updateUser} searchUsers={searchUsers} updateTeam={updateTeam} searchTeams={searchTeams} subtask={children} setCurrentTask={setCurrentTask} emitTaskData={emitTaskData} key={'sub_' + children.id} updateTitle={updateTitle} markAsFinish={markAsFinish} triggerEvDesc={triggerEvDesc}
            setTriggerEvDesc={setTriggerEvDesc} />)
        }
        childrenTasks = <div className='inputDiv fdiv100'><h6>{translations.subtasks}</h6><div className='sub_tasks'>{myChildrens}</div></div>
    }
    var logs = ''
    if(data.logs){
        logs = data.logs.map((item,key) => {
            return <div className='task_log' key={key}>{renderLogMessage(item, translations)}</div>
        })
    }
    var projects = ''
    if(data.projects){
        projects = data.projects.map((item, key) => {
            if (!item.from_parent) {
                if (item.customer.customer_responsable_phone) {
                    item.customer.customer_responsable_phone = item?.customer.customer_responsable_phone?.replace(/\s/g, '')
                    item.customer.customer_responsable_phone = item?.customer.customer_responsable_phone?.match(/.{1,3}/g)?.join(' ')
                }
                return <div data-tip data-for={'task_' + task_id + '_customer_' + key} className='task_project' key={key} style={{ background: '#' + item.project_color }} ><Link to={'/customer/' + item.customer.id + '/projects/' + item.id}>{item.customer.customer_name} &gt; {item.project_name}</Link><i className='fal fa-times' onClick={() => updateProject(item.id, true)}></i>
                <ReactTooltip id={'task_' + task_id + '_customer_' + key}>
                        <div className='mb5'><i className='w15 fal fa-phone-alt'></i><span>{item.customer.customer_responsable_phone ? item.customer.customer_responsable_phone : ''}</span></div>
                        <div className='mb5'><i className='w15 fal fa-envelope'></i><span>{item.customer.customer_responsable_mail ? item.customer.customer_responsable_mail : ''}</span></div>               
                </ReactTooltip>
                </div>
            }
            return ''
        })
    }
    var customFields = ''
    if(store.getState().customfields){
        customFields = store.getState().customfields.map((item, key)=> {
            let curr = data.customFields.filter((currcf) => currcf.CustomFieldId === item.id)
            if(curr.length){
                curr = curr[0].CustomFieldValId
            }
            if(item.customfield_active){
                return <CustomFieldPicker selectedVal={curr} fieldName={item.customfield_name} className='fdiv33' options={item.childrens} key={key} onSelect={(customfieldval_id) => assignCustomFieldToTask(data.id, item.id, customfieldval_id, data.TaskId)} />
            }
            return ''
        })
    }
    if (data.Shop && Object.keys(data.Shop).length) {
        if (data.Shop.shop_phone) {
            data.Shop.shop_phone = data.Shop?.shop_phone?.replace(/\s/g, '')
            data.Shop.shop_phone = data.Shop?.shop_phone?.match(/.{1,3}/g)?.join(' ')    
        }
        if (data.Shop.shop_mobile) {
            data.Shop.shop_mobile = data.Shop.shop_mobile?.replace(/\s/g, '')
            data.Shop.shop_mobile = data.Shop.shop_mobile?.match(/.{1,3}/g)?.join(' ')
        }
    }
    if (data.ContactPersons && Object.keys(data.ContactPersons).length) {
        if(data.ContactPersons.contactp_phone){
            data.ContactPersons.contactp_phone = data.ContactPersons.contactp_phone?.replace(/\s/g, '')
            data.ContactPersons.contactp_phone = data.ContactPersons.contactp_phone?.match(/.{1,3}/g)?.join(' ')
        }
        if(data.ContactPersons.contactp_mobile){
            data.ContactPersons.contactp_mobile = data.ContactPersons.contactp_mobile.replace(/\s/g, '')
            data.ContactPersons.contactp_mobile = data.ContactPersons.contactp_mobile?.match(/.{1,3}/g)?.join(' ')
        }
    }
    let descriptionProps = {}
    if(isFocused){
        descriptionProps = {
            defaultValue: taskDescription
        }
    }else{
        descriptionProps = {
            value: taskDescription,
            onFocus: () => setIsFocused(true)
        }
    }
    return (
        <div className='box_inner'>
            <div className='task_header box_header'>
                <div className='aicenter'>
                    <button className={'btn_standard header_task_button finish_task' + ((data.completed) ? ' active' : '')} onClick={() => markAsFinish()} ><i className='fal fa-check'></i> {(data.completed) ? translations.finished : translations.markAsFinished}</button>
                    <div className='task_actions mlauto aicenter' >
                        <div className='task_action work_in' onClick={workIn} data-tip data-for='dt_workin'>
                            <i className='fal fa-alarm-plus'></i>
                        </div>
                        <div className='task_action add_file_with_ico' data-tip data-for='dt_addfile'>
                            <form onSubmit={uploadTaskFile}>
                                <input type='file' className='hidden' id='uploadFile' pathtosave={'/public/taskImg/task_'+data.id+'/'} onChange={() => document.getElementById('add_file_form').click()} />
                                <label htmlFor='uploadFile' className='crpointer'>
                                    <i className='fal fa-paperclip'></i>
                                </label>
                                <button type='submit' id='add_file_form' className='hidden'></button>
                            </form>
                        </div>
                        <IconDisplay inner={
                            <div className='aicenter'>
                                <Datetime value={(data.remember_date) ? new Date(data.remember_date) : ''} viewDate={new Date()} onChange={updateRememberDate} closeOnSelect={true} inputProps={{ placeholder: translations.notAssigned, ...extraRememberDateParms }} />
                                {(!data.remember_date) ? '' : <i className='fal fa-times close' onClick={() => updateRememberDate()} ></i>}
                            </div>
                        } icon='fal fa-calendar-alt' parentProps={{ className: 'task_action delay_task', 'data-tip': 'data-tip', 'data-for':'dt_calendar'}}/>
                        {(data.TaskId) ? 
                            <div className='task_action transform_parent' onClick={() => assignToTask(data.id, data.TaskId, true)} data-tip data-for='dt_toparent'>
                            <i className='fal fa-sort-size-up-alt'></i>
                        </div> : ''}
                        <IconDisplay inner={
                            <TaskFinder onchoose={(parent_id) => {assignToTask(data.id, parent_id); document.querySelector('.transform_subtask i').click()}} />
                        } icon='fal fa-sort-size-down' parentProps={{ className: 'task_action transform_subtask','data-tip': 'data-tip', 'data-for': 'dt-tosubtask' }} />
                        <div className='task_action add_subtask' onClick={addSubTask} data-tip data-for='dt_addsubtask'>
                            <i className='fal fa-layer-plus'></i>
                        </div>
                        {/* <div className='task_action duplicate_task' data-tip data-for='dt_duplicatetask' onClick={duplicateTask}>
                            <i className='fal fa-copy'></i>
                        </div> */}
                        <div className='task_action delete' onClick={() => deleteTaskModal(data.id, data.TaskId)} data-tip data-for='dt_deletetask'>
                            <i className='fal fa-trash-alt'></i>
                        </div>
                        <div className='task_action close' onClick={resetTask} data-tip data-for='dt_close'>
                            <i className='fal fa-times close'></i>
                        </div>
                        <ReactTooltip id='dt_workin'>
                            {translations.addTime}
                        </ReactTooltip>
                        <ReactTooltip id='dt_addfile'>
                            {translations.addFile}
                        </ReactTooltip>
                        <ReactTooltip id='dt_calendar'>
                            {translations.hideUntil}
                        </ReactTooltip>
                        <ReactTooltip id='dt_toparent'>
                            {translations.toParent}
                        </ReactTooltip>
                        <ReactTooltip id='dt-tosubtask'>
                            {translations.toSubtask}
                        </ReactTooltip>
                        <ReactTooltip id='dt_addsubtask'>
                            {translations.addSubtask}
                        </ReactTooltip>
                        {/* <ReactTooltip id='dt_duplicatetask'>
                            {translations.duplicateTask}
                        </ReactTooltip> */}
                        <ReactTooltip id='dt_deletetask'>
                            {translations.deleteThisTask}
                        </ReactTooltip>
                        <ReactTooltip id='dt_close'>
                            {translations.close}
                        </ReactTooltip>
                    </div>
                </div>
                {(data.TaskId) ? <button className='btn_standard  back_parent' onClick={() => setCurrentTask(data.TaskId)} ><i className='fal fa-chevron-double-left'></i><span>{data.parent.task_name}</span></button>: ''}
            </div>
            <div className='task_content box_content wrap formContainer'>
                <div className='inputDiv fdiv100 task_name' key={'task_name'+data.id}>
                    <div className='aicenter'>
                        <span className='idtask'>#{data.id}</span><TextareaAutosize type='text' name='task_name' className='nobg noborders' required value={(taskName) ? taskName : ''} onChange={(e) => {
                            let myval = e.currentTarget.value
                            let myTriggerEvDesc = window.setTimeout(() => {
                                updateTitle(false, data.id, data.task_name, myval)
                            }, 3000)
                            clearTimeout(triggerEvDesc)
                            setTriggerEvDesc(myTriggerEvDesc)
                            setTaskName(e.target.value)
                        }} placeholder={translations.taskName} onBlur={updateTitle}/>
                    </div>
                </div>
                <div className='inputDiv fdiv33'>
                    <h6>{translations.user}</h6>
                    <PhotoInputSearcher timeToReset={timeToReset} cleanInput={() => updateUser(0)} fieldtoshow='username' pathImg='/userImg/' updateData={updateUser} getDataFrom={searchUsers} data={{image: (data.user && Object.keys(data.user).length) ? '/userImg/'+data.user.image : undefined, text: (data.user && Object.keys(data.user).length) ? data.user.username : undefined }} icon='fal fa-user' />
                </div>
                <div className='inputDiv fdiv33'>
                    <h6>{translations.team}</h6>
                    <PhotoInputSearcher timeToReset={timeToReset} cleanInput={() => updateTeam(0)} fieldtoshow='name' pathImg='/teamImg/' updateData={updateTeam} getDataFrom={searchTeams} data={{ image: (data.team && Object.keys(data.team).length) ? '/teamImg/' + data.team.image : undefined, text: (data.team && Object.keys(data.team).length) ? data.team.name : undefined }} icon='fal fa-users' />
                </div>
                <div className='inputDiv fdiv33'>
                    <h6>{translations.maxDate}</h6>
                    <div className='photoinput aicenter'>
                        <div className='photoinput_logo aicenter tacenter'>
                            <i className='fal fa-calendar-alt'></i>
                        </div>
                        <div className='photoinput_text aicenter tacenter'>
                            <Datetime value={(mydate) ? new Date(mydate) : ''} viewDate={new Date()} onChange={updateDate} closeOnSelect={true} inputProps={{ placeholder: translations.notAssigned, ...extraDateParms }} onBlur={updateDateFetch}/>                           
                            {(!mydate) ? '' : <i className='fal fa-times close' onClick={() => updateDate()}></i>}
                        </div>
                    </div>
                </div>
                <div className='inputDiv fdiv33'>
                    <h6>{translations.wastedHours}</h6>
                    <div className='photoinput aicenter'>
                        <div className='photoinput_logo aicenter tacenter'>
                            <i className='fal fa-clock'></i>
                        </div>
                        <div className='photoinput_text aicenter tacenter'>
                            {working && parseInt(working.task_id) === parseInt(task_id) ? 
                            <input type='text' readOnly value={translations.succWorkIn} />
                            :
                            <div className='aicenter'><input type='text' readOnly value={data.inverted_time}/><i className='fal fa-sliders-v close' onClick={modifyTime}></i></div>
                            }
                        </div>
                    </div>
                </div>
                <div className='inputDiv fdiv33'>
                    <h6>{translations.shop}</h6>
                    {data.projects.length
                        ? (
                            <PhotoInputSearcher timeToReset={timeToReset} cleanInput={() => updateShop(0)} fieldtoshow='shop_name' updateData={updateShop} getDataFrom={searchShops} data={{ text: (data.Shop && Object.keys(data.Shop).length) ? data.Shop.shop_name : undefined }} icon='fal fa-store-alt' justText={true} extraParams={{'data-tip': 'data-tip', 'data-for': 'shop_task_helper'}} />
                        )
                        : (<div className='photoinput aicenter'>
                            <div className='photoinput_logo aicenter tacenter'>
                                <i className='fal fa-store-alt'></i>
                            </div>
                            <div className='photoinput_text aicenter tacenter'>
                                <div className='aicenter'>
                                    <input type='text' readOnly value={translations.addProject}/>
                                </div>
                            </div>
                        </div>)
                    }
                    {data.Shop && Object.keys(data.Shop).length ? <ReactTooltip id='shop_task_helper'>
                        <h6>{data.Shop.shop_name}</h6>
                        <div className='mb5'><i className='w15 fal fa-home'></i><span>{data.Shop.shop_address}</span></div>
                        <div className='mb5'><i className='w15 fal fa-phone-alt'></i><span>{data.Shop.shop_phone}</span></div>
                        <div className='mb5'><i className='w15 fal fa-mobile'></i><span>{data.Shop.shop_mobile}</span></div>
                        <div className='mb5'><i className='w15 fal fa-envelope'></i><span>{data.Shop.shop_mail}</span></div>
                        <div className='mb5'><i className='w15 fal fa-user'></i><span>{data.Shop.shop_responsable}</span></div>
                    </ReactTooltip> : ''}
                </div>
                <div className='inputDiv fdiv33'>
                    <h6>{translations.contactPerson}</h6>
                    {data.projects.length
                        ? (
                            <PhotoInputSearcher timeToReset={timeToReset} cleanInput={() => updateContact(0)} fieldtoshow='contactp_name' updateData={updateContact} getDataFrom={searchContacts} data={{ text: (data.ContactPersons && Object.keys(data.ContactPersons).length) ? data.ContactPersons.contactp_name : undefined }} icon='fal fa-id-badge' justText={true} extraParams={{ 'data-tip': 'data-tip', 'data-for': 'contact_task_helper'}}/>
                        )
                        : (<div className='photoinput aicenter'>
                            <div className='photoinput_logo aicenter tacenter'>
                                <i className='fal fa-id-badge'></i>
                            </div>
                            <div className='photoinput_text aicenter tacenter'>
                                <div className='aicenter'>
                                    <input type='text' readOnly value={translations.addProject} />
                                </div>
                            </div>
                        </div>)
                    }
                    {data.ContactPersons && Object.keys(data.ContactPersons).length ? <ReactTooltip id='contact_task_helper'>
                        <h6>{data.ContactPersons.contactp_name}</h6>
                        <div className='mb5'><i className='w15 fal fa-phone-alt'></i><span>{data.ContactPersons.contactp_phone}</span></div>
                        <div className='mb5'><i className='w15 fal fa-mobile'></i><span>{data.ContactPersons.contactp_mobile}</span></div>
                        <div className='mb5'><i className='w15 fal fa-envelope'></i><span>{data.ContactPersons.contactp_mail}</span></div>
                    </ReactTooltip> : ''}
                </div>
                <div className='inputDiv fdiv100'>
                    <div className='aibaseline'>
                        <h6>{translations.projects}</h6>
                        <IconDisplay inner={<ProjectSearcher onchoose={updateProject} currentProjects={data.projects} />} button={<div className='addProject_task_inner ml10'><i className='fal fa-plus-circle'></i></div>} parentProps={{className: 'addProject_task'}}/>
                    </div>
                    <div className='task_projects aicenter'>
                        {projects}
                    </div>
            
                </div>
                <div className='inputDiv fdiv100 nopadding wrap'>
                    {customFields}
                </div>
                <div className='inputDiv fdiv100'>
                    <h6>{translations.description}</h6>
                    <textarea name='task_description' className='text-description' placeholder={translations.inDescription} onBlur={updateDescription} {...descriptionProps} onChange={(e) => {
                        let myval = e.currentTarget.value
                        let myTriggerEvDesc = window.setTimeout(() => {
                            updateDescription(false, data.description, myval)
                        }, 3000)
                        clearTimeout(triggerEvDesc)
                        setTriggerEvDesc(myTriggerEvDesc)
                        setTaskDescription(e.target.value)
                    }}></textarea>
                </div>
                {childrenTasks}
                {commentsFormated.length ? <div className='inputDiv fdiv100'>
                    {commentsFormated}
                </div> : ''}
                <div className='inputDiv fdiv100'>
                    <form method='post' action='/api/adminp/task/createTaskComment' onSubmit={formatComment}>
                        <h6>{translations.newComment}</h6>
                        <input type='hidden' readOnly name='task_id' value={task_id}/>
                        <input type='hidden' readOnly name='user_id' value={store.getState().user.id}/>
                        <input type='hidden' readOnly name='data_type' value='1'/>
                        <textarea name='content' placeholder={translations.inComment} required></textarea>
                        <button type='submit' className='btn_primary add_comment'>{translations.addComment}</button>
                    </form>
                </div>
                <div className='fdiv100 task_logs'>
                    {logs}
                </div>
            </div>
        </div>
    )
}
export const TaskEdit = connect(mapStateToProps)(MyTaskEdit)
const TaskList = ({extraParams, setCurrentTask, currentTask, emitTaskData, emitOldUser, emitOldTeam, deleteTaskModal, updateHeight, setUpdateHeight}) => {
    var translations = store.getState().language.translations
    const [data, setData] = useState(false)
    const [myParams, setMyParams] = useState(false)
    const [onlyActive, setOnlyActive] = useState(window.localStorage.getItem('be_active') ?parseInt( window.localStorage.getItem('be_active') ): 0)
    const [onlyVisible, setOnlyVisible] = useState(window.localStorage.getItem('be_visible') ? parseInt(window.localStorage.getItem('be_visible') ): 1)
    const [onlyAssigned, setOnlyAssigned] = useState(window.localStorage.getItem('be_assigned') && !extraParams.user_id ? parseInt(window.localStorage.getItem('be_assigned')) : 1)
    const [orderBy, setOrderBy] = useState({})
    const [reset, setReset] = useState(false)
    const [timeToReset, setTimeToReset] = useState(false)
    const [haveToFocus, setHaveToFocus] = useState(false)
    const [haveUpdated, setHaveUpdated] = useState(false)
    const [offset, setOffset] = useState(0)
    const [dataToAppend, setDataToAppend] = useState(false)
    const mylimit = 40
    const [page, setPage] = useState(0)
    const [ids, setIds] = useState([])
    const [triggerEvDesc, setTriggerEvDesc] = useState(false)
    const [searchbar, setSearchbar] = useState({
        fields: [
            {
                name: 'task_name',
                text: translations.title
            },
            {
                name: '$Projects.Customer.customer_name$',
                text: translations.customer
            },
            {
                name: '$User.username$',
                text: translations.username
            }
        ],
        searchBy: 'all',
        searchQuery: ''
    })
    const scrollTop = () => {
        if (document.querySelector('.task_list .box_content .tasklist_list')) {
            document.querySelector('.task_list .box_content .tasklist_list').scrollTop = 0   
        }
    }
    if (store.getState().customorder.length && !Object.keys(orderBy).length && !haveUpdated) {
        let customorderformated = {}
        store.getState().customorder.forEach((item) => {
            customorderformated[item.customorder_orderfor] = item.customorder_order
        })
        setHaveUpdated(true)
        window.setTimeout(() => {
            scrollTop()
            setOffset(0)
            setIds([])
            setPage(0)
            setOrderBy(customorderformated)
            setReset(true)
        }, 500)
    }
    const listenEnter = (key) => {
        let current = document.querySelector('.tasklist_item.active')
        if (current) {
            let pos = current.getAttribute('pos')
            pos = parseInt(pos)
            let modal = document.querySelector('.modal')
            if(key.keyCode === 40 && !modal){
                let target = document.querySelector('.tasklist_item[pos="'+(pos+1)+'"]')
                let havetofocusinput = document.querySelector('.task_input:focus')
                if(target){
                    if(havetofocusinput){
                        target.querySelector('.task_input').focus()
                    }
                    setCurrentTask(parseInt(target.getAttribute('task_id')))
                }
            }else if(key.keyCode === 38 && pos > 0 && !modal){
                let target = document.querySelector('.tasklist_item[pos="'+(pos-1)+'"]')
                let havetofocusinput = document.querySelector('.task_input:focus')
                if(target){
                    if(havetofocusinput){
                        target.querySelector('.task_input').focus()
                    }
                    setCurrentTask(parseInt(target.getAttribute('task_id')))
                }
            }
        }
    }
    const scrollEv = () => {
        let c = document.querySelector('.task_list .box_content .tasklist_list')
        let loadIn = (c.scrollHeight - c.offsetHeight) - 600
        let actual = c.scrollTop
        if (actual > loadIn) {
            c.removeEventListener('scroll', myScrollEv)
            let pg = parseInt(document.getElementById('page') ? document.getElementById('page').innerText : 0)
            let ofs = parseInt(document.getElementById('offset') ? document.getElementById('offset').innerText : 0)
            let myids = document.getElementById('myids') ? document.getElementById('myids').innerText.split(',') : []
            let assigned_selector = document.getElementById('assigned_selector') ? parseInt(document.getElementById('assigned_selector').value) : 1
            let changeVisible_selector = document.getElementById('changeVisible_selector') ? parseInt(document.getElementById('changeVisible_selector').value) : 0
            let finished_selector = document.getElementById('finished_selector') ? parseInt(document.getElementById('finished_selector').value) : 0
            let extraData = document.getElementById('extraParams') ? JSON.parse(document.getElementById('extraParams').innerText) : false
            let myorderBy = document.getElementById('orderBy') ? JSON.parse(document.getElementById('orderBy').innerText) : false
            let mysearchBy = document.getElementById('searchBy') ? JSON.parse(document.getElementById('searchBy').innerText) : false
            getData(true, ofs, pg, myids, myorderBy, mysearchBy, assigned_selector, changeVisible_selector,finished_selector, extraData )
        }
    }
    const [myScrollEv] = useState(() => scrollEv)
    const [eventEnter] = useState(() => listenEnter)
    const listenEventEnter = (remove = false) => {
        if (remove) {
            window.removeEventListener('keydown', eventEnter);
        } else {
            window.addEventListener('keydown', eventEnter)
        }
    }
    useEffect(() => {
        if (timeToReset === 1) {
            setTimeToReset(2)
        }
        if (timeToReset === 2) {
            setTimeToReset(false)
        }
        if (dataToAppend) {
            setDataToAppend(false)
            setData([...data, ...dataToAppend])
        }
        let c = document.querySelector('.task_list .box_content .tasklist_list')
        if (c) {
            c.addEventListener('scroll', myScrollEv)
        }
        return () => {
            let c = document.querySelector('.task_list .box_content .tasklist_list')
            if (c) {
                c.removeEventListener('scroll', myScrollEv)
            }
            window.removeEventListener('keydown', eventEnter);
        }
    }, [timeToReset, eventEnter, page, offset, mylimit, myScrollEv, dataToAppend, data])
    useEffect(() => {
        setHaveUpdated(false)
        setOrderBy({})
    }, [extraParams.team_id, extraParams.user_id, extraParams.customer_id])
    const updateUser = async (user_id = 0, olduser, task_id) => {
        await fetch('/api/adminp/task/assignTaskToUser', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                who_updates: store.getState().user.id,
                user_id,
                task_id
            })
        })
        emitOldUser(olduser, task_id)
        emitTaskData(task_id)
    }
    const updateTeam = async (team_id = 0, oldteam, task_id) => {
        await fetch('/api/adminp/task/assignTaskToTeam', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                who_updates: store.getState().user.id,
                team_id,
                task_id
            })
        })
        emitOldTeam(oldteam, task_id)
        emitTaskData(task_id)
    }
    const changeActive = async (val) => {
        if(val){
            val = parseInt(val)
        }
        window.localStorage.setItem('be_active', val)
        scrollTop()
        setOnlyActive(val)
        setOffset(0)
        setIds([])
        setPage(0)
        setReset(true)
    }
    const changeVisible = async (val) => {
        if(val){
            val = parseInt(val)
        }
        window.localStorage.setItem('be_visible', val)
        scrollTop()
        setOnlyVisible(val)
        setOffset(0)
        setIds([])
        setPage(0)
        setReset(true)
    }
    const changeAssigned = (val) => {
        if (val) {
            val = parseInt(val)
        }
        window.localStorage.setItem('be_assigned', val)
        scrollTop()
        setOnlyAssigned(val)
        setOffset(0)
        setIds([])
        setPage(0)
        setReset(true)
    }
    const updateOrderBy = (order) => {
        let tmpOrderBy = orderBy
        if (tmpOrderBy[order] === undefined) {
            tmpOrderBy[order] = 'ASC'
        } else if (tmpOrderBy[order] === 'ASC') {
            tmpOrderBy[order] = 'DESC'
        } else {
            delete tmpOrderBy[order]
        }
        scrollTop()
        setOrderBy(tmpOrderBy)
        setOffset(0)
        setIds([])
        setPage(0)
        setReset(true)
    }
    const createTask = async () => {
        var result = await fetch('/api/adminp/task/createTask', {
            method: 'POST',
            body: JSON.stringify({
                who_creates: store.getState().user.id,
                params: { task_name: '' },
                ...extraParams,
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        result = await result.json()
        setCurrentTask(parseInt(result))
        setHaveToFocus(parseInt(result))
        emitTaskData(parseInt(result))
    }
    const getDataFrom = () => {
        if(extraParams.team_id){
            return '/api/adminp/task/getTaskByTeam'
        }else if(extraParams.user_id){
            return '/api/adminp/task/getTaskByUser'
        }else if(extraParams.project_id){
            return '/api/adminp/task/getTaskByProject'
        } else {
            return '/api/adminp/task/findAllTasksDetailed'
        }
    }
    const getData = async (rs = false, ofs = 0, pg = 0, myids = [], myOrderBy = {}, mySearchBar = {}, assigned_selector = undefined, changeVisible_selector = undefined, finished_selector = undefined, myextraParams = extraParams) => {
        if (!rs) {
            ofs = offset
            pg = page
            assigned_selector = onlyAssigned
            changeVisible_selector = onlyVisible
            finished_selector = onlyActive
            myids = ids
            myOrderBy = orderBy
            mySearchBar = searchbar
        } else if (assigned_selector === undefined) {
            assigned_selector = onlyAssigned
            changeVisible_selector = onlyVisible
            finished_selector = onlyActive
        }
        let body = {...myextraParams, params : {}}
        var myorder = []
        if (mySearchBar.searchQuery) {
            var search = {}
            if (mySearchBar.searchBy === 'all') {
                search['$or'] = []
                mySearchBar.fields.map((item) => {
                    var citem = {}
                    citem[item.name] = { $iLike: '%' + mySearchBar.searchQuery + '%' }
                    search['$or'].push(citem)
                    return true;
                })
                body.params.where = search
            } else {
                search[mySearchBar.searchBy] = { $iLike: '%' + mySearchBar.searchQuery + '%' }
                body.params.where = search
            }
        }  
        if (changeVisible_selector !== 2) {
            if (body.params.where === undefined) {
                body.params.where = {}
            }
            if(body.params.where['$and'] === undefined){
                body.params.where['$and'] = {}
            }
            if(changeVisible_selector){
                let tmpor = {}
                tmpor['$or'] = []
                tmpor['$or'].push({remember_date: null})
                tmpor['$or'].push({remember_date: {$lte: new Date().toUTCString()}})
                body.params.where['$and'] = {...body.params.where['$and'],  ...tmpor }
            }else{
                body.params.where['$and'] = {remember_date: {$gt: new Date().toUTCString()}}
            }
        } else {
            if (body.params.order === undefined) {
                body.params.order = []
            }
            body.params.order.push(['remember_date', 'ASC'])
            myorder.push(['remember_date', 'ASC'])
        }
        if (finished_selector !== 2) {
            if(body.params.where === undefined){
                body.params.where = {}
            }
            if (body.params.where['$and'] === undefined) {
                body.params.where['$and'] = {}
            }
            body.params.where['$and'] = { ...body.params.where['$and'], completed: !!finished_selector }
        }else{
            if (body.params.order === undefined) {
                body.params.order = []
            }
            body.params.order.push(['completed', 'ASC'])
            myorder.push(['completed', 'ASC'])
        }
        if (assigned_selector !== undefined && assigned_selector === 0) {
            if (body.params.where === undefined) {
                body.params.where = {}
            }
            if (body.params.where['$and'] === undefined) {
                body.params.where['$and'] = {}
            }
            body.params.where['$and'] = { ...body.params.where['$and'], UserId: null }
        } else if (assigned_selector !== undefined && assigned_selector === 2) {
            if (body.params.where === undefined) {
                body.params.where = {}
            }
            if (body.params.where['$and'] === undefined) {
                body.params.where['$and'] = {}
            }
            body.params.where['$and'] = { ...body.params.where['$and'], UserId: { '$or': [null, store.getState().user.id] } }
        }
        if (myids.length) {
            if (body.params.where === undefined) {
                body.params.where = {}
            }
            if (body.params.where['$and'] === undefined) {
                body.params.where['$and'] = {}
            }
            body.params.where['$and'] = { ...body.params.where['$and'], id : {$not: myids } }
        }
        if(myOrderBy){
            if (body.params.order === undefined){
                body.params.order = []
            }
            for (var k in myOrderBy) {
                var order = myOrderBy[k]
                if(k.includes('task_cf_')){
                    let CustomFieldId = parseInt((k.split('task_cf_')[1]))
                    k = 'custom_fields.' + CustomFieldId + '.CustomFieldVal'
                    k += '.customfieldval_val'
                    let cfvalarr = []
                    if (store.getState().customfields) {
                        let mycf = store.getState().customfields.filter((item) => item.id === CustomFieldId)[0]
                        for(let k of mycf.childrens){
                            cfvalarr.push(k.customfieldval_val)
                        }    
                    }
                    body.params.order.push({
                        CustomFieldId,
                        order,
                        cfvalarr
                    })
                } else {
                    body.params.order.push([k, order])
                }
                myorder.push([k, order])
            }
        }
        if (orderBy['id'] === undefined) {
            body.params.order.push(['id', 'ASC'])
        }
        body.params.limit = mylimit
        // body.params.offset = ofs
        let result = await fetch(getDataFrom(), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })
        result = await result.json()
        // if (result && customfield_filter) {
        //     result = await specialOrderBy(myorder, result)
        // }
        if (pg === 0) {
            setData(result.data)
        } else if (result.data.length) {
            if (data) {
                setData(...data, ...result.data)   
            } else {
                setDataToAppend(result.data)
            }
        }
        if (result.data.length) {
            if (pg === 0) {
                result.offset += 1
            }
            setOffset(result.offset)
            setPage(pg + 1)
            setIds([...myids, ...result.myids])
        }
    }
    const updateThisTask = async (task_id, mydata, myids) => {
        let body = {
            ...extraParams,
            params: {
                where: {
                    id: task_id
                }
            }
        }
        var myorder = []
        if (searchbar.searchQuery) {
            var search = {}
            if (searchbar.searchBy === 'all') {
                search['$or'] = []
                searchbar.fields.map((item) => {
                    var citem = {}
                    citem[item.name] = { $iLike: '%' + searchbar.searchQuery + '%' }
                    search['$or'].push(citem)
                    return true;
                })
                body.params.where = { ...body.params.where, ...search }
            } else {
                search[searchbar.searchBy] = { $iLike: '%' + searchbar.searchQuery + '%' }
                body.params.where = { ...body.params.where, ...search }
            }
        }
        if (onlyVisible !== undefined) {
            if (body.params.where === undefined) {
                body.params.where = {}
            }
            if (body.params.where['$and'] === undefined) {
                body.params.where['$and'] = {}
            }
            if (onlyVisible) {
                let tmpor = {}
                tmpor['$or'] = []
                tmpor['$or'].push({ remember_date: null })
                tmpor['$or'].push({ remember_date: { $lte: new Date().toUTCString() } })
                body.params.where['$and'] = { ...body.params.where['$and'], ...tmpor }
            } else {
                body.params.where['$and'] = { remember_date: { $gt: new Date().toUTCString() } }
            }
        } else {
            if (body.params.order === undefined) {
                body.params.order = []
            }
            body.params.order.push(['remember_date', 'ASC'])
            myorder.push(['remember_date', 'ASC'])
        }
        if (onlyActive !== undefined) {
            if (body.params.where === undefined) {
                body.params.where = {}
            }
            if (body.params.where['$and'] === undefined) {
                body.params.where['$and'] = {}
            }
            body.params.where['$and'] = { ...body.params.where['$and'], completed: !!onlyActive }
        } else {
            if (body.params.order === undefined) {
                body.params.order = []
            }
            body.params.order.push(['completed', 'ASC'])
            myorder.push(['completed', 'ASC'])
        }
        if (onlyAssigned !== undefined && onlyAssigned === 0) {
            if (body.params.where === undefined) {
                body.params.where = {}
            }
            if (body.params.where['$and'] === undefined) {
                body.params.where['$and'] = {}
            }
            body.params.where['$and'] = { ...body.params.where['$and'], UserId: null }
        } else if (onlyAssigned !== undefined && onlyAssigned === 2) {
            if (body.params.where === undefined) {
                body.params.where = {}
            }
            if (body.params.where['$and'] === undefined) {
                body.params.where['$and'] = {}
            }
            body.params.where['$and'] = { ...body.params.where['$and'], UserId: { '$or': [null, store.getState().user.id] } }
        }
        if (orderBy) {
            if (body.params.order === undefined) {
                body.params.order = []
            }
            for (var k in orderBy) {
                var order = orderBy[k]
                if (k.includes('task_cf_')) {
                    k = 'custom_fields.' + (k.split('task_cf_')[1]) + '.CustomFieldVal'
                    k += '.customfieldval_val'
                } else {
                    body.params.order.push([k, order])
                }
                myorder.push([k, order])
            }
        }
        myorder.push(['id', 'ASC'])
        body.params.order.push(['id', 'ASC'])
        let res = await fetch(getDataFrom(), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })
        res = await res.json()
        res = res.data
        if (res.length) {
            res = res[0]
            let found = false
            mydata.forEach((item, key) => {
                if (parseInt(item.id) === parseInt(task_id)) {
                    mydata[key] = res
                    found = true
                }
            })
            if (!found) {
                mydata.push(res)
                setIds([...myids, task_id])
            }
        } else {
            let found = false
            mydata.forEach((item, key) => {
                if (parseInt(item.id) === parseInt(task_id)) {
                    found = key
                }
            })
            if (found !== false) {
                mydata.splice(found, 1)
            }
        }
        mydata = await specialOrderBy(myorder, mydata)
        setData(mydata)
        setTimeToReset(1)
        setUpdateHeight(true)
    }
    const updateSocketListData = (task_id) => {
        setTimeToReset(1)
        setReset({ task_id })
    }
    let mytmp = {}
    let mytmp2 = {}
    mytmp = myParams
    mytmp2 = extraParams
    if (mytmp.task_id) {
        delete mytmp.task_id
    }
    if (mytmp2.task_id) {
        delete mytmp2.task_id
    }
    if (!equals(JSON.stringify(mytmp), JSON.stringify(mytmp2))) {
        if (myParams.team_id) {
            socket.emit('leave', 'team_' + myParams.team_id)
        } else if (myParams.user_id) {
            socket.emit('leave', 'user_' + myParams.user_id)
        } else if (myParams.project_id) {
            if(Array.isArray(myParams.project_id)){
                for(let k in myParams.project_id){
                    socket.emit('leave', 'project_' + myParams.project_id[k])
                }
            }else{
                socket.emit('leave', 'project_' + myParams.project_id)
            }
        }
        socket.off('update_groupdata')
        socket.off('update_groupdata_globaltasks')
        if (extraParams.team_id) {
            socket.emit('create', 'team_' + extraParams.team_id)
        } else if (extraParams.user_id) {
            socket.emit('create', 'user_' + extraParams.user_id)
        } else if (extraParams.project_id) {
            if (Array.isArray(extraParams.project_id)) {
                for (let k in extraParams.project_id) {
                    socket.emit('create', 'project_' + extraParams.project_id[k])
                }
            } else {
                socket.emit('create', 'project_' + extraParams.project_id)
            }
        } else {
            socket.on('update_groupdata_globaltasks', updateSocketListData)
        }
        socket.on('update_groupdata', updateSocketListData)
        setMyParams(extraParams)
        getData(true)
    }
    const updateTitle = async (e, task_id, currentTaskName, forceVal = false) => {
        clearTimeout(triggerEvDesc)
        var task_name = forceVal
        if (!forceVal) {
            task_name = e.target.value
        }
        if(task_name !== currentTaskName){
            await fetch('/api/adminp/task/updateTask', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    id: task_id,
                    task_name
                })
            })
            emitTaskData(task_id)
        }
    }
    const markAsFinish = (task_id, completed) => {
        fetch('/api/adminp/task/updateTask', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                who_updates: store.getState().user.id,
                id: task_id,
                completed: !completed
            })
        })
        emitTaskData(task_id)
    }
    const updateSearchQuery = (info) => {
        scrollTop()
        setOffset(0)
        setIds([])
        setPage(0)
        setSearchbar({ ...info })
        getData(true, 0, 0, [], orderBy, {...info})
    }
    const searchUsers = async (value) => {
        var results = await searchUser(value, store.getState().user.id)
        return results
    }
    const searchTeams = async (value) => {
        var results = await searchTeam(value)
        return results
    }
    if (reset) {
        if (reset['task_id'] !== undefined) {
            updateThisTask(reset.task_id.task_id, data, ids)
            setReset(false)
        } else {
            setReset(false)
            getData()
        }
    }
    var dataRendered = []
    var activeishere = false
    if(data){
        if(onlyActive === undefined || onlyVisible === undefined){
            var status = 0 
        }
        for(let key in data){
            let item = data[key]
            if(onlyVisible !== undefined){
                if(onlyActive === undefined && status === 0 && !item.completed){
                    dataRendered.push(<div className='task_separator' key={'status'+status} ><h4>{translations.notFinisheds}</h4></div>)
                    status = 1
                }else if(onlyActive === undefined && status === 1 && item.completed){
                    dataRendered.push(<div className='task_separator' key={'status' + status} ><h4>{translations.finisheds}</h4></div>)
                    status = 2
                }
            }else{
                if (status === 0 && (!item.remember_date || new Date(item.remember_date) <= new Date())) {
                    dataRendered.push(<div className='task_separator' key={'status'+status} ><h4>{translations.showed}</h4></div>)
                    status = 1
                } else if (status === 1 && (new Date(item.remember_date) > new Date())) {
                    dataRendered.push(<div className='task_separator' key={'status'+status} ><h4>{translations.hidden}</h4></div>)
                    status = 2
                }
            }
            if(item && item.id === currentTask){
                activeishere = key
            }
            dataRendered.push(<TaskListItem emitTaskData={emitTaskData} pos={key} createTask={createTask} deleteTaskModal={deleteTaskModal} haveToFocus={haveToFocus} setHaveToFocus={setHaveToFocus} updateTeam={updateTeam} searchUsers={searchUsers} updateUser={updateUser} timeToReset={timeToReset} setCurrentTask={setCurrentTask} markAsFinish={markAsFinish} updateTitle={updateTitle} item={item} currentTask={currentTask} key={key} searchTeams={searchTeams} setTriggerEvDesc={setTriggerEvDesc} triggerEvDesc={triggerEvDesc} />)
        }
    }
    listenEventEnter(true)
    if(activeishere){
        listenEventEnter(false)
    }
    var cfrendered = ''
    if (store.getState().customfields) {
        let listedC = store.getState().customfields.filter((item) => item.customfield_allowList)
        var percent = 100 / listedC.length
        cfrendered = listedC.map((item, key) => {
            let customorder = store.getState().customorder
            let ordenable = true
            if(customorder.length){
                let subresult = customorder.filter((subitem) => subitem.customorder_orderfor === 'task_cf_' + item.id)[0]
                if(subresult && subresult.forced){
                    ordenable = false
                }
            }
            if(item.customfield_allowList){
                return (
                    <div 
                        data-tip data-for={'cf_'+item.id}
                        key={key} 
                        style={{ width: percent + '%' }} 
                        className={'cfheader tasklist_headeritem sortable tacenter' + ((orderBy['task_cf_'+item.id] !== undefined) ? ' active' : '')}  onClick={() => ordenable ? updateOrderBy('task_cf_'+item.id) : ''}>
                        <div className='tasklist_headerelm_inner'>
                            {(orderBy['task_cf_'+item.id] !== undefined && orderBy['task_cf_'+item.id] === 'DESC') ? <i className='fal fa-chevron-up'></i> : (orderBy['task_cf_'+item.id] !== undefined && orderBy['task_cf_'+item.id] === 'ASC') ? <i className='fal fa-chevron-down'></i> : <i className='fal fa-chevron-down'></i>}
                            <span>{listedC.length > 2 || currentTask ? item.customfield_name.substr(0,1) : item.customfield_name}</span>
                        </div>
                        {listedC.length > 1
                            ? <ReactTooltip id={'cf_' + item.id}>
                            <span>{item.customfield_name}</span>
                        </ReactTooltip> : ''
                        }
                </div>
                )
            }
            return ''
        })
    }
    if (updateHeight) {
        window.setTimeout(() => {
            setUpdateHeight(false)
        }, 250)
    }
    return (
        <div className='box_inner'>
            <div className='box_header aistart box_header_tasklist'>
                {Object.keys(extraParams).includes('user_id') || Object.keys(extraParams).includes('team_id') || Object.keys(extraParams).includes('project_id') ? <div className='btn_primary addtask' onClick={createTask}><i className='fal fa-plus-circle'></i><span>{translations.addTask}</span></div> : ''}
                <div className='searchbar mlauto'>
                    <div id='page' className='hidden'>{page}</div>
                    <div id='offset' className='hidden'>{offset}</div>
                    <div id='extraParams' className='hidden'>{JSON.stringify(extraParams)}</div>
                    <div id='myids' className='hidden'>{ids.join(',')}</div>
                    <div id='orderBy' className='hidden'>{JSON.stringify(orderBy)}</div>
                    <div id='searchBy' className='hidden'>{JSON.stringify(searchbar)}</div>
                <SearchBar searchbar={searchbar} sendInfoTo={updateSearchQuery} />
                    <div className='additional_buttons jcend'>
                    {!extraParams.user_id ? <CustomSelect selectProps={{
                        value: window.localStorage.getItem('be_assigned') ? window.localStorage.getItem('be_assigned') : '1',
                        onChange: changeAssigned,
                        id: 'assigned_selector'
                    }} options={[
                        {
                            value: '0',
                            text: translations.notAssigned
                        },
                        {
                            value: '1',
                            text: translations.all
                        },
                        {
                            value: '2',
                            text: translations.myTasks + ' & ' + translations.notAssigned
                        }
                    ]} /> : ''}
                    <CustomSelect selectProps={{
                        value: window.localStorage.getItem('be_visible') ? window.localStorage.getItem('be_visible') : '1',
                        onChange: changeVisible,
                        id: 'changeVisible_selector'
                    }} 
                    options={[
                        {
                            value: '1',
                            text: translations.showed
                        },
                        {
                            value: '0',
                            text: translations.hidden
                        },
                        {
                            value: '2',
                            text: translations.showedAndHidden
                        },
                    ]}/>
                    <CustomSelect selectProps={{
                        value: window.localStorage.getItem('be_active') ? window.localStorage.getItem('be_active') : '1',
                        onChange: changeActive,
                        id: 'finished_selector'
                    }} 
                    options={[
                        {
                            value: '0',
                            text: translations.notFinisheds
                        },
                        {
                            value: '1',
                            text: translations.finisheds
                        },
                        {
                            value: '2',
                            text: translations.finishedsAndNot
                        },
                    ]}/>
                </div>
                </div>
            </div>
            <div className='taskslist_header aicenter wrap'>
                <div className='tasklist_headeritem fdiv2'></div>
                <div className={'tasklist_headeritem fdiv6 tacenter sortable' + ((orderBy['id'] !== undefined ? ' active' : ''))} onClick={() => updateOrderBy('id')}>
                    <div className='tasklist_headerelm_inner'>{(orderBy['id'] !== undefined && orderBy['id'] === 'DESC') ? <i className='fal fa-chevron-up'></i> : (orderBy['id'] !== undefined && orderBy['id'] === 'ASC') ? <i className='fal fa-chevron-down'></i> : <i className='fal fa-chevron-down'></i>}<span>Nº</span></div>
                </div>
                <div className={'tasklist_headeritem fdiv49 sortable' + ((orderBy['task_name'] !== undefined) ? ' active' : '')} onClick={() => updateOrderBy('task_name')}><div className='tasklist_headerelm_inner'>{(orderBy['task_name'] !== undefined && orderBy['task_name'] === 'DESC') ? <i className='fal fa-chevron-up'></i> : (orderBy['task_name'] !== undefined && orderBy['task_name'] === 'ASC') ? <i className='fal fa-chevron-down'></i> : <i className='fal fa-chevron-down'></i>}<span>{translations.title}</span></div></div>
                <div className={'tasklist_headeritem aicenter nopadding ' + (currentTask ? 'fdiv7-5' : 'fdiv15')}>{translations.customer}</div>
                <div className={'aicenter nopadding ' + (currentTask ? 'fdiv20-5' : 'fdiv19-5') }>{cfrendered}</div>
                <div className={'tasklist_headeritem tacenter ' + (currentTask ? 'fdiv7-5' : 'fdiv4') }><i className='fal fa-user'></i></div>
                <div className={'tasklist_headeritem tacenter ' + (currentTask ? 'fdiv7-5' : 'fdiv4')}><i className='fal fa-users'></i></div>
            </div>
            <div className='box_content nopadding'>
                {!reset && data !== undefined && data && data.length ? 
                    <AutoSizer>
                        {({ width, height }) => (
                        <List
                            className={'tasklist_list'}
                            height={height}
                            rowCount={data.length}
                            rowHeight={!updateHeight ? ({ index }) => {
                                let base = document.getElementById('main').offsetWidth
                                if (!currentTask) {
                                    base = base * 100 / 1665
                                } else {
                                    base = base * 54 / 1665
                                }
                                let mycalc = (Math.ceil(data[index].task_name.length / base) - 1) * 18 + 64
                                if (mycalc <= 64) {
                                    return 64    
                                } else {
                                    return mycalc
                                }
                            } : 64}
                            rowRenderer={({ index, style }) => <MyRow data={data[index]} index={index} style={style} key={index}
                            emitTaskData={emitTaskData}
                            haveToFocus={haveToFocus}
                            deleteTaskModal={deleteTaskModal}
                            setHaveToFocus={setHaveToFocus}
                            setCurrentTask={setCurrentTask}
                            markAsFinish={markAsFinish}
                            updateTitle={updateTitle}
                            currentTask={currentTask}
                            timeToReset={timeToReset}
                            updateUser={updateUser}
                            updateTeam={updateTeam}
                            searchTeams={searchTeams}
                            searchUsers={searchUsers}
                            setTriggerEvDesc={setTriggerEvDesc}
                            triggerEvDesc={triggerEvDesc}
                                />}
                            width={width}
                            />
                        )}
                    </AutoSizer>
                : ''}
            </div>
        </div>
    )
}
export const MyRow = ({ index, style, data, emitTaskData, haveToFocus, deleteTaskModal, setHaveToFocus, setCurrentTask, markAsFinish, updateTitle, currentTask, timeToReset, updateUser, updateTeam, searchTeams, searchUsers, setTriggerEvDesc, triggerEvDesc }) => {
    return (
        <div className='taskitem_parent' key={index} style={style}><TaskListItem item={data}
            emitTaskData={emitTaskData}
            haveToFocus={haveToFocus}
            deleteTaskModal={deleteTaskModal}
            setHaveToFocus={setHaveToFocus}
            setCurrentTask={setCurrentTask}
            markAsFinish={markAsFinish}
            updateTitle={updateTitle}
            currentTask={currentTask}
            timeToReset={timeToReset}
            updateUser={updateUser}
            updateTeam={updateTeam}
            searchTeams={searchTeams}
            searchUsers={searchUsers}
            setTriggerEvDesc={setTriggerEvDesc}
            triggerEvDesc={triggerEvDesc}
        /></div>
    )
}
export const TaskListItem = ({emitTaskData, pos, haveToFocus, deleteTaskModal, createTask, setHaveToFocus, setCurrentTask, markAsFinish, updateTitle, item, currentTask, timeToReset, updateUser, updateTeam, searchTeams, searchUsers, setTriggerEvDesc, triggerEvDesc}) => {
    const [value, setValue] = useState(item.task_name)
    const [reset, setReset] = useState(true)
    var translations = store.getState().language.translations
    if(reset && value !== item.task_name){
        setValue(item.task_name)
    }
    const updateTmpName = (e) => {
        let myval = e.currentTarget.value
        let myTriggerEvDesc = window.setTimeout(() => {
            updateTitle(false, item.id, item.task_name, myval)
        }, 3000)
        clearTimeout(triggerEvDesc)
        setTriggerEvDesc(myTriggerEvDesc)
        setReset(false)
        setValue(myval)
    }
    function listenEnter(key) {
        // if (key.keyCode === 13) {
        //     document.querySelector('.tasklist_item.active .name textarea').blur()
        //     createTask()
        // }
        if ((key.keyCode === 8 || key.keyCode === 46)) {
            let input = key.target
            let parent = input.parentNode.parentNode
            let id = parent.getAttribute('task_id')
            let parent_id = parent.getAttribute('parenttask') ? parent.getAttribute('parenttask') : false 
            let canremove = (input.getAttribute('canremove') === 'yes') ? true : false
            if (canremove) {
                listenEventEnter(true)
                let next = document.getElementsByName('task_name')
                if(next[0] !== undefined){
                    next[0].focus()
                }
                deleteTaskModal(id, parent_id, true)
            }
        }
    }
    const [eventEnter] = useState(() => listenEnter)
    const listenEventEnter = (remove = false) => {
        if (remove) {
            window.removeEventListener('keydown', eventEnter);
        } else {
            window.addEventListener('keydown', eventEnter)
        }
    }
    if(haveToFocus === item.id) {
        setHaveToFocus(false)
    }
    const assignCustomFieldToTask = async (task_id, customfield_id, customfieldval_id, parent_id) => {
        await fetch('/api/adminp/task/assignCustomFieldToTask', {
            method: 'POST',
            body: JSON.stringify({
                task_id,
                customfield_id,
                customfieldval_id
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        emitTaskData(task_id)
        emitTaskData(parent_id)
    }
    var cfrendered = ''
    if (store.getState().customfields) {
        let listedC = store.getState().customfields.filter((item) => item.customfield_allowList)
        var percent = 100 / listedC.length
        cfrendered = listedC.map((myitem, key) => {
            let curr = item.custom_fields[myitem.id]
            if(curr){
                curr = curr.CustomFieldValId 
            }
            if (myitem.customfield_allowList) {
            return <CustomFieldPicker selectedVal={curr} options={myitem.childrens} style={{ width: percent + '%' }} key={key} onSelect={(customfieldval_id) => assignCustomFieldToTask(item.id, myitem.id, customfieldval_id, item.TaskId)}        mini={listedC.length > 1 && currentTask ? true : false} uk={'cf_tb_' + myitem.id + item.id}/>
            }
            return ''
        })
    }
    return(
        <div className={'tasklist_item aicenter wrap' + ((item.id === currentTask) ? ' active' : '')} id={'taskl_' + item.id} onClick={() => setCurrentTask(item.id)} pos={pos} task_id={item.id} parenttask={item.TaskId ? item.TaskId : ''} key={'taskl_' + item.id}>
            <div className={'completed fdiv4 tacenter aicenter' + ((item.completed) ? ' active' : '')} onClick={() => markAsFinish(item.id, item.completed)}>{(item.completed) ? <i className="fal fa-check-circle"></i> : <i className="fal fa-check-circle"></i>}</div>
            <div className='idtask aicenter fdiv4'>#{item.id}</div>
            <div className='name fdiv49 wrap aicenter'>
                <TextareaAutosize inputRef={input => item.id === haveToFocus ? input && input.focus() : ''} canremove={(value === '') ? 'yes' : ''} type='text' className='noborders  nobg task_input have_to_resize' value={value}  onBlur={(e) => { listenEventEnter(true); setReset(true); updateTitle(e, item.id, item.task_name) }} placeholder={translations.title} onChange={updateTmpName} onFocus={() => listenEventEnter(false)} />
                {item.childs.length ? <i className='havesubtasks fal fa-layer-group'></i> : ''}
                <div className='fdiv100 aicenter'>
                    <div className='created_date'>
                        <i className='fal fa-calendar-plus'></i>
                        <span>{processData(item.createdAt)}</span>
                    </div>
                    {item.max_date ? <div className='max_date'>
                        <i className='fal fa-alarm-clock'></i>
                        <span>{processData(item.max_date)}</span>
                    </div> : ''}
                </div>

            </div>
            <div className={'customer aicenter ' + (currentTask ? 'fdiv7-5' : 'fdiv15')}>
                <span data-tip data-for={'customers_' + item.id} className={'customer_inner' + (currentTask ? ' mini' : '')}>{item.projects.length ? <i className='fas fa-scrubber' style={{ color: item.projects.length ? ('#' + item.projects[0].project_color) : '' }}></i> : ''}{item.projects.length ? currentTask ? item.projects[0].Customer.customer_name.substr(0, 1) : item.projects[0].Customer.customer_name : '---'}</span>
                {item.projects.length ? <ReactTooltip id={'customers_' + item.id}>
                    {item.projects.map((subcustomer, key) => {
                        return <div key={key}>{subcustomer.Customer.customer_name} &gt; {subcustomer.project_name}</div>
                    })}
                </ReactTooltip> : ''}
            </div>
            <div className={'customfields aicenter ' + (currentTask ? 'fdiv20-5' : 'fdiv19-5')}>{cfrendered}</div>
            <div data-tip data-for={'user_' + item.id} className={'user  tacenter ' + (currentTask ? 'fdiv7-5' : 'fdiv4')}><PhotoInputSearcher onlyImg={true} timeToReset={timeToReset} cleanInput={() => updateUser(0, item.UserId, item.id)} fieldtoshow='username' pathImg='/userImg/' updateData={(user_id) => updateUser(user_id, item.UserId, item.id)} getDataFrom={searchUsers} data={{ image: (item.user && Object.keys(item.user).length) ? '/userImg/' + item.user.image : undefined, text: (item.user && Object.keys(item.user).length) ? item.user.username : undefined }} icon='fal fa-user' />
                {item.user ? <ReactTooltip id={'user_' + item.id}>
                    <span>{item.user.username}</span>
                </ReactTooltip> : ''}
            </div>
            <div data-tip data-for={'team_' + item.id} className={'team tacenter ' + (currentTask ? 'fdiv7-5' : 'fdiv4')}><PhotoInputSearcher onlyImg={true} timeToReset={timeToReset} cleanInput={() => updateTeam(0, item.TeamId, item.id)} fieldtoshow='name' pathImg='/teamImg/' updateData={(team_id) => updateTeam(team_id, item.TeamId, item.id)} getDataFrom={searchTeams} data={{ image: (item.team && Object.keys(item.team).length) ? '/teamImg/' + item.team.image : undefined, text: (item.team && Object.keys(item.team).length) ? item.team.name : undefined }} icon='fal fa-users' />
                {item.team ? <ReactTooltip id={'team_' + item.id}>
                       <span>{item.team.name}</span>
                </ReactTooltip> : ''}
            </div>
        </div>
    )
}
export const Subtask = ({ haveToFocus, setHaveToFocus, addSubTask, deleteTaskModal, subtask, markAsFinish, updateTitle, setCurrentTask, timeToReset, updateUser, searchUsers, searchTeams, updateTeam, triggerEvDesc, setTriggerEvDesc}) => {
    var translations = store.getState().language.translations
    const [value, setValue] = useState(subtask.task_name)
    const [reset, setReset] = useState(true)
    const updateTmpName = (e) => {
        let myval = e.currentTarget.value
        let myTriggerEvDesc = window.setTimeout(() => {
            updateTitle(false, subtask.id, subtask.task_name, myval)
        }, 3000)
        clearTimeout(triggerEvDesc)
        setTriggerEvDesc(myTriggerEvDesc)
        setReset(false)
        setValue(myval)
    }
    function listenEnter(key){
        if (key.keyCode === 13) {
            key.preventDefault()
            addSubTask()
        }
        if((key.keyCode === 8 || key.keyCode === 46)){
                let input = key.target
                let canremove = (input.getAttribute('canremove') === 'yes') ? true : false
                if(canremove){
                    listenEventEnter(true)
                    deleteTaskModal(subtask.id, subtask.TaskId)
                }
        }
    }
    const [eventEnter] = useState(() => listenEnter)
    const listenEventEnter = (remove = false) => {
        if(remove){
            window.removeEventListener('keydown', eventEnter);
        }else{
            window.addEventListener('keydown', eventEnter)
        }
    }
    if (reset && value !== subtask.task_name) {
        setValue(subtask.task_name)
    }
    if (haveToFocus === subtask.id){
        setHaveToFocus(false)
    }
    return (
    <div className='subtask_item aicenter'>
        <div className={'completed fdiv5 tacenter aicenter' + ((subtask.completed) ? ' active' : '')} onClick={() => markAsFinish(subtask.id, subtask.completed)}>{(subtask.completed) ? <i className="fal fa-check-circle"></i> : <i className="fal fa-check-circle"></i>}</div>
            <div className='name fdiv80 aicenter'><span className='idtask'>#{subtask.id}</span><TextareaAutosize type='text' inputRef={input => subtask.id === haveToFocus ? input && input.focus() : ''} canremove={(value === '') ? 'yes' : ''} className='nobg have_to_resize' value={value} onBlur={(e) => { listenEventEnter(true); setReset(true); updateTitle(e, subtask.id, subtask.task_name) }} onFocus={() => listenEventEnter(false)} onChange={updateTmpName} placeholder={translations.title} />{subtask.childs.length ? <i className='havesubtasks mrauto fal fa-layer-group'></i> : ''}</div>
            <div className='user fdiv7-5 tacenter'>
                <PhotoInputSearcher onlyImg={true} timeToReset={timeToReset} cleanInput={() => updateUser(0, subtask.UserId, subtask.id)} fieldtoshow='username' pathImg='/userImg/' updateData={(user_id) => updateUser(user_id, subtask.UserId, subtask.id)} getDataFrom={searchUsers} data={{ image: (subtask.user && Object.keys(subtask.user).length) ? '/userImg/' + subtask.user.image : undefined, text: (subtask.user && Object.keys(subtask.user).length) ? subtask.user.username : undefined }} icon='fal fa-user' />
            </div>
            <div className='team fdiv7-5 tacenter'>
                <PhotoInputSearcher onlyImg={true} timeToReset={timeToReset} cleanInput={() => updateTeam(0, subtask.TeamId, subtask.id)} fieldtoshow='name' pathImg='/teamImg/' updateData={(team_id) => updateTeam(team_id, subtask.TeamId, subtask.id)} getDataFrom={searchTeams} data={{ image: (subtask.team && Object.keys(subtask.team).length) ? '/teamImg/' + subtask.team.image : undefined, text: (subtask.team && Object.keys(subtask.team).length) ? subtask.team.name : undefined }} icon='fal fa-users' />
            </div>
        <div className='team fdiv5 tacenter crpointer' onClick={() => setCurrentTask(subtask.id)}><i className="fas fa-chevron-right"></i></div>
    </div>
    )
}
export const TaskFinder = ({onchoose}) => {
    const [value, setValue] = useState('')
    const [tasks, setTasks] = useState([])
    var translations = store.getState().language.translations
    const mySearchTask = async (e) => {
        let myvalue = e.target.value
        setValue(myvalue)
        let mytasks = await searchTasks(myvalue)
        setTasks(mytasks)
    }
    var tasksFormated = ''
    if(tasks.length){
        tasksFormated = tasks.map((item, key) => {
            return <div key={key} onClick={() => {onchoose(item.id); setValue('')}} ><span className='idtask'>#{item.id}</span> - <span className='task_name'>{item.task_name}</span></div>
        })
    }
    return (
    <div className='task_finder inputsearch'>
        <input value={value} placeholder={translations.searchTask} onChange={mySearchTask}/>
        <div className='searchresult'>{tasksFormated}</div>
    </div>
    )
}
export const ProjectSearcher = ({onchoose, currentProjects = [], helper = false}) => {
    const [value, setValue] = useState('')
    const [results, setResults] = useState([])
    const [customer, setCustomer] = useState(false)
    var translations = store.getState().language.translations
    const mySearchCustomers = async (e) => {
        let myvalue = e.target.value
        setValue(myvalue)
        let myresults = await searchCustomers(myvalue)
        setResults(myresults)
    }
    const mySearchProjects = async (e, mycustomer= customer) => {
        let myvalue = e.target.value
        setValue(myvalue)
        let myresults = await searchProjects(myvalue, mycustomer.id, currentProjects)
        setResults(myresults)
    }
    if(!customer){
        let myresults = ''
        if(results){
            myresults = results.map((item, key) => {
                return <div key={key} onClick={() =>{ setCustomer(item);mySearchProjects({target: {value: ''}}, item) }}>{item.customer_name}</div>
            })
        }
        return (
            <div className='project_finder inputsearch'>
                <input value={value} placeholder={translations.searchCustomer} onChange={mySearchCustomers} className='notInclude' />
                <div className='searchresult'>
                    {myresults}
                </div>
            </div>
        )
    }else{
        let myresults = ''
        if (results) {
            myresults = results.map((item, key) => {
                return <div key={key} onClick={(e) => { helper ? onchoose(item.id, item, customer) : onchoose(item.id);helper ? e.currentTarget.parentNode.parentNode.parentNode.click() : document.querySelector('.content').click(); setResults([]); setCustomer(false)}}>{item.project_name}</div>
            })
        }
        return (
            <div className='project_finder inputsearch'>
                <div className='project_finder_inner aicenter'>
                    <div className='project_customer_name aicenter' onClick={() => {setCustomer(false);setValue('');setResults([])}}>
                        <div>{customer.customer_name}</div>
                        <i className='fal fa-chevron-right'></i>
                    </div>
                    <input value={value} placeholder={translations.searchProject} onChange={mySearchProjects} className='notInclude' />
                </div>
                <div className='searchresult'>
                    {myresults}
                </div>
            </div>
        )
    }
}