import React , {useState} from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import MenuItem from '../minicomponents/MenuItem'
import Separator from '../minicomponents/Separator'
import store from '../reducers/reducers';
import { modifyTeam } from './administration/AdminTeams';
const equals = require('array-equal')
const mapStateToProps = state => ({ page: state.page, user: state.user })
const Menu = ({setModal, notification, setNotification, opened}) => {
    var translations = store.getState().language.translations
    const [menu, setMenu] = useState([])
    const [currentName, setCurrentName] = useState(false)
    const [rememberedTeams, setRememberedTeams] = useState(false)
    const formatTeams = async () => {
        var teams = await store.getState().user.teams
        teams = teams.map((item) => {
            let editable = false
            if(item.UserTeam.isAdmin){
                editable = (e) => modifyTeam(e, translations, notification, setNotification, setModal) 
            } else {
                editable = (e) => modifyTeam(e, translations, notification, setNotification, setModal, false) 
            }
            let team =  {
                idrow: item.id,
                name: 'team',
                subname: 'team'+item.id,
                text: item.name,
                image: '/teamImg/'+item.image,
                goTo: '/team/'+item.id,
                editable
            }
            if(store.getState().page.subname !== undefined && store.getState().page.subname.name === team.subname){
                team.active = true
            }
            return team
        })
        return teams
    }
    const updateMenu = async() => {
        let currmenu = [
            {
                name: 'dashboard',
                text: translations.dashboard,
                goTo: '/',
                icon: 'far fa-tachometer-alt-fastest'
            },
            {
                name: 'user_tasks',
                text: translations.myTasks,
                goTo: '/user/' + store.getState().user.id,
                icon: 'far fa-list-ul'
            },
            {
                name: 'user_calendar',
                text: translations.myCalendar,
                goTo: '/calendar/' + store.getState().user.id,
                icon: 'far fa-calendar-alt'
            },
            {
                name: 'customers',
                text: translations.customers,
                goTo: '/customers',
                icon: 'far fa-handshake'
            },
            {
                name: 'teams',
                text: translations.teams,
                icon: 'fas fa-users',
                goTo: '/teams/',
                sublinks: await formatTeams()
            },
            {
                name: 'users',
                text: translations.users,
                icon: 'fas fa-user',
                goTo: '/users/',
            },
            {
                name: 'repository',
                text: translations.repository,
                icon: 'fas fa-books',
                goTo: '/repository',
            },
        ]
        if(!equals(currmenu, menu)){
            setMenu(currmenu)
        }
    }
    if(store.getState().page.subname){
        if (currentName !== store.getState().page.subname.name) {
            updateMenu()
            setCurrentName(store.getState().page.subname.name)
        }
    }else{
        if(currentName !== store.getState().page.name){
            updateMenu()
            setCurrentName(store.getState().page.name)
        }
    }
    if (!rememberedTeams || !equals(JSON.stringify(rememberedTeams), JSON.stringify(store.getState().user.teams))){
        setRememberedTeams(store.getState().user.teams)
        updateMenu()
    }
    var menuFormated = menu.map((item, key) => {
        let hasoneactive = false
        if(store.getState().page.name && (store.getState().page.name.split(" ")).includes(item.name)){
            item.active = true
            hasoneactive = true
        }else if(item.sublinks){
            for(let k in item.sublinks){
                let sublink = item.sublinks[k]
                if (store.getState().page.subname !== undefined && store.getState().page.subname.name === sublink.subname) {
                    sublink.active = true
                    hasoneactive = true
                }
            }
        }
        return <MenuItem item={item} key={key} hasoneactive={hasoneactive}/>
    })
    var extralinks = [
        {
            name: 'settings',
            text: translations.settings,
            goTo: '/settings',
            icon: 'fas fa-cog'
        },
        {
            name: 'updatelog',
            text: translations.updatelog,
            goTo: '/updates',
            icon: 'fas fa-stream'
        }
    ]
    if(store.getState().user.admin_permissions === 1){
        extralinks.push({
            name: 'admin',
            text: translations.adminPanel,
            goTo: '/admin',
            icon: 'fas fa-user-cog',
            sublinks: [
                {
                    name: 'adminusers',
                    text: translations.users,
                    goTo: '/admin/users',
                    icon: 'fas fa-users-cog',
                },
                {
                    name: 'adminteams',
                    text: translations.teams,
                    goTo: '/admin/teams',
                    icon: 'fas fa-users-crown',
                },
                {
                    name: 'adminproducts',
                    text: translations.products,
                    goTo: '/admin/products',
                    icon: 'far fa-sitemap'
                },
                {
                    name: 'admincustomfields',
                    text: translations.customFields,
                    goTo: '/admin/customfields',
                    icon: 'fas fa-tags'
                },
                {
                    name: 'adminbagofhours',
                    text: translations.bagOfHours,
                    goTo: '/admin/bagofhours',
                    icon: 'fas fa-stopwatch'
                },
                {
                    name: 'adminnotifications',
                    text: translations.companyNotifications,
                    goTo: '/admin/notifications',
                    icon: 'fas fa-comment-exclamation'
                },
                {
                    name: 'adminstats',
                    text: translations.stats,
                    goTo: '/admin/stats',
                    icon: 'fas fa-chart-pie-alt'
                }
            ]
        })
    }
    var extraLinksFormated = extralinks.map((item, key) => {
        var hasoneactive = false
        item.extralink = true
        if (store.getState().page.name && (store.getState().page.name.split(" ")).includes(item.name)) {
            item.active = true
            hasoneactive = true
        }
        if(item.sublinks && store.getState().page.name){
            item.sublinks.map((sublink) => {
                if((store.getState().page.name.split(" ")).includes(sublink.name)){
                    sublink.active = true
                    hasoneactive = true
                }
                return sublink
            })
        }
        return <MenuItem item={item} key={key} hasoneactive={hasoneactive}/>
    })
    return ( 
    <div id='menu'>
        <Link to='/'><img src={opened ? '/logo.svg' : '/logo_block.svg'} alt='' /></Link>
        {menuFormated}
        <Separator />
        {extraLinksFormated}
    </div> 
    );
}
 
export default connect(mapStateToProps)(Menu);